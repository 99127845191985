import React from "react";

import Footer from "../../../components/Footer";
//import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
import LeftCntr from "../../../components/Profile/LeftCntr";
import HeaderUser from "../../../components/HeaderUser";

const WriteUs = () => {
 
 
  return (
    <>
      <HeaderUser />

      <div id="profileCntr">
        <div class="container"> 
        {/* <!--  / Profile  container \ --> */}
        
        <div class="row">
            <div class="col-sm-12 row-flex"> 

            {/* <!--  / Left container Start here \ --> */}
            <LeftCntr/>
            {/* <!--  / Left container ends here \ --> */}
            
            
            {/* <!--  / Right container Start here \ --> */}
            <div class="rightCntr prof-section" id="writeus">
            <h2 class="main_title">Write To Us</h2>

            <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
              <a underline="hover" color="inherit" href="/profile">
                Profile
              </a>
              <Typography color="text.primary"> Write To Us</Typography>
            </Breadcrumbs>
            
            {/* <!--  / Feedback Start here \ --> */}
            <div class="formBox">
              <div id="personal_infoform">
                <form id="frmFeedback" name="frmFeedback" novalidate>
                  <div class="row">
                    <div class="col-sm-7">
                      <div class="row form-group">
                        <div class="col-sm-12">
                          <label class="label">Please select your feedback category below <sup class="star"> *</sup></label>
                          <div class="select_dropdown">
                            <select class="Select Country">
                              <option selected="selected" value="">Select Category</option>
                              <option value="Cancellation">Cancellation</option>
                              <option value="Refund">Refund</option>
                              <option value="Chargeback">Chargeback</option>
                              <option value="Customer service">Customer service</option>
                              <option value="Chargeback">Chargeback</option>
                              <option value="Technical Issue">Technical Issue</option>
                              <option value="Feedback">Feedback</option>
                              <option value="Other">Other</option>
                            </select>
                           </div>
                        </div>
                      </div>
                      
                      <div class="row form-group experience" style={{display:"none"}}>
                        <div class="col-sm-12 inputSet">
                          <label class="label" style={{PaddingLeft:"0px"}}>Overall Experience</label>
                          <label>
                            <input name="Experience" type="radio" checked="checked" value="Excellent"/>
                            <span>Excellent</span> </label>
                          <label>
                            <input name="Experience" type="radio" value="Great"/>
                            <span>Great</span> </label>
                          <label>
                            <input name="Experience" type="radio" value="Average"/>
                            <span>Average</span> </label>
                          <label>
                            <input name="Experience" type="radio" value="Poor"/>
                            <span>Poor</span> </label>
                        </div>
                      </div>
                      
                      <div class="row form-group">
                        <div class="col-sm-12">
                          <label class="label">Is there anything you would like to tell us?<sup class="star"> *</sup></label>
                          <textarea id="Feedback" name="Feedback" rows="4" cols="4" class="textbox"></textarea>
                         </div>
                      </div>
                      <button class="button mt_mob" type="button">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!--  \ Feedback End here / -->  */}
            
          </div>
            {/* <!-- End My Information--> */}
            
            </div>
        </div>
        </div>
    
    
    </div>
    {/* <!--  / Profile  container \ -->  */}

    <Footer />
    </>
  );
};

export default WriteUs;
