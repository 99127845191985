
const Commission= ({Comm}) =>{
 if(Comm===undefined){return false;}
  if(Comm?.length===0 || Comm[0]===undefined)
{
  return false;
}
  return (
<>
<span>Commission ${Comm[0]?.ACommAmt}</span>
    </>
    );

}
export default Commission;