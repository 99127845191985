import React, {useState,useEffect} from "react";
//import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
import LeftCntr from "../../../components/Profile/LeftCntr";
import HeaderUser from "../../../components/HeaderUser";
import {getStorageData,apiBaseUrl} from '../../../global';
const MyInformation = () => {
 
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [isContactShow, setIsContactShow] = useState(false);
  

  const toggleInfoVisibility = () => {
    setIsInfoVisible(!isInfoVisible);
  };

  const toggleContactShow = () => {
    setIsContactShow(!isContactShow);
  };
  var initial={profile_img:"",name:"",dob:"",mname:"",gender:"",email:"",title:"",fname:"",lname:"",mobile:"",agency:"",country:"",state:"",city:"",zipcode:"",address:"",website:"",comment:"",consortium:"",IATA:"",CLIA:"",ASTA:"",reservation:"",roleId:"",ownerId:"",businessLicense:"",einTax:"",website_id:"",parent_id:"",cId:"",company_name:"",last_login:""};
  const [profileData, setProfileData] = useState(initial);
  const [msg, setmsg] = useState('');

  useEffect(() => {

  // console.log(getStorageData('userSession'));
   //debugger;
    let searchrq={website: "1",userId:getStorageData('userSession')?.id};
 var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Accept", "application/json");
  var sestting = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(searchrq),
    redirect: 'follow'
  };
  const url = apiBaseUrl + '/api/getProfile';
  fetch(url, sestting)
    // fetch('response.json', requestOptions)
      .then(response => response.json())
      .then(result => {
      setProfileData(result.profile);
      }).catch(error => console.log(error));
//console.log(validateRq);
//debugger;
//console.log(website);
},[]);
//console.log(profileData)


const handleForm1 = (event) => {
  const name = event.target.name;
    const value = event.target.value;
    setProfileData(values => ({...values, [name]: value}))
}
const handleProfile=(e)=>{
 // setprofileImg(e.target.files[0]);
  const name = e.target.name;
   const value = e.target.files[0];
  // //console.log()
   setProfileData(values => ({...values, [name]: value}))
}


const handleSubmit = (event) => {
  event.preventDefault();
 


    var myHeaders = new Headers();
    var postdata={...profileData};
    //console.log(profileData);
    let formData = new FormData();
    formData.append('id', postdata.id);
    formData.append('fname', postdata.fname);   
    formData.append('lname', postdata.lname);  
    formData.append('mname', postdata.mname);   
    formData.append('title', postdata.title);  
    formData.append('gender', postdata.gender);   
    formData.append('dob', postdata.dob); 
    formData.append('profile_img', postdata.profile_img);  
    //formData.append('profile_img', profileImg);   
     



    // myHeaders.append("Content-Type", "application/json");
    //   myHeaders.append("Accept", "application/json");
     // profileData.userId=getStorageData('userSession')?.id;
    const url = apiBaseUrl + '/api/updateAdmin';
    var requestOptions = {
      method: 'POST',
        headers: myHeaders,
        body: formData,
    //  body: JSON.stringify({...profileData}),
      redirect: 'follow'
    };
    fetch(url, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if(result.status==='OK'){
        setmsg(result.message);
      }else{
        setmsg('Oops something went wrong please try again!');
      }
      
     // console.log('ram');
      console.log(result);

    }
    ); 

    


}




const handleForm2 = (event) => {
  const name = event.target.name;
    const value = event.target.value;
    setProfileData(values => ({...values, [name]: value}))
}

const handleSubmit1 = (event) => {
  event.preventDefault();

  
  var myHeaders = new Headers();
  var postdata={...profileData};
  //console.log(profileData);
  let formData = new FormData();
  formData.append('id', postdata.id);
  formData.append('zipcode', postdata.zipcode);  
  formData.append('address', postdata.address);   
  formData.append('country', postdata.country);  
  formData.append('state', postdata.state);   
  formData.append('mobile', postdata.mobile);
  formData.append('city', postdata.city);  

  const url = apiBaseUrl + '/api/updateAdmincontact';
  var requestOptions = {
    method: 'POST',
      headers: myHeaders,
      body: formData,
    redirect: 'follow'
  };
  fetch(url, requestOptions)
  .then((response) => response.json())
  .then((result) => {
    if(result.status==='OK'){
      setmsg(result.message);
    }else{
      setmsg('Oops something went wrong please try again!');
    }

  }
  ); 

 

}



  return (
    <>
      <HeaderUser />

      <div id="profileCntr">
    <div class="container"> 
      {/* <!--  / Profile  container \ --> */}
      
      <div class="row">
        <div class="col-sm-12 row-flex"> 

          {/* <!--  / Left container Start here \ --> */}
          <LeftCntr/>
          {/* <!--  / Left container ends here \ --> */}
          
          
           {/* <!--  / Right container Start here \ --> */}
          <div class="rightCntr prof-section" id="myinformation" >
            <div id="myinfoTab"> 
              
              {/* <!--  / tabBox Start here \ --> */}
              <h2 className="main_title">My Details</h2>
              {/* <!--  \ tabBox End here / -->  */}

              <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
                <a underline="hover" color="inherit" href="/profile">
                  Profile
                </a>
                <Typography color="text.primary">My Information</Typography>
              </Breadcrumbs>

              {/* <!-- My detail tab Start here --> */}
              <div class="tabContent" id="tab1" style={{display: 'block'}}> 

                {/* <!--  / Personal Information Start here \ --> */}
                
                <div class="formBox">
                <form onSubmit={handleSubmit} method="post" >
                  <div class="row">
                    <div class="col-sm-12 title">
                      <h2>Personal Information</h2>
                      <Link class="edit" onClick={toggleInfoVisibility} to=""> Edit</Link> </div>
                  </div>
                  
                  <div id="personal_infoform" style={{ display: isInfoVisible ? 'block' : 'none' }}>
                   
                      <div style={{display: 'block'}} class="alert alert-danger msgdiv">{msg}</div>
                     <div class="row form-group">
                        <div class="col-sm-6">
                          <label class="lable">First Name<sup class="star">*</sup></label>
                          <input class="textbox textOnly"  onChange={(e)=>handleForm1(e)} data-val="true" maxlength="50" name="fname" type="text" value={profileData?.fname}/>
                         </div>
                        <div class="col-sm-6">
                          <label class="lable">Middle Name</label>
                          <input class="textbox textOnly"  onChange={(e)=>handleForm1(e)} name="mname" value={profileData?.mname} type="text"  />
                         </div>
                      </div>
                      <div class="row form-group">
                        <div class="col-sm-6">
                          <label class="lable">Last Name<sup class="star">*</sup></label>
                          <input class="textbox textOnly"  onChange={(e)=>handleForm1(e)}  name="lname" type="text" value={profileData?.lname}/>
                        </div>
                        <div class="col-sm-6">
                          <label class="lable">Date Of Birth<sup class="star">*</sup></label>
                          <input class="textbox"  onChange={(e)=>handleForm1(e)}  value={profileData?.dob} format="dd MMM yyyy" maxlength="50" name="dob" type="text"/>
                         </div>
                      </div>
                      <div class="row form-group">
                        <div class="col-sm-6">
                          <label class="lable">Gender<sup class="star">*</sup></label>
                          <div class="select_dropdown">
                            <select onChange={(e)=>handleForm1(e)} value={profileData?.gender}   class="form-control" data-val="true" name="gender">
                              <option value="">Gender</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                            </div>
                        </div>
                        
                      </div>
                      
                      <div class="row form-group">
                        <div class="col-sm-6">
                        <label class="lable">Profile Image</label>
                        <div class="input-group">
                            <input name="profile_img" onChange={(e)=>handleProfile(e)}  type="file" class="form-control" ></input><button class="btn btn-outline-secondary uploadbtn" type="button">Upload</button>
                            
                          </div>
                          <span id="tsa"><img width={40} height={50} src={apiBaseUrl+'/public/admin_assets/adImages/'+profileData?.profile_img} /> </span>
                          </div>
                          </div>
                      <button class="button float-end mt_mob" type="submit" >Save</button>
                   
                    <button class="button float-end grayBtn mt_mob" type="button">cancel</button>
                  </div>
                  
                  {/* <!-- Personal information start --> */}
                  <div id="personal_infoDetail" style={{ display: isInfoVisible ? 'none' : 'block' }}>
                  
                    <p><strong id="user_name"> {profileData?.fname?.toUpperCase()} {profileData?.lname?.toUpperCase()}  </strong></p>
                    <ul class="content_detail">
                      <li><span class="label">Gender : </span>
                        <span>{profileData?.gender}</span> 
                      </li>
                      <li><span class="label">Date Of Birth :</span> <span>{profileData?.dob}</span></li>
                      
                      <li><span class="label">Profile Image : </span> <span id="tsa"><img width={40} height={50} src={apiBaseUrl+'/public/admin_assets/adImages/'+profileData?.profile_img} /> </span></li>
                    </ul>
                  </div>
                  </form>
                  {/* <!-- Personal information end -->  */}
                </div>
                
                {/* <!--  \ Personal Information End here / -->  */}

                {/* <!--  / Contact Information Start here \ --> */}
                <form onSubmit={handleSubmit1} method="post" >
                <div class="formBox">
                  <div class="row">
                    <div class="col-sm-12 title">
                      <h2>Contact Information</h2>
                      <Link class="edit contact_infoform" onClick={toggleContactShow} to=""> Edit</Link> </div>
                      
                  </div>
                  
                  {/* <!-- Edit Mode For Contact information --> */}
                  <div id="contact_infoform" style={{ display: isContactShow ? 'block' : 'none' }}>
                  <div style={{display: 'block'}} class="alert alert-danger msgdiv">{msg}</div>
                      <div class="row form-group">
                        <div class="col-sm-6">
                          <label class="lable">Address Line 1<sup class="star">*</sup></label>
                          <input class="textbox" onChange={(e)=>handleForm2(e)} name="address" type="text" value={profileData?.address}/>
                         </div>
                         <div class="col-sm-6">
                          <label class="lable">State</label>
                          <input class="textbox" onChange={(e)=>handleForm2(e)}  maxlength="50" name="state" type="text" value={profileData?.state}/>
                           </div>
                        
                      </div>
                      <div class="row form-group">
                        <div class="col-sm-6">
                          <label class="lable">City<sup class="star">*</sup></label>
                          <input class="textbox" name="city" onChange={(e)=>handleForm2(e)}  type="text" value={profileData?.city}/>
                         </div>
                        <div class="col-sm-6">
                          <label class="lable">Country<sup class="star">*</sup></label>
                          <div class="select_dropdown">
                            <select class="Select Country" onChange={(e)=>handleForm2(e)} value={profileData?.country} name="country">
                            <option value="US">United States of America</option>
     <option value="AF">Afghanistan</option>
     <option value="AL">Albania</option>
     <option value="DZ">Algeria</option>
     <option value="AS">American Samoa</option>
     <option value="AD">Andorra</option>
     <option value="AO">Angola</option>
     <option value="AI">Anguilla</option>
     <option value="AQ">Antarctica</option>
     <option value="AG">Antigua and Barbuda</option>
     <option value="AR">Argentina</option>
     <option value="AM">Armenia</option>
     <option value="AW">Aruba</option>
     <option value="AU">Australia</option>
     <option value="AT">Austria</option>
     <option value="AZ">Azerbaijan</option>
     <option value="BS">Bahamas</option>
     <option value="BH">Bahrain</option>
     <option value="BD">Bangladesh</option>
     <option value="BB">Barbados</option>
     <option value="BY">Belarus</option>
     <option value="BE">Belgium</option>
     <option value="BZ">Belize</option>
     <option value="BJ">Benin</option>
     <option value="BM">Bermuda</option>
     <option value="BT">Bhutan</option>
     <option value="BO">Bolivia (Plurinational State of)</option>
     <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
     <option value="BA">Bosnia and Herzegovina</option>
     <option value="BW">Botswana</option>
     <option value="BV">Bouvet Island</option>
     <option value="BR">Brazil</option>
     <option value="IO">British Indian Ocean Territory</option>
     <option value="BN">Brunei Darussalam</option>
     <option value="BG">Bulgaria</option>
     <option value="BF">Burkina Faso</option>
     <option value="BI">Burundi</option>
     <option value="CV">Cabo Verde</option>
     <option value="KH">Cambodia</option>
     <option value="CM">Cameroon</option>
     <option value="CA">Canada</option>
     <option value="KY">Cayman Islands (the)</option>
     <option value="CF">Central African Republic (the)</option>
     <option value="TD">Chad</option>
     <option value="CL">Chile</option>
     <option value="CN">China</option>
     <option value="CX">Christmas Island</option>
     <option value="CC">Cocos (Keeling) Islands</option>
     <option value="CO">Colombia</option>
     <option value="KM">Comoros</option>
     <option value="CD">Congo (the Democratic Republic of the)</option>
     <option value="CG">Congo</option>
     <option value="CK">Cook Islands</option>
     <option value="CR">Costa Rica</option>
     <option value="HR">Croatia</option>
     <option value="CU">Cuba</option>
     <option value="CW">Cura?ao</option>
     <option value="CY">Cyprus</option>
     <option value="CZ">Czechia</option>
     <option value="CI">C?te d'Ivoire</option>
     <option value="DK">Denmark</option>
     <option value="DJ">Djibouti</option>
     <option value="DM">Dominica</option>
     <option value="DO">Dominican Republic (the)</option>
     <option value="EC">Ecuador</option>
     <option value="EG">Egypt</option>
     <option value="SV">El Salvador</option>
     <option value="GQ">Equatorial Guinea</option>
     <option value="ER">Eritrea</option>
     <option value="EE">Estonia</option>
     <option value="SZ">Eswatini</option>
     <option value="ET">Ethiopia</option>
     <option value="FK">Falkland Islands[Malvinas]</option>
     <option value="FO">Faroe Islands</option>
     <option value="FJ">Fiji</option>
     <option value="FI">Finland</option>
     <option value="FR">France</option>
     <option value="GF">French Guiana</option>
     <option value="PF">French Polynesia</option>
     <option value="TF">French Southern Territories (the)</option>
     <option value="GA">Gabon</option>
     <option value="GM">Gambia</option>
     <option value="GE">Georgia</option>
     <option value="DE">Germany</option>
     <option value="GH">Ghana</option>
     <option value="GI">Gibraltar</option>
     <option value="GR">Greece</option>
     <option value="GL">Greenland</option>
     <option value="GD">Grenada</option>
     <option value="GP">Guadeloupe</option>
     <option value="GU">Guam</option>
     <option value="GT">Guatemala</option>
     <option value="GG">Guernsey</option>
     <option value="GN">Guinea</option>
     <option value="GW">Guinea-Bissau</option>
     <option value="GY">Guyana</option>
     <option value="HT">Haiti</option>
     <option value="HM">Heard Island and McDonald Islands</option>
     <option value="VA">Holy See</option>
     <option value="HN">Honduras</option>
     <option value="HK">Hong Kong</option>
     <option value="HU">Hungary</option>
     <option value="IS">Iceland</option>
     <option value="IN">India</option>
     <option value="ID">Indonesia</option>
     <option value="IR">Iran (Islamic Republic of)</option>
     <option value="IQ">Iraq</option>
     <option value="IE">Ireland</option>
     <option value="IM">Isle of Man</option>
     <option value="IL">Israel</option>
     <option value="IT">Italy</option>
     <option value="JM">Jamaica</option>
     <option value="JP">Japan</option>
     <option value="JE">Jersey</option>
     <option value="JO">Jordan</option>
     <option value="KZ">Kazakhstan</option>
     <option value="KE">Kenya</option>
     <option value="KI">Kiribati</option>
     <option value="KP">Korea (the Democratic People's Republic of)</option>
     <option value="KR">Korea (the Republic of)</option>
     <option value="KW">Kuwait</option>
     <option value="KG">Kyrgyzstan</option>
     <option value="LA">Lao People's Democratic Republic</option>
     <option value="LV">Latvia</option>
     <option value="LB">Lebanon</option>
     <option value="LS">Lesotho</option>
     <option value="LR">Liberia</option>
     <option value="LY">Libya</option>
     <option value="LI">Liechtenstein</option>
     <option value="LT">Lithuania</option>
     <option value="LU">Luxembourg</option>
     <option value="MO">Macao</option>
     <option value="MG">Madagascar</option>
     <option value="MW">Malawi</option>
     <option value="MY">Malaysia</option>
     <option value="MV">Maldives</option>
     <option value="ML">Mali</option>
     <option value="MT">Malta</option>
     <option value="MH">Marshall Islands</option>
     <option value="MQ">Martinique</option>
     <option value="MR">Mauritania</option>
     <option value="MU">Mauritius</option>
     <option value="YT">Mayotte</option>
     <option value="MX">Mexico</option>
     <option value="FM">Micronesia (Federated States of)</option>
     <option value="MD">Moldova (the Republic of)</option>
     <option value="MC">Monaco</option>
     <option value="MN">Mongolia</option>
     <option value="ME">Montenegro</option>
     <option value="MS">Montserrat</option>
     <option value="MA">Morocco</option>
     <option value="MZ">Mozambique</option>
     <option value="MM">Myanmar</option>
     <option value="NA">Namibia</option>
     <option value="NR">Nauru</option>
     <option value="NP">Nepal</option>
     <option value="NL">Netherlands</option>
     <option value="NC">New Caledonia</option>
     <option value="NZ">New Zealand</option>
     <option value="NI">Nicaragua</option>
     <option value="NE">Niger</option>
     <option value="NG">Nigeria</option>
     <option value="NU">Niue</option>
     <option value="NF">Norfolk Island</option>
     <option value="MP">Northern Mariana Islands</option>
     <option value="NO">Norway</option>
     <option value="OM">Oman</option>
     <option value="PK">Pakistan</option>
     <option value="PW">Palau</option>
     <option value="PS">Palestine, State of</option>
     <option value="PA">Panama</option>
     <option value="PG">Papua New Guinea</option>
     <option value="PY">Paraguay</option>
     <option value="PE">Peru</option>
     <option value="PH">Philippines</option>
     <option value="PN">Pitcairn</option>
     <option value="PL">Poland</option>
     <option value="PT">Portugal</option>
     <option value="PR">Puerto Rico</option>
     <option value="QA">Qatar</option>
     <option value="MK">Republic of North Macedonia</option>
     <option value="RO">Romania</option>
     <option value="RU">Russian Federation (the)</option>
     <option value="RW">Rwanda</option>
     <option value="RE">Runion</option>
     <option value="BL">Saint Barthlemy</option>
     <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
     <option value="KN">Saint Kitts and Nevis</option>
     <option value="LC">Saint Lucia</option>
     <option value="MF">Saint Martin (French part)</option>
     <option value="PM">Saint Pierre and Miquelon</option>
     <option value="VC">Saint Vincent and the Grenadines</option>
     <option value="WS">Samoa</option>
     <option value="SM">San Marino</option>
     <option value="ST">Sao Tome and Principe</option>
     <option value="SA">Saudi Arabia</option>
     <option value="SN">Senegal</option>
     <option value="RS">Serbia</option>
     <option value="SC">Seychelles</option>
     <option value="SL">Sierra Leone</option>
     <option value="SG">Singapore</option>
     <option value="SX">Sint Maarten (Dutch part)</option>
     <option value="SK">Slovakia</option>
     <option value="SI">Slovenia</option>
     <option value="SB">Solomon Islands</option>
     <option value="SO">Somalia</option>
     <option value="ZA">South Africa</option>
     <option value="GS">South Georgia and the South Sandwich Islands</option>
     <option value="SS">South Sudan</option>
     <option value="ES">Spain</option>
     <option value="LK">Sri Lanka</option>
     <option value="SD">Sudan</option>
     <option value="SR">Suriname</option>
     <option value="SJ">Svalbard and Jan Mayen</option>
     <option value="SE">Sweden</option>
     <option value="CH">Switzerland</option>
     <option value="SY">Syrian Arab Republic</option>
     <option value="TW">Taiwan (Province of China)</option>
     <option value="TJ">Tajikistan</option>
     <option value="TZ">Tanzania, United Republic of</option>
     <option value="TH">Thailand</option>
     <option value="TL">Timor-Leste</option>
     <option value="TG">Togo</option>
     <option value="TK">Tokelau</option>
     <option value="TO">Tonga</option>
     <option value="TT">Trinidad and Tobago</option>
     <option value="TN">Tunisia</option>
     <option value="TR">Turkey</option>
     <option value="TM">Turkmenistan</option>
     <option value="TC">Turks and Caicos Islands</option>
     <option value="TV">Tuvalu</option>
     <option value="UG">Uganda</option>
     <option value="UA">Ukraine</option>
     <option value="AE">United Arab Emirates</option>
     <option value="GB">United Kingdom of Great Britain and Northern Ireland</option>
     <option value="UM">United States Minor Outlying Islands</option>
     <option value="UY">Uruguay</option>
     <option value="UZ">Uzbekistan</option>
     <option value="VU">Vanuatu</option>
     <option value="VE">Venezuela (Bolivarian Republic of)</option>
     <option value="VN">Viet Nam</option>
     <option value="VG">Virgin Islands (British)</option>
     <option value="VI">Virgin Islands (U.S.)</option>
     <option value="WF">Wallis and Futuna</option>
     <option value="EH">Western Sahara</option>
     <option value="YE">Yemen</option>
     <option value="ZM">Zambia</option>
     <option value="ZW">Zimbabwe</option>
     <option value="AX">Land Islands</option>
                            
                            </select>
                           </div>
                        </div>
                      </div>
                      <div class="row form-group">
                        <div class="col-sm-6">
                          <label class="lable">Zip Code<sup class="star">*</sup></label>
                          <input class="textbox" onChange={(e)=>handleForm2(e)}   maxlength="7" name="zipcode" type="text" value={profileData?.zipcode}/>
                          </div>
                          <div class="col-sm-6">
                          <label class="lable">Mobile</label>
                          <input class="textbox numbersOnly" data-val-length-max="10" data-val-length-min="10" onChange={(e)=>handleForm2(e)}  id="Mobile" maxlength="10" name="mobile" type="text" value={profileData?.mobile} />
                         </div>
                      </div>
                      
                      <button class="button float-end mt_mob" name="submit" type="submit" >Save </button>
                      <button class="button float-end grayBtn mt_mob" type="button">cancel</button>
                    
                  </div>
                  {/* <!-- Edit Mode For Contact information -->  */}
                  
                  
                  {/* <!-- Contact information start --> */}
                  <div id="contact_infoDetail" style={{ display: isContactShow ? 'none' : 'block' }}>
                    <ul class="content_detail">
                      <li><span class="label">Address Line1 :</span> {profileData?.address} </li>
                      {/* <li><span class="label">Address Line2  :</span> <span id="add2">-</span></li> */}
                      <li><span class="label">City  : </span> <span id="ct">{profileData?.city}</span></li>
                      <li><span class="label">State  : </span> <span id="st">{profileData?.state}</span></li>
                      <li><span class="label">Zip Code   : </span> <span id="czip">{profileData?.zipcode}</span></li>
                      <li><span class="label">Country   : </span> <span id="cntry">{profileData?.country}</span></li>
                      {/* <li><span class="label">Contact Phone   : </span> <span id="ccontact">-</span></li> */}
                      <li><span class="label">Mobile    : </span> <span id="cmobile">{profileData?.mobile}</span></li>
                      <li><span class="label">Email     : </span> <span id="cemail">{profileData?.email}</span></li>
                    </ul>
                  </div>
                  {/* <!-- Contact information end -->  */}

                </div>
                </form>

                {/* <!--  \ Contact Information End here / --> */}

              </div>
              
            </div>
          </div>
		{/* <!-- End My Information--> */}
		
        </div>
      </div>
    </div>
    
    
  </div>
  {/* <!--  / Profile  container \ -->  */}

      <Footer />
    </>
  );
};

export default MyInformation;
