import React, { useState,useEffect } from "react";
import HeaderUser from "../../components/HeaderUser";
import Footer from "../../components/Footer";
import { Link,useSearchParams } from "react-router-dom";
import ImagesPath from "../../components/ImagesPath";
import { apiBaseUrl,getStorageData} from '../../global';
import { ReactComponent as Plane } from "../../resources/images/icons/plane.svg";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
//import { Link } from 'react-router-dom';



import {getClassShort,formatEft} from './function.js';

import moment from"moment";
import Conpax from "./leftfilter/Conpax.js";
import Cprice from "./leftfilter/Cprice.js";

import Layoverdata from "./leftfilter/Layoverdata.js";

function Confirmation() {
  const [bookingRs,setBookingRs]=useState({});
  const [searchParams] = useSearchParams();
  const getStorage=((searchId)=>{
    let data=localStorage.getItem(searchId);
         return JSON.parse(data);
  });
  useEffect(() => {
 

    let TraceId = searchParams.get("TraceId");
    
    let storageData=getStorage(TraceId);
 //console.log(storageData)
 //debugger;
  if (storageData) {
    setBookingRs(storageData);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    var pnrRq = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(storageData),
      redirect: 'follow'
    };
    const url = apiBaseUrl + '/api/addBooking';
    //const url = apiBaseUrl + '/api/addBooking';
    fetch(url, pnrRq)
        .then(response => response.json())
        .then(result => {
                    console.log(result);
                  //  debugger;
                    if(result.status==='OK')
                    {
                      // inner email sending start
                    //  const urlEmail = apiBaseUrl + '/api/sendmail/'+result.bookingId;
               
                    const urlEmail = apiBaseUrl + '/api/sendmail/'+getStorageData('userSession').id+"/"+getStorageData('userSession').website_id;
                      fetch(urlEmail, pnrRq)
                      .then(response => response.json())
                      .then(result => {
                                 console.log(result);
                    
                      }).catch(error => console.log(error));

                      // inner email sending end

                    }
      
        }).catch(error => console.log(error));



    




}
else{
alert('Wrong Way');
return;
}
},[searchParams,apiBaseUrl]);










  return (
    <>
      <HeaderUser />

      {/* <!--  / Results container \ --> */}
      <div id="resultCntr">
        
        <div className="container">
          <div className="row">
            <div className="col-md-8 full-col">
              {/* <!-- confirmation Box--> */}
              <div className="confirmation__Box text-center">
                <div className="row">
                  <div className="col-md-4">
                    <img className="img" src={ImagesPath.confirm} alt="confirm" />
                  </div>
                  <div className="col-md-8 d-flex justify-content-center align-items-center flex-column">
                      <a href="javascript:window.print()" className="print_itinerary">
                        <i className="bi bi-printer"></i>Print itinerary{" "}
                      </a>

                      <img src={ImagesPath.bookingConfirm} alt="" />
                      <h4>Booking Confirmation</h4>

                      <p>booking is confirmed, Flyus Travels booking number is</p>

                      <div className="refrance__number">{bookingRs.pnrDetail?.pnr}</div>
                  </div>
                </div>
                
              </div>

              {/* <!-- confirmation Box --> */}

              {/* <!-- payment wrapper--> */}
              <div className="payment__wrapper">
                {/* <!-- / itenary Box \--> */}
                <div className="flight__itenaryBox">
                  {/* <!-- Flight head--> */}
                  <div className="head">
                    <h2 className="page__title flight_detail_title">
                      <strong>Flight</strong> Details
                    </h2>
                  </div>
                  {/* <!-- Flight detail--> */}

                  <div className="flightDetail">

                    

                    <div className="tab-content">
                      {/* <!-- Depart tab start here--> */}

  {/* <!-- Loop start here--> */}

  {bookingRs.selectedItinerary?.Flights.map((allsegm,index)=>

                              <div key={'intens'+index}>
                                <h4>{bookingRs.Airport[allsegm.Segments[0].From]} To {bookingRs.Airport[allsegm.Segments[allsegm.Stop].To]} {moment(allsegm.Segments[0].DepDt).format('ll')} with {allsegm.Stop} Stops 
                          </h4>
                            {allsegm.Segments.map((segm,inde)=>
                            <div key={'intens'+inde}>

 
                          <Layoverdata LayOver={segm.LayOver} inde={inde} />

                           
                             <div className="row" >
                              <div className="col-9 col-sm-4">
                                <div className="airlines__details">
                                  <img src={require('./../../resources/airlines/'+segm.MktAir+'.jpg')}  alt={segm.MktAir} />
                                  <div className="detail">
                                    <div className="air-name">{bookingRs.Airline[segm.MktAir]}</div>
                                    <div className="flight__info">
                                      Flight No: {segm.FltNum} | EQP-{segm.EqptType}
                                    </div>
                                    <div className="oprated_by">
                                      Operated by: {bookingRs.Airline[segm.OptAir]}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-3 col-sm-1 order-md-3 pl-0">
                                <div className="className__type">
                                  className: <br /> {getClassShort(segm.Cabin)}
                                </div>
                              </div>

                              <div className="col-sm-7">
                                {/* <!--  route--> */}
                                <div className="route__info">
                                  <div className="connecting__bar d-none d-md-block">
                                    <div className="duretion_time">
                                      <span>{formatEft(segm.Eft)}</span>
                                      <span className="plane">
                                        <Plane/>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="row">

                                    <div className="col-sm-6">
                                      <div className="destinationName">
                                        <div className="date">
                                          <div className="highlight">
                                         {moment(segm.DepDt).format('ll  [,] ddd ')}
                                          </div>{" "}
                                          {/* <small className="message_display ">
                                            Alternate Date
                                          </small> */}
                                        </div>
                                        <div className="time">{moment(segm.DepDt).format('hh:mm a')}</div>
                                        <div className="destination_fullname">
                                          <div
                                            className="text-overflow"
                                            title={bookingRs.Airport[segm.From]}
                                          >
                                            {bookingRs.Airport[segm.From]+" ("+segm.From+"}"}
                                           
                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                    <div className="TripTime  d-md-none d-block">
                                      Trip Time : {formatEft(segm.Eft)}
                                    </div>

                                    <div className="col-sm-6">
                                      <div className="destinationName">
                                        <div className="date">
                                          <span>{moment(segm.ArrDt).format('ll  [,] ddd ')}</span>{" "}
                                        </div>
                                        <div className="time">{moment(segm.ArrDt).format('hh:mm a')}</div>
                                        <div className="destination_fullname">
                                          <div className="highlight">
                                         
                                      <div className="text-overflow" title={bookingRs.Airport[segm.To]+" ("+segm.To+"}"}>
                                             {bookingRs.Airport[segm.To]+" ("+segm.To+"}"}
                                            </div>
                                          </div>
                                          {/* <small className="message_display ">
                                            Nearby Airport
                                          </small> */}
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>

                                {/* <!--  route-->    */}
                              </div>
                            </div>

                              </div>
                            )}
                            
                              <div className="row">
                              <div className="col-sm-8 offset-sm-4">
                                <div className="layover">
                                  {" "}
                                  <i className="bi bi-stopwatch"></i> Total Trip Time
                                  : {allsegm.TotalTime}
                                </div>
                              </div>
                            </div>
                             
                            <hr className="devider_line"></hr>
                            </div>
                                
                           )}




                    

    {/* <!-- Loop end here--> */}



                      {/* <!-- Depart tab end here--> */}

                      {/* <!-- Return Tab start here--> */}
                      
                      {/* <!-- Retrun tab End here--> */}
                    </div>
                  </div>
                </div>
                {/* <!-- \ itenary Box /--> */}
              </div>
              {/* <!-- payment wrapper--> */}

              {/* <!-- / traveler Box \--> */}
              <div className="confirmation__information">
                <h4>
                  <strong>Traveler</strong> Information
                </h4>
                {/* <!-- head--> */}
                <div className="head d-none d-md-block">
                  <div className="row">
                    <div className="col-sm-6">Name</div>

                    <div className="col-sm-3">Gender</div>

                    <div className="col-sm-3">DOB</div>
                  </div>
                </div>

                {/* <!-- Traveler Row--> */}
                                 {bookingRs.travellerDetails?.adult.length>0?<Conpax paxdetail={bookingRs.travellerDetails?.adult} />:""}  
                                 {bookingRs.travellerDetails?.child.length>0?<Conpax paxdetail={bookingRs.travellerDetails?.child} />:""}  
                                 {bookingRs.travellerDetails?.infant.length>0?<Conpax paxdetail={bookingRs.travellerDetails?.infant} />:""} 
                                 {bookingRs.travellerDetails?.infantSeat.length>0?<Conpax paxdetail={bookingRs.travellerDetails?.infantSeat} />:""}        
                  {/* <!-- Traveler Row--> */}

                
              </div>
              {/* <!-- \ traveler Box /--> */}

              {/* <!-- / contact Box \--> */}
              <div className="confirmation__information">
                <h4>
                  <strong>Contact</strong> Information
                </h4>

                {/* <!-- head--> */}
                <div className="head d-none d-md-block">
                  <div className="row">
                    <div className="col-sm-4">Contact Number</div>

                    <div className="col-sm-8 col-9">E-mail</div>
                  </div>
                </div>

                {/* <!-- Traveler Row--> */}
                <div className="detail">
                  <div className="row">
                    <div className="col-3 d-inline-block d-md-none">
                      <b>Contact </b>
                    </div>{" "}
                    <div className="col-sm-4 col-9">{bookingRs.billingDetail?.phone}</div>
                    <div className="col-3 d-inline-block d-md-none">
                      <b>E-mail</b>
                    </div>{" "}
                    <div className="col-sm-8 col-9">
                    {bookingRs.billingDetail?.email}
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- \ contact Box /--> */}
              <div className="text-center pt-3 d-none d-md-block">
                <a href="javascript:window.print()" className="print_button">
                  <i className="bi bi-printer"></i>Print itinerary{" "}
                </a>
              </div>
            </div>

            <div className="col-md-4 full-col">
              {/* <!--Price block start here --> */}
              <div className="price__Box">
                <div className="head">
                  <h4>Price details</h4>
                </div>

                <ul>

                  <Cprice paxdetail={bookingRs.selectedItinerary?.Adults} paxType={"Adult"} currency={bookingRs.selectedItinerary?.Cur} /> 
                  <Cprice paxdetail={bookingRs.selectedItinerary?.Childs} paxType={"Child"} currency={bookingRs.selectedItinerary?.Cur} /> 
                  <Cprice paxdetail={bookingRs.selectedItinerary?.Infants} paxType={"Infant"} currency={bookingRs.selectedItinerary?.Cur} /> 
                          

                  {/* <li>
                    <div className="row disscount--price">
                      <div className="col-8">Discount</div>{" "}
                      <div className="col-4 text-right">-$10.80</div>
                    </div>
                  </li>

                  <li className="cuponBlock">
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="text"
                          placeholder="Enter coupon Code here"
                          className="inputText"
                        />
                        <button className="submit">Apply</button>
                      </div>
                    </div>
                  </li> */}
                </ul>

                {/* <style>
							.cuponBlock{ width: 100%; overflow:hidden; }
							.cuponBlock .inputText { width:calc(100% - 73px); border: 1px solid #ccc;  padding: 6px 10px; float: left;}
							.cuponBlock .submit {border: none; background: #00ab53;  padding: 7px;  color: #fff; width: 73px; float:left; cursor:pointer; }
							.cuponBlock .submit:focus { border:none; outline:none; }
							.cuponBlock .submit.remove { background:#FB3D4E; }
						</style> */}

                <div className="total_price">
                  <div className="row">
                    <div className="col-7">Total Price ({bookingRs.selectedItinerary?.Cur})</div>
                    <div className="col-5 text-right">{bookingRs.selectedItinerary?.Cur} {bookingRs.selectedItinerary?.TotalFare}</div>
                  </div>
                </div>

                <div className="price__note">
                  All Fares displayed are quoted in USD and inclusive of base
                  fare, taxes and all fees. Additional{" "}
                  <Link to="/">Baggage Fees</Link> may apply as per the airline
                  policies. Your Credit/Debit card may be billed in multiple
                  charges totaling the final total price.
                </div>
              </div>
              {/* <!--Price block end here --> */}
            </div>
          </div>
        </div>
        <div className="text-center pt-3">
          <a
            href="javascript:window.print()"
            className="print_button d-none d-sm-block d-md-none"
          >
            <i className="bi bi-printer"></i>Print itinerary{" "}
          </a>
        </div>
        <div className="spacer d-none d-md-block"></div>
      </div>
      {/* <!--  \ Results container / --> */}

      <Footer />
    </>
  );
}

export default Confirmation;
