
const Bcoupon= ({show,handleRefundClick,addRefund}) =>{
  if(show===false)
{
  return false;
}
  return (
<>

<li>
                    <div className="row Refundable">
                      {addRefund === 'delete' && (
                        <div className="d-flex justify-content-between">
                          <div>Refundable Booking </div>
                          <button 
                            onClick={() => handleRefundClick('add')}
                            className={addRefund === 'add' ? 'active-button' : ''}
                          > 
                            <i className="bi bi-plus-circle-fill"></i> 
                            Add Now
                          </button>
                        </div>
                      )}
                      {addRefund === 'add' && (
                      <div className="d-flex justify-content-between">
                        <div>
                          Refundable Booking 
                          <button
                          onClick={() => handleRefundClick('delete')}
                          className={addRefund === 'delete' ? 'active-button' : ''}
                          > 
                            <i className="bi bi-trash3"></i></button>
                          </div>
                        <div>$33.90</div>
                      </div>
                      )}
                    </div>
                  </li>

                  <li>
                    <div className="d-flex justify-content-between disscount--price">
                      <div>Discount</div>
                      <div>-$10.80</div>
                    </div>
                  </li>

                  <li className="cuponBlock">
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="text"
                          placeholder="Enter coupon Code here"
                          className="inputText"
                        />
                        <button className="submit">Apply</button>
                      </div>
                    </div>
                  </li>

                         
    </>
    );

}
export default Bcoupon;