import React from "react";
const ShowFare= (fares) =>{
	  if(!fares.fares)
  return null;

	 let totalFares=fares.fares;
   
   //debugger;
   var arra=[];
   if(totalFares!=='')
   {
    
   if(totalFares.indexOf(".") !== -1)
   {
   arra=totalFares.split('.');
   }
   
	}

  return (
<>
<div className="price">${arra[0]}.{arra[1]} </div>
    </>
    );

}
export default ShowFare;