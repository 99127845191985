import { Link } from "react-router-dom";
//import ImagesPath from "../../components/ImagesPath";
import Form from 'react-bootstrap/Form';

const Baggage= ({bag}) =>{

  if(bag===false)
  {
    return false;
  }
  
  return (
<>

<div className="travelers-box addon commonpayment">
                <h2 className="page__title">
                  <strong>Add Checked</strong> Baggage
                </h2>

                <div className="add-title">
                  <div>
                    <div className="sub-title w-75">
                      Time is precious. Don’t waste on delayed baggage hassle.
                      Count on us. Keep your bags safe. Add checked baggage to
                      your booking.
                    </div>
                  </div>
                </div>
                <div id="baggageOptionsPart">
                  <div className="price">
                    <span className="chgWithCurrency" default-price="0">
                      $0
                    </span>

                    <small className="per-person">(Added to total fare)</small>
                  </div>
                  <div className="leg-section">
                    <div className="leg-data">
                      <div>Departure</div>
                      <div className="row ">
                        <div className="col-12 col-sm-6">
                          <div className="leg-detail">
                            <div className="name">
                              <span>
                                {" "}
                                LAS <img
                                  src="images/seatmap/next.svg"
                                  alt=""
                                />{" "}
                              </span>{" "}
                              MIA
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="d-flex justify-content-between align-items-center mb-2 fs-7">
                            <label>Passenger 1</label>

                            <div
                              className="leg-status form"
                              style={{ display: "block" }}
                            >
                              <div className="inputfieldset">
                                <div className="select-class">
                                  <Form.Select aria-label="Default select example">
                                    <option value="0">Select baggage</option>
                                    <option>1 x Carry-On Bag (+$30.00)</option>
                                    <option>1 x Checked Bag (+$30.00)</option>
                                    <option>2 x Checked Bag (+$80.00)</option>
                                    <option>3 x Checked Bag (+$120.00)</option>
                                    <option>
                                      1 x Carry-On Bag + 1 x Checked Bag
                                      (+$60.00)
                                    </option>
                                    <option>
                                      1 x Carry-On Bag + 2 x Checked Bag
                                      (+$110.00)
                                    </option>
                                    <option>
                                      1 x Carry-On Bag + 3 x Checked Bag
                                      (+$150.00)
                                    </option>
                                  </Form.Select>
                                </div>
                              </div>
                            </div>

                            <div
                              className="mb-2 text-right"
                              style={{ display: "none" }}
                            >
                              <Link to="/" className="removebaggage">
                                Remove
                              </Link>
                              <span className="select-show"> </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

                         
                         
    </>
    );

}
export default Baggage;