import React, {useState} from 'react'
import HeaderUser from '../../components/HeaderUser'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'


const OrderManager = () => {

    const [searchBooking, setSearchBooking] = useState(1);
    const handleSearchBooking = (tabNumber) => {
        setSearchBooking(tabNumber);
    };

  return (
    <>
      <HeaderUser/>

        {/* mybooking container start */}
        <div id='myBookingCntr'>
            <div className='container'>

                <div className='mybookingBox'>
                    <div className='bookingHead'>
                        <h3>Ordermanager</h3>
                        <Link className="notification">Notifications: <span>0</span></Link>
                    </div>
                    <div className='bookingBlock'>
                        <div className='bookingLeft'>
                            <div className='listBlock'>
                                <h4>Quick links</h4>

                                <ul>
                                    <li><Link>Open bookings <span>(0)</span></Link></li>
                                    <li><Link>All bookings <span>(3)</span></Link></li>
                                </ul>
                            </div>
                            <div className='listBlock'>
                                <h4>Ticket time limit</h4>

                                <ul>
                                    <li><Link>Next 24h <span>(0)</span></Link></li>
                                    <li><Link>24h & more <span>(0)</span></Link></li>
                                    <li><Link>Expired <span>(0)</span></Link></li>
                                </ul>
                            </div>
                            <div className='listBlock'>
                                <h4>Change notifications</h4>

                                <ul>
                                    <li><Link>TKTL warnings <span>(0)</span></Link></li>
                                    <li><Link>Schedule changes <span>(0)</span></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className='SearchBooking'>
                            <div className='holder'>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="Search bookings"
                                    name="radio-buttons-group"
                                >
                                <ul className="SearchBookingtab">
                                    <li
                                        className={searchBooking === 1 ? 'active' : ''}
                                        onClick={() => handleSearchBooking(1)}
                                    >
                                
                                        <FormControlLabel value="Search bookings" control={<Radio />} label="Search bookings" />
                                
                                    </li>
                                    <li
                                        className={searchBooking === 2 ? 'active' : ''}
                                        onClick={() => handleSearchBooking(2)}
                                    >
                                    
                                        <FormControlLabel value="Import PNR" control={<Radio />} label="Import PNR" />
                                    
                                    </li>
                                </ul>
                                </RadioGroup>
                                
                                {searchBooking === 1 && 
                                    <div className='Bookingtabdetail'>
                                        <div className='row'>
                                            <div className='col-md-8'>
                                            
                                                <input class="form-control input" name="" value="" type="text" placeholder="Enter last name or booking reference"/>
                                            
                                            </div>
                                            <div className='col-md-4'>
                                            <button className="handleBtn">
                                                Search
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {searchBooking === 2 && 
                                    <div className='Bookingtabdetail'>
                                        <div className='row'>
                                            <div className='col-md-9'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <select class="form-select" aria-label="select example">
                                                            <option value=""> Amadeus </option>
                                                            <option value=""> Sabre </option>
                                                            <option value=""> Galileo </option>
                                                            <option value=""> Farelogix (soon) </option>
                                                        </select>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <input class="form-control input" name="" value="" type="text" placeholder="Filekey"/>
                                                    </div>
                                                    <div className='col-md-4'>
                                                    <input class="form-control input" name="" value="Auto filled" type="text" placeholder="Auto filled"/>
                                                    </div>
                                                </div>    
                                            </div>
                                            <div className='col-md-3'>
                                                <button className="handleBtn">
                                                    Import PNR
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        {/* mybooking container ends */}

      <Footer/>
    </>
  )
}

export default OrderManager
