import React from "react";
import Header from "../../../components/Header";
import HeaderUser from "../../../components/HeaderUser";
import Footer from "../../../components/Footer";
import AgencyLeftMenu from "../../../components/Profile/AgencyLeftMenu";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
//import { Link } from "react-router-dom";

const GDSAgency = () => {
  return (
    <>
      <HeaderUser />

      {/* <!-- form container start --> */}
      <div id="profileCntr">
        <div class="container">
          {/* <!--  / Profile  container \ --> */}

          <div class="row">
            <div class="col-sm-12 row-flex">
              {/* <!--  / Left container Start here \ --> */}
              <AgencyLeftMenu />
              {/* <!--  / Left container Start ends \ --> */}

              {/* <!--  / Right container Start here \ --> */}
              <div class="rightCntr prof-section" id="myinformation">
                <div id="myinfoTab">
                  <h2 class="main_title">GDS and IATA data</h2>

                  <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
                    <Link underline="hover" color="inherit" to="/profile">
                      Profile
                    </Link>
                    <Typography color="text.primary">GDS and IATA data</Typography>
                  </Breadcrumbs>

                  {/* <!-- My detail tab Start here --> */}
                  <div>
                    {/* <!--  / company Information Start here \ --> */}
                    <div class="formBox">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="bankInformation">
                            <h2>My IATA details</h2>
                            <dl>
                              <dt>IATA-Nummer:</dt>
                              <dd>50637263</dd>
                            </dl>
                            <h2>My GDS details</h2>
                            <p>
                              Does your agency use Amadeus, Sabre, Galileo or
                              any other GDS? Then please share your GDS data
                              (Office ID, PCC, etc.) with us. Thank you very
                              much!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- End My Information--> */}
              </div>
              {/* <!-- End My Information--> */}
            </div>
          </div>
        </div>

        {/* <!--  / Profile  container \ -->  */}
      </div>
      {/* <!-- form container end --> */}

      <Footer />
    </>
  );
};

export default GDSAgency;
