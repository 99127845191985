import React, { useEffect,useState } from 'react';
import { Stepper, Step } from "react-form-stepper";
import Header from '../components/Header';
import HeaderUser from '../components/HeaderUser';
import Footer from '../components/Footer';
import { apiBaseUrl,getStorageData } from '../global';
import { useNavigate } from "react-router-dom";
//import axios from 'axios';

const Registration = () => {
  const navigate = useNavigate();
  const [goSteps, setGoSteps] = useState(0);
  const initialValues = {
    email: "",
    password: "",
    title: "",
    fname: "",
    lname: "",
    mobile: "",
    agency: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    address: "",
    website: "",
    comment: "",
    consortium: "",
    IATA: "",
    CLIA: "",
    ASTA: "",
    reservation: "",
    ownerId:"",
    EinTax:"",
    businessLicense:""
  };
  const [posts, setPosts] = useState(initialValues);
  const [message, setMessage] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [secondStep, setSecondStep] = useState(false);
  const [ownerId, setOwnerId] = useState("");
  const [einTax, setEinTax] = useState("");
  const [businessLicense, setBusinessLicense] = useState("");
  
  const [ownerError, setOwnerError] = useState('');
  const [einTaxError, setEinTaxError] = useState('');
  const [licenseError, setLicenseError] = useState('');

  const _setOwnerId=(event)=>{
    const fileUploaded = event.target.files[0];
    if (!fileUploaded) return;
    setOwnerId(fileUploaded);
    setOwnerError('');

  };
  const _setEinTax=(event)=>{
    const fileUploaded = event.target.files[0];
    if (!fileUploaded) return;
    setEinTax(event.target.files[0]);
    setEinTaxError('');

  };
  const _setBusinessLicense=(event)=>{
    const fileUploaded = event.target.files[0];
    if (!fileUploaded) return;
    setBusinessLicense(event.target.files[0]);
    setLicenseError('');
  };




    const validate=(posts)=>{
    const errors={};
    if(!posts.fname)
    {
      errors.fname='Fname is required';
    }
    if(!posts.lname)
    {
      errors.lname='Lname is required';
    }
    if(!posts.agency)
    {
      errors.agency='Agency is required';
    }
    if(!posts.address)
    {
      errors.address='Address is required';
    }
    if(!posts.zipcode)
    {
      errors.zipcode='Zipcode is required';
    }
    else if(posts.zipcode.length<5)
    {
      errors.zipcode='zipcode must be greater than 4 character';
    }
    if(!posts.website)
    {
      errors.website='Website is required';
    }
    if(!posts.comment)
    {
      errors.comment='Comment is required';
    }
    if(!posts.address)
    {
      errors.address='Address is required';
    }
    if(!posts.city)
    {
      errors.city='City is required';
    }
    if(!posts.state)
    {
      errors.state='State is required';
    }
    if(!posts.country)
    {
      errors.country='Country is required';
    }

    if(!posts.password)
    {
      errors.password='Password is required';
    }
    else if(posts.password.length<=5)
    {
      errors.password='Password must be greater than 5 character';
    }
    if(!posts.title)
    {
      errors.title='Title is required';
    }
   var mobilePat=/^[0-9]{10,15}$/;
    if(!posts.mobile)
    {
      errors.mobile='Mobile is required';
    }
    else if (!mobilePat.test(posts.mobile)) {
      errors.mobile='Mobile is not valid';
    }
  //  var filter =/^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    var filter= /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if(!posts.email)
    {
      errors.email='Email is required';
    }
    else if (!filter.test(posts.email)) {
      errors.email='Email is not valid';
    }


    return errors;
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPosts({...posts,[name]: value});
    //setFormErrors(validate(posts));
    if(secondStep===true)
    {
      setFormErrors(validate(posts));
    }
  }
const goToStep1=((e,posts)=>{
  e.preventDefault();
  setFormErrors(validate(posts));
  setSecondStep(true);
  if(!ownerId){
setOwnerError('Owner Image or pdf is required');
return false;
  }
  if(!businessLicense){
    setLicenseError('Business License Image or pdf is required');
    return false;
      }
  if(!einTax){
    setEinTaxError('Ein Tax Image or pdf is required');
    return false;
      }

  

});
const goToStep0=((e,posts)=>{
e.preventDefault();
 setGoSteps(0);
  setSecondStep(false);
  
});
  useEffect(()=>{
    if(Object.keys(formErrors).length===0 && isSubmit)
    {
      var myHeaders = new Headers();
        //  myHeaders.append("Content-Type", 'multipart/form-data;');
      // myHeaders.append("Content-Type", "application/json");
      //   myHeaders.append("Accept", "application/json");
       const url = apiBaseUrl + '/api/register';
      var postdata={...posts};

      let formData = new FormData();
         formData.append('fname', postdata.fname);   
         formData.append('lname', postdata.lname);   
         formData.append('email', postdata.email); 
         formData.append('password', postdata.password); 

         formData.append('title', postdata.title);   
         formData.append('mobile', postdata.mobile);   
         formData.append('agency', postdata.agency); 
         formData.append('country', postdata.country); 



         formData.append('state', postdata.state);   
         formData.append('city', postdata.city);   
         formData.append('zipcode', postdata.zipcode); 
         formData.append('address', postdata.address);


         formData.append('website', postdata.website);   
         formData.append('comment', postdata.comment);   
         formData.append('consortium', postdata.consortium); 
         formData.append('IATA', postdata.IATA);


         formData.append('CLIA', postdata.CLIA);   
         formData.append('ASTA', postdata.ASTA);   
         formData.append('reservation', postdata.reservation); 
         formData.append('ownerId', ownerId);
         formData.append('einTax', einTax);
         formData.append('businessLicense', businessLicense);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
       // body: JSON.stringify({ ...posts }),
        redirect: 'follow'
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setIsSubmit(false);
          if (result.status === 'true') {
            setMessage(result.message);
            navigate("/registrationsuccesful", {replace: true });
            //const engineUrl = engineBaseUrl + '/login';
          // window.location.href= engineBaseUrl + '/login';
            
          }
          else {
            setMessage(result.error);
          }
          
        }
        );
    }
    else if(Object.keys(formErrors).length===1 && secondStep)
    {
    setSecondStep(false);
    }
    else if(Object.keys(formErrors).length===0 && secondStep)
    {
    setGoSteps(1);
    }
    

  },[formErrors,isSubmit,posts,secondStep,ownerId,einTax,businessLicense]);

  const handleSubmit = (event) => {
    event.preventDefault();
   setFormErrors(validate(posts));
   setIsSubmit(true);
   setSecondStep(false);
  }
  return (
    <>
      {getStorageData('userSession')?.id!=null?<HeaderUser/>:<Header/>}

      {/* <!-- form container start -->
        <pre>{JSON.stringify(posts,undefined,2)}</pre>*/}
      <div id="formCntr" className='profile'>
        <div className='container'>
          {/* <pre>{JSON.stringify(posts, undefined, 2)}</pre> */}
          <Stepper activeStep={goSteps}>
            <Step onClick={() => setGoSteps(0)} label="Name and address" />
            <Step onClick={() => setGoSteps(1)} label="Agency Membership" />
            <Step onClick={() => setGoSteps(2)} label="Confirm registration" />
          </Stepper>
          <div className="formholder">

            {/* <!-- form box start --> */}
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="formBox">
                {goSteps === 0 && (

                  <div className="col-xs-6 col-md-offset-3">
                    <div className="col-md-12">
                      <h3>Name and address</h3>

                      <h4>Kinldy enter below details.</h4>

                      <fieldset>

                        <div className="row justify-content-between">
                          <div className="col-sm-12">
                            <div>
                              <div className="row">
                                <div className="col-sm-12 col-md-3 form_col">
                                  <div className="form-floating">
                                    <select className="form-select" required value={posts.title || ""} onChange={(event) => handleChange(event)} name="title" id="floatingSelect" aria-label="Floating label select example">
                                      <option value="">Select Title</option>
                                      <option value="Ms">Ms.</option>
                                      <option value="Mr">Mr.</option>
                                      <option value="Mrs">Mrs.</option>
                                      <option value="Dr">Dr.</option>
                                    </select>
                                    <label for="floatingSelect">Selects Title</label>
                                    {formErrors.title && <span className="error">{formErrors.title}</span>}
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-3 form_col">
                                  <div className="form-floating">
                                    <input className="form-control input" id="floatingInputValue" name="fname" onChange={(event) => handleChange(event)} value={posts.fname || ""} type="text" placeholder="First name* " />
                                    <label for="floatingInputValue">First name*</label>
                                    {formErrors.fname && <span className="error">{formErrors.fname}</span>}
                                  </div>
                                </div>

                               

                                


                              
                                <div className="col-sm-12 col-md-3 form_col">
                                  <div className="form-floating">
                                    <input className="form-control input" id="floatingInputValue" name="lname" onChange={(event) => handleChange(event)} value={posts.lname || ""} type="text" placeholder="Last Name* " />
                                    <label for="floatingInputValue">Last Name*</label>
                                    {formErrors.lname && <span className="error">{formErrors.lname}</span>}
                                  </div>
                                </div>

                                <div className="col-sm-12 col-md-3 form_col">
                                  <div className="form-floating">
                                    <input className="form-control input" id="floatingInputValue" name="agency" onChange={(event) => handleChange(event)} value={posts.agency || ""} type="text" placeholder="e.g. Liberty Travel* " />
                                    <label for="floatingInputValue">Agency name*</label>
                                    {formErrors.agency && <span className="error">{formErrors.agency}</span>}
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-4 form_col">
                                  <div className="form-floating">
                                    <input className="form-control input" id="floatingInputValue" name="address" onChange={(event) => handleChange(event)} value={posts.address || ""} type="text" placeholder="Address* " />
                                    <label for="floatingInputValue">Address*</label>
                                    {formErrors.address && <span className="error">{formErrors.address}</span>}
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-4 form_col">
                                  <div className="form-floating">
                                    <input className="form-control input" minLength={5} maxLength={10} name="zipcode" onChange={(event) => handleChange(event)} value={posts.zipcode || ""} id="floatingInputValue" type="text" placeholder="ZIP code* " />
                                    <label for="floatingInputValue">ZIP code*</label>
                                    {formErrors.zipcode && <span className="error">{formErrors.zipcode}</span>}
                                  </div>
                                </div>

                                <div className="col-sm-12 col-md-4 form_col">
                                  <div className="form-floating">
                                    <input className="form-control input" name="city" onChange={(event) => handleChange(event)} value={posts.city || ""} id="floatingInputValue" type="text" placeholder="City* " />
                                    <label for="floatingInputValue">City*</label>
                                    {formErrors.city && <span className="error">{formErrors.city}</span>}
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-6 form_col">
                                  <div className="form-floating">
                                    <select className="form-select" name="country" onChange={(event) => handleChange(event)} value={posts.country || ""} required id="floatingSelect" aria-label="Floating label select example">
                                      <option value="">Select Country</option>
                                      <option value="US">United States of America</option>
     <option value="AF">Afghanistan</option>
     <option value="AL">Albania</option>
     <option value="DZ">Algeria</option>
     <option value="AS">American Samoa</option>
     <option value="AD">Andorra</option>
     <option value="AO">Angola</option>
     <option value="AI">Anguilla</option>
     <option value="AQ">Antarctica</option>
     <option value="AG">Antigua and Barbuda</option>
     <option value="AR">Argentina</option>
     <option value="AM">Armenia</option>
     <option value="AW">Aruba</option>
     <option value="AU">Australia</option>
     <option value="AT">Austria</option>
     <option value="AZ">Azerbaijan</option>
     <option value="BS">Bahamas</option>
     <option value="BH">Bahrain</option>
     <option value="BD">Bangladesh</option>
     <option value="BB">Barbados</option>
     <option value="BY">Belarus</option>
     <option value="BE">Belgium</option>
     <option value="BZ">Belize</option>
     <option value="BJ">Benin</option>
     <option value="BM">Bermuda</option>
     <option value="BT">Bhutan</option>
     <option value="BO">Bolivia (Plurinational State of)</option>
     <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
     <option value="BA">Bosnia and Herzegovina</option>
     <option value="BW">Botswana</option>
     <option value="BV">Bouvet Island</option>
     <option value="BR">Brazil</option>
     <option value="IO">British Indian Ocean Territory</option>
     <option value="BN">Brunei Darussalam</option>
     <option value="BG">Bulgaria</option>
     <option value="BF">Burkina Faso</option>
     <option value="BI">Burundi</option>
     <option value="CV">Cabo Verde</option>
     <option value="KH">Cambodia</option>
     <option value="CM">Cameroon</option>
     <option value="CA">Canada</option>
     <option value="KY">Cayman Islands (the)</option>
     <option value="CF">Central African Republic (the)</option>
     <option value="TD">Chad</option>
     <option value="CL">Chile</option>
     <option value="CN">China</option>
     <option value="CX">Christmas Island</option>
     <option value="CC">Cocos (Keeling) Islands</option>
     <option value="CO">Colombia</option>
     <option value="KM">Comoros</option>
     <option value="CD">Congo (the Democratic Republic of the)</option>
     <option value="CG">Congo</option>
     <option value="CK">Cook Islands</option>
     <option value="CR">Costa Rica</option>
     <option value="HR">Croatia</option>
     <option value="CU">Cuba</option>
     <option value="CW">Cura?ao</option>
     <option value="CY">Cyprus</option>
     <option value="CZ">Czechia</option>
     <option value="CI">C?te d'Ivoire</option>
     <option value="DK">Denmark</option>
     <option value="DJ">Djibouti</option>
     <option value="DM">Dominica</option>
     <option value="DO">Dominican Republic (the)</option>
     <option value="EC">Ecuador</option>
     <option value="EG">Egypt</option>
     <option value="SV">El Salvador</option>
     <option value="GQ">Equatorial Guinea</option>
     <option value="ER">Eritrea</option>
     <option value="EE">Estonia</option>
     <option value="SZ">Eswatini</option>
     <option value="ET">Ethiopia</option>
     <option value="FK">Falkland Islands[Malvinas]</option>
     <option value="FO">Faroe Islands</option>
     <option value="FJ">Fiji</option>
     <option value="FI">Finland</option>
     <option value="FR">France</option>
     <option value="GF">French Guiana</option>
     <option value="PF">French Polynesia</option>
     <option value="TF">French Southern Territories (the)</option>
     <option value="GA">Gabon</option>
     <option value="GM">Gambia</option>
     <option value="GE">Georgia</option>
     <option value="DE">Germany</option>
     <option value="GH">Ghana</option>
     <option value="GI">Gibraltar</option>
     <option value="GR">Greece</option>
     <option value="GL">Greenland</option>
     <option value="GD">Grenada</option>
     <option value="GP">Guadeloupe</option>
     <option value="GU">Guam</option>
     <option value="GT">Guatemala</option>
     <option value="GG">Guernsey</option>
     <option value="GN">Guinea</option>
     <option value="GW">Guinea-Bissau</option>
     <option value="GY">Guyana</option>
     <option value="HT">Haiti</option>
     <option value="HM">Heard Island and McDonald Islands</option>
     <option value="VA">Holy See</option>
     <option value="HN">Honduras</option>
     <option value="HK">Hong Kong</option>
     <option value="HU">Hungary</option>
     <option value="IS">Iceland</option>
     <option value="IN">India</option>
     <option value="ID">Indonesia</option>
     <option value="IR">Iran (Islamic Republic of)</option>
     <option value="IQ">Iraq</option>
     <option value="IE">Ireland</option>
     <option value="IM">Isle of Man</option>
     <option value="IL">Israel</option>
     <option value="IT">Italy</option>
     <option value="JM">Jamaica</option>
     <option value="JP">Japan</option>
     <option value="JE">Jersey</option>
     <option value="JO">Jordan</option>
     <option value="KZ">Kazakhstan</option>
     <option value="KE">Kenya</option>
     <option value="KI">Kiribati</option>
     <option value="KP">Korea (the Democratic People's Republic of)</option>
     <option value="KR">Korea (the Republic of)</option>
     <option value="KW">Kuwait</option>
     <option value="KG">Kyrgyzstan</option>
     <option value="LA">Lao People's Democratic Republic</option>
     <option value="LV">Latvia</option>
     <option value="LB">Lebanon</option>
     <option value="LS">Lesotho</option>
     <option value="LR">Liberia</option>
     <option value="LY">Libya</option>
     <option value="LI">Liechtenstein</option>
     <option value="LT">Lithuania</option>
     <option value="LU">Luxembourg</option>
     <option value="MO">Macao</option>
     <option value="MG">Madagascar</option>
     <option value="MW">Malawi</option>
     <option value="MY">Malaysia</option>
     <option value="MV">Maldives</option>
     <option value="ML">Mali</option>
     <option value="MT">Malta</option>
     <option value="MH">Marshall Islands</option>
     <option value="MQ">Martinique</option>
     <option value="MR">Mauritania</option>
     <option value="MU">Mauritius</option>
     <option value="YT">Mayotte</option>
     <option value="MX">Mexico</option>
     <option value="FM">Micronesia (Federated States of)</option>
     <option value="MD">Moldova (the Republic of)</option>
     <option value="MC">Monaco</option>
     <option value="MN">Mongolia</option>
     <option value="ME">Montenegro</option>
     <option value="MS">Montserrat</option>
     <option value="MA">Morocco</option>
     <option value="MZ">Mozambique</option>
     <option value="MM">Myanmar</option>
     <option value="NA">Namibia</option>
     <option value="NR">Nauru</option>
     <option value="NP">Nepal</option>
     <option value="NL">Netherlands</option>
     <option value="NC">New Caledonia</option>
     <option value="NZ">New Zealand</option>
     <option value="NI">Nicaragua</option>
     <option value="NE">Niger</option>
     <option value="NG">Nigeria</option>
     <option value="NU">Niue</option>
     <option value="NF">Norfolk Island</option>
     <option value="MP">Northern Mariana Islands</option>
     <option value="NO">Norway</option>
     <option value="OM">Oman</option>
     <option value="PK">Pakistan</option>
     <option value="PW">Palau</option>
     <option value="PS">Palestine, State of</option>
     <option value="PA">Panama</option>
     <option value="PG">Papua New Guinea</option>
     <option value="PY">Paraguay</option>
     <option value="PE">Peru</option>
     <option value="PH">Philippines</option>
     <option value="PN">Pitcairn</option>
     <option value="PL">Poland</option>
     <option value="PT">Portugal</option>
     <option value="PR">Puerto Rico</option>
     <option value="QA">Qatar</option>
     <option value="MK">Republic of North Macedonia</option>
     <option value="RO">Romania</option>
     <option value="RU">Russian Federation (the)</option>
     <option value="RW">Rwanda</option>
     <option value="RE">Runion</option>
     <option value="BL">Saint Barthlemy</option>
     <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
     <option value="KN">Saint Kitts and Nevis</option>
     <option value="LC">Saint Lucia</option>
     <option value="MF">Saint Martin (French part)</option>
     <option value="PM">Saint Pierre and Miquelon</option>
     <option value="VC">Saint Vincent and the Grenadines</option>
     <option value="WS">Samoa</option>
     <option value="SM">San Marino</option>
     <option value="ST">Sao Tome and Principe</option>
     <option value="SA">Saudi Arabia</option>
     <option value="SN">Senegal</option>
     <option value="RS">Serbia</option>
     <option value="SC">Seychelles</option>
     <option value="SL">Sierra Leone</option>
     <option value="SG">Singapore</option>
     <option value="SX">Sint Maarten (Dutch part)</option>
     <option value="SK">Slovakia</option>
     <option value="SI">Slovenia</option>
     <option value="SB">Solomon Islands</option>
     <option value="SO">Somalia</option>
     <option value="ZA">South Africa</option>
     <option value="GS">South Georgia and the South Sandwich Islands</option>
     <option value="SS">South Sudan</option>
     <option value="ES">Spain</option>
     <option value="LK">Sri Lanka</option>
     <option value="SD">Sudan</option>
     <option value="SR">Suriname</option>
     <option value="SJ">Svalbard and Jan Mayen</option>
     <option value="SE">Sweden</option>
     <option value="CH">Switzerland</option>
     <option value="SY">Syrian Arab Republic</option>
     <option value="TW">Taiwan (Province of China)</option>
     <option value="TJ">Tajikistan</option>
     <option value="TZ">Tanzania, United Republic of</option>
     <option value="TH">Thailand</option>
     <option value="TL">Timor-Leste</option>
     <option value="TG">Togo</option>
     <option value="TK">Tokelau</option>
     <option value="TO">Tonga</option>
     <option value="TT">Trinidad and Tobago</option>
     <option value="TN">Tunisia</option>
     <option value="TR">Turkey</option>
     <option value="TM">Turkmenistan</option>
     <option value="TC">Turks and Caicos Islands</option>
     <option value="TV">Tuvalu</option>
     <option value="UG">Uganda</option>
     <option value="UA">Ukraine</option>
     <option value="AE">United Arab Emirates</option>
     <option value="GB">United Kingdom of Great Britain and Northern Ireland</option>
     <option value="UM">United States Minor Outlying Islands</option>
     <option value="UY">Uruguay</option>
     <option value="UZ">Uzbekistan</option>
     <option value="VU">Vanuatu</option>
     <option value="VE">Venezuela (Bolivarian Republic of)</option>
     <option value="VN">Viet Nam</option>
     <option value="VG">Virgin Islands (British)</option>
     <option value="VI">Virgin Islands (U.S.)</option>
     <option value="WF">Wallis and Futuna</option>
     <option value="EH">Western Sahara</option>
     <option value="YE">Yemen</option>
     <option value="ZM">Zambia</option>
     <option value="ZW">Zimbabwe</option>
     <option value="AX">Land Islands</option>

                                    </select>
                                    <label for="floatingSelect">Selects Coutry</label>
                                    {formErrors.country && <span className="error">{formErrors.country}</span>}
                                  </div>
                                </div>

                                {/* <div className="col-sm-12 col-md-6 form_col">
                                  <div className="form-floating">
                                    <select name="state" onChange={(event) => handleChange(event)} value={posts.state || ""} className="form-select" id="floatingSelect" aria-label="Floating label select example">
                                      <option value="">Select State</option>
                                      <option value="MP">MP</option>
                                      <option value="UP">UP</option>

                                    </select>
                                    <label for="floatingSelect">Selects State</label>
                                    {formErrors.state && <span className="error">{formErrors.state}</span>}
                                  </div>

                                </div> */}

                                <div className="col-sm-12 col-md-6 form_col">
                                  <div className="form-floating">
                                    <input name="state" maxLength={15} onChange={(event) => handleChange(event)} value={posts.state || ""} className="form-control input" id="floatingInputValue" type="text" placeholder="Enter State" />
                                    <label for="floatingInputValue">State</label>
                                    {formErrors.state && <span className="error">{formErrors.state}</span>}
                                  </div>
                                </div>




                                <div className="col-sm-12 col-md-6 form_col">
                                  <div className="form-floating">
                                    <input name="mobile" maxLength={15} onChange={(event) => handleChange(event)} value={posts.mobile || ""} className="form-control input" id="floatingInputValue" type="tel" placeholder="Phone number" />
                                    <label for="floatingInputValue">Mobile number</label>
                                    {formErrors.mobile && <span className="error">{formErrors.mobile}</span>}
                                  </div>
                                </div>
                                {/* <div className="col-sm-12 col-md-6 form_col">
                                  <div className="form-floating">
                                    <input name="phone" onChange={(event) => handleChange(event)} value={posts.phone || ""} className="form-control input" id="floatingInputValue" type="tel" placeholder="Phone number" />
                                    <label for="floatingInputValue">Phone number</label>
                                  </div>
                                </div> */}

                                <div className="col-sm-12 col-md-6 form_col">
                                  <div className="form-floating">
                                    <input name="email" onChange={(event) => handleChange(event)} value={posts.email || ""} className="form-control input" id="floatingInputValue" type="email" placeholder="Email address" />
                                    <label for="floatingInputValue">Email address</label>
                                    {formErrors.email && <span className="error">{formErrors.email}</span>}
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-6 form_col">
                                  <div className="form-floating">
                                    <input required name="password" onChange={(event) => handleChange(event)} value={posts.password || ""} className="form-control input" id="floatingInputValue"  type="password" placeholder="Password" />
                                    <label for="floatingInputValue">Password</label>
                                    {formErrors.password && <span className="error">{formErrors.password}</span>}
                                  </div>
                                </div>

                                <div className="col-sm-12 col-md-6 form_col">
                                  <div className="form-floating">
                                    <input name="website" onChange={(event) => handleChange(event)} value={posts.website || ""} className="form-control input" id="floatingInputValue" type="text" placeholder="Website" />
                                    <label for="floatingInputValue">Website</label>
                                    {formErrors.website && <span className="error">{formErrors.website}</span>}
                                  </div>
                                </div>
                              </div>
                              <div className="row">

                                                  <div className="col-sm-12 col-md-4 form_col">
                                                    <label className="upload_label">Owner Id</label>
                                                    <div className="input-group">
                                                        <input onChange={(event)=>_setOwnerId(event)}  name="ownerId" type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload"/>
                                                        <button className="btn btn-outline-secondary uploadbtn" type="button" id="inputGroupFileAddon04">Upload</button>
                                                       
                                                    </div>
                                                    {ownerError && <span className="error">{ownerError}</span>}
                                                    {ownerId && (
        <img height={40} width={40}
          src={URL.createObjectURL(ownerId)}
          alt={ownerId.name}
        />
      )}
                                                </div>
                                                <div className="col-sm-12 col-md-4 form_col">
                                                    <label className="upload_label">Business License</label>
                                                    <div className="input-group">
                                                        <input onChange={(event)=>_setBusinessLicense(event)}  name="businessLicense" type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload"/>
                                                        <button className="btn btn-outline-secondary uploadbtn" type="button" id="inputGroupFileAddon04">Upload</button>
                                                       
                                                    </div>
                                                    {licenseError && <span className="error">{licenseError}</span>}
                                                    {businessLicense && (
        <img height={40} width={40}
          src={URL.createObjectURL(businessLicense)}
          alt={businessLicense.name}
        />
      )}
                                                </div>
                                                <div className="col-sm-12 col-md-4 form_col">
                                                    <label className="upload_label">Ein Tax</label>
                                                    <div className="input-group">
                                                        <input type="file" onChange={(event)=>_setEinTax(event)}  name="einTax" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload"/>
                                                        <button className="btn btn-outline-secondary uploadbtn" type="button" id="inputGroupFileAddon04">Upload</button>
                                                        
                                                    </div>
                                                    {einTaxError && <span className="error">{einTaxError}</span>}
                                                    {einTax && (
        <img height={40} width={40}
          src={URL.createObjectURL(einTax)}
          alt={einTax.name}
        />
      )}
                                                </div>
                                            </div> 
                              <div className="row">

                                <div className="col-sm-12 form_col">
                                  <div className="form-floating">
                                    <textarea name="comment" onChange={(event) => handleChange(event)} value={posts.comment || ""} className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: '100px' }}></textarea>
                                    <label for="floatingTextarea2">Comments</label>
                                    {formErrors.comment && <span className="error">{formErrors.comment}</span>}
                                  </div>
                                </div>


                              </div>
                            </div>
                            {/* <!-- <div className="row">
                                          <div className="col-sm-12 form_col">
                                              <a href="customerlp.html" className="submitBtn"><i className="fa-solid fa-right-to-bracket"></i> SignUp Now</a>
                                          </div>
                                      </div>
                                      <div className="row">
                                          <div className="col-sm-12 form_col">
                                              <p> Already registered? <a className="forgotlink" href="customer_login.html">SignIn</a></p>
                                          </div>
                                      </div> --> */}

                          </div>
                        </div>
                        {/* <button onClick={() => setGoSteps(1)} className="btn btn-primary nextBtn btn-lg pull-right submitBtn" type="button">
                          Next
                        </button> */}
                        <button onClick={(e) => goToStep1(e,posts)} className="btn btn-primary nextBtn btn-lg pull-right submitBtn" type="button">
                          Next
                        </button>
                      </fieldset>


                      {/* <button className="btn btn-primary nextBtn btn-lg pull-right submitBtn" type="button">Next</button> */}

                    </div>
                  </div>

                )}
                {goSteps === 1 && (
                  <div className="col-xs-6 col-md-offset-3">
                    <div className="col-md-12">
                      <h3>Agency Membership</h3>


                      <h4>Kinldy enter below details.</h4>

                      <fieldset>

                        <div className="row justify-content-between">
                          <div className="col-sm-12">
                            <div>
                              <div className="row">

                                <div className="col-sm-12 form_col">
                                  <p>Are you a memeber of a consortium or affiliation?*</p>
                                  <div className="btn-group radiobtngroup" role="group" aria-label="Basic radio toggle button group">
                                    <input type="radio" className="btn-check" name="consortium" onChange={(event) => handleChange(event)} checked={posts.consortium === 'yes'} value="yes" id="btnradio1" autoComplete="off" />
                                    <label className="btn btn-outline-primary" for="btnradio1">Yes</label>

                                    <input type="radio" className="btn-check" name="consortium" onChange={(event) => handleChange(event)} checked={posts.consortium === 'no'} value="no" id="btnradio2" autoComplete="off" />
                                    <label className="btn btn-outline-primary" for="btnradio2">No</label>
                                  </div>
                                </div>

                                <div className="col-sm-12 form_col">
                                  <p>Are you an ARC / IATA member?*</p>
                                  <div className="btn-group radiobtngroup" role="group" aria-label="Basic radio toggle button group">
                                    <input type="radio" className="btn-check" name="IATA" onChange={(event) => handleChange(event)} checked={posts.IATA === 'yes'} value="yes" id="btnradio3" autoComplete="off" />
                                    <label className="btn btn-outline-primary" for="btnradio3">Yes</label>

                                    <input type="radio" className="btn-check" name="IATA" onChange={(event) => handleChange(event)} checked={posts.IATA === 'no'} value="no" id="btnradio4" autoComplete="off" />
                                    <label className="btn btn-outline-primary" for="btnradio4">No</label>
                                  </div>
                                </div>

                                <div className="col-sm-12 form_col">
                                  <p>Are you a CLIA member?*</p>
                                  <div className="btn-group radiobtngroup" role="group" aria-label="Basic radio toggle button group">
                                    <input type="radio" name="CLIA" onChange={(event) => handleChange(event)} checked={posts.CLIA === 'yes'} value="yes" className="btn-check" id="btnradio5" autoComplete="off" />
                                    <label className="btn btn-outline-primary" for="btnradio5">Yes</label>

                                    <input type="radio" name="CLIA" onChange={(event) => handleChange(event)} checked={posts.CLIA === 'no'} value="no" className="btn-check" id="btnradio6" autoComplete="off" />
                                    <label className="btn btn-outline-primary" for="btnradio6">No</label>
                                  </div>
                                </div>

                                <div className="col-sm-12 form_col">
                                  <p>Are you an ASTA member?*</p>
                                  <div className="btn-group radiobtngroup" role="group" aria-label="Basic radio toggle button group">
                                    <input type="radio" className="btn-check" name="ASTA" onChange={(event) => handleChange(event)} checked={posts.ASTA === 'yes'} value="yes" id="btnradio7" autoComplete="off" />
                                    <label className="btn btn-outline-primary" for="btnradio7">Yes</label>

                                    <input type="radio" className="btn-check" name="ASTA" onChange={(event) => handleChange(event)} checked={posts.ASTA === 'no'} value="no" id="btnradio8" autoComplete="off" />
                                    <label className="btn btn-outline-primary" for="btnradio8">No</label>
                                  </div>
                                </div>

                                <div className="col-sm-12 form_col">
                                  <p>Do you use a reservation system?</p>
                                  <div className="btn-group radiobtngroup" role="group" aria-label="Basic radio toggle button group">
                                    <input type="radio" className="btn-check" name="reservation" onChange={(event) => handleChange(event)} checked={posts.reservation === 'yes'} value="yes" id="btnradio9" autoComplete="off" />
                                    <label className="btn btn-outline-primary" for="btnradio9">Yes</label>

                                    <input type="radio" className="btn-check" name="reservation" onChange={(event) => handleChange(event)} checked={posts.reservation === 'no'} value="no" id="btnradio10" autoComplete="off" />
                                    <label className="btn btn-outline-primary" for="btnradio10">No</label>
                                  </div>
                                </div>

                              </div>
                            </div>


                          </div>
                        </div>
                        <button type="button" onClick={() => setGoSteps(2)} className="btn btn-primary nextBtn btn-lg pull-right submitBtn">Next</button>
                      </fieldset>



                    </div>
                  </div>

                )}
                {goSteps === 2 && (
                  <div class="col-xs-6 col-md-offset-3">
                    <div class="col-md-12">
                      <h3>Summary and Confirm registration</h3>
                      {message}
                      <div class="formholder">
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th><h4>Name and address</h4></th>
                                <th><button onClick={(e) => goToStep0(e,posts)} class="pull-right EditBtn"><i class="bi bi-pencil-square"></i>Edit</button></th>
                              </tr>
                              <tr>
                                <th>Title</th>
                                <th>Detail</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>Name</th>
                                <td>{posts.fname} {posts.lname}</td>
                              </tr>
                              <tr>
                                <th>Mobile</th>
                                <td>{posts.mobile}</td>
                              </tr>
                              <tr>
                                <th>Email</th>
                                <td>{posts.email}</td>
                              </tr>
                              <tr>
                                <th>Agency Name</th>
                                <td>{posts.agency}</td>
                              </tr>
                              <tr>
                                <th>Address</th>
                                <td>{posts.address}</td>
                              </tr>
                              <tr>
                                <th>Zip Code </th>
                                <td>{posts.zipcode}</td>
                              </tr>
                              <tr>
                                <th>City</th>
                                <td>{posts.city}</td>
                              </tr>
                              <tr>
                                <th>State</th>
                                <td>{posts.state}</td>
                              </tr>
                              <tr>
                                <th>Country</th>
                                <td>{posts.country}</td>
                              </tr>
                              
                            </tbody>
                          </table>

                        </div>

                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th><h4>Agency Membership</h4></th>
                                <th><button onClick={() => setGoSteps(1)} class="pull-right EditBtn"><i class="bi bi-pencil-square"></i>Edit</button></th>
                              </tr>
                              <tr>
                                <th>Title</th>
                                <th>Detail</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>Are you a memeber of a consortium or affiliation?*</th>
                                <td>{posts.consortium}</td>
                              </tr>
                              <tr>
                                <th>Are you an ARC / IATA member?*</th>
                                <td>{posts.IATA}</td>
                              </tr>
                              <tr>
                                <th>Are you a CLIA member?*</th>
                                <td>{posts.CLIA}</td>
                              </tr>
                              <tr>
                                <th>Are you an ASTA member?*</th>
                                <td>{posts.ASTA}</td>
                              </tr>
                              <tr>
                                <th>Do you use a reservation system?</th>
                                <td>{posts.reservation}</td>
                              </tr>
                            </tbody>
                          </table>

                        </div>
                        <button name="submit" class="btn btn-success btn-lg pull-right submitBtn" type="submit">Submit</button>
                      </div>
                      {/* <button class="btn btn-primary prevBtn btn-lg pull-left submitBtn" type="button">Previous</button> */}
                      {/* <button onClick={() => setGoSteps(3)} class="btn btn-success btn-lg pull-right submitBtn" type="submit">Submit</button> */}
                    </div>
                  </div>
                )}
              </div>
            </form>
            {/* <!-- form box Ends --> */}

          </div>

        </div>
      </div>
      {/* <!-- form container end --> */}

      <Footer />
    </>
  )
}

export default Registration


