import React from 'react'
import { Link } from 'react-router-dom'

const AgencyLeftMenu = () => {
  return (
    <div>
      <div class="leftCntr">
        <div class="profile_imageBlock">
        <figure class="image"><span class="changeColor">RS</span></figure>
        <div class="name_col">
            <div class="name"> <span class="displayusername_2">Rajdeep Singh</span>
            <div class="small"> Last login: <br class="d-none d-xl-block"/>
                <span class="LastLoginDate">Dec 03, 2023, 10:48:50 PM</span> </div>
            </div>
        </div>
        </div>
        <div class="leftMenu">
            <ul>
            <li><Link to="/myagency" class="mytrip active"><i class="bi bi-journal-check"></i> My Agency</Link></li>
            <li><Link to="/bankdetail" class="myinformation"><i class="bi bi-person-lines-fill"></i> Bank details and contract data</Link></li>
            <li><Link to="/gdsagency" class="writeus"><i class="bi bi-pencil-square"></i> GDS and IATA data</Link></li>
            <li><Link to="/mycoleagues" class="mycolleagues"><i class="bi bi-gear"></i> My colleagues</Link></li>
            {/* <li><Link to="/profilesetting" class="setting"><i class="bi bi-gear"></i> Change Password</Link></li> */}
            <li><Link to="/logout" class="signout"><i class="bi bi-box-arrow-in-right"></i> Sign Out</Link></li>
        </ul>
        </div>
    </div>
    </div>
  )
}

export default AgencyLeftMenu
