import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ImagesPath from '../components/ImagesPath'
import HeaderUser from "../components/HeaderUser";
import { getStorageData} from '../global';

const ToolNServices = () => {
  return (
    <div>
     {getStorageData('userSession')?.id!=null?<HeaderUser/>:<Header/>}

      {/* <!--  / Banner container \ --> */}
      <div id="InnerBannerCntr" style={{ background:`url(${ImagesPath.ToolNServices_banner})`, backgroundSize: "cover"}}>
        <div className="container">
          <div className='content'>
            <h1>Tools & Services</h1>
            <p>Our high-tech booking system is the key to your success. </p>
          </div>
        </div>
      </div>
      {/* <!--  \ Banner container / --> */}

      {/* <!--  / about container \ --> */}
      <div id="aboutCntr">
        <div className="container">
            <div className='img'>
              <img src={ImagesPath.about_img1} alt="about_img1" />
            </div>
            <h2 className='content'>Tools and Services</h2>
            <p>We at FlyUS Travels know what B2B partners need and how to give it to them. Our Tools and Services are designed to help your business be more efficient, come up with new ideas, and connect with others easily. We will give you the tools to improve your services and your relationships with customers, so you can explore a world of options.</p>
            <div className='clearfix'></div>
        </div>

        <div className="container">
            
            <h4>Platform for booking:</h4>
            <p>Our high-tech booking system is the key to your success. It's designed for business-to-business partners and has a simple interface, real-time availability, and a huge selection of flights, hotels, and car rentals. Make your business run more smoothly and make booking easy for your customers.</p>

            <h4>Customizable APIs:</h4>
            <p>With our flexible APIs, you can easily add FlyUS Travels to the systems you already have in place. You can easily improve your website or app by using our large database of travel information, which includes flights, hotels, and more.</p>

            <h4>Solutions with a white label:</h4>
            <p>Make your brand stand out with our White Label Solutions. By adding your logo to our platform, you can make the experience for your clients more seamless and consistent while taking advantage of FlyUS Travels's cutting-edge technology.</p>

            <h4>Business Intelligence Tools:</h4>
            <p>With our Business Intelligence Tools, you can make smart choices. You can improve your strategies and stay ahead in the competitive travel world by learning about market trends, customer behavior, and performance metrics.</p>

            <h4>Personal account management:</h4>
            <p>With our Dedicated Account Management, you'll get personalized help. A dedicated team is put together to learn about your specific needs and make sure you get custom solutions and proactive help to help your business grow.</p>

            <h4> Help and training:</h4>
            <p>Our training and support services will help you stay on top of the latest information in your field. FlyUS Travels offers thorough training sessions to keep your team up to date on the newest features and make sure you get the most out of our tools and services.</p>
            <p>Work with FlyUS Travels for a B2B experience that will change your life. Our Tools and Services are made to not only meet your needs but also go above and beyond them. This way, you can focus on what you do best: giving your clients the best travel options. Improve your business-to-business trip with FlyUS Travels.</p>
        </div>
      </div>
      {/* <!--  \ about container / --> */}

      <Footer/>
    </div>
  )
}

export default ToolNServices


