import React, { useState } from "react";
import HeaderUser from "../../components/HeaderUser";
import Footer from "../../components/Footer";
//import airportData from "./airport.json";
//import index from "../Home";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode,Navigation } from 'swiper/modules';

//import { apiBaseUrl,engineBaseUrl } from '../../global';
//import { useAuth } from "../../provider/authProvider";
import { useNavigate } from "react-router-dom";
import GenericForm from './GenericForm';
//import AirNews from "../../components/AirNews";
//import $ from 'jquery';
//import Passanger from './leftfilter/Passanger';
//import "jquery-ui-dist/jquery-ui";
const UserHome = () => {
  //const { token } = useAuth();
  const navigate = useNavigate();
  const [isSearchVisible, setIsSearchVisible] = useState(false);
const searchShow = () => {
  setIsSearchVisible(true);
  

};
const searchHide = () => {
setIsSearchVisible(false);
};

  

  return (
    <div>
      
      <HeaderUser />
      
      {/* <!--  / banneruser container \ --> */}
      <div id="banneruserCntr">
        <div className="container">
          {/* <div class="notification">
            <ul>
              <li><a href="">Last searches <span>0</span></a></li>
              <li><a href="">New search</a></li>
              <li><a href="">Notifications: <span>0</span></a></li>
            </ul>
          </div> */}
          <div className="row">
            <div className="col-sm-12">
            <GenericForm searchHide={searchHide} isSearchVisible={true} closeButtonShow={false} />
            </div>
          </div>
        </div>
      </div>
      {/* <!--  \ banneruser container / --> */}

      {/* <!--  / airlineLogo container \ --> */}
      {/* <div id="airlineLogoCntr">
        <div className="container">
          <div className="sliderBlock">
            <Swiper
            slidesPerView={12}
            spaceBetween={10}
            freeMode={true}
            //centeredSlides={true}
            navigation={true}
            modules={[FreeMode, Navigation]}
            className="mySwiper"
            breakpoints={{
              480: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 6,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 8,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 10,
                spaceBetween: 10,
              },
            }}
          >
            <SwiperSlide><img src="../images/airline/8Z.jpg" alt="8Z" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9A.jpg" alt="9A" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9B.jpg" alt="9B" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9C.jpg" alt="9C" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9E.jpg" alt="9E" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9F.jpg" alt="9F" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9G.jpg" alt="9G" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9H.jpg" alt="9H" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9I.jpg" alt="9I" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9J.jpg" alt="9J" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9K.jpg" alt="9K" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9l.jpg" alt="9l" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9M.jpg" alt="9M" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9N.jpg" alt="9N" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9P.jpg" alt="9P" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9Q.jpg" alt="9Q" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9R.jpg" alt="9R" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9S.jpg" alt="9S" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9T.jpg" alt="9T" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9U.jpg" alt="9U" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/8Z.jpg" alt="8Z" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9A.jpg" alt="9A" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9B.jpg" alt="9B" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9C.jpg" alt="9C" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9E.jpg" alt="9E" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9F.jpg" alt="9F" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9G.jpg" alt="9G" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9H.jpg" alt="9H" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9I.jpg" alt="9I" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9J.jpg" alt="9J" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9K.jpg" alt="9K" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9l.jpg" alt="9l" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9M.jpg" alt="9M" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9N.jpg" alt="9N" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9P.jpg" alt="9P" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9Q.jpg" alt="9Q" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9R.jpg" alt="9R" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9S.jpg" alt="9S" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9T.jpg" alt="9T" /></SwiperSlide>
            <SwiperSlide><img src="../images/airline/9U.jpg" alt="9U" /></SwiperSlide>
            </Swiper>

            <div className="sliderbottom">
              <a className="editlist" href="#">
                <i className="bi bi-pencil-square"></i> Edit List
              </a>
              <a className="editlist" href="#">
                {" "}
                All Airline
              </a>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!--  \ airlineLogo container / --> */}

      {/* <!--  / Air News container \ --> */}
      {/* <AirNews/> */}
      {/* <!--  \ Air News container / --> */}

      {/* <!--  / service box \ --> */}
      <div class="commonserviceBox">

        <div class="container">
          <div class="row">
              
                  <div class="col-sm-12">
                  <div class="service_slider"><div class="service_block">
                      <img src="../images/icons/support.svg" class="icon" alt=""/>
                        <div class="detail">
                          <h3>24/7 Support</h3>
                            <p>24x7 support service provider</p>
                        </div>
                    </div><div class="service_block">
                      <img src="images/icons/secure.svg" class="icon" alt=""/>
                        <div class="detail">
                          <h3>Secure Booking</h3>
                            <p>Find best holiday destination</p>
                        </div>
                    </div><div class="service_block">
                      <img src="images/icons/best-price.svg" class="icon" alt=""/>
                        <div class="detail">
                          <h3>Best Price Deal</h3>
                            <p>Best price deal with us</p>
                        </div>
                    </div></div>
                
              
                </div>
            </div>
        </div>

      </div>
      {/* <!--  \ service box / --> */}

      {/* <!--  /  packages box \ --> */}
      <div class="sliderwithinfo">
        <div className="container">

          <h2 class="page_title"><span>Best</span>  Packages <a href="#" class="viewall redBtn">View All</a></h2>
          
          <div class="holder">
          <Swiper
            slidesPerView={4}
            spaceBetween={10}
            freeMode={true}
            //centeredSlides={true}
            navigation={true}
            modules={[FreeMode, Navigation]}
            className="mySwiper"
            breakpoints={{
              480: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
          >
            <SwiperSlide>
              <div class="items"><div class="block"><figure><img src="images/package/thailand.jpg" alt="thailand" /> 
              <figcaption><strong>THAILAND</strong> | 5D/4N</figcaption></figure>
                <div class="detail">
                    <div class="price">
                      {/* <span class="discount d-none">$9200</span>  */}
                      <div>
                        $227 / <span className="perPerson">Per Person</span>
                      </div>
                      <button className="redBtn">Book Now</button>
                    </div>
                    <p>Cost based on 2 Pax travelling together</p>
                </div>
              </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div class="items"><div class="block"><figure><img src="images/package/singapore.jpg" alt="singapore" /> 
              <figcaption><strong>SINGAPORE</strong> | 5D/4N</figcaption></figure>
                <div class="detail">
                    <div class="price">
                      {/* <span class="discount">$22000</span> */}
                      <div>
                      $615 / <span className="perPerson">Per Person</span>
                      </div>
                      <button className="redBtn">Book Now</button>
                    </div>
                    <p>Cost based on 2 Pax travelling together</p>
                </div>
              </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div class="items"><div class="block"><figure><img src="images/package/malaysia.jpg" alt="malaysia" /> 
              <figcaption><strong>MALAYSIA</strong> | 5D/4N</figcaption></figure>
                <div class="detail">
                    <div class="price">
                      {/* <span class="discount">$25999</span> */}
                      <div>
                      $240 / <span className="perPerson">Per Person</span>
                      </div>
                      <button className="redBtn">Book Now</button>
                    </div>
                    <p>Cost based on 2 Pax travelling together</p>
                </div>
              </div>
              </div>
              </SwiperSlide>

              <SwiperSlide>
              <div class="items"><div class="block"><figure><img src="images/package/dubai.jpg" alt="dubai" /> 
              <figcaption><strong>DUBAI</strong> | 5D/4N</figcaption></figure>
                <div class="detail">
                  <div class="price">
                    {/* <span class="discount">$25999</span> */}
                    <div>
                    $400 / <span className="perPerson">Per Person</span>
                    </div>
                    <button className="redBtn">Book Now</button>
                  </div>
                  <p>Cost based on 2 Pax travelling together</p>
                </div>
              </div>
              </div>
              </SwiperSlide>

              <SwiperSlide>
              <div class="items"><div class="block"><figure><img src="images/package/bali.jpg" alt="bali" /> 
              <figcaption><strong>BALI</strong> | 6D/5N</figcaption></figure>
                <div class="detail">
                  <div class="price">
                    {/* <span class="discount">$25999</span> */}
                    <div>
                    $370 / <span className="perPerson">Per Person</span>
                    </div>
                    <button className="redBtn">Book Now</button>
                  </div>
                  <p>Cost based on 2 Pax travelling together</p>
                </div>
              </div>
              </div>
              </SwiperSlide>

              <SwiperSlide>
              <div class="items"><div class="block"><figure><img src="images/package/maldives.jpg" alt="maldives" /> 
              <figcaption><strong>MALDIVES</strong> | 5D/4N</figcaption></figure>
                <div class="detail">
                  <div class="price">
                    {/* <span class="discount">$25999</span> */}
                    <div>
                    $1100 / <span className="perPerson">Per Person</span>
                    </div>
                    <button className="redBtn">Book Now</button>
                  </div>
                  <p>Cost based on 2 Pax travelling together</p>
                </div>
              </div>
              </div>
              </SwiperSlide>

            </Swiper>
          </div>
        </div>
      </div>
      {/* <!--  \  packages box / --> */}

      {/* <!--  /  Destination box \ --> */}
      <div class="sliderwithinfo">
        <div className="container">

          <h2 class="page_title"><span>Popular</span>  Destination <a href="#" class="viewall redBtn">View All</a></h2>
          
          <div class="holder">
          <Swiper
            slidesPerView={4}
            spaceBetween={10}
            freeMode={true}
            //centeredSlides={true}
            navigation={true}
            modules={[FreeMode, Navigation]}
            className="mySwiper"
            breakpoints={{
              480: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
          >
            <SwiperSlide>
              <div class="items"><div class="block"><figure><img src="images/package/thailand.jpg" alt="thailand" /> 
              <figcaption><strong>THAILAND</strong> | 5D/4N</figcaption></figure>
                <div class="detail">
                    <div class="price">
                      {/* <span class="discount d-none">$9200</span>  */}
                      <div>
                        $227 / <span className="perPerson">Per Person</span>
                      </div>
                      <button className="redBtn">Book Now</button>
                    </div>
                    <p>Cost based on 2 Pax travelling together</p>
                </div>
              </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div class="items"><div class="block"><figure><img src="images/package/singapore.jpg" alt="singapore" /> 
              <figcaption><strong>SINGAPORE</strong> | 5D/4N</figcaption></figure>
                <div class="detail">
                    <div class="price">
                      {/* <span class="discount">$22000</span> */}
                      <div>
                      $615 / <span className="perPerson">Per Person</span>
                      </div>
                      <button className="redBtn">Book Now</button>
                    </div>
                    <p>Cost based on 2 Pax travelling together</p>
                </div>
              </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div class="items"><div class="block"><figure><img src="images/package/malaysia.jpg" alt="malaysia" /> 
              <figcaption><strong>MALAYSIA</strong> | 5D/4N</figcaption></figure>
                <div class="detail">
                    <div class="price">
                      {/* <span class="discount">$25999</span> */}
                      <div>
                      $240 / <span className="perPerson">Per Person</span>
                      </div>
                      <button className="redBtn">Book Now</button>
                    </div>
                    <p>Cost based on 2 Pax travelling together</p>
                </div>
              </div>
              </div>
              </SwiperSlide>

              <SwiperSlide>
              <div class="items"><div class="block"><figure><img src="images/package/dubai.jpg" alt="dubai" /> 
              <figcaption><strong>DUBAI</strong> | 5D/4N</figcaption></figure>
                <div class="detail">
                  <div class="price">
                    {/* <span class="discount">$25999</span> */}
                    <div>
                    $400 / <span className="perPerson">Per Person</span>
                    </div>
                    <button className="redBtn">Book Now</button>
                  </div>
                  <p>Cost based on 2 Pax travelling together</p>
                </div>
              </div>
              </div>
              </SwiperSlide>

              <SwiperSlide>
              <div class="items"><div class="block"><figure><img src="images/package/bali.jpg" alt="bali" /> 
              <figcaption><strong>BALI</strong> | 6D/5N</figcaption></figure>
                <div class="detail">
                  <div class="price">
                    {/* <span class="discount">$25999</span> */}
                    <div>
                    $370 / <span className="perPerson">Per Person</span>
                    </div>
                    <button className="redBtn">Book Now</button>
                  </div>
                  <p>Cost based on 2 Pax travelling together</p>
                </div>
              </div>
              </div>
              </SwiperSlide>

              <SwiperSlide>
              <div class="items"><div class="block"><figure><img src="images/package/maldives.jpg" alt="maldives" /> 
              <figcaption><strong>MALDIVES</strong> | 5D/4N</figcaption></figure>
                <div class="detail">
                  <div class="price">
                    {/* <span class="discount">$25999</span> */}
                    <div>
                    $1100 / <span className="perPerson">Per Person</span>
                    </div>
                    <button className="redBtn">Book Now</button>
                  </div>
                  <p>Cost based on 2 Pax travelling together</p>
                </div>
              </div>
              </div>
              </SwiperSlide>

            </Swiper>
          </div>
        </div>
      </div>
      {/* <!--  \  Destination box / --> */}

      <Footer />
    </div>
  );
};
export default UserHome;
