import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ImagesPath from '../components/ImagesPath'
import HeaderUser from '../components/HeaderUser';
import {getStorageData} from '../global';
const OurCompany = () => {
  return (
    <div>
      {getStorageData('userSession')?.id!=null?<HeaderUser/>:<Header/>}

      {/* <!--  / Banner container \ --> */}
      <div id="InnerBannerCntr" style={{ background:`url(${ImagesPath.ourCompanyBanner})`, backgroundSize: "cover"}}>
        <div className="container">
          <div className='content'>
            <h1>Our Company</h1>
            <p>we want to be your partner</p>
          </div>
        </div>
      </div>
      {/* <!--  \ Banner container / --> */}

      {/* <!--  / about container \ --> */}
      <div id="aboutCntr">
        <div className="container">
            <h2 className='content d-block d-md-none'>Our Company</h2>
            <div className='img'>
              <img src={ImagesPath.about_img1} alt="about_img" />
            </div>
            <h2 className='content d-none d-md-block'>Our Company</h2>
            <p><strong>Welcome to FlyUS Travels, where you can find unique travel experiences.</strong></p>
            <p>At FlyUS Travels, we're proud to be more than just a travel company; we want to be your partner as you travel the world. Our company was started by people who love to travel, are dedicated to doing the best job possible, and want to change the way you travel.</p>
          
        </div>
      </div>
      {/* <!--  \ about container / --> */}

      {/* <!--  / mission container \ --> */}
      <div id="missionCntr">
        <div className="container">
            <div className='holder'>
              <div className='block'>
                <div className='icon'><img src={ImagesPath.mission} alt="mission" /></div>
                <h4>Our Mission</h4>
                <p>Our goal is to help you reach your travel goals. Whether you're a business, travel agency, or tour operator, we're committed to giving you the best services possible and making sure that every trip is smooth, memorable, and just right.</p>
              </div>
              
              <div className='block'>
                <div className='icon'><img src={ImagesPath.whyChoose} alt="whyChoose" /></div>
                <h4>Why You Should Pick Us</h4>
                <p>What makes FlyUS Travels unique is that we are always committed to your success. Our dedication to excellence is built into every part of our business, from our cutting-edge technology to our low prices and personalized service. We put your needs first and make sure that our platform can fit the needs of your business.</p>
              </div>

              <div className='block'>
                <div className='icon'><img src={ImagesPath.team} alt="team" /></div>
                <h4>Our Team</h4>
                <p>Get to know the passionate people who run FlyUS Travels. The people on our team are experts in the field, tech-savvy, and travel fans who work together to give you new ideas. You're not just a customer when you work with us; you're an important part of our journey through the world.</p>
              </div>
            </div>
            <p>Check out our website to learn about all the services we provide, and join us in making travel experiences that go beyond what you expect. FlyUS Travels: The Start of Your Trip!</p>
        </div>
      </div>
      {/* <!--  \ mission container / --> */}

      <Footer/>
    </div>
  )
}

export default OurCompany


