import {

   Wifi, 
   AirlineSeatReclineNormal,
   Cable,
   OndemandVideo,
   Fastfood
  } from '@mui/icons-material';
  import { useState } from 'react'
  import {
     FormControlLabel,
      Radio,
   // RadioGroup,
  
} from '@mui/material';
import moment from"moment";

  import ImagesPath from '../../../components/ImagesPath';
import { Button } from 'react-bootstrap';
import { getClassShort,formatEft } from '../function';
import { apiBaseUrl} from "../../../global";
const Faredetail= ({id,visibleAllDivIndex,searchRq,first,url,Airline,Airport,markup,setLocalStorage}) =>{

   // console.log(first)
    const [isPriceSow, setIsPriceSow] = useState(false);
    const [PriceSow, setPriceSow] = useState(false);
   
    const priceBreakdowHnadle = () => {
        //console.log(isPriceSow)
        setPriceSow(!PriceSow)
      setIsPriceSow(!isPriceSow);
    };
    const ShowPrice = `price_breakdown ${isPriceSow ? 'showPrice table-responsive' : 'hidePrice'}`;
 
    let AdultBaseFare=0;
    let ChildsBaseFare=0;
    let InfantsBaseFare=0;
    let InfantPass=0;
    let ChildPass=0;
    let AdultPass=0;
if(first.Adults!=='null' && first.Adults.Pass!==undefined){
     AdultBaseFare=first.Adults.BaseFare;
     AdultPass=first.Adults.Pass;
}
if(first.Childs!=='null'  && first.Childs.Pass!==undefined){
    ChildsBaseFare=first.Childs.BaseFare;
    ChildPass=first.Childs.Pass;
}
if(first.Infants!=='null'  && first.Infants.Pass!==undefined){
   InfantsBaseFare=first.Infants.BaseFare;
  
}

let AdultChildPass=parseInt(ChildPass)+parseInt(AdultPass);

if(markup!=null && markup!='' ){
  ChildsBaseFare=(parseFloat(first.Childs.BaseFare)-parseFloat(markup)).toFixed(2)
 
  AdultBaseFare=(parseFloat(first.Adults.BaseFare)-parseFloat(markup)).toFixed(2)

}else{
    markup=0.00;
}
const [searchRequest, setsearchRequest]=useState();
//let searchRq=

const HandleFareRules= (firstData,searchRq1)=>{
    console.log(firstData)
    //debugger;
    let obidarr=[];
 firstData.Flights.map((index,ii)=>{
    obidarr[ii]=index.ObId
    })
    console.log(obidarr)
    //let ibId=firstData.Flights[1].ObId
  let searchRq={trcaId:firstData.TraceId,
                itinId:firstData.ItinId,
                segmentId:obidarr,
                userId:searchRq1.userId,
                website:searchRq1.website
                }
                //console.log(searchRq)
    var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Accept", "application/json");
  const url = apiBaseUrl + "/api/fareRule";
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(searchRq),
    redirect: "follow",
  };
  fetch(url, requestOptions)
  .then((response) => response.json())
  .then((result)=>{
    console.log(result)
  })


 }


  return (
<>

<div className='available_flight' style={{ display: visibleAllDivIndex.includes(id) ? "block" : "none" }}>

                                    {first.Flights.map((iii,index)=> 

                                 <div className='available_flight_row' key={'key'+index}>
                                    
                                    <FormControlLabel value="female" control={<Radio />} />
                                    <div className='itnery_flight_row'>
                                            {iii.Segments.map((segm,ind)=>
                                               
                                                    <div key={ind+"segmm"} className='itnery_flight'>
                                
                                                    <div className='flight_img_code_number'>
                                                        {/* <FormControlLabel value="female" control={<Radio />} /> */}
                                                        <a  href='/'><img src={require('../../../resources/airlines/'+segm.MktAir+'.jpg')}  alt={segm.MktAir} /></a>
                                                        {/* <a href='/'><img src={ImagesPath.canada} alt={segm.MktAir} /></a> */}
                                                    
                                                        <span className='code'>{segm.MktAir}</span>
                                                        <span className='code'>{segm.FltNum}</span>
                                                    </div>
                                                    <div className='flight_date_airport_code'>
                                                        <span>{moment(segm.DepDt).format('ddd')},</span>
                                                        <span>{moment(segm.DepDt).format('DDMMM')}</span>
                                                        <span>{segm.From} - {segm.To}</span>
                                                    </div>
                                                    {/* {JSON.stringify(segm)} */}
                                                    <div className='airport_time_duration_booking_class'>
                                                        <span>{moment(segm.DepDt).format('hh:mm a')} - {moment(segm.ArrDt).format('hh:mm a')}</span>
                                                        <span>{formatEft(segm.Eft)}</span>
                                                        <span>{getClassShort(segm.Cabin)}.</span>
                                                    </div>
                                                    <div className='airbus'>/{segm.EqptType}</div>
                                                    <div className='flight_amenities'>
                                                        <ul>
                                                            <li><AirlineSeatReclineNormal/></li>
                                                            <li><Wifi/></li>
                                                            <li><Cable/></li>
                                                            <li><OndemandVideo/></li>
                                                            <li><Fastfood/></li>
                                                        </ul>
                                                    </div>
                                                    </div>
                                                

                                    )} 
                                    </div>                           
                                </div>
                                    )}
                            
                              <div className='buttongroup'>
                                  <Button  onClick={() => setLocalStorage(first,first.TraceId,id,searchRq,url,Airline,Airport,markup)}  className='booknowBtn'>Book now</Button>
                                  <div>
                                      <button className='rightBtn'>Offer</button>
                                      <button onClick={()=>HandleFareRules(first,searchRq)} className='rightBtn'>Rules</button>
                                      <button className='rightBtn'>Seats</button>
                                      <button className='rightBtn'>Extras</button>
                                      <button onClick={priceBreakdowHnadle} className='rightBtn'>Price Breakdown</button>
                                  </div>
                              </div>
                              {/* price_breakdown */}
                            <div className={ShowPrice}>
                            <h4 className='mb-3'>Price breakdown</h4>
                                <table className="table">
                                    
                                <thead>
                                    <tr>
                                        <th>Pax Type</th>
                                        <th>Pax Count</th>
                                        <th>Base Fare</th>
                                        <th>Tax</th>
                                        <th>Total Fare</th>
                                    </tr>
                                </thead>
                                    <tbody>
                                    {first.Adults.Pass && 
                                        <tr>
                                            <th>Adults</th>
                                            <td>{first.Adults.Pass}</td>
                                            <td>$ {AdultBaseFare}</td>
                                            <td>$ {first.Adults.Tax || 0}</td>
                                            <td>$ {((parseFloat(AdultBaseFare)+parseFloat(first.Adults.Tax))*parseFloat(first.Adults.Pass)).toFixed(2)}</td>
                                        </tr>
                                        }
                                        {first.Childs.Pass && 
                                        <tr>
                                            <th>Childs</th>
                                            <td>{first.Childs.Pass}</td>
                                            <td>$ {ChildsBaseFare}</td>
                                            <td>$ {first.Childs.Tax || 0}</td>
                                            <td>$ {((parseFloat(ChildsBaseFare)+parseFloat(first.Childs.Tax))*parseFloat(first.Childs.Pass)).toFixed(2)}</td>
                                        </tr>
                                        }
                                        {first.Infants.Pass && 
                                        <tr>
                                            <th>Infants</th>
                                            <td>{first.Infants.Pass}</td>
                                            <td>$ {InfantsBaseFare}</td>
                                            <td>$ {first.Infants.Tax || 0}</td>
                                            <td>$ {((parseFloat(InfantsBaseFare)+parseFloat(first.Infants.Tax))*parseFloat(first.Infants.Pass)).toFixed(2)}</td>
                                        </tr>
                                        }
                                        <tr>
                                            <th>Markup</th>
                                            <td> </td>
                                            <td> </td>
                                            <td> </td>
                                            <td>$ {(parseFloat(markup)*parseInt(AdultChildPass)).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <th className='total'>Total</th>
                                            <td className='total'> </td>
                                            <td className='total'> </td>
                                            <td className='total'> </td>
                                            <td className='total'><strong>$ {first.TotalFare}</strong></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* price_breakdown */}
                              



                      </div>

                         
                         
    </>
    );

}
export default Faredetail;