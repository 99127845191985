import React from 'react';
import { Stepper, Step } from "react-form-stepper";
import { useState } from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';
import ImagesPath from '../components/ImagesPath';
import { Link } from 'react-router-dom';


const RegistrationSuccesful = () => {
  const [goSteps, setGoSteps] = useState(0);
  
  return (
    <>
    <Header/>

    {/* <!-- form container start --> */}
    <div id="formCntr">
        <div className='container'>

          <Stepper activeStep={goSteps}>
            <Step onClick={() => setGoSteps(0)} label="Name and address" />
            <Step onClick={() => setGoSteps(1)} label="Agency Membership" />
            <Step onClick={() => setGoSteps(2)} label="Confirm registration" />
          </Stepper>
          <div class="formholder">

              {/* <!-- form box start --> */}
              <div class="formBox">
                {goSteps === 0 && (
                  
                  <div class="col-xs-6 col-md-offset-3">
                    <div class="col-md-12">
                      <h3>Name and address</h3>
                      
                      <h4>Kinldy enter below details.</h4>
                      <form action="#">
                          <fieldset>

                              <div class="row justify-content-between">
                                  <div class="col-sm-12">
                                      <div>
                                          <div class="row">
                                              <div class="col-sm-12 col-md-3 form_col">
                                                  <div class="form-floating">
                                                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                                        <option value="">Title</option>
                                                        <option value="">Ms.</option>
                                                        <option value="">Mr.</option>
                                                        <option value="">Mrs.</option>
                                                        <option value="">Dr.</option>
                                                    </select>
                                                    <label for="floatingSelect">Selects Coutry</label>
                                                  </div>
                                              </div>
                                              <div class="col-sm-12 col-md-3 form_col">
                                                  <div class="form-floating">
                                                      <input class="form-control input" id="floatingInputValue" name="" value="" type="text" placeholder="First name* "/>
                                                      <label for="floatingInputValue">First name*</label>
                                                  </div>
                                              </div>
                                              <div class="col-sm-12 col-md-3 form_col">
                                                  <div class="form-floating">
                                                      <input class="form-control input" id="floatingInputValue" name="" value="" type="text" placeholder="Last Name* "/>
                                                      <label for="floatingInputValue">Last Name*</label>
                                                  </div>
                                              </div>
                                          
                                              <div class="col-sm-12 col-md-3 form_col">
                                                  <div class="form-floating">
                                                      <input class="form-control input" id="floatingInputValue" name="" value="" type="text" placeholder="e.g. Liberty Travel* "/>
                                                      <label for="floatingInputValue">Agency name*</label>
                                                  </div>
                                              </div>
                                              <div class="col-sm-12 col-md-4 form_col">
                                                  <div class="form-floating">
                                                      <input class="form-control input" id="floatingInputValue" name="" value="" type="text" placeholder="Address* "/>
                                                      <label for="floatingInputValue">Address*</label>
                                                  </div>
                                              </div>
                                              <div class="col-sm-12 col-md-4 form_col">
                                                  <div class="form-floating">
                                                      <input class="form-control input" id="floatingInputValue" name="" value="" type="text" placeholder="ZIP code* "/>
                                                      <label for="floatingInputValue">ZIP code*</label>
                                                  </div>
                                              </div>
                                          
                                              <div class="col-sm-12 col-md-4 form_col">
                                                <div class="form-floating">
                                                    <input class="form-control input" id="floatingInputValue" name="" value="" type="text" placeholder="City* "/>
                                                    <label for="floatingInputValue">City*</label>
                                                </div>
                                              </div>
                                              <div class="col-sm-12 col-md-6 form_col">
                                                <div class="form-floating">
                                                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                                        <option value="">Country</option>
                                                        <option value="">United States</option>
                                                        <option value="">Canada</option>
                                                        <option value="">other countries</option>
                                                    </select>
                                                    <label for="floatingSelect">Selects Coutry</label>
                                                </div>
                                              </div>
                                              
                                              <div class="col-sm-12 col-md-6 form_col">
                                                  <div class="form-floating">
                                                      <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                                          <option value="">State</option>
                                                          <option value="">MP</option>
                                                          <option value="">UP</option>
                                                          <option value="">Rajasthan</option>
                                                      </select>
                                                      <label for="floatingSelect">Selects State</label>
                                                  </div>
                                                  
                                              </div>
                                          
                                              <div class="col-sm-12 col-md-6 form_col">
                                                  <div class="form-floating">
                                                      <input class="form-control input" id="floatingInputValue"  name="" value="" type="tel" placeholder="Phone number"/>
                                                      <label for="floatingInputValue">Phone number</label>
                                                  </div>
                                              </div>
                                              <div class="col-sm-12 col-md-6 form_col">
                                                <div class="form-floating">
                                                    <input class="form-control input" id="floatingInputValue"  name="" value="" type="tel" placeholder="Mobile number"/>
                                                    <label for="floatingInputValue">Mobile number</label>
                                                </div>
                                              </div>

                                              <div class="col-sm-12 col-md-6 form_col">
                                                  <div class="form-floating">
                                                      <input class="form-control input" id="floatingInputValue" name="" value="" type="text" placeholder="Email address"/>
                                                      <label for="floatingInputValue">Email address</label>
                                                  </div>
                                              </div>

                                              <div class="col-sm-12 col-md-6 form_col">
                                                <div class="form-floating">
                                                    <input class="form-control input" id="floatingInputValue" name="" value="" type="text" placeholder="Website"/>
                                                    <label for="floatingInputValue">Website</label>
                                                </div>
                                            </div>
                                          </div>

                                          <div class="row">
                                              
                                              <div class="col-sm-12 form_col">
                                                  <div class="form-floating">
                                                      <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{height: '100px'}}></textarea>
                                                      <label for="floatingTextarea2">Comments</label>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      {/* <!-- <div class="row">
                                          <div class="col-sm-12 form_col">
                                              <a href="customerlp.html" class="submitBtn"><i class="fa-solid fa-right-to-bracket"></i> SignUp Now</a>
                                          </div>
                                      </div>
                                      <div class="row">
                                          <div class="col-sm-12 form_col">
                                              <p> Already registered? <a class="forgotlink" href="customer_login.html">SignIn</a></p>
                                          </div>
                                      </div> --> */}
                                      
                                  </div>
                              </div>
                              <button className="btn btn-primary nextBtn btn-lg pull-right submitBtn" onClick={() => setGoSteps(1)}>
                                Next
                              </button>
                          </fieldset>
                      </form>
                  
                      {/* <button class="btn btn-primary nextBtn btn-lg pull-right submitBtn" type="button">Next</button> */}
                      
                    </div>
                  </div>
                  
                )}
                {goSteps === 1 && (
                  <div class="col-xs-6 col-md-offset-3">
                  <div class="col-md-12">
                    <h3>Agency Membership</h3>
                    
                     
                    <h4>Kinldy enter below details.</h4>
                    <form action="#">
                        <fieldset>

                            <div class="row justify-content-between">
                                <div class="col-sm-12">
                                    <div>
                                        <div class="row">
                                          
                                            <div class="col-sm-12 form_col">
                                              <p>Are you a memeber of a consortium or affiliation?*</p>
                                              <div class="btn-group radiobtngroup" role="group" aria-label="Basic radio toggle button group">
                                                <input type="radio" class="btn-check" name="consortium" id="btnradio1" autocomplete="off"/>
                                                <label class="btn btn-outline-primary" for="btnradio1">Yes</label>
                                              
                                                <input type="radio" class="btn-check" name="consortium" id="btnradio2" autocomplete="off"/>
                                                <label class="btn btn-outline-primary" for="btnradio2">No</label>
                                              </div>
                                            </div>

                                            <div class="col-sm-12 form_col">
                                              <p>Are you an ARC / IATA member?*</p>
                                              <div class="btn-group radiobtngroup" role="group" aria-label="Basic radio toggle button group">
                                                <input type="radio" class="btn-check" name="IATA" id="btnradio3" autocomplete="off"/>
                                                <label class="btn btn-outline-primary" for="btnradio3">Yes</label>
                                              
                                                <input type="radio" class="btn-check" name="IATA" id="btnradio4" autocomplete="off"/>
                                                <label class="btn btn-outline-primary" for="btnradio4">No</label>
                                              </div>
                                            </div>

                                            <div class="col-sm-12 form_col">
                                              <p>Are you a CLIA member?*</p>
                                              <div class="btn-group radiobtngroup" role="group" aria-label="Basic radio toggle button group">
                                                <input type="radio" class="btn-check" name="CLIA" id="btnradio5" autocomplete="off"/>
                                                <label class="btn btn-outline-primary" for="btnradio5">Yes</label>
                                              
                                                <input type="radio" class="btn-check" name="CLIA" id="btnradio6" autocomplete="off"/>
                                                <label class="btn btn-outline-primary" for="btnradio6">No</label>
                                              </div>
                                            </div>

                                            <div class="col-sm-12 form_col">
                                              <p>Are you an ASTA member?*</p>
                                              <div class="btn-group radiobtngroup" role="group" aria-label="Basic radio toggle button group">
                                                <input type="radio" class="btn-check" name="ASTA" id="btnradio7" autocomplete="off"/>
                                                <label class="btn btn-outline-primary" for="btnradio7">Yes</label>
                                              
                                                <input type="radio" class="btn-check" name="ASTA" id="btnradio8" autocomplete="off"/>
                                                <label class="btn btn-outline-primary" for="btnradio8">No</label>
                                              </div>
                                            </div>

                                            <div class="col-sm-12 form_col">
                                              <p>Do you use a reservation system?</p>
                                              <div class="btn-group radiobtngroup" role="group" aria-label="Basic radio toggle button group">
                                                <input type="radio" class="btn-check" name="reservation" id="btnradio9" autocomplete="off"/>
                                                <label class="btn btn-outline-primary" for="btnradio9">Yes</label>
                                              
                                                <input type="radio" class="btn-check" name="reservation" id="btnradio10" autocomplete="off"/>
                                                <label class="btn btn-outline-primary" for="btnradio10">No</label>
                                              </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                            <button onClick={() => setGoSteps(2)} class="btn btn-primary nextBtn btn-lg pull-right submitBtn" type="button">Next</button>
                        </fieldset>
                    </form>
                    
                    
                  </div>
                </div>
                  
                )}
                {goSteps === 2 && (
                  
                  <div class="col-xs-6 col-md-offset-3">
                    <div class="col-md-12">
                      <h3>Summary and Confirm registration</h3>
                      <div class="formholder">
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th><h4>Name and address</h4></th>
                                <th><button class="pull-right EditBtn"><i class="bi bi-pencil-square"></i>Edit</button></th>
                              </tr>
                              <tr>
                                <th>Title</th>
                                <th>Detail</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>Name</th>
                                <td>Meenu Dhakar</td>
                              </tr>
                              <tr>
                                <th>Agency Name</th>
                                <td>flyustravels</td>
                              </tr>
                              <tr>
                                <th>Address</th>
                                <td>Punjab 160071, India</td>
                              </tr>
                              <tr>
                                <th>Zip Code </th>
                                <td>160071</td>
                              </tr>
                              <tr>
                                <th>City</th>
                                <td>Punjab</td>
                              </tr>
                              <tr>
                                <th>Country</th>
                                <td>Other Countries</td>
                              </tr>
                              <tr>
                                <th>Phone Number</th>
                                <td>9684114231</td>
                              </tr>
                            </tbody>
                          </table>
                          
                        </div>

                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th><h4>Agency Membership</h4></th>
                                <th><button class="pull-right EditBtn"><i class="bi bi-pencil-square"></i>Edit</button></th>
                              </tr>
                              <tr>
                                <th>Title</th>
                                <th>Detail</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>Are you a memeber of a consortium or affiliation?*</th>
                                <td>No</td>
                              </tr>
                              <tr>
                                <th>Are you an ARC / IATA member?*</th>
                                <td>No</td>
                              </tr>
                              <tr>
                                <th>Are you a CLIA member?*</th>
                                <td>No</td>
                              </tr>
                              <tr>
                                <th>Are you an ASTA member?*</th>
                                <td>No</td>
                              </tr>
                              <tr>
                                <th>Do you use a reservation system?</th>
                                <td>No</td>
                              </tr>
                            </tbody>
                          </table>
                          
                        </div>
                        <button onClick={() => setGoSteps(3)} class="btn btn-success btn-lg pull-right submitBtn" type="submit">Submit</button>
                      </div>
                      {/* <button class="btn btn-primary prevBtn btn-lg pull-left submitBtn" type="button">Previous</button> */}
                      {/* <button onClick={() => setGoSteps(3)} class="btn btn-success btn-lg pull-right submitBtn" type="submit">Submit</button> */}
                    </div>
                  </div>
                   
                )}
              </div>
              {/* <!-- form box Ends --> */}

          </div>

      </div>
    </div>
    {/* <!-- form container end --> */}

    <Footer/>

    
    <div id='Reg__SuccesfulPopup'>
          
          <div className='Reg__SuccesfulBlock'>
            <div className='girlimg'><img src={ImagesPath.reg_succes_bg} alt="" /></div>
            <div className='content'>
              <img className='checkIcon' src={ImagesPath.reg_succes_check} alt="Checked" />
              <h3>Welcome to Flyus Travels</h3> 
              Simple sign in to your account and the account login screen will appear.
              <Link style={{"textDecoration":"none;"}} to="/"><button className='Singin_button'>Signin to your account</button></Link>
            </div>
             
          </div>

    </div>
    </>
  )
}

export default RegistrationSuccesful


