import React, { useState } from 'react'
import HeaderUser from '../../components/HeaderUser';
import Footer from '../../components/Footer';
//import { Link } from 'react-router-dom';
import ImagesPath from '../../components/ImagesPath';

//import { styled } from '@mui/material/styles';
//import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';


import {
    Accordion, 
    AccordionSummary,
    AccordionDetails,
    FormControlLabel,
    FormControl,
    Radio,
    RadioGroup,
    Checkbox,
    MenuItem,
    Slider,
} 
from '@mui/material';

import Select from '@mui/material/Select';

import {
    CircularProgress,
    Textarea,
} 
from '@mui/joy';

import {
    Flight,
    ExpandMore,
    Wifi, 
    AirlineSeatReclineNormal,
    Cable,
    OndemandVideo,
    Fastfood,
    Cancel,
    CheckCircle,
    ArrowDropDown,
} 
from '@mui/icons-material';
import {Dropdown, DropdownButton, Form} from 'react-bootstrap';


import { Link } from 'react-router-dom';

const marks = [
    {
      value: 0,
      label: '05:01h',
    },
    {
      value: 100,
      label: '13:40h',
    },
  ];
 
  const price = [
    {
      value: 0,
      label: '$ 00.00',
    },
    {
      value: 100,
      label: '$ 946.25',
    },
  ];
 
const PriceBreakdownPopup = () => {

    // search tab start
    const [activeTab, setActiveTab] = useState(0);
    const handleTabClick = (index) => {
    setActiveTab(index);
    };
    // search tab ends

    // Change search start
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const searchShow = () => {
        setIsSearchVisible(true);
    };
    const searchHide = () => {
      setIsSearchVisible(false);
    };
    // Change search ends

    // Filter show start
    const [isFilterSow, setIsFilterSow] = useState(false);
    const toggleFilterSow = () => {
      setIsFilterSow(!isFilterSow);
    };
    const SowFilter = `my-div ${isFilterSow ? 'filterBox showfilterBox' : 'filterBox'}`;
    const ToggleIcon = `my-div ${isFilterSow ? 'filterburger closeIcon' : 'filterburger burgerIcon'}`;
    // Filter show ends

    // best result dropdow start
    const [isBestResult, setIsBestResult] = useState(false);
    const toggleBestResult = () => {
      setIsBestResult(!isBestResult);
    };
    // best result dropdow ends


    // price breakdown start
    const [isPriceSow, setIsPriceSow] = useState(false);
    const priceBreakdowHnadle = () => {
      setIsPriceSow(!isPriceSow);
    };
    const SowPrice = `price_breakdown ${isPriceSow ? 'showPrice table-responsive' : 'hidePrice'}`;
    //const hidePrice = `price_breakdown ${isPriceSow ? 'hidePrice table-responsive' : ''}`;
    // price breakdown ends


  return (
    <div>
      <HeaderUser/>
       
        {/* <!-- Result Container start --> */}
        <div id="resultCntr">
            <div className="container px_0">

            {/* Search Box start */}
            {isSearchVisible === true &&( 
              <div className="searchBox Resultsearch"
              >
                  <Link className='searchHideBtn' onClick={searchHide}>Close</Link>
                  <ul className="tabBox">
                    <li
                      className={activeTab === 0 ? "active-tab , active " : ""}
                      onClick={() => handleTabClick(0)}
                    >Round Trip</li>
                    <li
                      className={activeTab === 1 ? "active-tab , active " : ""}
                      onClick={() => handleTabClick(1)}
                    >
                      One Way
                    </li>
                    <li
                      className={activeTab === 2 ? "active-tab , active " : ""}
                      onClick={() => handleTabClick(2)}
                    >
                      Multi City
                    </li>
                  </ul>
                  <div className="clearfix"></div>
                  {activeTab === 0 &&
                  <div className="searchBlock">
                    <p>Round Trip</p>
                    <div className="flightSearch">
                      <div className="row">
                        <div className="col-sm-12 position-relative">
                          <div id="feildrowBlock">
                            <div id="feildrow" className="feildrow">
                              <div className="eng_column1">
                                <form className="form-floating destination">
                                  <input
                                    type="text"
                                    className="form-control input"
                                    id="Origin"
                                    placeholder="Origin"
                                  />
                                  <label for="Origin">Origin</label>
                                  <span className="icons location bi bi-geo-alt-fill"></span>
                                </form>
                                <form className="form-floating destination">
                                  <input
                                    type="text"
                                    className="form-control input"
                                    id="Destination"
                                    placeholder="Destination"
                                  />
                                  <label for="destination">Destination</label>
                                  <span className="icons location bi bi-geo-alt-fill"></span>
                                </form>
                              </div>
                              <div className="eng_column2">
                                <div className="dateblock">
                                  <input
                                    type="text"
                                    className="datepicker"
                                    name="daterange"
                                    placeholder="Date"
                                    value="Date"
                                    readonly
                                  />
                                  <span className="icons location bi bi-calendar2-range-fill"></span>
                                  {/* <script>
                                  $(function() {
                                    $('input[name="daterange"]').daterangepicker({
                                      opens: 'left'
                                    }, function(start, end, label) {
                                      console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
                                    });
                                  });
                                </script> */}
                                </div>
                                <div className="timeblock">
                                  <input type="text" className="timePicker" />
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <!-- <div className="addfieldRowBlank"></div> --> */}
                          <div className="addfieldBtn">
                            <div className="add_delete_Row" onclick="addrow()">
                              <button>
                                <i className="bi bi-plus"></i>
                              </button>
                            </div>
                            <div
                              id="deleterow"
                              className="add_delete_Row"
                              onclick="deleterow()"
                            >
                              <button>
                                <i className="bi bi-dash"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-6 col-sm-12 pr-none">
                          <div className="eng_column3">
                            <div className="label cursor travellerblock traveller">
                              <input
                                type="text"
                                value="Passengers "
                                className="inputField valid cursor"
                                readonly
                              />
                              <span className="icons person-icon bi bi-person-plus-fill"></span>
                            </div>
                            <div
                              className="travllerDropdownBox travllerBox"
                              style={{ display: "none" }}
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="guestBox">
                                    <div className="head">
                                      Passengers
                                      {/* <!-- <span className="tooltipBlock">
                                            <script>
                                              $(function () {
                                                $('[data-toggle="Passengers"]').tooltip();
                                              })
                                            </script>
                                            <button className="btn btn-secondary"  data-toggle="Passengers" data-bs-placement="bottom" title="Offers do not include bags booked as ancillary service." ><i className="bi bi-exclamation-square-fill"></i></button>
                                          </span> --> */}
                                      {/* <script>
                                            $(document).ready(function(){
                                              $(".tooltip-icon").click(function(){
                                                $(".tooltip-new").show();
                                              });
                                              $(".close_popup").click(function(){
                                                $(".tooltip-new").hide();
                                              });
                                              
                                              $(document).on('click touch', function(event) {
                                              if (!$(event.target).parents().addBack().is('.tooltip-icon')) {
                                                $('.tooltip-new').hide();
                                              }
                                            });
                                              $('.inner_block').on('click touch', function(event) {
                                              event.stopPropagation();
                                            });
                                              
                                            });
                                          </script> */}
                                      {/* <!-- Tooltip Block start--> */}
                                      <div className="tooltip_block">
                                        <i className="tooltip-icon bi bi-exclamation-square-fill"></i>
                                        <div className="tooltip-new  tooltip-top">
                                          <div className="inner_block">
                                            <ul className="list">
                                              <li>
                                                Children must be accompanied by an
                                                adult (&gt; 18).
                                              </li>
                                              <li>
                                                Infants under 2 years of age must
                                                travel on the lap of an
                                                accompanying adult. Max. one
                                                infant per adult.
                                              </li>
                                              <li>
                                                Some airlines offer youth fares
                                                for travelers from 12 years of
                                                age. Please find information on
                                                age restrictions etc. in the fare
                                                notes.
                                              </li>
                                              <li>
                                                Some airlines offer student fares
                                                which require a student identity
                                                card. Please check the fare notes
                                                for conditions.
                                              </li>
                                            </ul>
                                            <p>
                                              Please contact your service team
                                              for:
                                            </p>
                                            <ul className="list">
                                              <li>
                                                Group bookings (&gt; 9 seats)
                                              </li>
                                              <li>
                                                Children turning 12 or infants
                                                turning 2 years during the journey
                                              </li>
                                              <li>
                                                Single travellers under 18 years
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <!-- Tooltip Block End--> */}
                                    </div>

                                    {/* <!--Adult--> */}
                                    <div className="add-traveler">
                                      <span className="travelers__options">
                                        <a href="javascript:;" className="remove">
                                          <i className="fa fa-minus"></i>
                                        </a>{" "}
                                        <strong className="number">1</strong>
                                        <a href="javascript:;" className="add">
                                          <i className="fa fa-plus"></i>
                                        </a>
                                      </span>
                                      Adult
                                    </div>

                                    {/* <!--Children--> */}
                                    <div className="add-traveler">
                                      <span className="travelers__options">
                                        <a href="javascript:;" className="remove">
                                          <i className="fa fa-minus"></i>
                                        </a>
                                        <strong className="number">1</strong>
                                        <a href="javascript:;" className="add">
                                          <i className="fa fa-plus"></i>
                                        </a>
                                      </span>
                                      Children <small>(2-11)</small>
                                    </div>

                                    {/* <!--Infants--> */}
                                    <div className="add-traveler">
                                      <span className="travelers__options">
                                        <a href="javascript:;" className="remove disable">
                                          <i className="fa fa-minus"></i>
                                        </a>
                                        <strong className="number">0</strong>
                                        <a href="javascript:;" className="add">
                                          <i className="fa fa-plus"></i>
                                        </a>
                                      </span>
                                      Infants <small>(&lt;2)</small>
                                    </div>

                                    {/* <!--Youth--> */}
                                    <div className="add-traveler">
                                      <span className="travelers__options">
                                        <a href="javascript:;" className="remove disable">
                                          <i className="fa fa-minus"></i>
                                        </a>
                                        <strong className="number">0</strong>
                                        <a href="javascript:;" className="add">
                                          <i className="fa fa-plus"></i>
                                        </a>
                                      </span>
                                      Youth
                                    </div>

                                    {/* <!--Students -->  */}
                                    <div className="add-traveler">
                                      <span className="travelers__options">
                                        <a href="javascript:;" className="remove disable">
                                          <i className="fa fa-minus"></i>
                                        </a>
                                        <strong className="number">0</strong>
                                        <a href="javascript:;" className="add">
                                          <i className="fa fa-plus"></i>
                                        </a>
                                      </span>
                                      Students
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12">
                                  {" "}
                                  <a href="/" className="done_Btn">
                                    Done
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="eng_column3">
                            <div className="label cursor travellerblock economy">
                              <input
                                type="text"
                                value="Cabin className"
                                className="inputField valid cursor"
                                readonly
                              />
                              <span className="icons person-icon bi bi-person-lines-fill"></span>
                            </div>
                            <div
                              className="travllerDropdownBox economyBox"
                              style={{ display: "none" }}
                            >
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <div className="head">Cabin className</div>
                                      <select className="form-select mb-4">
                                        <option selected>Economy</option>
                                        <option value="1">Premium Economy</option>
                                        <option value="2">Business</option>
                                        <option value="3">First</option>
                                        <option value="3">Cabin mix</option>
                                      </select>
                                      <ul className="classNameType inputSet">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              name="classNametype"
                                              checked=""
                                            />
                                            <span>
                                              All segments in selected cabin className{" "}
                                            </span>{" "}
                                          </label>
                                          {/* <!-- Tooltip Block start--> */}
                                          <div className="tooltip_block">
                                            <i className="tooltip-icon bi bi-exclamation-square-fill"></i>
                                            <div className="tooltip-new  tooltip-top">
                                              <div className="inner_block">
                                                <p>
                                                  Only results where the cabin
                                                  className is available for all
                                                  segments.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          {/* <!-- Tooltip Block End--> */}
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              name="classNametype"
                                            />
                                            <span>Non-stop only</span>{" "}
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              name="classNametype"
                                            />
                                            <span>No airport change</span>{" "}
                                          </label>
                                        </li>
                                        <li className="d-flex justify-content-between align-items-center mt-2">
                                          <label>
                                            <span>Maximum transfer</span>{" "}
                                          </label>
                                          <select
                                            style={{ width: "70px" }}
                                            className="form-select"
                                          >
                                            <option selected></option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                          </select>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-12">
                                  {" "}
                                  <a href="/" className="done_Btn">
                                    Done
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="showMorediv"
                          style={{ display: "none" }}
                          className="col-md-6 col-sm-12 pl-none"
                        >
                          <div className="eng_column3">
                            <div className="label cursor travellerblock Alliance">
                              <input
                                type="text"
                                value="Alliance"
                                className="inputField valid cursor"
                                readonly
                              />
                              <span className="icons person-icon bi bi-person-plus-fill"></span>
                            </div>
                            <div
                              className="travllerDropdownBox AllianceBox"
                              style={{ display: "none", zIndex: "100" }}
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="guestBox">
                                    <div className="head">Alliance</div>
                                    <select className="form-select mb-4">
                                      <option selected>Alliance</option>
                                      <option value="1">Star Alliance</option>
                                      <option value="2">Oneworld</option>
                                      <option value="3">Skyteam</option>
                                    </select>

                                    <ul className="airlinename">
                                      <li>
                                        <label for="Airline">
                                          <strong> Airline</strong>
                                        </label>
                                        <div className="airlineBlock d-flex justify-content-between">
                                          <div>
                                            <input
                                              type="text"
                                              autocomplete="!autocomplete"
                                              maxlength="3"
                                            />
                                          </div>
                                          <div>
                                            <input
                                              type="text"
                                              autocomplete="!autocomplete"
                                              maxlength="3"
                                            />
                                          </div>
                                          <div>
                                            <input
                                              type="text"
                                              autocomplete="!autocomplete"
                                              maxlength="3"
                                            />
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <label for="Airline">
                                          <strong> Booking className</strong>
                                        </label>
                                        <div className="airlineBlock booking d-flex justify-content-between">
                                          <div>
                                            <input
                                              type="text"
                                              autocomplete="!autocomplete"
                                              maxlength="3"
                                            />
                                          </div>
                                          <div>
                                            <input
                                              type="text"
                                              autocomplete="!autocomplete"
                                              maxlength="3"
                                            />
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-sm-12">
                                  {" "}
                                  <a href="/" className="done_Btn">
                                    Done
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="eng_column3">
                            <div className="label cursor travellerblock Tariff">
                              <input
                                type="text"
                                value="Tariff & Penalties"
                                className="inputField valid cursor"
                                readonly
                              />
                              <span className="icons person-icon bi bi-person-lines-fill"></span>
                            </div>
                            <div
                              className="travllerDropdownBox TariffBox"
                              style={{ display: "none", zIndex: "100" }}
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="guestBox">
                                    <h6>Tariff</h6>
                                    <div className="dropdown">
                                      <button
                                        className="btn dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        PUBNEG, NET, LOW/DIR/CHA
                                      </button>
                                      <ul
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton1"
                                      >
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              disabled="true"
                                              name="classNametype"
                                              checked=""
                                            />
                                            <span>PUBNEG</span>{" "}
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              disabled="true"
                                              name="classNametype"
                                              checked=""
                                            />
                                            <span>NET</span>{" "}
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              name="classNametype"
                                            />
                                            <span>LOW/DIR/CHA</span>{" "}
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              name="classNametype"
                                            />
                                            <span>VFR</span>{" "}
                                          </label>
                                        </li>
                                      </ul>
                                    </div>

                                    <h6 className="mt-4">
                                      Penalties
                                      {/* <!-- Tooltip Block start--> */}
                                      <div className="tooltip_block">
                                        <i className="tooltip-icon bi bi-exclamation-square-fill"></i>
                                        <div className="tooltip-new  tooltip-top">
                                          <div className="inner_block">
                                            <p>
                                              By selecting another penalty option
                                              we will only search among GDS
                                              results. (Penalties based on airline
                                              filing)
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <!-- Tooltip Block End--> */}
                                    </h6>
                                    <select className="form-select mb-4">
                                      <option selected="">
                                        {" "}
                                        All recommendations{" "}
                                      </option>
                                      <option value="1">
                                        {" "}
                                        No cancellation fee{" "}
                                      </option>
                                      <option value="2"> Refundable </option>
                                      <option value="3">
                                        {" "}
                                        Rebooking/Refund possible{" "}
                                      </option>
                                    </select>
                                    <span className="tooltipBlock">
                                      <label>
                                        <input type="checkbox" name="classNametype" />
                                        <span>Baggage fares only</span>{" "}
                                      </label>

                                      {/* <!-- Tooltip Block start--> */}
                                      <div className="tooltip_block">
                                        <i className="tooltip-icon bi bi-exclamation-square-fill"></i>
                                        <div className="tooltip-new  tooltip-top">
                                          <div className="inner_block">
                                            <p>
                                              Offers do not include bags booked as
                                              ancillary service.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <!-- Tooltip Block End--> */}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-12">
                                  {" "}
                                  <a href="/" className="done_Btn">
                                    Done
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-sm-12">
                          <button id="show-more" className="moreoptionBtn">
                            More search options
                          </button>
                          <button
                            id="show-less"
                            style={{ display: "none" }}
                            className="moreoptionBtn"
                          >
                            Less search options
                          </button>
                          <button className="resetBtn">Reset search</button>
                        </div>
                      </div>
                    </div>

                    <button className="searchButton">Search</button>
                  </div>
                  }

                  {activeTab === 1 &&
                  <div className="searchBlock">
                    <p>One Way</p>
                    <div className="flightSearch">
                      <div className="row">
                        <div className="col-sm-12 position-relative">
                          <div id="feildrowBlock">
                            <div id="feildrow" className="feildrow">
                              <div className="eng_column1">
                                <form className="form-floating destination">
                                  <input
                                    type="text"
                                    className="form-control input"
                                    id="Origin"
                                    placeholder="Origin"
                                  />
                                  <label for="Origin">Origin</label>
                                  <span className="icons location bi bi-geo-alt-fill"></span>
                                </form>
                                <form className="form-floating destination">
                                  <input
                                    type="text"
                                    className="form-control input"
                                    id="Destination"
                                    placeholder="Destination"
                                  />
                                  <label for="destination">Destination</label>
                                  <span className="icons location bi bi-geo-alt-fill"></span>
                                </form>
                              </div>
                              <div className="eng_column2">
                                <div className="dateblock">
                                  <input
                                    type="text"
                                    className="datepicker"
                                    name="daterange"
                                    placeholder="Date"
                                    value="Date"
                                    readonly
                                  />
                                  <span className="icons location bi bi-calendar2-range-fill"></span>
                                  {/* <script>
                                  $(function() {
                                    $('input[name="daterange"]').daterangepicker({
                                      opens: 'left'
                                    }, function(start, end, label) {
                                      console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
                                    });
                                  });
                                </script> */}
                                </div>
                                <div className="timeblock">
                                  <input type="text" className="timePicker" />
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <!-- <div className="addfieldRowBlank"></div> --> */}
                          <div className="addfieldBtn">
                            <div className="add_delete_Row" onclick="addrow()">
                              <button>
                                <i className="bi bi-plus"></i>
                              </button>
                            </div>
                            <div
                              id="deleterow"
                              className="add_delete_Row"
                              onclick="deleterow()"
                            >
                              <button>
                                <i className="bi bi-dash"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-6 col-sm-12 pr-none">
                          <div className="eng_column3">
                            <div className="label cursor travellerblock traveller">
                              <input
                                type="text"
                                value="Passengers "
                                className="inputField valid cursor"
                                readonly
                              />
                              <span className="icons person-icon bi bi-person-plus-fill"></span>
                            </div>
                            <div
                              className="travllerDropdownBox travllerBox"
                              style={{ display: "none" }}
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="guestBox">
                                    <div className="head">
                                      Passengers
                                      {/* <!-- <span className="tooltipBlock">
                                            <script>
                                              $(function () {
                                                $('[data-toggle="Passengers"]').tooltip();
                                              })
                                            </script>
                                            <button className="btn btn-secondary"  data-toggle="Passengers" data-bs-placement="bottom" title="Offers do not include bags booked as ancillary service." ><i className="bi bi-exclamation-square-fill"></i></button>
                                          </span> --> */}
                                      {/* <script>
                                            $(document).ready(function(){
                                              $(".tooltip-icon").click(function(){
                                                $(".tooltip-new").show();
                                              });
                                              $(".close_popup").click(function(){
                                                $(".tooltip-new").hide();
                                              });
                                              
                                              $(document).on('click touch', function(event) {
                                              if (!$(event.target).parents().addBack().is('.tooltip-icon')) {
                                                $('.tooltip-new').hide();
                                              }
                                            });
                                              $('.inner_block').on('click touch', function(event) {
                                              event.stopPropagation();
                                            });
                                              
                                            });
                                          </script> */}
                                      {/* <!-- Tooltip Block start--> */}
                                      <div className="tooltip_block">
                                        <i className="tooltip-icon bi bi-exclamation-square-fill"></i>
                                        <div className="tooltip-new  tooltip-top">
                                          <div className="inner_block">
                                            <ul className="list">
                                              <li>
                                                Children must be accompanied by an
                                                adult (&gt; 18).
                                              </li>
                                              <li>
                                                Infants under 2 years of age must
                                                travel on the lap of an
                                                accompanying adult. Max. one
                                                infant per adult.
                                              </li>
                                              <li>
                                                Some airlines offer youth fares
                                                for travelers from 12 years of
                                                age. Please find information on
                                                age restrictions etc. in the fare
                                                notes.
                                              </li>
                                              <li>
                                                Some airlines offer student fares
                                                which require a student identity
                                                card. Please check the fare notes
                                                for conditions.
                                              </li>
                                            </ul>
                                            <p>
                                              Please contact your service team
                                              for:
                                            </p>
                                            <ul className="list">
                                              <li>
                                                Group bookings (&gt; 9 seats)
                                              </li>
                                              <li>
                                                Children turning 12 or infants
                                                turning 2 years during the journey
                                              </li>
                                              <li>
                                                Single travellers under 18 years
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <!-- Tooltip Block End--> */}
                                    </div>

                                    {/* <!--Adult--> */}
                                    <div className="add-traveler">
                                      <span className="travelers__options">
                                        <a href="javascript:;" className="remove">
                                          <i className="fa fa-minus"></i>
                                        </a>{" "}
                                        <strong className="number">1</strong>
                                        <a href="javascript:;" className="add">
                                          <i className="fa fa-plus"></i>
                                        </a>
                                      </span>
                                      Adult
                                    </div>

                                    {/* <!--Children--> */}
                                    <div className="add-traveler">
                                      <span className="travelers__options">
                                        <a href="javascript:;" className="remove">
                                          <i className="fa fa-minus"></i>
                                        </a>
                                        <strong className="number">1</strong>
                                        <a href="javascript:;" className="add">
                                          <i className="fa fa-plus"></i>
                                        </a>
                                      </span>
                                      Children <small>(2-11)</small>
                                    </div>

                                    {/* <!--Infants--> */}
                                    <div className="add-traveler">
                                      <span className="travelers__options">
                                        <a href="javascript:;" className="remove disable">
                                          <i className="fa fa-minus"></i>
                                        </a>
                                        <strong className="number">0</strong>
                                        <a href="javascript:;" className="add">
                                          <i className="fa fa-plus"></i>
                                        </a>
                                      </span>
                                      Infants <small>(&lt;2)</small>
                                    </div>

                                    {/* <!--Youth--> */}
                                    <div className="add-traveler">
                                      <span className="travelers__options">
                                        <a href="javascript:;" className="remove disable">
                                          <i className="fa fa-minus"></i>
                                        </a>
                                        <strong className="number">0</strong>
                                        <a href="javascript:;" className="add">
                                          <i className="fa fa-plus"></i>
                                        </a>
                                      </span>
                                      Youth
                                    </div>

                                    {/* <!--Students -->  */}
                                    <div className="add-traveler">
                                      <span className="travelers__options">
                                        <a href="javascript:;" className="remove disable">
                                          <i className="fa fa-minus"></i>
                                        </a>
                                        <strong className="number">0</strong>
                                        <a href="javascript:;" className="add">
                                          <i className="fa fa-plus"></i>
                                        </a>
                                      </span>
                                      Students
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12">
                                  {" "}
                                  <a href="/" className="done_Btn">
                                    Done
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="eng_column3">
                            <div className="label cursor travellerblock economy">
                              <input
                                type="text"
                                value="Cabin className"
                                className="inputField valid cursor"
                                readonly
                              />
                              <span className="icons person-icon bi bi-person-lines-fill"></span>
                            </div>
                            <div
                              className="travllerDropdownBox economyBox"
                              style={{ display: "none" }}
                            >
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <div className="head">Cabin className</div>
                                      <select className="form-select mb-4">
                                        <option selected>Economy</option>
                                        <option value="1">Premium Economy</option>
                                        <option value="2">Business</option>
                                        <option value="3">First</option>
                                        <option value="3">Cabin mix</option>
                                      </select>
                                      <ul className="classNameType inputSet">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              name="classNametype"
                                              checked=""
                                            />
                                            <span>
                                              All segments in selected cabin className{" "}
                                            </span>{" "}
                                          </label>
                                          {/* <!-- Tooltip Block start--> */}
                                          <div className="tooltip_block">
                                            <i className="tooltip-icon bi bi-exclamation-square-fill"></i>
                                            <div className="tooltip-new  tooltip-top">
                                              <div className="inner_block">
                                                <p>
                                                  Only results where the cabin
                                                  className is available for all
                                                  segments.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          {/* <!-- Tooltip Block End--> */}
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              name="classNametype"
                                            />
                                            <span>Non-stop only</span>{" "}
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              name="classNametype"
                                            />
                                            <span>No airport change</span>{" "}
                                          </label>
                                        </li>
                                        <li className="d-flex justify-content-between align-items-center mt-2">
                                          <label>
                                            <span>Maximum transfer</span>{" "}
                                          </label>
                                          <select
                                            style={{ width: "70px" }}
                                            className="form-select"
                                          >
                                            <option selected></option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                          </select>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-12">
                                  {" "}
                                  <a href="/" className="done_Btn">
                                    Done
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="showMorediv"
                          style={{ display: "none" }}
                          className="col-md-6 col-sm-12 pl-none"
                        >
                          <div className="eng_column3">
                            <div className="label cursor travellerblock Alliance">
                              <input
                                type="text"
                                value="Alliance"
                                className="inputField valid cursor"
                                readonly
                              />
                              <span className="icons person-icon bi bi-person-plus-fill"></span>
                            </div>
                            <div
                              className="travllerDropdownBox AllianceBox"
                              style={{ display: "none", zIndex: "100" }}
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="guestBox">
                                    <div className="head">Alliance</div>
                                    <select className="form-select mb-4">
                                      <option selected>Alliance</option>
                                      <option value="1">Star Alliance</option>
                                      <option value="2">Oneworld</option>
                                      <option value="3">Skyteam</option>
                                    </select>

                                    <ul className="airlinename">
                                      <li>
                                        <label for="Airline">
                                          <strong> Airline</strong>
                                        </label>
                                        <div className="airlineBlock d-flex justify-content-between">
                                          <div>
                                            <input
                                              type="text"
                                              autocomplete="!autocomplete"
                                              maxlength="3"
                                            />
                                          </div>
                                          <div>
                                            <input
                                              type="text"
                                              autocomplete="!autocomplete"
                                              maxlength="3"
                                            />
                                          </div>
                                          <div>
                                            <input
                                              type="text"
                                              autocomplete="!autocomplete"
                                              maxlength="3"
                                            />
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <label for="Airline">
                                          <strong> Booking className</strong>
                                        </label>
                                        <div className="airlineBlock booking d-flex justify-content-between">
                                          <div>
                                            <input
                                              type="text"
                                              autocomplete="!autocomplete"
                                              maxlength="3"
                                            />
                                          </div>
                                          <div>
                                            <input
                                              type="text"
                                              autocomplete="!autocomplete"
                                              maxlength="3"
                                            />
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-sm-12">
                                  {" "}
                                  <a href="/" className="done_Btn">
                                    Done
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="eng_column3">
                            <div className="label cursor travellerblock Tariff">
                              <input
                                type="text"
                                value="Tariff & Penalties"
                                className="inputField valid cursor"
                                readonly
                              />
                              <span className="icons person-icon bi bi-person-lines-fill"></span>
                            </div>
                            <div
                              className="travllerDropdownBox TariffBox"
                              style={{ display: "none", zIndex: "100" }}
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="guestBox">
                                    <h6>Tariff</h6>
                                    <div className="dropdown">
                                      <button
                                        className="btn dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        PUBNEG, NET, LOW/DIR/CHA
                                      </button>
                                      <ul
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton1"
                                      >
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              disabled="true"
                                              name="classNametype"
                                              checked=""
                                            />
                                            <span>PUBNEG</span>{" "}
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              disabled="true"
                                              name="classNametype"
                                              checked=""
                                            />
                                            <span>NET</span>{" "}
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              name="classNametype"
                                            />
                                            <span>LOW/DIR/CHA</span>{" "}
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              name="classNametype"
                                            />
                                            <span>VFR</span>{" "}
                                          </label>
                                        </li>
                                      </ul>
                                    </div>

                                    <h6 className="mt-4">
                                      Penalties
                                      {/* <!-- Tooltip Block start--> */}
                                      <div className="tooltip_block">
                                        <i className="tooltip-icon bi bi-exclamation-square-fill"></i>
                                        <div className="tooltip-new  tooltip-top">
                                          <div className="inner_block">
                                            <p>
                                              By selecting another penalty option
                                              we will only search among GDS
                                              results. (Penalties based on airline
                                              filing)
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <!-- Tooltip Block End--> */}
                                    </h6>
                                    <select className="form-select mb-4">
                                      <option selected="">
                                        {" "}
                                        All recommendations{" "}
                                      </option>
                                      <option value="1">
                                        {" "}
                                        No cancellation fee{" "}
                                      </option>
                                      <option value="2"> Refundable </option>
                                      <option value="3">
                                        {" "}
                                        Rebooking/Refund possible{" "}
                                      </option>
                                    </select>
                                    <span className="tooltipBlock">
                                      <label>
                                        <input type="checkbox" name="classNametype" />
                                        <span>Baggage fares only</span>{" "}
                                      </label>

                                      {/* <!-- Tooltip Block start--> */}
                                      <div className="tooltip_block">
                                        <i className="tooltip-icon bi bi-exclamation-square-fill"></i>
                                        <div className="tooltip-new  tooltip-top">
                                          <div className="inner_block">
                                            <p>
                                              Offers do not include bags booked as
                                              ancillary service.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <!-- Tooltip Block End--> */}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-12">
                                  {" "}
                                  <a href="/" className="done_Btn">
                                    Done
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-sm-12">
                          <button id="show-more" className="moreoptionBtn">
                            More search options
                          </button>
                          <button
                            id="show-less"
                            style={{ display: "none" }}
                            className="moreoptionBtn"
                          >
                            Less search options
                          </button>
                          <button className="resetBtn">Reset search</button>
                        </div>
                      </div>
                    </div>

                    <button className="searchButton">Search</button>
                  </div>
                  }

                  {activeTab === 2 &&
                  <div className="searchBlock">
                    <p>Multi City</p>
                    <div className="flightSearch">
                      <div className="row">
                        <div className="col-sm-12 position-relative">
                          <div id="feildrowBlock">
                            <div id="feildrow" className="feildrow">
                              <div className="eng_column1">
                                <form className="form-floating destination">
                                  <input
                                    type="text"
                                    className="form-control input"
                                    id="Origin"
                                    placeholder="Origin"
                                  />
                                  <label for="Origin">Origin</label>
                                  <span className="icons location bi bi-geo-alt-fill"></span>
                                </form>
                                <form className="form-floating destination">
                                  <input
                                    type="text"
                                    className="form-control input"
                                    id="Destination"
                                    placeholder="Destination"
                                  />
                                  <label for="destination">Destination</label>
                                  <span className="icons location bi bi-geo-alt-fill"></span>
                                </form>
                              </div>
                              <div className="eng_column2">
                                <div className="dateblock">
                                  <input
                                    type="text"
                                    className="datepicker"
                                    name="daterange"
                                    placeholder="Date"
                                    value="Date"
                                    readonly
                                  />
                                  <span className="icons location bi bi-calendar2-range-fill"></span>
                                  {/* <script>
                                  $(function() {
                                    $('input[name="daterange"]').daterangepicker({
                                      opens: 'left'
                                    }, function(start, end, label) {
                                      console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
                                    });
                                  });
                                </script> */}
                                </div>
                                <div className="timeblock">
                                  <input type="text" className="timePicker" />
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <!-- <div className="addfieldRowBlank"></div> --> */}
                          <div className="addfieldBtn">
                            <div className="add_delete_Row" onclick="addrow()">
                              <button>
                                <i className="bi bi-plus"></i>
                              </button>
                            </div>
                            <div
                              id="deleterow"
                              className="add_delete_Row"
                              onclick="deleterow()"
                            >
                              <button>
                                <i className="bi bi-dash"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-6 col-sm-12 pr-none">
                          <div className="eng_column3">
                            <div className="label cursor travellerblock traveller">
                              <input
                                type="text"
                                value="Passengers "
                                className="inputField valid cursor"
                                readonly
                              />
                              <span className="icons person-icon bi bi-person-plus-fill"></span>
                            </div>
                            <div
                              className="travllerDropdownBox travllerBox"
                              style={{ display: "none" }}
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="guestBox">
                                    <div className="head">
                                      Passengers
                                      {/* <!-- <span className="tooltipBlock">
                                            <script>
                                              $(function () {
                                                $('[data-toggle="Passengers"]').tooltip();
                                              })
                                            </script>
                                            <button className="btn btn-secondary"  data-toggle="Passengers" data-bs-placement="bottom" title="Offers do not include bags booked as ancillary service." ><i className="bi bi-exclamation-square-fill"></i></button>
                                          </span> --> */}
                                      {/* <script>
                                            $(document).ready(function(){
                                              $(".tooltip-icon").click(function(){
                                                $(".tooltip-new").show();
                                              });
                                              $(".close_popup").click(function(){
                                                $(".tooltip-new").hide();
                                              });
                                              
                                              $(document).on('click touch', function(event) {
                                              if (!$(event.target).parents().addBack().is('.tooltip-icon')) {
                                                $('.tooltip-new').hide();
                                              }
                                            });
                                              $('.inner_block').on('click touch', function(event) {
                                              event.stopPropagation();
                                            });
                                              
                                            });
                                          </script> */}
                                      {/* <!-- Tooltip Block start--> */}
                                      <div className="tooltip_block">
                                        <i className="tooltip-icon bi bi-exclamation-square-fill"></i>
                                        <div className="tooltip-new  tooltip-top">
                                          <div className="inner_block">
                                            <ul className="list">
                                              <li>
                                                Children must be accompanied by an
                                                adult (&gt; 18).
                                              </li>
                                              <li>
                                                Infants under 2 years of age must
                                                travel on the lap of an
                                                accompanying adult. Max. one
                                                infant per adult.
                                              </li>
                                              <li>
                                                Some airlines offer youth fares
                                                for travelers from 12 years of
                                                age. Please find information on
                                                age restrictions etc. in the fare
                                                notes.
                                              </li>
                                              <li>
                                                Some airlines offer student fares
                                                which require a student identity
                                                card. Please check the fare notes
                                                for conditions.
                                              </li>
                                            </ul>
                                            <p>
                                              Please contact your service team
                                              for:
                                            </p>
                                            <ul className="list">
                                              <li>
                                                Group bookings (&gt; 9 seats)
                                              </li>
                                              <li>
                                                Children turning 12 or infants
                                                turning 2 years during the journey
                                              </li>
                                              <li>
                                                Single travellers under 18 years
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <!-- Tooltip Block End--> */}
                                    </div>

                                    {/* <!--Adult--> */}
                                    <div className="add-traveler">
                                      <span className="travelers__options">
                                        <a href="javascript:;" className="remove">
                                          <i className="fa fa-minus"></i>
                                        </a>{" "}
                                        <strong className="number">1</strong>
                                        <a href="javascript:;" className="add">
                                          <i className="fa fa-plus"></i>
                                        </a>
                                      </span>
                                      Adult
                                    </div>

                                    {/* <!--Children--> */}
                                    <div className="add-traveler">
                                      <span className="travelers__options">
                                        <a href="javascript:;" className="remove">
                                          <i className="fa fa-minus"></i>
                                        </a>
                                        <strong className="number">1</strong>
                                        <a href="javascript:;" className="add">
                                          <i className="fa fa-plus"></i>
                                        </a>
                                      </span>
                                      Children <small>(2-11)</small>
                                    </div>

                                    {/* <!--Infants--> */}
                                    <div className="add-traveler">
                                      <span className="travelers__options">
                                        <a href="javascript:;" className="remove disable">
                                          <i className="fa fa-minus"></i>
                                        </a>
                                        <strong className="number">0</strong>
                                        <a href="javascript:;" className="add">
                                          <i className="fa fa-plus"></i>
                                        </a>
                                      </span>
                                      Infants <small>(&lt;2)</small>
                                    </div>

                                    {/* <!--Youth--> */}
                                    <div className="add-traveler">
                                      <span className="travelers__options">
                                        <a href="javascript:;" className="remove disable">
                                          <i className="fa fa-minus"></i>
                                        </a>
                                        <strong className="number">0</strong>
                                        <a href="javascript:;" className="add">
                                          <i className="fa fa-plus"></i>
                                        </a>
                                      </span>
                                      Youth
                                    </div>

                                    {/* <!--Students -->  */}
                                    <div className="add-traveler">
                                      <span className="travelers__options">
                                        <a href="javascript:;" className="remove disable">
                                          <i className="fa fa-minus"></i>
                                        </a>
                                        <strong className="number">0</strong>
                                        <a href="javascript:;" className="add">
                                          <i className="fa fa-plus"></i>
                                        </a>
                                      </span>
                                      Students
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12">
                                  {" "}
                                  <a href="/" className="done_Btn">
                                    Done
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="eng_column3">
                            <div className="label cursor travellerblock economy">
                              <input
                                type="text"
                                value="Cabin className"
                                className="inputField valid cursor"
                                readonly
                              />
                              <span className="icons person-icon bi bi-person-lines-fill"></span>
                            </div>
                            <div
                              className="travllerDropdownBox economyBox"
                              style={{ display: "none" }}
                            >
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <div className="head">Cabin className</div>
                                      <select className="form-select mb-4">
                                        <option selected>Economy</option>
                                        <option value="1">Premium Economy</option>
                                        <option value="2">Business</option>
                                        <option value="3">First</option>
                                        <option value="3">Cabin mix</option>
                                      </select>
                                      <ul className="classNameType inputSet">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              name="classNametype"
                                              checked=""
                                            />
                                            <span>
                                              All segments in selected cabin className{" "}
                                            </span>{" "}
                                          </label>
                                          {/* <!-- Tooltip Block start--> */}
                                          <div className="tooltip_block">
                                            <i className="tooltip-icon bi bi-exclamation-square-fill"></i>
                                            <div className="tooltip-new  tooltip-top">
                                              <div className="inner_block">
                                                <p>
                                                  Only results where the cabin
                                                  className is available for all
                                                  segments.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          {/* <!-- Tooltip Block End--> */}
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              name="classNametype"
                                            />
                                            <span>Non-stop only</span>{" "}
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              name="classNametype"
                                            />
                                            <span>No airport change</span>{" "}
                                          </label>
                                        </li>
                                        <li className="d-flex justify-content-between align-items-center mt-2">
                                          <label>
                                            <span>Maximum transfer</span>{" "}
                                          </label>
                                          <select
                                            style={{ width: "70px" }}
                                            className="form-select"
                                          >
                                            <option selected></option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                          </select>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-12">
                                  {" "}
                                  <a href="/" className="done_Btn">
                                    Done
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="showMorediv"
                          style={{ display: "none" }}
                          className="col-md-6 col-sm-12 pl-none"
                        >
                          <div className="eng_column3">
                            <div className="label cursor travellerblock Alliance">
                              <input
                                type="text"
                                value="Alliance"
                                className="inputField valid cursor"
                                readonly
                              />
                              <span className="icons person-icon bi bi-person-plus-fill"></span>
                            </div>
                            <div
                              className="travllerDropdownBox AllianceBox"
                              style={{ display: "none", zIndex: "100" }}
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="guestBox">
                                    <div className="head">Alliance</div>
                                    <select className="form-select mb-4">
                                      <option selected>Alliance</option>
                                      <option value="1">Star Alliance</option>
                                      <option value="2">Oneworld</option>
                                      <option value="3">Skyteam</option>
                                    </select>

                                    <ul className="airlinename">
                                      <li>
                                        <label for="Airline">
                                          <strong> Airline</strong>
                                        </label>
                                        <div className="airlineBlock d-flex justify-content-between">
                                          <div>
                                            <input
                                              type="text"
                                              autocomplete="!autocomplete"
                                              maxlength="3"
                                            />
                                          </div>
                                          <div>
                                            <input
                                              type="text"
                                              autocomplete="!autocomplete"
                                              maxlength="3"
                                            />
                                          </div>
                                          <div>
                                            <input
                                              type="text"
                                              autocomplete="!autocomplete"
                                              maxlength="3"
                                            />
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <label for="Airline">
                                          <strong> Booking className</strong>
                                        </label>
                                        <div className="airlineBlock booking d-flex justify-content-between">
                                          <div>
                                            <input
                                              type="text"
                                              autocomplete="!autocomplete"
                                              maxlength="3"
                                            />
                                          </div>
                                          <div>
                                            <input
                                              type="text"
                                              autocomplete="!autocomplete"
                                              maxlength="3"
                                            />
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-sm-12">
                                  {" "}
                                  <a href="/" className="done_Btn">
                                    Done
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="eng_column3">
                            <div className="label cursor travellerblock Tariff">
                              <input
                                type="text"
                                value="Tariff & Penalties"
                                className="inputField valid cursor"
                                readonly
                              />
                              <span className="icons person-icon bi bi-person-lines-fill"></span>
                            </div>
                            <div
                              className="travllerDropdownBox TariffBox"
                              style={{ display: "none", zIndex: "100" }}
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="guestBox">
                                    <h6>Tariff</h6>
                                    <div className="dropdown">
                                      <button
                                        className="btn dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        PUBNEG, NET, LOW/DIR/CHA
                                      </button>
                                      <ul
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton1"
                                      >
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              disabled="true"
                                              name="classNametype"
                                              checked=""
                                            />
                                            <span>PUBNEG</span>{" "}
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              disabled="true"
                                              name="classNametype"
                                              checked=""
                                            />
                                            <span>NET</span>{" "}
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              name="classNametype"
                                            />
                                            <span>LOW/DIR/CHA</span>{" "}
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              name="classNametype"
                                            />
                                            <span>VFR</span>{" "}
                                          </label>
                                        </li>
                                      </ul>
                                    </div>

                                    <h6 className="mt-4">
                                      Penalties
                                      {/* <!-- Tooltip Block start--> */}
                                      <div className="tooltip_block">
                                        <i className="tooltip-icon bi bi-exclamation-square-fill"></i>
                                        <div className="tooltip-new  tooltip-top">
                                          <div className="inner_block">
                                            <p>
                                              By selecting another penalty option
                                              we will only search among GDS
                                              results. (Penalties based on airline
                                              filing)
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <!-- Tooltip Block End--> */}
                                    </h6>
                                    <select className="form-select mb-4">
                                      <option selected="">
                                        {" "}
                                        All recommendations{" "}
                                      </option>
                                      <option value="1">
                                        {" "}
                                        No cancellation fee{" "}
                                      </option>
                                      <option value="2"> Refundable </option>
                                      <option value="3">
                                        {" "}
                                        Rebooking/Refund possible{" "}
                                      </option>
                                    </select>
                                    <span className="tooltipBlock">
                                      <label>
                                        <input type="checkbox" name="classNametype" />
                                        <span>Baggage fares only</span>{" "}
                                      </label>

                                      {/* <!-- Tooltip Block start--> */}
                                      <div className="tooltip_block">
                                        <i className="tooltip-icon bi bi-exclamation-square-fill"></i>
                                        <div className="tooltip-new  tooltip-top">
                                          <div className="inner_block">
                                            <p>
                                              Offers do not include bags booked as
                                              ancillary service.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <!-- Tooltip Block End--> */}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-12">
                                  {" "}
                                  <a href="/" className="done_Btn">
                                    Done
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-sm-12">
                          <button id="show-more" className="moreoptionBtn">
                            More search options
                          </button>
                          <button
                            id="show-less"
                            style={{ display: "none" }}
                            className="moreoptionBtn"
                          >
                            Less search options
                          </button>
                          <button className="resetBtn">Reset search</button>
                        </div>
                      </div>
                    </div>

                    <button className="searchButton">Search</button>
                  </div>
                  }
                  
            </div> 
            )}
            {/* Search Box ends */}

            {/* <!-- resultkey box start --> */}
            {isSearchVisible === false &&(
            <div className="resultkeyBox">
                <div className="resultkey">
                <div className="title">Search</div>
                <ul>
                    <li>NYC - IND</li>
                    <li>01 SEP</li>
                    <li>2ADT, 1CHD</li>
                    <li>Premium Economy</li>
                </ul>
                </div>

                <div className="changeSearch">
                <ul>
                    <li><Link onClick={searchShow}>Change</Link></li>
                    <li><Link onClick={searchShow}>New search</Link></li>
                </ul>
                </div>
            </div>
            )}
            {/* <!-- resultkey box end --> */}

            {/* <!-- resultheader Box start --> */}
            <div className="resultheaderBox">
              <div className="left">
                <button onClick={toggleFilterSow} className={ToggleIcon}>
                  <i className="bi bi-filter-left"></i>
                  <i className="bi bi-x"></i>
                </button>
                <div className="title">Results</div>
                <p>110 Result with 3 Carriers found</p>
              </div>
              <div className="right">
                <ul>
                    <li><Link>Compare offers</Link></li>
                    <li><Link>Expand all</Link></li>
                    <li>
                      {/* <Form.Select aria-label="Default select example" className="BestResultDropdown">
                        <option>Best results</option>
                        <option value="1">Price Descending</option>
                        <option value="2">Airline A - Z</option>
                        <option value="3">Airline Z - A</option>
                        <option value="4">Duration Ascending</option>
                        <option value="5">Duration Descending</option>
                        <option value="6">Best results</option>
                      </Form.Select> */}

                      <button className='bestresults' onClick={toggleBestResult}> Best results <ArrowDropDown/></button>
                      {isBestResult &&(
                       
                        <div className="BestResultDropdown">
                          
                          <div className='dropvalue active'>Price Ascending</div>
                          <div className='dropvalue'>Price Descending</div>
                          <div className='dropvalue'>Airline A - Z</div>
                          <div className='dropvalue'>Airline Z - A</div>
                          <div className='dropvalue'>Duration Ascending</div>
                          <div className='dropvalue'>Duration Descending</div>
                          <div className='dropvalue'>Best results</div>
                        </div>
                      )}
                    </li>
                </ul>
              </div>
            </div>
            {/* <!-- resultheader Box end --> */}
            
            {/* <!-- applyedFilter Box start --> */}
            <div className="applyedFilterBox">
              <ul>
                <li>Nonstop <i class="bi bi-x resetFilter"></i></li>
                <li>Departure time - Early Morning (12:am - 4:59am) <i class="bi bi-x resetFilter"></i></li>
                <li>Return time - Morning (5:am - 11:59am) <i class="bi bi-x resetFilter"></i></li>
                <li>American Airlines <i class="bi bi-x resetFilter"></i></li>
                <li>Layover time - 3h 40m <i class="bi bi-x resetFilter"></i></li>
                <span class="clear_filter">Clear All filters</span>
              </ul>
            </div>
            {/* <!-- applyedFilter Box end --> */}

            {/* <!-- filterresult container start --> */}
            <div id="filterresultCntr">

                {/* <!-- filter Box start --> */}
                <div 
                className={SowFilter}
                >

                <div className="resetblock">
                    <ul>
                    <li><a href="/">Reset all</a></li>
                    <li><a href="/">Expand all</a></li>
                    <li><a href="/">Collapse all</a></li>
                    </ul>
                </div>

                <div className="no_filter">
                    <p>No filters applied</p>
                </div>

                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className='tabheader'
                    >
                    Markup
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='filterBlock'>
                            <div className='row_filter'>
                                <div className='left'>
                                    <p>Agent markup p.p.</p>
                                </div>
                                <div className='right'>
                                    <input className='countinput' type="number" name="number" placeholder="$1"/>
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className='tabheader'
                    >
                        Number of stops
                    </AccordionSummary>
                    <AccordionDetails>
                    
                        <div className='filterBlock'>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="Non-stop (6)" />
                                </div>
                                <div className='right'>$ 1,945.60</div>
                            </div>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="incl. max. 1 stop(344)" />
                                </div>
                                <div className='right'>$ 1,945.60</div>
                            </div>
                            
                        </div>
                    
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className='tabheader'
                    >
                        Maximum travel time
                    </AccordionSummary>
                    <AccordionDetails>
                        
                            <div className='filterBlock'>
                                <div className='duration_slider'>
                                    <div className='row_filter'>
                                        <div className='left'>LAS - LGA</div>
                                        <div className='right'><input className='travelTime' type="text" value="" /></div>
                                    </div>
                                    <div className="travelTimeSlider">
                                        <Slider
                                            aria-label="Restricted values"
                                            defaultValue={20}
                                            // valueLabelFormat={valueLabelFormat}
                                            // getAriaValueText={valuetext}
                                            step={null}
                                            valueLabelDisplay="auto"
                                            marks={marks}
                                        />
                                    </div>
                                </div>
                                <div className='duration_slider'>
                                    <div className='row_filter'>
                                        <div className='left'>LGA - LAS</div>
                                        <div className='right'><input className='travelTime' type="text" value="" /></div>
                                    </div>
                                    <div className="travelTimeSlider">
                                        <Slider
                                            aria-label="Restricted values"
                                            defaultValue={20}
                                            // valueLabelFormat={valueLabelFormat}
                                            // getAriaValueText={valuetext}
                                            step={null}
                                            valueLabelDisplay="auto"
                                            marks={marks}
                                        />
                                    </div>
                                </div>
                            </div>
                        
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className='tabheader'
                    >
                        Departure & arrival time
                    </AccordionSummary>
                    <AccordionDetails>
                        
                            <div className='filterBlock'>
                                <div className='duration_slider'>
                                    <div className='row_filter'>
                                        <div className='left'>LAS - LGA</div>
                                        <div className='right'><input className='travelTime' type="text" value="" /> <input className='travelTime' type="text" value="" /></div>
                                    </div>
                                    <div className="travelTimeSlider">
                                        <Slider
                                            aria-label="Restricted values"
                                            defaultValue={20}
                                            // valueLabelFormat={valueLabelFormat}
                                            // getAriaValueText={valuetext}
                                            step={null}
                                            valueLabelDisplay="auto"
                                            marks={marks}
                                        />
                                    </div>
                                </div>
                                <div className='duration_slider'>
                                    <div className='row_filter'>
                                        <div className='left'>LGA - LAS</div>
                                        <div className='right'><input className='travelTime' type="text" value="" /> <input className='travelTime' type="text" value="" /></div>
                                    </div>
                                    <div className="travelTimeSlider">
                                        <Slider
                                            aria-label="Restricted values"
                                            defaultValue={20}
                                            // valueLabelFormat={valueLabelFormat}
                                            // getAriaValueText={valuetext}
                                            step={null}
                                            valueLabelDisplay="auto"
                                            marks={marks}
                                        />
                                    </div>
                                </div>
                            </div>
                        
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className='tabheader'
                    >
                        Equipment
                    </AccordionSummary>
                    <AccordionDetails>
                    
                        <div className='filterBlock'>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="Non-stop (6)" />
                                </div>
                                <div className='right'>$ 1,945.60</div>
                            </div>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="incl. max. 1 stop(344)" />
                                </div>
                                <div className='right'>$ 1,945.60</div>
                            </div>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="incl. max. 1 stop(344)" />
                                </div>
                                <div className='right'>$ 1,945.60</div>
                            </div>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="incl. max. 1 stop(344)" />
                                </div>
                                <div className='right'>$ 1,945.60</div>
                            </div>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="incl. max. 1 stop(344)" />
                                </div>
                                <div className='right'>$ 1,945.60</div>
                            </div>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="incl. max. 1 stop(344)" />
                                </div>
                                <div className='right'>$ 1,945.60</div>
                            </div>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="incl. max. 1 stop(344)" />
                                </div>
                                <div className='right'>$ 1,945.60</div>
                            </div>
                            
                        </div>
                    
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className='tabheader'
                    >
                        Via
                    </AccordionSummary>
                    <AccordionDetails>
                    
                        <div className='filterBlock'>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="Non-stop (6)" />
                                </div>
                                <div className='right'>$ 1,945.60</div>
                            </div>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="incl. max. 1 stop(344)" />
                                </div>
                                <div className='right'>$ 1,945.60</div>
                            </div>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="incl. max. 1 stop(344)" />
                                </div>
                                <div className='right'>$ 1,945.60</div>
                            </div>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="incl. max. 1 stop(344)" />
                                </div>
                                <div className='right'>$ 1,945.60</div>
                            </div>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="incl. max. 1 stop(344)" />
                                </div>
                                <div className='right'>$ 1,945.60</div>
                            </div>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="incl. max. 1 stop(344)" />
                                </div>
                                <div className='right'>$ 1,945.60</div>
                            </div>
                            
                        </div>
                    
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className='tabheader'
                    >
                        Departure
                    </AccordionSummary>
                    <AccordionDetails>
                  
                        <div className='filterBlock'>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="Las Vegas Harry Reid Intl Apt" />
                                </div>
                                <div className='right'>$ 542.96</div>
                            </div>
                        </div>
                    
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className='tabheader'
                    >
                        Destination
                    </AccordionSummary>
                    <AccordionDetails>
                    
                        <div className='filterBlock'>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="New York La Guardia Apt(123)" />
                                </div>
                                <div className='right'>$ 542.96</div>
                            </div>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="Newark Liberty International Apt(183)" />
                                </div>
                                <div className='right'>$ 542.96</div>
                            </div>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="New York J F Kennedy International Apt(43)" />
                                </div>
                                <div className='right'>$ 542.96</div>
                            </div>
                        </div>
                    
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className='tabheader'
                    >
                        Price
                    </AccordionSummary>
                    <AccordionDetails>
                    
                        <div className='filterBlock'>
                            <div className='duration_slider'>
                                <div className='row_filter'>
                                    <div className='left'>Max</div>
                                    <div className='right'><input className='travelTime' type="text" value="$ 946.25" /></div>
                                </div>
                                <div className="travelTimeSlider">
                                    <Slider
                                        aria-label="Always visible"
                                        defaultValue={80}
                                        //valueLabelFormat={pricevalueLabelFormat}
                                        //getAriaValueText={pricevaluetext}
                                        step={10}
                                        valueLabelDisplay="on"
                                        marks={price}
                                    />
                                </div>
                            </div>
                        </div>
                    
                    </AccordionDetails>
                </Accordion>

                </div>
                {/* <!-- filter Box ends --> */}

                

                {/* <!-- result Box start --> */}
                <div className="resultBox">

                    <div className="resultblock">
                        <div className="row">
                            <div className="col-sm-12">
                            <div className="head">Departure  <span className="date"> | Wed, Nov 1</span></div>
                            <div className="row">
                                <div className="col-md-5 col-sm-12 d-flex">
                                <div className="airlinelogo"><img src={ImagesPath.delta} alt="DL"/></div>
                                <div className="airlineInfo">
                                    <div className="airlineName">delta airline </div>
                                    <div className="airlineNumber">Flight No: 006 | EQP-006 <i className="bi bi-exclamation-square-fill"></i></div>
                                </div>
                                </div>
                                <div className="col-md-7 col-sm-12">
                                <div className="leg_detail">
                                    <div className="city">
                                    <div className="time"><strong>12:00</strong> pm</div>
                                    <div className="code">MCI</div>
                                    </div>
                                    <div className="connecting_block">
                                    <div className="leg_points">
                                        <div className='stopage'>
                                            <div className="layovertime">18<span>h</span>  00<span>m</span> </div>
                                            <div className="stops">
                                                {/* <div className="layovertime">18<span>h</span>  00<span>m</span> </div> */}
                                                <i></i>
                                                    <br/>
                                                <b>DCA</b>
                                            </div>
                                        </div>

                                    </div>
                                    </div>
                                    <div className="city">
                                    <div className="time"><strong>12:00</strong> am</div>
                                    <div className="code">ATL</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="head">Return  <span className="date">| Wed, Nov 1</span></div>
                            <div className="row">
                                <div className="col-md-5 col-sm-12 d-flex">
                                <div className="airlinelogo"><img src={ImagesPath.delta} alt="DL"/></div>
                                <div className="airlineInfo">
                                    <div className="airlineName">delta airline </div>
                                    <div className="airlineNumber">Flight No: 006 | EQP-006 <i className="bi bi-exclamation-square-fill"></i></div>
                                </div>
                                </div>
                                <div className="col-md-7 col-sm-12">
                                <div className="leg_detail">
                                    <div className="city">
                                    <div className="time"><strong>12:00</strong> pm</div>
                                    <div className="code">MCI</div>
                                    </div>
                                    <div className="connecting_block">
                                    <div className="leg_points">
                                        <div className='stopage'>
                                            <div className="layovertime">18<span>h</span>  00<span>m</span> </div>
                                            <div className="stops">
                                                {/* <div className="layovertime">18<span>h</span>  00<span>m</span> </div> */}
                                                <i></i>
                                                    <br/>
                                                <b>DCA</b>
                                            </div>
                                        </div>

                                    </div>
                                    </div>
                                    <div className="city">
                                    <div className="time"><strong>12:00</strong> am</div>
                                    <div className="code">ATL</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="price_list">
                                  <div className='w_100 d-flex flex-row align-items-center'>
                                      <div className='price_block'>
                                          <div className="price">$522.<sup>99</sup></div>
                                          <div className="fare__breakup">
                                              <a href="/" className="fare__detail">Fare Detail
                                                  <span>(incl. taxes)</span>
                                              </a>
                                          </div>
                                      </div>
                                      <a href="/" className="select__btn">Select <i className="bi bi-chevron-down ml-2" aria-hidden="true"></i></a>
                                  </div>
                                  
                                  <div className='baggageBlock'>
                                  {/* <HtmlTooltip
                                      title={
                                      <React.Fragment>
                                          <div className="tooltipBox baggage_1 text-left">
                                              <div className="baggage_row">
                                                  <div className="popup_icon relative"> <img src="images/icons/bag-1.png" alt="" className="icons"/> </div>
                                                  <div className="d-flex"> Personal Item <span className="green baggage_status"> <img alt="icons" src="images/icons/check.svg"/> Included </span> </div>
                                                  <div className="light"></div>
                                              </div>
                                              <div className="baggage_row">
                                                  <div className="popup_icon relative"> <img src="images/icons/bag-2.png" alt="" className="icons"/> </div>
                                                  <div className="d-flex"> Carry-on Bag <span className="green baggage_status"> <img alt="icons" src="images/icons/check.svg"/> Included </span> </div>
                                                  <div className="light"></div>
                                              </div>
                                              <div className="baggage_row">
                                                  <div className="popup_icon relative"> <img src="images/icons/bag-3.png" alt="" className="icons"/> </div>
                                                  <div className="d-flex"> Checked Bag <span className="green baggage_status"> <img alt="icons" src="images/icons/check.svg"/> Included </span> </div>
                                                  <div className="light"></div>
                                              </div>
                                          </div>
                                      </React.Fragment>
                                      }
                                      arrow
                                  >
                                      <ul>
                                          <li>
                                              <CheckCircle className='icon'/>
                                              <img src={ImagesPath.baggage_icon1} alt="" />
                                          </li>
                                          <li>
                                              <CheckCircle className='icon'/>
                                              <img src={ImagesPath.baggage_icon2} alt="" />
                                          </li>
                                          <li>
                                              <Cancel className='icon'/>
                                              <img src={ImagesPath.baggage_icon3} alt="" />
                                          </li>
                                      </ul>
                                  </HtmlTooltip> */}

                                <div className='baggageBlock'>
                                    <ul>
                                        <li>
                                            <CheckCircle className='icon'/>
                                            <img src={ImagesPath.baggage_icon1} alt="" />
                                        </li>
                                        <li>
                                            <CheckCircle className='icon'/>
                                            <img src={ImagesPath.baggage_icon2} alt="" />
                                        </li>
                                        <li>
                                            <Cancel className='icon'/>
                                            <img src={ImagesPath.baggage_icon3} alt="" />
                                        </li>
                                    </ul>
                                </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                        <div className='available_flight' style={{display: 'block'}}>
                            <div className='available_flight_row'>
                                
                                <div className='flight_img_code_number'>
                                    <FormControlLabel value="female" control={<Radio />} />
                                    <a href="javascript:;"><img src={ImagesPath.canada} alt="JetBlue" /></a>
                                    <span className='code'>B6</span>
                                    <span className='code'>748</span>
                                </div>
                                <div className='flight_date_airport_code'>
                                    <span>Fri,</span>
                                    <span>15SEP</span>
                                    <span>LAS - JFK</span>
                                </div>
                                <div className='airport_time_duration_booking_className'>
                                    <span>03:55P - 11:54P</span>
                                    <span>04:59 h</span>
                                    <span>L7/Econ.</span>
                                </div>
                                <div className='airbus'>/321</div>
                                <div className='flight_amenities'>
                                    <ul>
                                        <li><AirlineSeatReclineNormal/></li>
                                        <li><Wifi/></li>
                                        <li><Cable/></li>
                                        <li><OndemandVideo/></li>
                                        <li><Fastfood/></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='available_flight_row'>
                                
                                <div className='flight_img_code_number'>
                                    <FormControlLabel value="female" control={<Radio />} />
                                    <a href="javascript:;"><img src={ImagesPath.canada} alt="JetBlue" /></a>
                                    <span className='code'>B6</span>
                                    <span className='code'>748</span>
                                </div>
                                <div className='flight_date_airport_code'>
                                    <span>Fri,</span>
                                    <span>15SEP</span>
                                    <span>LAS - JFK</span>
                                </div>
                                <div className='airport_time_duration_booking_className'>
                                    <span>03:55P - 11:54P</span>
                                    <span>04:59 h</span>
                                    <span>L7/Econ.</span>
                                </div>
                                <div className='airbus'>/321</div>
                                <div className='flight_amenities'>
                                    <ul>
                                        <li><AirlineSeatReclineNormal/></li>
                                        <li><Wifi/></li>
                                        <li><Cable/></li>
                                        <li><OndemandVideo/></li>
                                        <li><Fastfood/></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='available_flight_row'>
                                
                                <div className='flight_img_code_number'>
                                    <FormControlLabel value="female" control={<Radio />} />
                                    <a href="javascript:;"><img src={ImagesPath.canada} alt="JetBlue" /></a>
                                    <span className='code'>B6</span>
                                    <span className='code'>748</span>
                                </div>
                                <div className='flight_date_airport_code'>
                                    <span>Fri,</span>
                                    <span>15SEP</span>
                                    <span>LAS - JFK</span>
                                </div>
                                <div className='airport_time_duration_booking_className'>
                                    <span>03:55P - 11:54P</span>
                                    <span>04:59 h</span>
                                    <span>L7/Econ.</span>
                                </div>
                                <div className='airbus'>/321</div>
                                <div className='flight_amenities'>
                                    <ul>
                                        <li><AirlineSeatReclineNormal/></li>
                                        <li><Wifi/></li>
                                        <li><Cable/></li>
                                        <li><OndemandVideo/></li>
                                        <li><Fastfood/></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='buttongroup'>
                                <button className='booknowBtn'>Book now</button>
                                <div>
                                    <button className='rightBtn'>Offer</button>
                                    <button className='rightBtn'>Rules</button>
                                    <button className='rightBtn'>Seats</button>
                                    <button className='rightBtn'>Extras</button>
                                    <button className='rightBtn' onClick={priceBreakdowHnadle}>Price Breakdown</button>
                                </div>
                            </div>
                            {/* price_breakdown */}
                            <div className={SowPrice}>
                                <table className="table">
                                <thead>
                                    <tr>
                                        <th colSpan={2}><h4>Price breakdown</h4></th>
                                    </tr>
                                </thead>
                                    <tbody>
                                        <tr>
                                            <th>No.</th>
                                            <td>1</td>
                                        </tr>
                                        <tr>
                                            <th>Price</th>
                                            <td>$ 480.93</td>
                                        </tr>
                                        <tr>
                                            <th>Tax</th>
                                            <td>$ 75.17</td>
                                        </tr>
                                        <tr>
                                            <th>Saving</th>
                                            <td>$ 0.00</td>
                                        </tr>
                                        <tr>
                                            <th>Tkt-fee</th>
                                            <td>$ 5.00</td>
                                        </tr>
                                        <tr>
                                            <th>Markup</th>
                                            <td>$ 0.00</td>
                                        </tr>
                                        <tr>
                                            <th>Total</th>
                                            <td>$ 561.10	</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* price_breakdown */}
                        </div>
                    </div>

                    <div className="resultblock">
                        <div className="row">
                            <div className="col-sm-12">
                            <div className="head">Departure  <span className="date"> | Wed, Nov 1</span></div>
                            <div className="row">
                                <div className="col-md-5 col-sm-12 d-flex">
                                <div className="airlinelogo"><img src={ImagesPath.delta} alt="DL"/></div>
                                <div className="airlineInfo">
                                    <div className="airlineName">delta airline </div>
                                    <div className="airlineNumber">Flight No: 006 | EQP-006 <i className="bi bi-exclamation-square-fill"></i></div>
                                </div>
                                </div>
                                <div className="col-md-7 col-sm-12">
                                <div className="leg_detail">
                                    <div className="city">
                                    <div className="time"><strong>12:00</strong> pm</div>
                                    <div className="code">MCI</div>
                                    </div>
                                    <div className="connecting_block">
                                    <div className="leg_points">
                                        <div className='stopage'>
                                            <div className="layovertime">18<span>h</span>  00<span>m</span> </div>
                                            <div className="stops">
                                                {/* <div className="layovertime">18<span>h</span>  00<span>m</span> </div> */}
                                                <i></i>
                                                    <br/>
                                                <b>DCA</b>
                                            </div>
                                        </div>

                                    </div>
                                    </div>
                                    <div className="city">
                                    <div className="time"><strong>12:00</strong> am</div>
                                    <div className="code">ATL</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="head">Return  <span className="date">| Wed, Nov 1</span></div>
                            <div className="row">
                                <div className="col-md-5 col-sm-12 d-flex">
                                <div className="airlinelogo"><img src={ImagesPath.delta} alt="DL"/></div>
                                <div className="airlineInfo">
                                    <div className="airlineName">delta airline </div>
                                    <div className="airlineNumber">Flight No: 006 | EQP-006 <i className="bi bi-exclamation-square-fill"></i></div>
                                </div>
                                </div>
                                <div className="col-md-7 col-sm-12">
                                <div className="leg_detail">
                                    <div className="city">
                                    <div className="time"><strong>12:00</strong> pm</div>
                                    <div className="code">MCI</div>
                                    </div>
                                    <div className="connecting_block">
                                    <div className="leg_points">
                                        <div className='stopage'>
                                            <div className="layovertime">18<span>h</span>  00<span>m</span> </div>
                                            <div className="stops">
                                                {/* <div className="layovertime">18<span>h</span>  00<span>m</span> </div> */}
                                                <i></i>
                                                    <br/>
                                                <b>DCA</b>
                                            </div>
                                        </div>

                                    </div>
                                    </div>
                                    <div className="city">
                                    <div className="time"><strong>12:00</strong> am</div>
                                    <div className="code">ATL</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-sm-12">
                            <div className="price_list">
                                <div className='w_100 d-flex flex-row align-items-center'>
                                    <div className='price_block'>
                                        <div className="price">$522.<sup>99</sup></div>
                                        <div className="fare__breakup">
                                            <a href="/" className="fare__detail">Fare Detail
                                                <span>(incl. taxes)</span>
                                            </a>
                                        </div>
                                    </div>
                                    <a href="/" className="select__btn">Select <i className="bi bi-chevron-down ml-2" aria-hidden="true"></i></a>
                                </div>
                                
                                <div className='baggageBlock'>
                                    <ul>
                                        <li>
                                            <CheckCircle className='icon'/>
                                            <img src={ImagesPath.baggage_icon1} alt="" />
                                        </li>
                                        <li>
                                            <CheckCircle className='icon'/>
                                            <img src={ImagesPath.baggage_icon2} alt="" />
                                        </li>
                                        <li>
                                            <Cancel className='icon'/>
                                            <img src={ImagesPath.baggage_icon3} alt="" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="resultblock">
                        <div className="row">
                            <div className="col-sm-12">
                            <div className="head">Departure  <span className="date"> | Wed, Nov 1</span></div>
                            <div className="row">
                                <div className="col-md-5 col-sm-12 d-flex">
                                <div className="airlinelogo"><img src={ImagesPath.delta} alt="DL"/></div>
                                <div className="airlineInfo">
                                    <div className="airlineName">delta airline </div>
                                    <div className="airlineNumber">Flight No: 006 | EQP-006 <i className="bi bi-exclamation-square-fill"></i></div>
                                </div>
                                </div>
                                <div className="col-md-7 col-sm-12">
                                <div className="leg_detail">
                                    <div className="city">
                                    <div className="time"><strong>12:00</strong> pm</div>
                                    <div className="code">MCI</div>
                                    </div>
                                    <div className="connecting_block">
                                    <div className="leg_points">
                                        <div className='stopage'>
                                            <div className="layovertime">18<span>h</span>  00<span>m</span> </div>
                                            <div className="stops">
                                                {/* <div className="layovertime">18<span>h</span>  00<span>m</span> </div> */}
                                                <i></i>
                                                    <br/>
                                                <b>DCA</b>
                                            </div>
                                        </div>

                                    </div>
                                    </div>
                                    <div className="city">
                                    <div className="time"><strong>12:00</strong> am</div>
                                    <div className="code">ATL</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="head">Return  <span className="date">| Wed, Nov 1</span></div>
                            <div className="row">
                                <div className="col-md-5 col-sm-12 d-flex">
                                <div className="airlinelogo"><img src={ImagesPath.delta} alt="DL"/></div>
                                <div className="airlineInfo">
                                    <div className="airlineName">delta airline </div>
                                    <div className="airlineNumber">Flight No: 006 | EQP-006 <i className="bi bi-exclamation-square-fill"></i></div>
                                </div>
                                </div>
                                <div className="col-md-7 col-sm-12">
                                <div className="leg_detail">
                                    <div className="city">
                                    <div className="time"><strong>12:00</strong> pm</div>
                                    <div className="code">MCI</div>
                                    </div>
                                    <div className="connecting_block">
                                    <div className="leg_points">
                                        <div className='stopage'>
                                            <div className="layovertime">18<span>h</span>  00<span>m</span> </div>
                                            <div className="stops">
                                                {/* <div className="layovertime">18<span>h</span>  00<span>m</span> </div> */}
                                                <i></i>
                                                    <br/>
                                                <b>DCA</b>
                                            </div>
                                        </div>

                                    </div>
                                    </div>
                                    <div className="city">
                                    <div className="time"><strong>12:00</strong> am</div>
                                    <div className="code">ATL</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-sm-12">
                            <div className="price_list">
                                <div className='w_100 d-flex flex-row align-items-center'>
                                    <div className='price_block'>
                                        <div className="price">$522.<sup>99</sup></div>
                                        <div className="fare__breakup">
                                            <a href="/" className="fare__detail">Fare Detail
                                                <span>(incl. taxes)</span>
                                            </a>
                                        </div>
                                    </div>
                                    <a href="/" className="select__btn">Select <i className="bi bi-chevron-down ml-2" aria-hidden="true"></i></a>
                                </div>
                                {/* <div className="seat_left highlight mt-3">Hurry!! Only 9 Seats left</div> */}

                                <div className='baggageBlock'>
                                    <ul>
                                        <li>
                                            <CheckCircle className='icon'/>
                                            <img src={ImagesPath.baggage_icon1} alt="" />
                                        </li>
                                        <li>
                                            <CheckCircle className='icon'/>
                                            <img src={ImagesPath.baggage_icon2} alt="" />
                                        </li>
                                        <li>
                                            <Cancel className='icon'/>
                                            <img src={ImagesPath.baggage_icon3} alt="" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="resultblock">
                        <div className="row">
                            <div className="col-sm-12">
                            <div className="head">Departure  <span className="date"> | Wed, Nov 1</span></div>
                            <div className="row">
                                <div className="col-md-5 col-sm-12 d-flex">
                                <div className="airlinelogo"><img src={ImagesPath.delta} alt="DL"/></div>
                                <div className="airlineInfo">
                                    <div className="airlineName">delta airline </div>
                                    <div className="airlineNumber">Flight No: 006 | EQP-006 <i className="bi bi-exclamation-square-fill"></i></div>
                                </div>
                                </div>
                                <div className="col-md-7 col-sm-12">
                                <div className="leg_detail">
                                    <div className="city">
                                    <div className="time"><strong>12:00</strong> pm</div>
                                    <div className="code">MCI</div>
                                    </div>
                                    <div className="connecting_block">
                                    <div className="leg_points">
                                        <div className='stopage'>
                                            <div className="layovertime">18<span>h</span>  00<span>m</span> </div>
                                            <div className="stops">
                                                {/* <div className="layovertime">18<span>h</span>  00<span>m</span> </div> */}
                                                <i></i>
                                                    <br/>
                                                <b>DCA</b>
                                            </div>
                                        </div>

                                    </div>
                                    </div>
                                    <div className="city">
                                    <div className="time"><strong>12:00</strong> am</div>
                                    <div className="code">ATL</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="head">Return  <span className="date">| Wed, Nov 1</span></div>
                            <div className="row">
                                <div className="col-md-5 col-sm-12 d-flex">
                                <div className="airlinelogo"><img src={ImagesPath.delta} alt="DL"/></div>
                                <div className="airlineInfo">
                                    <div className="airlineName">delta airline </div>
                                    <div className="airlineNumber">Flight No: 006 | EQP-006 <i className="bi bi-exclamation-square-fill"></i></div>
                                </div>
                                </div>
                                <div className="col-md-7 col-sm-12">
                                <div className="leg_detail">
                                    <div className="city">
                                    <div className="time"><strong>12:00</strong> pm</div>
                                    <div className="code">MCI</div>
                                    </div>
                                    <div className="connecting_block">
                                    <div className="leg_points">
                                        <div className='stopage'>
                                            <div className="layovertime">18<span>h</span>  00<span>m</span> </div>
                                            <div className="stops">
                                                {/* <div className="layovertime">18<span>h</span>  00<span>m</span> </div> */}
                                                <i></i>
                                                    <br/>
                                                <b>DCA</b>
                                            </div>
                                        </div>

                                    </div>
                                    </div>
                                    <div className="city">
                                    <div className="time"><strong>12:00</strong> am</div>
                                    <div className="code">ATL</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-sm-12">
                            <div className="price_list">
                                <div className='w_100 d-flex flex-row align-items-center'>
                                    <div className='price_block'>
                                        <div className="price">$522.<sup>99</sup></div>
                                        <div className="fare__breakup">
                                            <a href="/" className="fare__detail">Fare Detail
                                                <span>(incl. taxes)</span>
                                            </a>
                                        </div>
                                    </div>
                                    <a href="/" className="select__btn">Select <i className="bi bi-chevron-down ml-2" aria-hidden="true"></i></a>
                                </div>
                                {/* <div className="seat_left highlight mt-3">Hurry!! Only 9 Seats left</div> */}

                                <div className='baggageBlock'>
                                    <ul>
                                        <li>
                                            <CheckCircle className='icon'/>
                                            <img src={ImagesPath.baggage_icon1} alt="" />
                                        </li>
                                        <li>
                                            <CheckCircle className='icon'/>
                                            <img src={ImagesPath.baggage_icon2} alt="" />
                                        </li>
                                        <li>
                                            <Cancel className='icon'/>
                                            <img src={ImagesPath.baggage_icon3} alt="" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="resultblock">
                        <div className="row">
                            <div className="col-sm-12">
                            <div className="head">Departure  <span className="date"> | Wed, Nov 1</span></div>
                            <div className="row">
                                <div className="col-md-5 col-sm-12 d-flex">
                                <div className="airlinelogo"><img src={ImagesPath.delta} alt="DL"/></div>
                                <div className="airlineInfo">
                                    <div className="airlineName">delta airline </div>
                                    <div className="airlineNumber">Flight No: 006 | EQP-006 <i className="bi bi-exclamation-square-fill"></i></div>
                                </div>
                                </div>
                                <div className="col-md-7 col-sm-12">
                                <div className="leg_detail">
                                    <div className="city">
                                    <div className="time"><strong>12:00</strong> pm</div>
                                    <div className="code">MCI</div>
                                    </div>
                                    <div className="connecting_block">
                                    <div className="leg_points">
                                        <div className='stopage'>
                                            <div className="layovertime">18<span>h</span>  00<span>m</span> </div>
                                            <div className="stops">
                                                {/* <div className="layovertime">18<span>h</span>  00<span>m</span> </div> */}
                                                <i></i>
                                                    <br/>
                                                <b>DCA</b>
                                            </div>
                                        </div>

                                    </div>
                                    </div>
                                    <div className="city">
                                    <div className="time"><strong>12:00</strong> am</div>
                                    <div className="code">ATL</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="head">Return  <span className="date">| Wed, Nov 1</span></div>
                            <div className="row">
                                <div className="col-md-5 col-sm-12 d-flex">
                                <div className="airlinelogo"><img src={ImagesPath.delta} alt="DL"/></div>
                                <div className="airlineInfo">
                                    <div className="airlineName">delta airline </div>
                                    <div className="airlineNumber">Flight No: 006 | EQP-006 <i className="bi bi-exclamation-square-fill"></i></div>
                                </div>
                                </div>
                                <div className="col-md-7 col-sm-12">
                                <div className="leg_detail">
                                    <div className="city">
                                    <div className="time"><strong>12:00</strong> pm</div>
                                    <div className="code">MCI</div>
                                    </div>
                                    <div className="connecting_block">
                                    <div className="leg_points">
                                        <div className='stopage'>
                                            <div className="layovertime">18<span>h</span>  00<span>m</span> </div>
                                            <div className="stops">
                                                {/* <div className="layovertime">18<span>h</span>  00<span>m</span> </div> */}
                                                <i></i>
                                                    <br/>
                                                <b>DCA</b>
                                            </div>
                                        </div>

                                    </div>
                                    </div>
                                    <div className="city">
                                    <div className="time"><strong>12:00</strong> am</div>
                                    <div className="code">ATL</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-sm-12">
                            <div className="price_list">
                                <div className='w_100 d-flex flex-row align-items-center'>
                                    <div className='price_block'>
                                        <div className="price">$522.<sup>99</sup></div>
                                        <div className="fare__breakup">
                                            <a href="/" className="fare__detail">Fare Detail
                                                <span>(incl. taxes)</span>
                                            </a>
                                        </div>
                                    </div>
                                    <a href="/" className="select__btn">Select <i className="bi bi-chevron-down ml-2" aria-hidden="true"></i></a>
                                </div>
                                {/* <div className="seat_left highlight mt-3">Hurry!! Only 9 Seats left</div> */}

                                <div className='baggageBlock'>
                                    <ul>
                                        <li>
                                            <CheckCircle className='icon'/>
                                            <img src={ImagesPath.baggage_icon1} alt="" />
                                        </li>
                                        <li>
                                            <CheckCircle className='icon'/>
                                            <img src={ImagesPath.baggage_icon2} alt="" />
                                        </li>
                                        <li>
                                            <Cancel className='icon'/>
                                            <img src={ImagesPath.baggage_icon3} alt="" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className='loadingPopup' style={{ display: 'none'}}>
                        <CircularProgress className="loader" sx={{ '--CircularProgress-size': '80px' }}>
                            <Flight style={{width: '40px', height: '40px'}}/>
                        </CircularProgress>
                        <h4>Loading...</h4>
                    </div>    
                </div>
                {/* <!-- result Box ends --> */}

            </div>
            {/* <!-- filterresult container ends --> */}

            </div>
        </div>
        {/* <!-- Result Container end --> */}

      <Footer/>
        {/* <div className='price_breakdown_overlay'>
            <div className='price_breakdown_overlay_inner'>
                <div className='table-responsive price_breakdown'>
                    <Link className='closeBtn' to="/"><Cancel/></Link>
                    <table className="table">
                    <thead>
                        <tr>
                            <th colSpan={2}><h4>Price breakdown</h4></th>
                        </tr>
                    </thead>
                        <tbody>
                            <tr>
                                <th>No.</th>
                                <td>1</td>
                            </tr>
                            <tr>
                                <th>Price</th>
                                <td>$ 480.93</td>
                            </tr>
                            <tr>
                                <th>Tax</th>
                                <td>$ 75.17</td>
                            </tr>
                            <tr>
                                <th>Saving</th>
                                <td>$ 0.00</td>
                            </tr>
                            <tr>
                                <th>Tkt-fee</th>
                                <td>$ 5.00</td>
                            </tr>
                            <tr>
                                <th>Markup</th>
                                <td>$ 0.00</td>
                            </tr>
                            <tr>
                                <th>Total</th>
                                <td>$ 561.10	</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div> */}
    </div>
  )
}



export default PriceBreakdownPopup


