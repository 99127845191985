import {memo} from "react"
import { Link} from "react-router-dom";
const Paymenttop= ({backUrl}) =>{
if(backUrl===null)
{
	return false;
}
  return (
<>
<div className="payment__top">
            <div className="row">
              <div className="col-sm-3">
                <Link to={"/result?"+backUrl} className="go__back">
                  <i className="fa fa-angle-left"></i> Go Back
                </Link>
              </div>

              <div className="col-sm-9 d-none d-lg-block">
                <ul className="secure__Box">
                  <li>
                    <i className="bi bi-credit-card-2-back"></i> Secure <br />
                    Payment System
                  </li>
                  <li>
                    <i className="bi bi-check-circle"></i> Easy <br />
                    Booking
                  </li>
                  <li>
                    <i className="bi bi-patch-check"></i> Certified
                    <br />
                    Travel Portal
                  </li>
                  <li>
                    {" "}
                    <i className="bi bi-bookmark-check"></i> Focused on <br />
                    Quality Services
                  </li>
                  <li>
                    <i className="bi bi-emoji-smile"></i> Happy <br />
                    Customers
                  </li>
                </ul>
              </div>
            </div>
          </div>
</>
   
    );

}
export default memo(Paymenttop);