import React from "react";
import HeaderUser from "../../components/HeaderUser";
import Footer from "../../components/Footer";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import ImagesPath from "../../components/ImagesPath";
import ShowFare from "./leftfilter/ShowFare";
import ItineraryHeading from "./leftfilter/ItineraryHeading";
import Loader from "./leftfilter/Loader";
import {
  deepFilter,
  fullUrl,
  getStopValue,
  urlPart,
  getClass,
  includeAirline,
} from "./function.js";
//import { useAuth } from "../../provider/authProvider";
//import { useNavigate } from "react-router-dom";
import { apiBaseUrl, website, cId, engine, metaname } from "../../global";
import { v4 as uuid } from "uuid";
//import {isMobile} from 'react-device-detect';
import moment from "moment";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  //  FormControl,
  Radio,
  // RadioGroup,
  Checkbox,
  Slider,
} from "@mui/material";

// import {
//     CircularProgress,
//     Textarea,
// }
// from '@mui/joy';

import { useEffect, useState, useCallback } from "react";

import {
  // Flight,
  ExpandMore,
  Wifi,
  AirlineSeatReclineNormal,
  Cable,
  OndemandVideo,
  Fastfood,
  CheckCircle,
  Cancel,
  ArrowDropDown,
} from "@mui/icons-material";
import GenericForm from "./GenericForm";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/perspective.css";
import "tippy.js/themes/light.css";
import Faredetail from "./leftfilter/Faredetail.js";
import Commission from "./leftfilter/Commission.js";

// const marks = [
//     {
//       value: 0,
//       label: '05:01h',
//     },
//     {
//       value: 100,
//       label: '14:40h',
//     },
//   ];
//   function valuetext(value: number) {
//     return `${value}h`;
//   }

//   function valueLabelFormat(value: number) {
//     return marks.findIndex((mark) => mark.value === value) + 1;
//   }

//   const price = [
//     {
//       value: 0,
//       label: '$ 00.00',
//     },
//     {
//       value: 100,
//       label: '$ 946.25',
//     },
//   ];

function rangeValuePrice(value) {
  return `${value}°C`;
}

const Result = () => {
  //const { token } = useAuth();
  const [searchParams] = useSearchParams();
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [searchRq, setSearchRq] = useState({});
  const [searchRs, setSearchRs] = useState({});
  const [markupFilterRs, setMarkupFilterRs] = useState([]);
  const [filterRs, setFilterRs] = useState([]);
  const [markup, setMarkup] = useState(null);
  // const [filterArray] = useState({rating:[],budget:[],fareType:[],propertyType:[],availableMealPlan:[],locality:[],facility:[],sorting:[]});
  const [filterArray, setFilterArray] = useState({
    price: [],
    stop: [],
    airline: [],
    airport: [],
  });
  const [singleState, setSingleState] = useState({});
  const [priceRange, setPriceRange] = useState([]);
  const [stopes, setStopes] = useState([]);
  const [airport, setAirport] = useState([]);
  const [airline, setAirline] = useState([]);
  const [show, setShowing] = useState(null);
  const [totalResults, setTotalResults] = useState(null);

  const [viaExpand, setViaExpand] = useState(false);
  const [airlineExpand, setAirlineExpand] = useState(false);
  const [stopExpand, setStopExpand] = useState(false);
  const [markupExpand, setMarkupExpand] = useState(false);
  const [priceExpand, setPriceExpand] = useState(false);

  const [url] = useState(urlPart[1]);
  const expandAllAccordion = (e) => {
    e.preventDefault();
    setViaExpand(true);
    setAirlineExpand(true);
    setStopExpand(true);
    setMarkupExpand(true);
    setPriceExpand(true);
  };
  const collapseAllAccordion = (e) => {
    e.preventDefault();
    setViaExpand(false);
    setAirlineExpand(false);
    setStopExpand(false);
    setMarkupExpand(false);
    setPriceExpand(false);
  };

  const resetAllData = (e) => {
    e.preventDefault();
    let result = searchRs;
    let totalResults = result.Results.length;

    // console.log(result.Results.Filter);
    let MinPrice = result.Filter.MinPrice;
    let MaxPrice = result.Filter.MaxPrice;
    setTotalResults(totalResults);
    setShowing(totalResults);
    setPriceRange([MinPrice, MaxPrice]);
    rangeValuePrice(MinPrice);
    rangeValuePrice(MaxPrice);
    setMarkupFilterRs([]);
    setFilterRs([]);
    let airportList = [...airport];
    setAirport(
      airportList.map((air) => {
        if (air.isSelected === true) {
          return { ...air, isSelected: false };
        } else {
          return air;
        }
      })
    );

    let airlineList = [...airline];
    setAirline(
      airlineList.map((airl) => {
        if (airl.isSelected === true) {
          return { ...airl, isSelected: false };
        } else {
          return airl;
        }
      })
    );
    let stopeList = [...stopes];
    setStopes(
      stopeList.map((stp) => {
        if (stp.isSelected === true) {
          return { ...stp, isSelected: false };
        } else {
          return stp;
        }
      })
    );

    setMarkup("");
    setMarkupFilterRs(result.Results);
    setFilterRs(result.Results);
    setFilterArray({ price: [], stop: [], airline: [], airport: [] });
    setDrpFilter("Price Ascending");
  };

  const handleStop = useCallback(
    (item, index) => {
      const updateStop = [...stopes];
      //         if(index===1000)
      //         {
      // console.log(updateStop);
      //         }
      //         debugger;
      updateStop[index] = { ...item, isSelected: !item.isSelected };
      setStopes(updateStop);
      let filterData = updateStop.filter(
        (locali) => locali.isSelected === true
      );
      filterArray.stop = [];
      filterData.forEach((stopName) => {
        let stopId = getStopValue(stopName.Stopes);
        filterArray.stop.push(stopId);
      });
      let markupResult = [...markupFilterRs];
      let dataF = markupResult.filter((item) => {
        let a = deepFilter(item, filterArray);
        if (a === true) {
          return item;
        } else {
          return false;
        }
      });
      setFilterRs([]);
      dataF = sortingD(dataF, drpFilter);
      setFilterRs(dataF);
      setShowing(dataF.length);
      setFilterArray(filterArray);
    },
    [stopes, filterArray, markupFilterRs]
  );

  const handleAirline = useCallback(
    (item, index) => {
      const updateAirline = [...airline];
      updateAirline[index] = { ...item, isSelected: !item.isSelected };
      setAirline(updateAirline);
      let filterData = updateAirline.filter(
        (locali) => locali.isSelected === true
      );
      filterArray.airline = [];
      filterData.forEach((airlineName) => {
        filterArray.airline.push(airlineName.AirlineCode);
      });
      let markupResult = [...markupFilterRs];
      let dataF = markupResult.filter((item) => {
        let a = deepFilter(item, filterArray);
        if (a === true) {
          return item;
        } else {
          return false;
        }
      });
      setFilterRs([]);
      dataF = sortingD(dataF, drpFilter);
      setFilterRs(dataF);
      setShowing(dataF.length);
      setFilterArray(filterArray);
      //console.log(filterArray);
    },
    [airline, filterArray, markupFilterRs]
  );
  const handleConnecting = useCallback(
    (item, index) => {
      const updateAirport = [...airport];
      updateAirport[index] = { ...item, isSelected: !item.isSelected };
      setAirport(updateAirport);
      let filterData = updateAirport.filter(
        (locali) => locali.isSelected === true
      );
      filterArray.airport = [];
      filterData.forEach((AirportName) => {
        filterArray.airport.push(AirportName.AirportCode);
      });
      let markupResult = [...markupFilterRs];
      let dataF = markupResult.filter((item) => {
        let a = deepFilter(item, filterArray);
        if (a === true) {
          return item;
        } else {
          return false;
        }
      });
      setFilterRs([]);
      dataF = sortingD(dataF, drpFilter);
      setFilterRs(dataF);
      setShowing(dataF.length);
      setFilterArray(filterArray);
    },
    [airport, filterArray, markupFilterRs]
  );

  const handlePriceRange = useCallback(
    (event, priceRange) => {
      setPriceRange(priceRange);
      const price = [...priceRange];
      let min = price[0];
      let max = price[1];
      filterArray.price = [];
      filterArray.price.push(min);
      filterArray.price.push(max);
      const markupResult = [...markupFilterRs];
      setFilterRs([]);
      let dataF = markupResult.filter((item) => {
        let a = deepFilter(item, filterArray);
        if (a === true) {
          return item;
        } else {
          return false;
        }
      });

      setFilterRs([]);
      dataF = sortingD(dataF, drpFilter);
      setFilterRs(dataF);
      setShowing(dataF.length);
      setFilterArray(filterArray);
      //  console.log(filterArray)
    },
    [priceRange, filterArray, markupFilterRs]
  );

  const handleMarkup = (e) => {
    const filterResults = [...searchRs.Results];
    setFilterRs([]);
    setMarkupFilterRs([]);
    setDataIsLoaded(true);
    let totalResults = filterResults.length;
    setTotalResults(totalResults);
    setShowing(totalResults);
    var markupIncrease = parseInt(e.target.value);
    if (isNaN(markupIncrease) || markupIncrease.toString().length === 0) {
      markupIncrease = "";
    }
    const updateData = filterResults.map((filterd) => {
      if (markupIncrease !== "") {
        var childCount = 0;
        var childsObj;
        var childTotalBaseFare = 0;
        var infantTotalBaseFare = 0;
        if (filterd.Childs !== "null") {
          childCount = filterd.Childs.Pass;
          var childBaseFare =
            parseFloat(markupIncrease) + parseFloat(filterd.Childs.BaseFare);
          childTotalBaseFare = childCount * childBaseFare;
          var childTaxFare = filterd.Childs.Tax;
          childsObj = {
            Pass: childCount,
            BaseFare: childBaseFare.toString(),
            Tax: childTaxFare,
          };
        } else {
          childsObj = "null";
        }
        if (filterd.Infants !== "null") {
          var InfantCount = filterd.Infants.Pass;
          var infantBaseFare = parseFloat(filterd.Infants.BaseFare);
          infantTotalBaseFare = InfantCount * infantBaseFare;
        }
        var adultCount = filterd.Adults.Pass;
        var totalPass = +adultCount + +childCount;
        var markupIncreaseTotal = markupIncrease * totalPass;
        var adultBaseFare = +markupIncrease + +filterd.Adults.BaseFare;
        var adultTotalBaseFare = adultCount * adultBaseFare;
        var adultTaxFare = filterd.Adults.Tax;
        var FarePerAdult = parseFloat(adultBaseFare) + parseFloat(adultTaxFare);
        var totalBaseFare =
          parseFloat(adultTotalBaseFare) +
          parseFloat(childTotalBaseFare) +
          parseFloat(infantTotalBaseFare);
        var adultsObj = {
          Pass: adultCount,
          BaseFare: adultBaseFare.toString(),
          Tax: adultTaxFare,
        };
        return {
          ...filterd,
          FarePerAdult: FarePerAdult.toString(),
          BaseFare: totalBaseFare.toString(),
          TotalFare: (
            parseFloat(filterd.TotalFare) + markupIncreaseTotal
          ).toString(),
          Adults: adultsObj,
          Childs: childsObj,
        };
      } else {
        return {
          ...filterd,
          FarePerAdult: filterd.FarePerAdult,
          BaseFare: filterd.BaseFare,
          TotalFare: filterd.TotalFare,
          Adults: filterd.Adults,
          Childs: filterd.Childs,
        };
      }
    });
    setMarkup(markupIncrease);
    setFilterRs(updateData);
    setMarkupFilterRs(updateData);
    if (markupIncrease !== "" && markupIncrease > 0) {
      setSingleState((previousState) => {
        return {
          ...previousState,
          maxPrice: singleState.maxPrice + parseInt(markupIncrease),
        };
      });
    } else if (markupIncrease !== "" && markupIncrease < 0) {
      setSingleState((previousState) => {
        return {
          ...previousState,
          minPrice: singleState.minPrice + parseInt(markupIncrease),
        };
      });
    }
    const singleValue = { ...singleState };
    const min = singleValue.minPrice;
    const max = singleValue.maxPrice;
    setPriceRange([min, max]);
    rangeValuePrice(min);
    rangeValuePrice(max);
    setAirport(
      airport.map((air) => {
        if (air.isSelected === true) {
          return { ...air, isSelected: false };
        } else {
          return air;
        }
      })
    );
    setAirline(
      airline.map((airl) => {
        if (airl.isSelected === true) {
          return { ...airl, isSelected: false };
        } else {
          return airl;
        }
      })
    );
    setStopes(
      stopes.map((stp) => {
        if (stp.isSelected === true) {
          return { ...stp, isSelected: false };
        } else {
          return stp;
        }
      })
    );
    setDrpFilter("Price Ascending");

    setDataIsLoaded(false);
  };

  useEffect(() => {
    setFilterRs([]);
    let tripLen = searchParams.get("tripLen");
    let adults = searchParams.get("adt");
    let cur = searchParams.get("cur");
    let chd = searchParams.get("chd");
    let inf = searchParams.get("inf");
    let infSeat = searchParams.get("infSeat");
    let tripType = searchParams.get("tripType");
    let cls = searchParams.get("class");
    const unique_id = uuid();
    //   let segments=[];

    let airline1 = searchParams.get("airline1");
    let airline2 = searchParams.get("airline2");
    let airline3 = searchParams.get("airline3");
    let baggageFaresOnly = searchParams.get("baggageFaresOnly");
    let nonStop = searchParams.get("nonStop");
    let maxTransfer = searchParams.get("maxTransfer");
    let allAirline = includeAirline(airline1, airline2, airline3);

    var sessionData = JSON.parse(localStorage.getItem("userSession"));
    //console.log(JSON.parse(localStorage.getItem('userSession')));

    var searchRq = {
      userId: localStorage.getItem("id"),
      src: "SA",
      tripLen: tripLen,
      cur: cur,
      baggageFaresOnly: baggageFaresOnly,
      nonStop: nonStop,
      maxTransfer: maxTransfer,
      airline: allAirline,
      tripType: tripType,
      classType: cls,
      adults: adults,
      childs: chd,
      infants: inf,
      infantSeat: infSeat,
      segments: [],
      TraceId: unique_id,
      cId: sessionData.cId,
      website: sessionData.website_id,
      metaname: metaname,
      booking: "no",
      eType: "b2b",
    };

    for (let i = 1; i <= tripLen; i++) {
      if (tripType == 2) {
        let org = searchParams.get("org" + i);
        let des = searchParams.get("des" + i);
        let dt = searchParams.get("dt" + i);
        dt = moment(dt).format("DD-MM-yyyy");
        let seg = {
          origin: org.substring(0, 3),
          destination: des.substring(0, 3),
          departureDate: dt,
        };
        let seg1 = {
          origin: des.substring(0, 3),
          destination: org.substring(0, 3),
          departureDate: moment(searchParams.get("returnDate")).format(
            "DD-MM-yyyy"
          ),
        };
        searchRq.segments.push(seg);
        searchRq.segments.push(seg1);
      } else {
        let org = searchParams.get("org" + i);
        let des = searchParams.get("des" + i);
        let dt = searchParams.get("dt" + i);
        dt = moment(dt).format("DD-MM-yyyy");
        let seg = {
          origin: org.substring(0, 3),
          destination: des.substring(0, 3),
          departureDate: dt,
        };
        searchRq.segments.push(seg);
      }
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    //const url = apiBaseUrl + '/api/search';
    const url = apiBaseUrl + "/api/lowFareSearch";
    // alert(url)
    //  console.log(searchRq);
    //  debugger;

    setSearchRq(searchRq);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(searchRq),
      redirect: "follow",
    };

      //   var requestOptions = {
      //     method: 'GET',
      //     headers: myHeaders,
      //     redirect: 'follow'
      // };
    setDataIsLoaded(true);
    fetch(url, requestOptions)
      // fetch('response.json', requestOptions)
     .then((response) => response.json())
      .then((result) => {
        //  console.log(result);
        // debugger;
        if (result.status === "OK") {
          let totalResults = result.Results.length;
          // console.log(result);
          //  debugger;
          // console.log(result.Results.Filter);
          let MinPrice = result.Filter.MinPrice;
          let MaxPrice = result.Filter.MaxPrice;
          let currency = result.Results[0].Cur;
          setTotalResults(totalResults);
          setShowing(totalResults);
          setSearchRs(result);
          setMarkupFilterRs(result.Results);
          setFilterRs(result.Results);
          setPriceRange([MinPrice, MaxPrice]);
          //  setStopes(result.Filter.Stops_Index);
          let stop = [];
          let airline = [];
          let airport = [];
          let stopIndex = result.Filter.Stops_Index;
          stopIndex.forEach((st, indd) => {
            let a = { Price: st.Price, Stopes: st.Stopes, isSelected: false };
            stop.push(a);
          });

          let SortedAirline = result.Filter.SortedAirline;
          let AirlineNameObj = result.Airline;
          SortedAirline.forEach((st, indd) => {
            let a = {
              Price: st.Price,
              AirlineCode: st.AirlineCode,
              AirlineName: AirlineNameObj[st.AirlineCode],
              isSelected: false,
            };
            airline.push(a);
          });

          let ConnectingD = result.Connecting;
          ConnectingD.forEach((st, indd) => {
            let a = {
              AirportCode: st.Code,
              Price: "",
              AirportName: st.Code + "-" + st.Name,
              isSelected: false,
            };
            airport.push(a);
          });
          setStopes(stop);
          setAirline(airline);
          setAirport(airport);

          setSingleState({
            totalResults: totalResults,
            filterCounts: totalResults,
            minPrice: MinPrice,
            maxPrice: MaxPrice,
            currency: currency,
          });
          setDataIsLoaded(false);
        }
      })
      .catch((error) => console.log(error));
  }, [searchParams, apiBaseUrl]);


  /* 
  fare rules
  */

 
  
  // best result dropdow start
  const [isBestResult, setIsBestResult] = useState(false);
  const toggleBestResult = () => {
    setIsBestResult(!isBestResult);
  };

  const [drpFilter, setDrpFilter] = useState("Price Ascending");

  const _setDrpFilter = useCallback(
    (val) => {
      const markupResult = [...markupFilterRs];
      setFilterRs([]);
      let dataF = markupResult.filter((item) => {
        let a = deepFilter(item, filterArray);
        if (a === true) {
          return item;
        } else {
          return false;
        }
      });

      setFilterRs([]);
      dataF = sortingD(dataF, val);
      setFilterRs(dataF);
      setShowing(dataF.length);
      setDrpFilter(val);
      setIsBestResult(false);
    },
    [filterArray, markupFilterRs]
  );

  const sortingD = (dataF, val) => {
    let arraySort = [];
    if (val === "Price Ascending") {
      dataF.sort(
        (a, b) => parseFloat(a.FarePerAdult) - parseFloat(b.FarePerAdult)
      );
    } else if (val === "Price Descending") {
      dataF.sort(
        (a, b) => parseFloat(b.FarePerAdult) - parseFloat(a.FarePerAdult)
      );
    }
    return dataF;
  };
  const navigate = useNavigate();

  const setLocalStorage = (
    data,
    TraceId,
    key,
    searchRq,
    backUrl,
    Airline,
    Airport,
    markup
  ) => {
    let dataa = {
      Key: key,
      searchRq: searchRq,
      itinerary: data,
      backUrl: backUrl,
      TraceId: TraceId,
      Airline: Airline,
      Airport: Airport,
      markup: markup
    };
    let da = JSON.stringify(dataa);
    localStorage.setItem(TraceId, da);
    let urldata =
      "?TraceId=" + TraceId + "&ItenId=" + data.ItinId + "&" + backUrl;
    navigate("/payment" + urldata, { replace: true });
  };
  // best result dropdow ends

  // modifysearch ends
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const searchShow = () => {
    // setDataIsLoaded(true);
    setIsSearchVisible(true);
    // setFilterRs([])
  };
  const searchHide = () => {
   
    setIsSearchVisible(false);
  };
  // modifysearch ends

  // Filter show start
  const [isFilterSow, setIsFilterSow] = useState(false);
  const toggleFilterSow = () => {
    setIsFilterSow(!isFilterSow);
  };
  const SowFilter = `my-div ${
    isFilterSow ? "filterBox showfilterBox" : "filterBox"
  }`;
  const ToggleIcon = `my-div ${
    isFilterSow ? "filterburger closeIcon" : "filterburger burgerIcon"
  }`;
  // Filter show ends

  const [visibleAllDivIndex, setVisibleAllDivIndex] = useState([]);

  const handleVisibleDiv = (e, id) => {
    e.preventDefault();

    let isAvailable = visibleAllDivIndex.includes(id);
    if (isAvailable === true) {
      const arr = visibleAllDivIndex.filter((a) => a !== id);
      setVisibleAllDivIndex(arr);
    } else {
      setVisibleAllDivIndex([...visibleAllDivIndex, id]);
    }
    //console.log(isAvailable)
    //debugger;
  };
  const Resultbag= ({bagcnt,bag}) =>{
    if(bag===undefined)
    {
      return false;
    }
    if(bag.length===0)
    {
      return false;
    }
   // console.log(bagcnt);
    let desc1='';
    let desc2='';
    if(bag['0']['Desc1']!==undefined)
    {
       desc1=bag[0]['Desc1'];
       desc2=bag[0]['Desc2'];
    }
    
    return (
  <>
  
  <Tippy
                          interactive={true}
                          animation="perspective"
                          theme={"light"}
                          content={
                            <div className="baggageTooltipBox">
                              <div className="baggage_row">
                                <div className="popup_icon">
                                  <img
                                    src={ImagesPath.baggage_icon1}
                                    alt=""
                                    className="icons"
                                  />
                                </div>
                                <div className="baggageTitle">BAGGAGE: {bagcnt}</div>
                              </div>
                              <div className="baggage_row">
                                <div>
                                  {desc1}
                                </div>
                              </div>
                              <div className="baggage_row">
                                <div>
                                  {desc2}
                                </div>
                              </div>
                              {/* <div className="baggage_row">
                                <div className="toTal"><span className="blueBg">ExtraBag</span> <span className="baggagePrice">$200/bag</span></div>
                              </div> */}
                            </div>
                          }
                        >
                          <div className="baggageBlock">
                            <ul>
                              <li>
                                <CheckCircle className="icon" />
                                <img src={ImagesPath.baggage_icon1} alt="" />
                              </li>
                              <li>
                                <CheckCircle className="icon" />
                                <img src={ImagesPath.baggage_icon2} alt="" />
                              </li>
                              <li>
                                <CheckCircle className="icon " />
                                <img src={ImagesPath.baggage_icon3} alt="" />
                              </li>
                            </ul>
                          </div>
                        </Tippy>
  
                           
                           
      </>
      );
  
  }
  return (
    <div>
      <HeaderUser />

      {/* <!-- Result Container start --> */}
      <div id="resultCntr">
        <div className="container">
          {/* <Link className='searchHideBtn' onClick={searchHide}>Close</Link> */}
          {/* Search Box start */}
          {/* {isSearchVisible&& <Link className="searchHideBtn" onClick={searchHide}>
            Close
          </Link>} */}
          {isSearchVisible ? 
          (
             
            <GenericForm
              searchHide={searchHide}
              isSearchVisible={isSearchVisible}
              closeButtonShow={true}
            />
          ) : (
            ""
          )}

          {/* Search Box ends */}

          {/* <!-- tab box start --> */}
          {/* <div className="resulttabBox">
                <ul>
                <li><a className="active" href="/">Flight</a></li>
                <li><a href="/">Flyus Holidays</a></li>
                </ul>
            </div> */}
          {/* <!-- tab box end --> */}

          {/* <!-- resultkey box start --> */}
          {isSearchVisible === false && searchParams.get("org1") !== "" && (
            <div className="resultkeyBox">
              <div className="resultkey">
                <div className="title">Search</div>
                <ul>
                  <li>
                    {" "}
                    {searchParams.get("org1").substring(0, 3)} -{" "}
                    {searchParams.get("des1").substring(0, 3)}
                  </li>
                  <li>{searchParams.get("dt1")}</li>
                  <li>
                    {searchParams.get("adt")}ADT, {searchParams.get("chd")}CHD ,{" "}
                    {searchParams.get("inf")}INF
                  </li>
                  <li>{getClass(searchParams.get("class"))}</li>
                </ul>
              </div>

              <div className="changeSearch">
                <ul>
                  <li>
                    <Link onClick={searchShow}>Modify</Link>
                  </li>
                  <li>
                    <Link onClick={searchShow}>New search</Link>
                  </li>
                </ul>
              </div>
            </div>
          )}                                                                
          {/* <!-- resultkey box end --> */}

          {/* <!-- resultheader Box start --> */}
          <div className="resultheaderBox">
            <div className="left">
              <button onClick={toggleFilterSow} className={ToggleIcon}>
                <i className="bi bi-filter-left"></i>
                <i className="bi bi-x"></i>
              </button>
              <div className="title">Results</div>
              <p>
                {show} Result found from {totalResults}
              </p>
            </div>
            <div className="right">
              <ul>
                <li>
                  <Link>Compare offers</Link>
                </li>
                <li>
                  <Link>Expand all</Link>
                </li>
                <li>
                  {/* <Form.Select aria-label="Default select example" className="BestResultDropdown">
                        <option>Best results</option>
                        <option value="1">Price Descending</option>
                        <option value="2">Airline A - Z</option>
                        <option value="3">Airline Z - A</option>
                        <option value="4">Duration Ascending</option>
                        <option value="5">Duration Descending</option>
                        <option value="6">Best results</option>
                      </Form.Select> */}

                  <button className="bestresults" onClick={toggleBestResult}>
                    {" "}
                    {drpFilter} <ArrowDropDown />
                  </button>
                  {isBestResult && (
                    <div className="BestResultDropdown">
                      <div
                        onClick={(e) => _setDrpFilter(e.target.textContent)}
                        className="dropvalue"
                      >
                        Price Ascending
                      </div>
                      <div
                        onClick={(e) => _setDrpFilter(e.target.textContent)}
                        className="dropvalue"
                      >
                        Price Descending
                      </div>
                      {/* <div onClick={(e)=>setDrpFilter(e.target.textContent)} className='dropvalue'>Airline A - Z</div>
                          <div onClick={(e)=>setDrpFilter(e.target.textContent)} className='dropvalue'>Airline Z - A</div>
                          <div onClick={(e)=>setDrpFilter(e.target.textContent)} className='dropvalue'>Duration Ascending</div>
                          <div onClick={(e)=>setDrpFilter(e.target.textContent)} className='dropvalue'>Duration Descending</div>
                          <div onClick={(e)=>setDrpFilter(e.target.textContent)} className='dropvalue'>Best results</div> */}
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- resultheader Box end --> */}

          {/* <!-- applyedFilter Box start --> */}
          {/* {JSON.stringify(filterArray)} */}

          <div className="applyedFilterBox">
            <ul>
              {filterArray.airline.length === 0 &&
                filterArray.stop.length === 0 &&
                filterArray.airport.length === 0 && <p>No filters applied</p>}

              {stopes.map((fil, index) => {
                if (fil.isSelected === true) {
                  return (
                    <li
                      onClick={() => handleStop(fil, index)}
                      key={index + "stop"}
                    >
                      {fil.Stopes} Stop <i className="bi bi-x resetFilter"></i>
                    </li>
                  );
                }
                return null;
              })}

              {airline.map((fil, index) => {
                if (fil.isSelected === true) {
                  return (
                    <li
                      onClick={() => handleAirline(fil, index)}
                      key={index + "stop"}
                    >
                      {fil.AirlineName} <i className="bi bi-x resetFilter"></i>
                    </li>
                  );
                }
                return null;
              })}

              {airport.map((fil, index) => {
                if (fil.isSelected === true) {
                  return (
                    <li
                      onClick={() => handleConnecting(fil, index)}
                      key={index + "stop"}
                    >
                      {fil.AirportCode} <i className="bi bi-x resetFilter"></i>
                    </li>
                  );
                }
                return null;
              })}
              {(filterArray.airline.length > 0 ||
                filterArray.stop.length > 0 ||
                filterArray.airport.length > 0) && (
                <span onClick={(e) => resetAllData(e)} className="clear_filter">
                  Clear All filters
                </span>
              )}
            </ul>
          </div>

          {/* <!-- applyedFilter Box end --> */}

          {/* <!-- filterresult container start --> */}
          <div id="filterresultCntr">
            {/* <!-- filter Box start --> */}
            <div className={SowFilter}>
              <div className="resetblock">
                <ul>
                  <li>
                    <a onClick={resetAllData} href="#">
                      Reset all
                    </a>
                  </li>
                  <li>
                    <a onClick={expandAllAccordion} href="#">
                      Expand all
                    </a>
                  </li>
                  <li>
                    <a onClick={collapseAllAccordion} href="#">
                      Collapse all
                    </a>
                  </li>
                </ul>
              </div>

              <Accordion
                onChange={() => setMarkupExpand(!markupExpand)}
                expanded={markupExpand}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="tabheader"
                >
                  Markup
                </AccordionSummary>
                <AccordionDetails>
                  <div className="filterBlock">
                    <div className="row_filter">
                      <div className="left">
                        <p>Agent markup </p>
                      </div>
                      <div className="right">
                        $
                        <input
                          className="countinput"
                          type="number"
                          onChange={(e) => handleMarkup(e)}
                          value={markup}
                          name="markup"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                onChange={() => setPriceExpand(!priceExpand)}
                expanded={priceExpand}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="tabheader"
                >
                  Price
                </AccordionSummary>
                <AccordionDetails>
                  <div className="filterBlock">
                    <div className="duration_slider">
                      <div className="travelTimeSlider">
                        <Slider
                          getAriaLabel={() => "Price range"}
                          value={priceRange}
                          max={singleState.maxPrice}
                          min={singleState.minPrice}
                          step={1}
                          onChange={handlePriceRange}
                          valueLabelDisplay="on"
                          getAriaValueText={rangeValuePrice}
                          className="rangelider"
                        />
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                onChange={() => setStopExpand(!stopExpand)}
                expanded={stopExpand}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="tabheader"
                >
                  Number of stops
                </AccordionSummary>
                <AccordionDetails>
                  <div className="filterBlock">
                    {stopes.map((fil, ind) => (
                      <div className="row_filter" key={"stopes" + ind}>
                        <div className="left">
                          <FormControlLabel
                            className="select_icon"
                            control={
                              <Checkbox
                                checked={fil.isSelected}
                                onChange={() => handleStop(fil, ind)}
                              />
                            }
                            label={fil.Stopes + " Stop"}
                          />
                        </div>
                        <div className="right">${fil.Price}</div>
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>

              {/* <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className='tabheader'
                    >
                        Maximum travel time
                    </AccordionSummary>
                    <AccordionDetails>
                        
                            <div className='filterBlock'>
                                <div className='duration_slider'>
                                    <div className='row_filter'>
                                        <div className='left'>LAS - LGA</div>
                                        <div className='right'><input className='travelTime' type="text" value={valuetext} /></div>
                                    </div>
                                    <div className="travelTimeSlider">
                                        <Slider
                                            aria-label="Restricted values"
                                            defaultValue={20}
                                            valueLabelFormat={valueLabelFormat}
                                            getAriaValueText={valuetext}
                                            step={null}
                                            valueLabelDisplay="auto"
                                            marks={marks}
                                        />
                                    </div>
                                </div>
                                <div className='duration_slider'>
                                    <div className='row_filter'>
                                        <div className='left'>LGA - LAS</div>
                                        <div className='right'><input className='travelTime' type="text" value={valuetext} /></div>
                                    </div>
                                    <div className="travelTimeSlider">
                                        <Slider
                                            aria-label="Restricted values"
                                            defaultValue={20}
                                            valueLabelFormat={valueLabelFormat}
                                            getAriaValueText={valuetext}
                                            step={null}
                                            valueLabelDisplay="auto"
                                            marks={marks}
                                        />
                                    </div>
                                </div>
                            </div>
                        
                    </AccordionDetails>
                </Accordion> */}

              {/* <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className='tabheader'
                    >
                        Departure & arrival time
                    </AccordionSummary>
                    <AccordionDetails>
                        
                            <div className='filterBlock'>
                                <div className='duration_slider'>
                                    <div className='row_filter'>
                                        <div className='left'>LAS - LGA</div>
                                        <div className='right'><input className='travelTime' type="text" value={valuetext} /> <input className='travelTime' type="text" value={valuetext} /></div>
                                    </div>
                                    <div className="travelTimeSlider">
                                        <Slider
                                            aria-label="Restricted values"
                                            defaultValue={20}
                                            valueLabelFormat={valueLabelFormat}
                                            getAriaValueText={valuetext}
                                            step={null}
                                            valueLabelDisplay="auto"
                                            marks={marks}
                                        />
                                    </div>
                                </div>
                                <div className='duration_slider'>
                                    <div className='row_filter'>
                                        <div className='left'>LGA - LAS</div>
                                        <div className='right'><input className='travelTime' type="text" value={valuetext} /> <input className='travelTime' type="text" value={valuetext} /></div>
                                    </div>
                                    <div className="travelTimeSlider">
                                        <Slider
                                            aria-label="Restricted values"
                                            defaultValue={20}
                                            valueLabelFormat={valueLabelFormat}
                                            getAriaValueText={valuetext}
                                            step={null}
                                            valueLabelDisplay="auto"
                                            marks={marks}
                                        />
                                    </div>
                                </div>
                            </div>
                        
                    </AccordionDetails>
                </Accordion> */}

              <Accordion
                onChange={() => setAirlineExpand(!airlineExpand)}
                expanded={airlineExpand}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="tabheader"
                >
                  Airline
                </AccordionSummary>
                <AccordionDetails>
                  <div className="filterBlock">
                    {airline.map((fil, ind) => (
                      <div className="row_filter" key={"air" + ind}>
                        <div className="left">
                          <FormControlLabel
                            className="select_icon"
                            control={
                              <Checkbox
                                checked={fil.isSelected}
                                onChange={() => handleAirline(fil, ind)}
                              />
                            }
                            label={fil.AirlineName}
                          />
                        </div>
                        <div className="right">${fil.Price}</div>
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                onChange={() => setViaExpand(!viaExpand)}
                expanded={viaExpand}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="tabheader"
                >
                  Via Airport
                </AccordionSummary>
                <AccordionDetails>
                  <div className="filterBlock">
                    {airport.map((fil, ind) => (
                      <div className="row_filter" key={"airport" + ind}>
                        <div className="left">
                          <FormControlLabel
                            className="select_icon"
                            control={
                              <Checkbox
                                checked={fil.isSelected}
                                onChange={() => handleConnecting(fil, ind)}
                              />
                            }
                            label={fil.AirportName}
                          />
                        </div>
                        <div className="right">{fil.Price}</div>
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>

              {/* <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className='tabheader'
                    >
                        Departure
                    </AccordionSummary>
                    <AccordionDetails>
                   
                        <div className='filterBlock'>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="Las Vegas Harry Reid Intl Apt" />
                                </div>
                                <div className='right'>$ 542.96</div>
                            </div>
                        </div>
                    
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className='tabheader'
                    >
                        Destination
                    </AccordionSummary>
                    <AccordionDetails>
                    
                        <div className='filterBlock'>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="New York La Guardia Apt(123)" />
                                </div>
                                <div className='right'>$ 542.96</div>
                            </div>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="Newark Liberty International Apt(183)" />
                                </div>
                                <div className='right'>$ 542.96</div>
                            </div>
                            <div className='row_filter'>
                                <div className='left'>
                                    <FormControlLabel className='select_icon' control={<Checkbox defaultChecked />} label="New York J F Kennedy International Apt(43)" />
                                </div>
                                <div className='right'>$ 542.96</div>
                            </div>
                        </div>
                    
                    </AccordionDetails>
                </Accordion> */}
            </div>
            {/* <!-- filter Box ends --> */}

            {/* <!-- result Box start --> */}
            <div className="resultBox">
              {filterRs.map((first, index) => (
                <div className="resultblock" key={"result" + index}>
                  <Commission Comm={first?.Comm} />
                  <div className="row">
                    <div className="col-sm-12">
                      {first.Flights.map((second, indexs) => (
                        <div key={"result1" + index + indexs}>
                          <ItineraryHeading itinerary={second} Airline={searchRs.Airline}  first={first} Airport={searchRs.ObAirport}/>
                        </div>
                      ))}
                    </div>
                    <div className="col-sm-12">

                   
                      <div className="price_list">
                      
                        <div className="w_100 d-flex flex-row align-items-center">
                        
                          <div className="price_block">
                          
                            <ShowFare fares={first.TotalFare} />
                            <div className="fare__breakup">
                              <Link
                                onClick={(e) => handleVisibleDiv(e, index)}
                                className="fare__detail"
                              >
                                {visibleAllDivIndex.includes(index) === true
                                  ? "Close"
                                  : ""}{" "}
                                Flight Detail
                                <span>(incl. taxes)</span>
                              </Link>
                            </div>
                          </div>

                          <Link
                            onClick={() =>
                              setLocalStorage(
                                first,
                                first.TraceId,
                                index,
                                searchRq,
                                urlPart(1),
                                searchRs.Airline,
                                searchRs.ObAirport,
                                markup
                              )
                            }
                            className="select__btn"
                          >
                            Select{" "}
                            <i
                              className="bi bi-chevron-right ml-2"
                              aria-hidden="true"
                            ></i>
                          </Link>
                          {/* <div className="seat_left highlight mt-3">Hurry!! Only 9 Seats left</div> */}
                        </div>

                        <Resultbag bagcnt={first.BagCnt} bag={first?.Bag} />
                        

                      </div>
                    </div>
                  </div>

                  <Faredetail
                    key={index}
                    id={index}
                    visibleAllDivIndex={visibleAllDivIndex}
                    first={first}
                    markup={markup}
                    searchRq={searchRq}
                    url={urlPart(1)}
                    Airline={searchRs.Airline}
                    Airport={searchRs.ObAirport}
                    setLocalStorage={setLocalStorage}
                  />
                </div>
              ))}
              <Loader dataIsLoaded={dataIsLoaded} />
            </div>
            {/* <!-- result Box ends --> */}
          </div>
          {/* <!-- filterresult container ends --> */}
        </div>
      </div>
      {/* <!-- Result Container end --> */}

      <Footer />
      {/* <Loader dataIsLoaded={dataIsLoaded} /> */}
    </div>
  );
};

export default Result;
