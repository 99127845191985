
const Conpax= ({paxdetail}) =>{

  
  if(paxdetail===undefined || paxdetail===null || paxdetail.length<1 )
  {
    return false;
  }

  return (
<>

                  <div className="detail">
                {paxdetail.map((pax,index)=>
                   <div className="row" key={'paxd'+index}>
                   <div className="col-3 d-inline-block d-md-none">
                     <b>Name</b>
                   </div>{" "}
                   <div className="col-sm-6 col-9">{pax?.title} {pax?.firstName} {pax?.middleName} {pax?.lastName}</div>
                   <div className="col-3 d-inline-block d-md-none">
                     <b>Gender</b>
                   </div>{" "}
                   <div className="col-sm-3 col-9">{pax?.gender}</div>
                   <div className="col-3 d-inline-block d-md-none">
                     <b>DOB</b>
                   </div>{" "}
                   <div className="col-sm-3 col-9">{pax?.day+"/"+pax?.month+"/"+pax?.year}</div>
                 </div>
                 )}
                </div>
                         
                         
    </>
    );

}
export default Conpax;