import { Link } from "react-router-dom";
//import ImagesPath from "../../components/ImagesPath";
import Form from 'react-bootstrap/Form';

const Multiairline= ({showAirline,airline1,airline2,airline3,setAirline1,setAirline2,setAirline3,hideAirline }) =>{
//console.log(showAirline);
  if(showAirline===false)
  {
    return false;
  }
  
  return (
<>

<div className="travllerDropdownBox AllianceBox" style={{ display: "block", zIndex: 100 }} >
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="guestBox">
                          {/* <div className="head">Alliance</div>
                          <select className="form-select mb-4">
                            <option selected>Alliance</option>
                            <option value={1}>Star Alliance</option>
                            <option value={2}>Oneworld</option>
                            <option value={3}>Skyteam</option>
                          </select> */}
                          <ul className="airlinename">
                            <li>
                              <label htmlFor="Airline">
                                <strong> Airline Iata Code</strong>
                              </label>
                              <div className="airlineBlock d-flex justify-content-between">
                                <div>
                                  <input
                                    type="text"
                                    name="airline1" 
                                    
                                    onChange={(e)=>setAirline1(e.target.value.toUpperCase())}
                                    value={airline1 || ""}
                                    autoComplete="!autocomplete"
                                    maxLength={2}
                                  />
                                </div>
                                <div>
                                  <input
                                    type="text"
                                    value={airline2 || ""}
                                    onChange={(e)=>setAirline2(e.target.value.toUpperCase())}
                                    name="airline2"
                                    autoComplete="!autocomplete"
                                    maxLength={2}
                                  />
                                </div>
                                <div>
                                  <input
                                    type="text"
                                     value={airline3 || ""} 
                                    onChange={(e)=>setAirline3(e.target.value.toUpperCase())}
                                    name="airline3"
                                    autoComplete="!autocomplete"
                                    maxLength={2}
                                  />
                                </div>
                              </div>
                            </li>
                            {/* <li>
                              <label htmlFor="Airline">
                                <strong> Booking class</strong>
                              </label>
                              <div className="airlineBlock booking d-flex justify-content-between">
                                <div>
                                  <input
                                    type="text"
                                    autoComplete="!autocomplete"
                                    maxLength={3}
                                  />
                                </div>
                                <div>
                                  <input
                                    type="text"
                                    autoComplete="!autocomplete"
                                    maxLength={3}
                                  />
                                </div>
                              </div>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        {" "}
                        <a onClick={(e)=>hideAirline(false)} href="javascript:void(0);" className="done_Btn">
                          Done
                        </a>
                      </div>
                    </div>
                  </div>

                         
                         
    </>
    );

}
export default Multiairline;