import React, { useState,useEffect } from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer'
import ImagesPath from '../components/ImagesPath'
import { Link } from 'react-router-dom'
import {apiBaseUrl} from '../global';
const ForgotPassword = () => {
    const initialValues={email: ""};
    const [posts, setPosts] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [errors, seterrors] = useState('');
    const [msg, setMsg] = useState('');
   const [isSubmit, setIsSubmit] = useState(false);

   const validate=(posts)=>{
    const errors={};
    var filter= /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if(!posts.email)
    {
      errors.email='Email is required';
    }
    else if (!filter.test(posts.email)) {
      errors.email='Email is not valid';
    }
    return errors;

   }

   const handleChange = (event) => {
    const { name, value } = event.target;
    setPosts({...posts,[name]: value});
    setFormErrors(validate(posts));        
  }

    const handleSubmit = (event) => {
        event.preventDefault();
       setFormErrors(validate(posts));
       setIsSubmit(true);
       
      
       }
       
       useEffect(()=>{
        if(Object.keys(formErrors).length===0 && isSubmit) {
            var myHeaders = new Headers();
                 myHeaders.append("Content-Type", "application/json");
                 myHeaders.append("Accept", "application/json");
                 const url = apiBaseUrl + '/api/forgotPass';
                 var requestOptions = {
                   method: 'POST',
                   headers: myHeaders,
                   body: JSON.stringify({ ...posts }),
                   redirect: 'follow'
                 };
                 fetch(url, requestOptions)
                 .then((response)=>response.json())
                 .then((result)=>{
                   if(result.status==="OK"){
                    setMsg(result.msg);
                    
                   }else{
                    seterrors(result.error);
                   }
                   console.log(result);
                 }
                 ); 
             }

       },[formErrors,posts,isSubmit]);

     

      

  return (
    <>
      <Header/>

      {/* <!-- form container start --> */}
      <div id="formCntr">
     
          <div class="container">
              <div class="formwrapper">
             
                  <div class="row d-flex justify-content-around align-items-center">
                      <div class="col-md-5">
                          <div class="formleft">
                              <figure class="img1"><img src={ImagesPath.login_img} alt=""/></figure>
                          </div>
                      </div>
                      <div class="col-md-5">
                          <div class="signin">
                              <div class="formholder">
                              
                                  {/* <!-- form box start --> */}
                                  <div class="formBox">
                                  
                              
                                      <h4>Forgot your Password?</h4>
                                      <p> You log in with your e-mail address? Please enter your e-mail address. You will then receive a link to change your password. This link is valid for a maximum of 24 hours.</p>
                                      {msg && <span className="error">{msg}</span>}
                                      {errors && <span className="error">{errors}</span>}
                                      <form action="#" onSubmit={handleSubmit}>
                                          <fieldset>
                                              <div class="row">
                                                  <div class="col-sm-12 form_col">
                                                      <div class="form-floating">
                                                          <input class="form-control input" onChange={(event)=>handleChange(event)} id="floatingInputValue" name="email" value={posts.email || ""} type="text" placeholder="Customer number or email"/>
                                                          <label for="floatingInputValue">Customer number or email</label>
                                                          {formErrors.email && <span className="error">{formErrors.email}</span>}
                                                      </div>
                                                  </div>
                                              </div>
                                              
                                              <div class="row">
                                                  <div class="col-sm-12 form_col d-flex justify-content-center">
                                                      <button class="submitBtn" type='submit' name='submit'>Request email</button>
                                                  </div>
                                              </div>
                                              <div class="row">
                                                  <div class="col-sm-12 form_col">
                                                      <p><Link class="forgotlink" to="/login">Back to login page</Link></p>
                                                  </div>
                                              </div>

                                          </fieldset>
                                      </form>
                                  </div>
                                  {/* <!-- form box end --> */}
                                  
                              </div>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
          
          
      </div>
      {/* <!-- form container end --> */}

      <Footer/>
    </>
  )
}

export default ForgotPassword


