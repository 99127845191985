
import {getClassShort,formatEft} from '../function.js';

import moment from"moment";
import{ReactComponent as Plane} from "../../../resources/images/icons/plane.svg";
import { Link, json } from "react-router-dom";
const Layoverdata=({LayOver,inde})=>{
 
  if(LayOver===undefined || LayOver===null || inde===0)
  {
    return false;
  }

  return (
    <>
                                <div className="row">
                              <div className="col-sm-8 offset-sm-4">
                                <div className="layover">
                                <i className="bi bi-stopwatch"></i> Layover : {LayOver}
                                </div>
                              </div>
                            </div>
        </>
        );
}
const PaymentTab1= ({validateData}) =>{
  if(validateData===null)
  {
    return false;
  }
  
  return (
<>
                         {validateData.itin.Flights.map((allsegm,index)=>
                          <div key={'inten'+index}>
                            <ul className="flighttab">
                        <li>
                          <Link
                            id="departTab"
                            className="tab-button active tabclick"
                          >
                           {/* {JSON.stringify(allsegm)}    */}
                           {validateData.Airport[allsegm.Segments[0].From]} To {validateData.Airport[allsegm.Segments[allsegm.Stop].To]} {moment(allsegm.Segments[0].DepDt).format('ll')} with {allsegm.Stop} Stops 
                          </Link>
                        </li>
                      </ul>
                      <div className="clearfix"></div>
                         
                            {allsegm.Segments.map((segm,inde)=>
                            <div key={'intens'+inde}>

 
                          <Layoverdata LayOver={segm.LayOver} inde={inde} />

                           
                             <div className="row" >
                              <div className="col-9 col-sm-4">
                                <div className="airlines__details">
                                  <img src={require('../../../resources/airlines/'+segm.MktAir+'.jpg')}  alt={segm.MktAir} />
                                  <div className="detail">
                                    <div className="air-name">{validateData.Airline[segm.MktAir]}</div>
                                    <div className="flight__info">
                                      Flight No: {segm.FltNum} | EQP-{segm.EqptType}
                                    </div>
                                    <div className="oprated_by">
                                      Operated by: {validateData.Airline[segm.OptAir]}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-3 col-sm-1 order-md-3 pl-0">
                                <div className="className__type">
                                  className: <br /> {getClassShort(segm.Cabin)}
                                </div>
                              </div>

                              <div className="col-sm-7">
                                {/* <!--  route--> */}
                                <div className="route__info">
                                  <div className="connecting__bar d-none d-md-block">
                                    <div className="duretion_time">
                                      <span>{formatEft(segm.Eft)}</span>
                                      <span className="plane">
                                        <Plane/>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="row">

                                    <div className="col-sm-6">
                                      <div className="destinationName">
                                        <div className="date">
                                          <div className="highlight">
                                         {moment(segm.DepDt).format('ll  [,] ddd ')}
                                          </div>{" "}
                                          {/* <small className="message_display ">
                                            Alternate Date
                                          </small> */}
                                        </div>
                                        <div className="time">{moment(segm.DepDt).format('hh:mm a')}</div>
                                        <div className="destination_fullname">
                                          <div
                                            className="text-overflow"
                                            title={validateData.Airport[segm.From]}
                                          >
                                            {validateData.Airport[segm.From]+" ("+segm.From+"}"}
                                           
                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                    <div className="TripTime  d-md-none d-block">
                                      Trip Time : {formatEft(segm.Eft)}
                                    </div>

                                    <div className="col-sm-6">
                                      <div className="destinationName">
                                        <div className="date">
                                          <span>{moment(segm.ArrDt).format('ll  [,] ddd ')}</span>{" "}
                                        </div>
                                        <div className="time">{moment(segm.ArrDt).format('hh:mm a')}</div>
                                        <div className="destination_fullname">
                                          <div className="highlight">
                                         
                                      <div className="text-overflow" title={validateData.Airport[segm.To]+" ("+segm.To+"}"}>
                                             {validateData.Airport[segm.To]+" ("+segm.To+"}"}
                                            </div>
                                          </div>
                                          {/* <small className="message_display ">
                                            Nearby Airport
                                          </small> */}
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>

                                {/* <!--  route-->    */}
                              </div>
                            </div>

                              </div>
                            )}
                            
                              <div className="row">
                              <div className="col-sm-8 offset-sm-4">
                                <div className="layover">
                                  {" "}
                                  <i className="bi bi-stopwatch"></i> Total Trip Time
                                  : {allsegm.TotalTime}
                                </div>
                              </div>
                            </div>
                             

                            </div>

                           )}
                            

                            {/* <!-- Layover Start here--> */}
                          

                           


                            {/* <!-- Layover End here--> */}


                         
                         
    </>
    );

}
export default PaymentTab1;