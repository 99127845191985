import {memo} from "react"
import ImagesPath from "../../../components/ImagesPath";
import { useSearchParams } from "react-router-dom";
import {
    Flight
} 
from '@mui/icons-material';

import {
    CircularProgress,
  
} 
from '@mui/joy';
const Loader= ({dataIsLoaded, searchRq}) =>{
    const [searchParams] = useSearchParams();

    let tripType = searchParams.get("tripType");
    //console.log(tripType)
    let adults = searchParams.get("adt");
    let org1 = searchParams.get("org1");
    let org1aray = org1.split("-");
    let des1 = searchParams.get("des1");
    let des1aray = des1.split("-");
    let chd = searchParams.get("chd");
    let returnDate='';
    if(tripType==='2'){
     returnDate = searchParams.get("returnDate");
    }
   // let inf = searchParams.get("inf");
    let departureDate = searchParams.get("dt1");
    
   // org1=JFK-John%20F%20Kennedy-New%20York-United%20States-US&des1=MIA-Miami-Miami-United%20States-US
    //console.log(adults)
   // console.log(dataIsLoaded)
	    if(!dataIsLoaded)
    return null;
	
  return (
<>
{/* <div className='loadingPopup'>
    <CircularProgress className="loader" sx={{ '--CircularProgress-size': '80px' }}>
        <Flight style={{width: '40px', height: '40px'}}/>
    </CircularProgress>
    <h4>Loading...</h4>
</div>  */}
        <div className='ResultMidPage'>
            <div className='poupHolder'>
              <div className='topblock'>
                <img className='planeIcon' src={ImagesPath.loadingairPlane} alt="loadingairPlane" />
                <div className='title'>Loading... </div>
              </div>
              <div className='loadingbar'><span></span></div>
              <p>Now searching, just a few seconds while we fetch results</p>
              <h4>{org1aray[2]}, {org1aray[4]} {departureDate} - {des1aray[2]} {returnDate} {adults|| 0 } Adults {chd || 0 }Children</h4>
            </div>
        </div>
    </>
    );

}
export default memo(Loader);