import * as React from 'react';
 //import moment from "moment";
 import moment from 'moment-timezone';
moment().tz("America/Los_Angeles");



const BookingList = ({bookingdata}) => {


  //let MktAirUrl = MktAir + ".jpg";
  //let airUrl = require("../../../resources/airlines/" + MktAirUrl);
if(bookingdata===undefined)
{
  return (
    <>
      <div className='bookinglisttable'>
        <table className='bookingList'>
          <tr><td>No results found</td></tr>
          </table>
          </div>
          </>
  );
}




   
    return (
      <>
        <div className='bookinglisttable'>
          <table className='bookingList'>
          {bookingdata?.map((data) =>
            <tr>
           
              <td>
              
              <>
                <div className='pnr'> {data.booking.bookingId}</div>
                <div className='supnr'>{data.booking.PNR}</div>
                </>
                
              </td>
              



              <td>
                <div className='passangerName'>{data.booking.PaxTitle}  {data.booking.PaxFirstName}  {data.booking.PaxMiddleName}  {data.booking.PaxLastName} </div>
              </td>
              <td>
              

                <div className='bookingway'>
                  <div className='departure'>
                    <span className='airportName'>{data.booking.origin}</span>
                    <span className='bookingdate'>{moment(data.sector[0].DepDt).format("DDMMMYY").toUpperCase()} </span>
                   
                  </div>
                  <div className='airlineName'><img src={require("../../../resources/airlines/" + data.sector[0].airline+'.jpg')} alt="9U" /></div>
                  <div className='return'>
                  {data.booking.destination ? <span className='airportName'>{data.booking.destination}</span>:<span className='airportName'>{data.booking.destination}</span>}

                  {data.sector[1]?.DepDt ? <span className='bookingdate'>{moment(data.sector[1]?.DepDt).format("DDMMMYY").toUpperCase()}</span>:''}
                 
                 
                    
                  </div>
                </div>
              </td>
              <td>
              
                <div className='BookingStatus red'>{data.booking.status_name}</div>
              </td>
              <td>
                <div className='bookinDate'>Booking date <br/>{moment(data.booking.created_at.slice(0,10)).format("ll  [,] ddd ")}</div>
                {/* <span className='bookingdate'>{data.booking.created_at} </span> */}
              </td>
              <td>
                <div className='fareType'>PUB</div>
              </td>
              <td>
                <div className='Totalfare'>${data.booking.TotalFare}</div>
              </td>
              <td>
                <div className='agentName'>
                  <span> {data.booking.adminName}</span>
                  <span>Click to enter note</span>
                </div>
              </td>
            </tr>
             )}
            {/* <tr>
              <td>
                <div className='pnr'> 4UBGCF</div>
                <div className='supnr'>S032YPW</div>
              </td>
              <td>
                <div className='passangerName'> SIARI/MOHAMED SALAH  MR </div>
              </td>
              <td>
                <div className='bookingway'>
                  <div className='departure'>
                    <span className='airportName'>IAD</span>
                    <span className='bookingdate'>06JUL23</span>
                  </div>
                  <div className='airlineName'><img src="./images/airline/9S.jpg" alt="9S" /></div>
                  <div className='return'>
                    <span className='airportName'>CDG</span>
                    <span className='bookingdate'>12SEP23</span>
                  </div>
                </div>
              </td>
              <td>
                <div className='BookingStatus green'>departed</div>
              </td>
              <td>
                <div className='bookinDate'>Booking date <br/> 02JUN23</div>
              </td>
              <td>
                <div className='fareType'>PUB</div>
              </td>
              <td>
                <div className='Totalfare'>$ 887.20</div>
              </td>
              <td>
                <div className='agentName'>
                  <span>Rajdeep Singh</span>
                  <span>Click to enter note</span>
                </div>
              </td>
            </tr> */}
           
          </table>

          <div className='bookingPagination'>
            <div className='numberOfResult'><span className='title'>Result :</span> 3</div>
            <div className='pageCount'>
              <p>No of results per page :</p>
              <select className="counter">
                <option>20</option>
                <option>50</option>
                <option>100</option>
              </select>
            </div>
            <div className='pagination'>
              <button>Previous</button>
              <button>Next</button>
            </div>
        </div>
        </div>
        
      </>
    );
}

export default BookingList
