import React from "react";
import moment from "moment";
import Tippy from "@tippyjs/react";
const GroupIng = (group) => {
  if (group.group === "0") return null;
  return (
    <>
      <hr />
    </>
  );
};
const LayoverTippy=({segmData,inde,Airport})=>{
  
  if(inde===0)
  {
    return false;
  }
  return (
    <>
                                <li key={"tippy"+inde} ><strong>Stop 1 :</strong> {segmData.LayOver} layover, {segmData.From} - {Airport[segmData.From]}</li>
        </>
        );
}
const ItineraryHeading = (itinerary) => {
  if (!itinerary.itinerary) return null;

  let MktAir = itinerary.itinerary.Segments[0].MktAir;
  let AirlineName = itinerary.Airline[MktAir];
  let MktAirUrl = MktAir + ".jpg";
  let airUrl = require("../../../resources/airlines/" + MktAirUrl);
  let group = itinerary.itinerary.Group;
  let depAirport = itinerary.itinerary.Segments[0].From;
  let DepDt = itinerary.itinerary.Segments[0].DepDt;
  let DepDtTime = moment(DepDt).format("hh:mm a");
  let ArrDt = itinerary.itinerary.Segments[itinerary.itinerary.Stop].ArrDt;
  let DEqptType = itinerary.itinerary.Segments[0].EqptType;
  let DFltNum = itinerary.itinerary.Segments[0].FltNum;
  //moment.locale();
  DepDt = moment(DepDt).format("ll  [,] ddd ");
  let ArrDtTime = moment(ArrDt).format("hh:mm a");
  let arrAirport = itinerary.itinerary.Segments[itinerary.itinerary.Stop].To;
  let TotalTime = itinerary.itinerary.TotalTime;
  let hour = TotalTime.slice(0, 2);
  let minute = TotalTime.slice(3, 5);
  var arr = [];
  var countSegment = itinerary.itinerary.Segments.length;
  if (countSegment > 1) {
    var segments = itinerary.itinerary.Segments;
    segments.forEach((element, index) => {
      if (index < countSegment - 1) {
        arr.push(element.To);
      }
    });
    //debugger;
  }
  //console.log(arr)
  return (
    <>
      <GroupIng group={group} />
      <div className="head">
        {depAirport} To {arrAirport} <span className="date"> | {DepDt}</span>
      </div>
      <div className="row">
        <div className="col-md-5 d-flex">
          <div className="airlinelogo">
            <img src={airUrl} alt="{MktAir}" />
          </div>
          <div className="airlineInfo">
            <div className="airlineName">{AirlineName} </div>
            <div className="airlineNumber">
              Flight No: {DFltNum} |EQP-{DEqptType}{" "}
              <i className="bi bi-exclamation-square-fill"></i>
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="leg_detail">
            <div className="city">
              <div className="time">
                <strong>{DepDtTime}</strong>{" "}
              </div>
              <div className="code">{depAirport}</div>
            </div>

            <div className="connecting_block">
              <div className="leg_points">
                <Tippy
                  interactive={true}
                  animation="perspective"
                  theme={"light"}
                  content={
                    <div className="TotalDuration">
                        <div className="title">Total Duration: <span>{hour}H{minute}M</span></div>
                        <ul>
                         
                          {itinerary.itinerary.Segments.map((segm, index) => (
                            <LayoverTippy segmData={segm} inde={index} Airport={itinerary.Airport}/>
                      // <li key={"tippy"+index} ><strong>Stop 1 :</strong> 5h 55m layover, PEK - Beijing Capital</li>
                    ))}
                        </ul>
                    </div>
                  }
                >
                  <div className="stopage">
                    <div className="layovertime">
                      {hour}
                      <span>h</span>
                      {minute}
                      <span>m</span>{" "}
                    </div>
                    {arr.map((segm, index) => (
                      <div className="stops" key={"segment" + index}>
                        <i></i>
                        <br />
                        <b>{segm}</b>
                      </div>
                    ))}
                  </div>
                </Tippy>
              </div>
            </div>

            <div className="city">
              <div className="time">
                <strong>{ArrDtTime}</strong>{" "}
              </div>
              <div className="code">{arrAirport}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ItineraryHeading;
