const ImagesPath = {
    //header images path
    logo: require('../resources/images/logo.png'),


    //banner images path
    banner1: require('../resources/images/banner1.jpg'),
    banner2: require('../resources/images/banner2.jpg'),
    banner3: require('../resources/images/banner3.jpg'),
    banner4: require('../resources/images/banner4.jpg'),
    banner5: require('../resources/images/banner5.jpg'),
    mob_banner1: require('../resources/images/mob_banner1.jpg'),
    mob_banner2: require('../resources/images/mob_banner2.jpg'),
    mob_banner3: require('../resources/images/mob_banner3.jpg'),
    mob_banner4: require('../resources/images/mob_banner4.jpg'),
    mob_banner5: require('../resources/images/mob_banner5.jpg'),
    about_banner: require('../resources/images/banner/about_banner.jpg'),
    ourCompanyBanner: require('../resources/images/banner/our_company_banner.jpg'),
    press_banner: require('../resources/images/banner/press_banner.jpg'),
    ToolNServices_banner: require('../resources/images/banner/ToolNServices_banner.jpg'),
    BecomingCustomer_banner: require('../resources/images/banner/BecomingCustomer_banner.jpg'),

    //airlinelogo images path
    delta:require('../resources/images/airline_logo/DL.jpg'),

    //airline images path
    canada:require('../resources/images/airline/Canada.png'),

    //servicesBox images path
    icon1: require('../resources/images/icon1.png'),
    icon2: require('../resources/images/icon2.png'),
    icon3: require('../resources/images/icon3.png'),
    mission: require('../resources/images/icons/mission.png'),
    team: require('../resources/images/icons/team.png'),
    whyChoose: require('../resources/images/icons/whyChoose.png'),
    

    //footer images path
    footer_img: require('../resources/images/footer_img.jpg'),


    //generic images path
    login_img: require('../resources/images/login_img.png'),
    PlaneIcon1: require('../resources/images/icons/plane.svg'),
    confirm: require('../resources/images/payment/confirmation.jpg'),
    ua: require('../resources/images/airline_logo/ua.png'),
    dl: require('../resources/images/airline_logo/DL.jpg'),

    baggage_icon1: require('../resources/images/icons/bag1.png'),
    baggage_icon2: require('../resources/images/icons/bag2.png'),
    baggage_icon3: require('../resources/images/icons/bag3.png'),
    bookingConfirm: require('../resources/images/icons/booking-confirm.png'),
    notfound: require('../resources/images/icons/not_found.png'),

    card: require('../resources/images/payment/card.png'),
    //reg_succes_bg: require('../resources/images/reg_succes_bg1.png'),
    reg_succes_bg: require('../resources/images/reg_succes_bg2.jpg'),
    reg_succes_check: require('../resources/images/icons/check.png'),
    loadingairPlane: require('../resources/images/icons/loadingairPlane.png'),
    NotFound: require('../resources/images/Not_found.png'),

    about_img1: require('../resources/images/banner/about-img1.jpg'),
}
export default ImagesPath;
