import React, { useState,useEffect } from "react";

import Footer from "../../../components/Footer";
//import { Link } from "react-router-dom";
//import { apiBaseUrl} from '../../../global';
import { Breadcrumbs, Typography } from "@mui/material";
import LeftCntr from "../../../components/Profile/LeftCntr";
import HeaderUser from "../../../components/HeaderUser";
import {getStorageData,apiBaseUrl} from '../../../global';
const ProfileSetting = () => {
 
  var initial={currentPassword:"",newpassWord:"",confirmPassword:"" };

  const [inputs, setInputs] = useState(initial);
  const [formErrors, setFormErrors] = useState({});
  const [msg, setmsg] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    //setFormErrors(validate(inputs));
    setIsSubmit(false);
    setInputs(values => ({...values, [name]: value}))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(inputs));
    setIsSubmit(true);
     
    
   //console.log(inputs);
  }

  useEffect(()=>{

    if(Object.keys(formErrors).length===0 && isSubmit  ) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
       inputs.userId=getStorageData('userSession')?.id;
      const url = apiBaseUrl + '/api/changepassword';
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({...inputs}),
        redirect: 'follow'
      };
      fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setIsSubmit(false);
        if(result.status==='OK'){
        setmsg(result.messege);
        
        }else{
          setmsg('Oops something went wrong please try again!');
        }
      }
      ); 

    } 

  },[formErrors,inputs,isSubmit]);

  const validate=(inputs)=>{
    const errors={};
    if(!inputs.newpassWord)
    {
      errors.newpassWord='New passWord Password is required';
    }
    else if(inputs.newpassWord.length<=5)
    {
      errors.newpassWord='Password must be greater than 5 character';
    }
    if(!inputs.confirmPassword)
    {
      errors.confirmPassword='Confirm Password is required';
    }
    if( inputs.confirmPassword!= inputs.newpassWord)
    {
      errors.confirmPassword="Password does not match";
    }
    
   
    if(!inputs.currentPassword)
    {
      errors.currentPassword='Current Password is required';
    }
    
    return errors;
  }

  return (
    <>
      <HeaderUser />

      <div id="profileCntr">
        <div class="container"> 
        {/* <!--  / Profile  container \ --> */}
        
        <div class="row">
            <div class="col-sm-12 row-flex"> 

            {/* <!--  / Left container Start here \ --> */}
            <LeftCntr/>
            {/* <!--  / Left container ends here \ --> */}
            
            
            {/* <!--  / Right container Start here \ --> */}
            <div class="rightCntr prof-section" id="writeus">
            <h2 class="main_title">Settings</h2>

            <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
              <a underline="hover" color="inherit" href="/profile">
                Profile
              </a>
              <Typography color="text.primary">Profile Setting</Typography>
            </Breadcrumbs>
            
            {/* <!--  / Feedback Start here \ --> */}

            <form onSubmit={handleSubmit} >
            <div class="formBox">
            
              <div class="row">
                <div class="col-sm-12 title">
                  <h2>Change Password</h2>
                </div>
              </div>
              <div id="personal_infoform">
                  <div class="reset-screen1" id="firstScreen">
                    <div class="row form-group">
                      <div class="col-sm-6">
					  <label>Current password</label>
                        <input class="textbox" name="currentPassword" placeholder="Current password" type="text" required value={inputs.currentPassword || ""}  onChange={handleChange} />
                        {formErrors.currentPassword && <span className="error">{formErrors.currentPassword}</span>}
                         </div>
						 
						  <div class="col-sm-6">
							<label>New password</label>
							<input class="textbox" name="newpassWord" placeholder="New password" type="text" value={inputs.newpassWord || ""} required onChange={handleChange} />
              {formErrors.newpassWord && <span className="error">{formErrors.newpassWord}</span>}
                         </div>
                    </div>
					
					 <div class="row form-group">
                      <div class="col-sm-6">
					  <label>Confirm new password</label>
                        <input class="textbox" name="confirmPassword" placeholder="Confirm new password" value={inputs.confirmPassword || ""} required  type="text" onChange={handleChange} />
                        {formErrors.confirmPassword && <span className="error">{formErrors.confirmPassword}</span>}
                         </div>
						 
                    </div>
					
                    {/* //<button type="button" class="button float-end mt_mob">Update password</button> */}
                    <button type="submit" name='submit'  class="button float-end mt_mob">Update password</button>
                  </div>
             
              </div>
            </div>
            </form>
            {/* <!--  \ Feedback End here / -->  */}
            
          </div>
            {/* <!-- End My Information--> */}
            
            </div>
        </div>
        </div>
    
    
    </div>
    {/* <!--  / Profile  container \ -->  */}

    <Footer />
    </>
  );
};

export default ProfileSetting;
