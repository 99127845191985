import React, { useState, useEffect,useCallback } from "react";
import airportData from "./airport.json";
//import index from "../Home";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  FlightTakeoff,
  FlightLand,
  SignalCellularNoSimRounded,
  ConnectedTvOutlined,
  CalendarMonth,
  Timeline,
  AccessTimeFilled,
  SearchOutlined,
} from "@mui/icons-material";
import moment from "moment";
//import { apiBaseUrl,engineBaseUrl } from '../../global';
//import { useAuth } from "../../provider/authProvider";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
//import $ from 'jquery';
import Multiairline from './leftfilter/Multiairline';
import { includeAirline,stringToBoolean } from "./function";
//import "jquery-ui-dist/jquery-ui";
const GenericForm = ({ searchHide, isSearchVisible, closeButtonShow }) => {
  //const { token } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showPass, _setShowPass] = useState(false);
  const [adults, setAdults] = useState(1);
  const [childs, setChilds] = useState(0);
  const [infants, setInfants] = useState(0);
  const [infantSeat, setInfantSeat] = useState(0);
  const [passengerSelection, setPassengerSelection] = useState("Passanger");
  const [cabin, setCabin] = useState("Y");
  const [activeTab, setActiveTab] = useState(1);

  const [baggageFaresOnly, setBaggageFaresOnly] = useState(false);
  const [nonStop, setNonStop] = useState(false);
  const [maxTransfer, setMaxTransfer] = useState("");
  const [airline1, setAirline1] = useState("");
  const [airline2, setAirline2] = useState("");
  const [airline3, setAirline3] = useState("");
  const [allAirline, setAllAirline] = useState("");
  
  const [cabinArray] = useState([
    { cabin: "Y", name: "Economy", isSelected: true },
    { cabin: "S", name: "Premium Economy", isSelected: false },
    { cabin: "F", name: "First", isSelected: false },
    { cabin: "C", name: "Business", isSelected: false },
  ]);

  const [cabinTextSelection, setCabinTextSelection] = useState("Economy");
  const [cabinShow, _setCabinShow] = useState(false);
  const [showAirline,setShowAirline]=useState(false);
  const [segments, setSegments] = useState([
    {
      org: "",
      des: "",
      dt: new Date(),
      minDt: new Date(),
      time: "",
      returnDate: "",
      returnMinDt: new Date(),
    },
  ]);
  const [suggestions, setSuggestions] = useState([{ org: [], des: [] }]);

  const setCabinShow = () => {
    _setCabinShow(!cabinShow);
  };
  const setShowPass = () => {
    _setShowPass(!showPass);
  };

  const passDone = (e) => {
    e.preventDefault();
    let total = allPass();
    var content = "";
    if (total > 1) {
      content = "Passengers " + total;
    } else {
      content = "Passenger " + total;
    }
    setPassengerSelection(content);
    _setShowPass(false);
  };
  const allPass = () => {
    let totalpass =
      parseInt(adults) +
      parseInt(childs) +
      parseInt(infants) +
      parseInt(infantSeat);
    return parseInt(totalpass);
  };
  const increaseAdult = (e) => {
    e.preventDefault();
    let total = allPass();
    if (total < 9) {
      setAdults(parseInt(adults) + 1);
    }
  };
  const decreaseAdult = (e) => {
    e.preventDefault();
    if (parseInt(adults) !== 1) {
      setAdults(adults - 1);
      if (infants > adults) {
        setInfants(infants - 1);
      }
    }
  };
  const increaseChild = (e) => {
    e.preventDefault();
    let total = allPass();
    if (total < 9) {
      setChilds(parseInt(childs) + 1);
    }
  };
  const decreaseChild = (e) => {
    e.preventDefault();
    if (childs !== 0) {
      setChilds(childs - 1);
    }
  };

  const increaseInfant = (e) => {
    e.preventDefault();
    let total = allPass();
    if (total < 9 && infants < adults) {
      setInfants(parseInt(infants) + 1);
    }
  };
  const decreaseInfant = (e) => {
    e.preventDefault();
    if (infants !== 0) {
      setInfants(infants - 1);
    }
  };
  const increaseInfantSeat = (e) => {
    e.preventDefault();
    let total = allPass();
    if (total < 9 && infantSeat < adults) {
      setInfantSeat(parseInt(infantSeat) + 1);
    }
  };
  const decreaseInfantSeat = (e) => {
    e.preventDefault();
    if (infantSeat !== 0) {
      setInfantSeat(infantSeat - 1);
    }
  };

  const Passanger = ({ show }) => {
    if (show === false) return null;
    //debugger;
    return (
      <>
        <div className="travllerDropdownBox travllerBox">
          <div className="row">
            <div className="col-sm-12">
              <div className="guestBox">
                <div className="head">
                  Passengers
                  {/* <span class="tooltipBlock">
                                          
                                          <button class="btn btn-secondary"  data-toggle="Passengers" data-bs-placement="bottom" title="Offers do not include bags booked as ancillary service." ><i class="bi bi-exclamation-square-fill"></i></button>
                                        </span> */}
                  {/* Tooltip Block start*/}
                  {/* <div className="tooltip_block">
                                      <i className="tooltip-icon bi bi-exclamation-square-fill" />
                                      <div className="tooltip-new  tooltip-top">
                                        <div className="inner_block">
                                          <ul className="list">
                                            <li>Children must be accompanied by an adult (&gt; 18).</li>
                                            <li>Infants under 2 years of age must travel on the lap of an accompanying adult. Max. one infant per adult.</li>
                                            <li>Some airlines offer youth fares for travelers from 12 years of age. Please find information on age restrictions etc. in the fare notes.</li>
                                            <li>Some airlines offer student fares which require a student identity card. Please check the fare notes for conditions.</li>
                                          </ul>
                                          <p>Please contact your service team for:</p>
                                          <ul className="list">
                                            <li>Group bookings (&gt; 9 seats)</li>
                                            <li>Children turning 12 or infants turning 2 years during the journey</li>
                                            <li>Single travellers under 18 years</li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div> */}
                  {/* Tooltip Block End*/}
                </div>
                {/*Adult*/}
                <div className="add-traveler">
                  <span className="travelers__options">
                    <button onClick={decreaseAdult} className="remove">
                      <i className="fa fa-minus" />
                    </button>{" "}
                    <strong className="number">{adults}</strong>
                    <button href="#" onClick={increaseAdult} className="add">
                      <i className="fa fa-plus" />
                    </button>
                  </span>
                  Adult
                </div>
                {/*Children*/}
                <div className="add-traveler">
                  <span className="travelers__options">
                    <button onClick={decreaseChild} className="remove">
                      <i className="fa fa-minus" />
                    </button>
                    <strong className="number">{childs}</strong>
                    <button className="add" onClick={increaseChild}>
                      <i className="fa fa-plus" />
                    </button>
                  </span>
                  Children <small>(2-11)</small>
                </div>
                {/*Infants*/}
                <div className="add-traveler">
                  <span className="travelers__options">
                    <button onClick={decreaseInfant} className="remove disable">
                      <i className="fa fa-minus" />
                    </button>
                    <strong className="number">{infants}</strong>
                    <button onClick={increaseInfant} className="add">
                      <i className="fa fa-plus" />
                    </button>
                  </span>
                  Infants <small>(&lt;2)</small>
                </div>
                {/*Youth*/}
                <div className="add-traveler">
                  <span className="travelers__options">
                    <button
                      onClick={decreaseInfantSeat}
                      className="remove disable"
                    >
                      <i className="fa fa-minus" />
                    </button>
                    <strong className="number">{infantSeat}</strong>
                    <button onClick={increaseInfantSeat} className="add">
                      <i className="fa fa-plus" />
                    </button>
                  </span>
                  Infant Seat
                </div>
                {/*Students */}
              </div>
            </div>
            <div className="col-sm-12">
              {" "}
              <a
                href="javascript:void(0);"
                onClick={passDone}
                className="done_Btn"
              >
                Done
              </a>
            </div>
          </div>
        </div>
      </>
    );
  };

  let addSegment = () => {
    let newsegments = [...segments];
    let lastIndex = newsegments.length - 1;
    let mind = newsegments[lastIndex].dt;
    let setOrigin = newsegments[lastIndex].des;
    setSegments([
      ...segments,
      { org: setOrigin, des: "", dt: mind, minDt: mind, time: "" },
    ]);
    setSuggestions([...suggestions, { org: [], des: [] }]);
  };

  let removeSegment = (i) => {
    //alert(i);
    let newsegments = [...segments];
    newsegments.splice(i, 1);
    let newSuggestions = [...suggestions];
    newSuggestions.splice(i, 1);
    setSuggestions(newSuggestions);
    setSegments(newsegments);
  };

  let handleDate = (i, date) => {
    let newsegments = [...segments];
    newsegments[i].dt = date;

    let lenth = newsegments.length;
    if (lenth > 1 && i + 1 !== lenth) {
      if (newsegments[i + 1].minDt !== undefined) {
        newsegments[i + 1].minDt = date;
        newsegments[i + 1].dt = date;
      }
    } else if (lenth === 1) {
      newsegments[i].minDt = new Date();
      newsegments[i].returnMinDt = newsegments[0].dt;
      // if (activeTab === 2) {
      //   newsegments[i].returnMinDt = newsegments[0].dt;
      // }else {
        
      // }
      // newsegments[i].returnDate = date;
    }
    setSegments(newsegments);
  };
  let handleReturnDate = (i, date) => {
    let newsegments = [...segments];
    newsegments[i].returnDate = date;
    if (activeTab === 2 && i === 0) {
      newsegments[i].returnMinDt = newsegments[0].dt;
    }
    setSegments(newsegments);
  };

  let handleTime = (i, e) => {
    let newsegments = [...segments];
    newsegments[i][e.target.name] = e.target.value;
    setSegments(newsegments);
  };

  let handleSegment = (i, e) => {
    let newsegments = [...segments];
    newsegments[i][e.target.name] = e.target.value;
    let matches = [];
    let newSuggestions = [...suggestions];
    var text = e.target.value;
    if (text.length === 3) {
      matches = airportData.filter((item) =>
        item.substring(0, 3).toLowerCase().includes(text.toLowerCase())
      );
    } else if (text.length > 0) {
      matches = airportData.filter((item) =>
        item?.toLowerCase().includes(text.toLowerCase())
      );
    }
    if (e.target.name === "org") {
      newSuggestions[i].org = matches;
      setSuggestions(newSuggestions);
    } else if (e.target.name === "des") {
      newSuggestions[i].des = matches;
      setSuggestions(newSuggestions);
    }
  };

  const onClickSegment = (i, text, type) => {
    let newsegments = [...segments];
    let newSuggestions = [...suggestions];
    if (type === "org") {
      newsegments[i].org = text;
      newSuggestions[i].org = [];
    } else if (type === "des") {
      newsegments[i].des = text;
      newSuggestions[i].des = [];
    }
    setSegments(newsegments);
    setSuggestions(newSuggestions);
  };

  let removeSegment_last = (activeTab) => {
  let newsegments = [...segments];
  let newSuggestions = [...suggestions];
  let segmentLen=newsegments.length;
  let lastIndex=segmentLen-1;
    if(activeTab===2)
  {
    
      if(segmentLen>1)
    {
      setSegments(
        newsegments.filter((a,index) => index < 1)
      );
      setSuggestions( newSuggestions.filter((a,index) =>index < 1));
    }
    
  }
  else if(activeTab===1)
  {
    if(segmentLen>1)
    {
      setSegments(
      newsegments.filter((a,index) => index === 0)
      );
      setSuggestions( newSuggestions.filter((a,index) => index === 0));
    }
    
  }
  else if (activeTab===3)
  {
    if(segmentLen<2)
    {
     let arra=[];
     let sugg=[];
     let mind = newsegments[segmentLen-1].dt;
      for(let i=segmentLen;i<2;i++)
      {
       arra.push({ org: "", des: "",dt: mind, minDt: mind,time:""});
       sugg.push({ org: [], des: [] });
      }
      setSegments([...segments,...arra]);
      setSuggestions([...suggestions,...sugg ]);
    }
  }
  //console.log(newsegments);
  
};

  // search tab start

  const handleTabClick = (activeTab) => {
    setActiveTab(activeTab);
    removeSegment_last(activeTab);
  };
  
  // search tab ends

  const GetIcon = ({ type }) => {
    if (type === "org") {
      return <FlightTakeoff className="flighticon" />;
    } else if (type === "des") {
      return <FlightLand className="flighticon" />;
    }
  };

  const RemoveFirstMinusSegment = ({ activeTab, index, segmentCount }) => {
    //console.log(indexData);
    if (activeTab < 2 || index === 0 ) {
      return false;
    }
    let plus = false;
    if (segmentCount - 1 === index && segmentCount < 5) {
      plus = true;
    }
    let minus = true;
    if (segmentCount === 2) {
      minus = false;
    }

    return (
      <>
        <div className="addfieldBtn">
          {plus === true && (
            <div className="add_delete_Row" onClick={() => addSegment()}>
              <button type="button" title="Add Segment">
                <i className="bi bi-plus" />
              </button>
            </div>
          )}
          {minus === true && (
            <div
              id="deleterow"
              onClick={() => removeSegment(index)}
              className="add_delete_Row"
            >
              <button>
                <i className="bi bi-dash" />
              </button>
            </div>
          )}
        </div>
      </>
    );
  };

  const AutoSuggestData = ({ autosuggestion, segIndex, type }) => {
    if (autosuggestion.length === 0) {
      return false;
    }
    return (
      <>
        <div className="autosuggest">
          {autosuggestion.map((suggestions, i) => {
            return (
              <React.Fragment key={type + segIndex + i}>
                <h6 onClick={() => onClickSegment(segIndex, suggestions, type)}>
                  <GetIcon type={type} />
                  {/* <i className="fa fa-map-marker"></i> */}
                  {`${suggestions}`}
                </h6>
              </React.Fragment>
            );
          })}
        </div>
      </>
    );
  };

  
  const handleCabin = (e) => {
    // console.log(e.target.value)
    setCabin(e.target.value);
    let selection = "";
    if (e.target.value === "Y") {
      selection = "Economy Class";
    } else if (e.target.value === "C") {
      selection = "Business Class";
    } else if (e.target.value === "F") {
      selection = "First Class";
    } else if (e.target.value === "S") {
      selection = "Premium Economy";
    }
    setCabinTextSelection(selection);
  };
  const hideCabin = (e) => {
    setCabinShow(false);
  };
  const hideAirline = (e) => {
   // e.preventDefault();
    setShowAirline(false);
    let allAirline=includeAirline(airline1,airline2,airline3);
      setAllAirline(allAirline);
  };
  const CabinContent = ({ cabinShow, handleCabin, cabin }) => {
    if (cabinShow === false) {
      return false;
    }

    return (
      <>
        <div className="travllerDropdownBox economyBox">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-sm-12">
                  <div className="head">Cabin Class</div>

                  <select
                    name="cabin"
                    value={cabin}
                    onChange={handleCabin}
                    className="form-select mb-4"
                  >
                    {cabinArray.map((cname, index) => (
                      <option key={cabin + index} value={cname.cabin}>
                        {cname.name}
                      </option>
                    ))}
                  </select>
                  <ul className="classType inputSet">
                    {/* <li> */}
                      {/* <label>
                        <input
                          type="checkbox"
                          name="classtype"
                          defaultChecked
                        />
                        <span>All segments in selected cabin class </span>{" "}
                      </label> */}
                      {/* Tooltip Block start*/}
                      {/* <div className="tooltip_block">
                                          <i className="tooltip-icon bi bi-exclamation-square-fill" />
                                          <div className="tooltip-new  tooltip-top">
                                            <div className="inner_block">
                                              <p>
                                                Only results where the cabin
                                                class is available for all
                                                segments.
                                              </p>
                                            </div>
                                          </div>
                                        </div> */}
                      {/* Tooltip Block End*/}
                    {/* </li> */}
                    <li>
                      <label>
                        <input onChange={()=>setNonStop(!nonStop)} type="checkbox" value={nonStop} checked={nonStop} name="nonStop" />
                        <span>Non-stop only</span>{" "}
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type="checkbox" onChange={()=>setBaggageFaresOnly(!baggageFaresOnly)} value={baggageFaresOnly}  checked={baggageFaresOnly} name="baggageFaresOnly"/>
                        <span>Baggage fare only</span>{" "}
                      </label>
                    </li>
                    {/* <li>
                      <label>
                        <input type="checkbox" name="classtype" />
                        <span>No airport change</span>{" "}
                      </label>
                    </li> */}
                    <li className="d-flex justify-content-between align-items-center mt-2">
                      <label>
                        <span>Maximum transfer </span>{" "}
                      </label>
                      <select onChange={(e)=>setMaxTransfer(e.target.value)}  value={maxTransfer} style={{ width: "70px" }} className="form-select">
                        <option value=''></option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                      </select>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              {" "}
              <a
                href="javascript:void(0);"
                onClick={hideCabin}
                className="done_Btn"
              >
                Done
              </a>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  let handleSubmit = (event) => {
    event.preventDefault();
   
    // console.log(inputFields);
    //let room=inputFields.length;
    let trip = activeTab;
    // if (trip > 3) {
    //   trip = 3;
    // }
    // console.log(activeTab);
    //debugger;
    let index = 1;
    let orgin = "";
    let orgin2 = "";
    segments.forEach((element) => {
      if (activeTab === 2) {
        orgin +=
          "&org" +
          index +
          "=" +
          element.org +
          "&des" +
          index +
          "=" +
          element.des +
          "&dt" +
          index +
          "=" +
          moment(element.dt).format("DD-MMM-yyyy") +
          "&time" +
          index +
          "=";

        orgin2 =
          "&org" +
          index +
          "=" +
          element.des +
          "&des" +
          index +
          "=" +
          element.org +
          "&returnDate=" +
          moment(element.returnDate).format("DD-MMM-yyyy") +
          "&time" +
          index +
          "=";
      } else {
        orgin +=
          "&org" +
          index +
          "=" +
          element.org +
          "&des" +
          index +
          "=" +
          element.des +
          "&dt" +
          index +
          "=" +
          moment(element.dt).format("DD-MMM-yyyy") +
          "&time" +
          index +
          "=" +
          element.time;
      }

      // moment(element.dt).format('DD-MMM-yyyy');

      index = index + 1;
    });

    let urlData =
      "?cur=USD&adt=" +
      adults +
      "&chd=" +
      childs +
      "&inf=" +
      infants +
      "&infSeat=" +
      infantSeat +
      "&tripType=" +
      trip +
      orgin +
      orgin2 +
      "&class=" +
      cabin +
      "&tripLen=" +
      segments.length+"&baggageFaresOnly=" +
      baggageFaresOnly +"&nonStop=" +
      nonStop+"&maxTransfer=" +
      maxTransfer+"&airline1=" +
      airline1+"&airline2=" +
      airline2+"&airline3=" +
      airline3;
     
    navigate("/result" + urlData, { replace: true });
    searchHide();
   
    //alert(queryUrl)
    // {currency:'USD',latitude:'',longitude:'',is_hotel:'',destination:'',hotelid:''}
    //   window.location.href = queryUrl;

    //alert(JSON.stringify(segments));
  };

  useEffect(() => {
    let tripLen = searchParams.get("tripLen");
    let adults = searchParams.get("adt");
    //let cur = searchParams.get("cur");
    let chd = searchParams.get("chd");
    let inf = searchParams.get("inf");
    let infSeat = searchParams.get("infSeat");
    let tripType = searchParams.get("tripType");
    let cls = searchParams.get("class");

    if (adults !== null && tripLen !== null) {
      setAdults(adults);
      setChilds(chd);
      setInfants(inf);
      setInfantSeat(infSeat);
      setCabin(cls);
      setActiveTab(parseInt(tripType));
      let airline1=searchParams.get("airline1");
      let airline2=searchParams.get("airline2");
      let airline3=searchParams.get("airline3");
      let baggageFaresOnly=searchParams.get("baggageFaresOnly");
      let nonStop=searchParams.get("nonStop");
      let maxTransfer=searchParams.get("maxTransfer");
      let allAirline=includeAirline(airline1,airline2,airline3);
      setAllAirline(allAirline);
      setAirline1(airline1);
      setAirline2(airline2);
      setAirline3(airline3);
      
console.log(baggageFaresOnly);
      setBaggageFaresOnly(stringToBoolean(baggageFaresOnly));
      setNonStop(stringToBoolean(nonStop));
      setMaxTransfer(maxTransfer);


      //  removeSegment_last(parseInt(tripType))
      let arra = [];
      let sugg = [];
      for (let i = 1; i <= tripLen; i++) {
        let org = searchParams.get("org" + i);
        let des = searchParams.get("des" + i);
        let dt = searchParams.get("dt" + i);
        let returnDate = "";

        let tm = searchParams.get("time" + i);

        var mindate;
        if (i === 1) {
          mindate = new Date();

          if (tripType == 2) {
            let dt1 = searchParams.get("returnDate");
            returnDate = new Date(dt1);
          } else if (tripType == 3) {
            let dt2 = searchParams.get("dt2");
            returnDate = new Date(dt2);
          }
        } else {
          let t = i - 1;
          let datt = searchParams.get("dt" + t);
          mindate = new Date(datt);
        }
        //debugger;
        let seg = "";
        if (i === 1) {
          seg = {
            org: org,
            des: des,
            dt: new Date(dt),
            minDt: mindate,
            time: tm,
            returnDate: returnDate,
            returnMinDt: new Date(dt),
          };
        } else {
          seg = {
            org: org,
            des: des,
            dt: new Date(dt),
            minDt: mindate,
            time: tm,
          };
        }

        arra.push(seg);
        sugg.push({ org: [], des: [] });
      }

      setSegments(arra);
      setSuggestions(sugg);
    }
  }, [searchParams]);

 

  // Change search start

  // Change search ends
  
  return (
    
     <form onSubmit={handleSubmit}>
      <div className="searchBox Resultsearch">
      {closeButtonShow &&
          
          <li  onClick={searchHide} className="searchHideBtn">
             Close
           
          </li>
      }
       
        <ul className="tabBox">
        
          <li
            className={activeTab === 2 ? "active-tab , active " : ""}
            onClick={() => handleTabClick(2)}
          >
            Round Trip
          </li>
          <li
            className={activeTab === 1 ? "active-tab , active " : ""}
            onClick={() => handleTabClick(1)}
          >
            One Way
          </li>
          <li
            className={activeTab === 3 ? "active-tab , active " : ""}
            onClick={() => handleTabClick(3)}
          >
            Multi City
          </li>
        </ul>
        <div className="clearfix"></div>

        <div className="searchBlock">
          <div className="flightSearch">
            {segments.map((element, index) => (
              <div className="row">
                <div className="col-sm-12 position-relative">
                  <div id="feildrowBlock">
                    <div id="feildrow" className="feildrow" key={"rr" + index}>
                      <div className="eng_column1">
                        <div className="form-floating destination">
                          <input
                            type="text"
                            autoComplete="off"
                            name="org"
                            required="required"
                            onChange={(e) => handleSegment(index, e)}
                            value={element.org || ""}
                            className="form-control input"
                            placeholder="Origin"
                          />
                          <label htmlFor="Origin">Origin</label>
                          <FlightTakeoff className="icons" />
                          <AutoSuggestData
                            autosuggestion={suggestions[index].org}
                            segIndex={index}
                            type={"org"}
                          />
                        </div>
                        <div className="form-floating destination">
                          <input
                            type="text"
                            onChange={(e) => handleSegment(index, e)}
                            name="des"
                            required="required"
                            autoComplete="off"
                            value={element.des || ""}
                            className="form-control input"
                            placeholder="Destination"
                          />
                          <label htmlFor="destination">Destination</label>
                          
                          <FlightLand className="icons" />
                          <AutoSuggestData
                            autosuggestion={suggestions[index].des}
                            segIndex={index}
                            type={"des"}
                          />
                        </div>
                      </div>

                      <div className="eng_column2">
                        <div className="dateblock">
                          <DatePicker
                            selected={element.dt}
                            onChange={(e) => handleDate(index, e)}
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="Departure Date"
                            minDate={element.minDt}
                            required="required"
                            monthsShown={2}
                            className="datepicker"
                          />

                          {/* <input
                                      type="text"
                                      className="datepicker"
                                      name="daterange"
                                      placeholder="Date"
                                      value="Date"
                                      readonly
                                    /> */}
                          
                          <CalendarMonth className="icons"/>
                        </div>

                          {activeTab === 2 && (
                            <div className="dateblock">
                              <DatePicker
                                selected={element.returnDate}
                                onChange={(e) => handleReturnDate(index, e)}
                                dateFormat="dd-MMM-yyyy"
                                required="required"
                                placeholderText="Return Date"
                                minDate={element.returnMinDt}
                                monthsShown={2}
                                className="datepicker"
                              />
                              <CalendarMonth className="icons"/>
                            </div>
                            
                          )}

                          {activeTab === 3 && (
                            <div className="timeblock">
                            <input
                              type="text"
                              placeholder="Time"
                              onChange={(e) => handleTime(index, e)}
                              name="time"
                              value={element.time || ""}
                              className="timePicker"
                            />
                            
                            <AccessTimeFilled className="icons"/>
                            </div>
                          )}
                          
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>

                  <RemoveFirstMinusSegment
                    activeTab={activeTab}
                    index={index}
                    segmentCount={segments.length}
                  />
                </div>
              </div>
            ))}

            <div className="row mt-2">
              <div className="col-md-6 col-sm-12 pr-none">
                <div className="eng_column3">
                  <div className="label cursor travellerblock traveller">
                    <input
                      type="text"
                      value={passengerSelection}
                      onClick={setShowPass}
                      className="inputField valid cursor"
                      readOnly
                    />
                    <span className="icons person-icon bi bi-person-plus-fill" />
                  </div>
                  {/* <Passanger show={showPass} data={setShowPass}/> */}
                  <Passanger show={showPass} />
                </div>
                <div className="eng_column3">
                  <div className="label cursor travellerblock economy">
                    <input
                      type="text"
                      onClick={setCabinShow}
                      value={cabinTextSelection}
                      className="inputField valid cursor"
                      readOnly
                    />
                    <span className="icons person-icon bi bi-person-lines-fill" />
                  </div>
                  <CabinContent
                    cabinShow={cabinShow}
                    handleCabin={handleCabin}
                    cabin={cabin}
                  />
                </div>
              </div>
              <div
                id="showMorediv"
                style={{ display: "block" }}
                className="col-md-6 col-sm-12 pl-none"
              >
                <div className="eng_column3">
                  <div className="label cursor travellerblock Alliance">
                    <input
                      type="text" onClick={(e)=>setShowAirline(!showAirline)} name="allAirline" value={allAirline || ""}
                      defaultValue="Airline"
                      className="inputField valid cursor"
                      readOnly
                    />
                    <span className="icons person-icon bi bi-person-plus-fill" />
                  </div>

                <Multiairline showAirline={showAirline} airline1={airline1} airline2={airline2} airline3={airline3} setAirline1={setAirline1} setAirline2={setAirline2} setAirline3={setAirline3} hideAirline={hideAirline}  />


                </div>
                <div className="eng_column3" style={{ display: "none"}}>
                  <div className="label cursor travellerblock Tariff">
                    <input
                      type="text"
                      defaultValue="Tariff & Penalties"
                      className="inputField valid cursor"
                      readOnly
                    />
                    <span className="icons person-icon bi bi-person-lines-fill" />
                  </div>
                  <div
                    className="travllerDropdownBox TariffBox"
                    style={{ display: "none", zIndex: 100 }}
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="guestBox">
                          <h6>Tariff</h6>
                          <div className="dropdown">
                            <button
                              className="btn dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              PUBNEG, NET, LOW/DIR/CHA
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li>
                                <label>
                                  <input
                                    type="checkbox"
                                    disabled="true"
                                    name="classtype"
                                    defaultChecked
                                  />
                                  <span>PUBNEG</span>{" "}
                                </label>
                              </li>
                              <li>
                                <label>
                                  <input
                                    type="checkbox"
                                    disabled="true"
                                    name="classtype"
                                    defaultChecked
                                  />
                                  <span>NET</span>{" "}
                                </label>
                              </li>
                              <li>
                                <label>
                                  <input type="checkbox" name="classtype" />
                                  <span>LOW/DIR/CHA</span>{" "}
                                </label>
                              </li>
                              <li>
                                <label>
                                  <input type="checkbox" name="classtype" />
                                  <span>VFR</span>{" "}
                                </label>
                              </li>
                            </ul>
                          </div>
                          <h6 className="mt-4">
                            Penalties
                            {/* Tooltip Block start*/}
                            <div className="tooltip_block">
                              <i className="tooltip-icon bi bi-exclamation-square-fill" />
                              <div className="tooltip-new  tooltip-top">
                                <div className="inner_block">
                                  <p>
                                    By selecting another penalty option we will
                                    only search among GDS results. (Penalties
                                    based on airline filing)
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* Tooltip Block End*/}
                          </h6>
                          <select className="form-select mb-4">
                            <option selected> All recommendations </option>
                            <option value={1}> No cancellation fee </option>
                            <option value={2}> Refundable </option>
                            <option value={3}>
                              {" "}
                              Rebooking/Refund possible{" "}
                            </option>
                          </select>
                          <span className="tooltipBlock">
                            <label>
                              <input type="checkbox" name="classtype" />
                              <span>Baggage fares only</span>{" "}
                            </label>
                            {/* Tooltip Block start*/}
                            <div className="tooltip_block">
                              <i className="tooltip-icon bi bi-exclamation-square-fill" />
                              <div className="tooltip-new  tooltip-top">
                                <div className="inner_block">
                                  <p>
                                    Offers do not include bags booked as
                                    ancillary service.
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* Tooltip Block End*/}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        {" "}
                        <a href="javascript:void(0);" className="done_Btn">
                          Done
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row mt-2">
                          <div className="col-sm-12 d-flex justify-content-center">
                            <button id="show-more" className="moreoptionBtn">
                              More search options
                            </button>
                            <button
                              id="show-less"
                              style={{ display: "none" }}
                              className="moreoptionBtn"
                            >
                              Less search options
                            </button>
                            <button className="resetBtn">Reset search</button>
                          </div>
                        </div> */}
          </div>
          <button type="submit" className="searchButton">
            Search
          </button>
        </div>
       
      </div>
      </form>
  );
                      
};
export default GenericForm;
