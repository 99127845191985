import React from 'react'
import ImagesPath from './ImagesPath'
import { Link, NavLink } from 'react-router-dom'
import { Nav, Navbar } from 'react-bootstrap'
import { useEffect, useState } from 'react';
//import (getStorageData) from './pages/FlightFlow/function.js';
// import Menu from './Menu'
import { apiBaseUrl, setStorageData, getStorageData } from '../global';
const Header = () => {
  const [website, setWebsite] = useState();


  useEffect(() => {
    let searchrq = { website_id: "1" };
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    var sestting = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(searchrq),
      redirect: 'follow'
    };
    const url = apiBaseUrl + '/api/getWebsite';
    fetch(url, sestting)
      // fetch('response.json', requestOptions)
      .then(response => response.json())
      .then(result => {
        setWebsite(result);
        setStorageData(result, 'website');
      }).catch(error => console.log(error));
    //console.log(validateRq);
    //debugger;
    //console.log(website);
  }, []);

  return (
    <>
      {/* <!-- header container start --> */}
      <header id="headerCntr">
        <div className="headertop">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="topsocial">
                    <ul>
                      <li><Link target='blank' to={website?.facebookLink}><i className="fa fa-facebook"></i></Link></li>
                      <li><Link target='blank' to={website?.twitterLink}><i className="fa fa-twitter"></i></Link></li>
                      <li><Link target='blank' to={website?.youtubeLink}><i className="fa fa-google-plus"></i></Link></li>
                      <li><Link target='blank' to={website?.linkdinLink}><i className="fa fa-linkedin"></i></Link></li>
                    </ul>
                  </div>
                  <div className="topsupport">
                    <ul>
                      <li><Link to="/"><i className="fa fa-exclamation-circle"></i>Support</Link></li>
                      <li><Link to="/"><i className="fa fa-envelope"></i>Email Us</Link></li>
                      <li><Link to={'tel:' + website?.phone1}><i className="fa fa-phone"></i>Help Line : {website?.phone1} </Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="holder">
            {/* <!-- menu box start --> */}
            <div className="menuBox">
              <Navbar expand="lg"
              >
                <Navbar.Brand as={NavLink} className="logo" to="/">
                  <img src={ImagesPath.logo} alt="logo" />
                </Navbar.Brand>

                <Navbar.Toggle
                  className="navbar-toggler"
                  type="button"
                  //data-bs-toggle="collapse" 
                  //data-bs-target="#navbarSupportedContent" 
                  aria-controls="navbarSupportedContent"
                //aria-expanded="false" 
                //aria-label="Toggle navigation"
                >
                  <span className="bi bi-list"></span>
                </Navbar.Toggle>
                <Navbar.Collapse
                  id="navbarScroll">
                  <Nav className="navbar-nav me-auto mb-2 mb-lg-0"
                    navbarScroll
                  >
                    <Nav.Link as={NavLink} className="nav-link" aria-current="page" to="/ourcompany" > Our company </Nav.Link>
                   
                    <Nav.Link as={NavLink}  className="nav-link" to="/toolnservices">Our services</Nav.Link>
                    <Nav.Link as={NavLink}  className="nav-link" to="/becomeagent">Become a Agent</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div className="loginBtn"><Link className="nav-link" to="/login"> <i className="bi bi-person-circle"></i> Login</Link>
              </div>
              {/* <!-- menu box end --> */}
            </div>
          </div>
        </div>
      </header>
      <div className="headerblank"></div>
      {/* <!-- header container end -->  */}
    </>
  )
}

export default Header
