import { RouterProvider, createBrowserRouter } from "react-router-dom";
// import { HashRouter,createHashRouter } from 'react-router-dom';
import { useAuth } from "../provider/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import Logout from "../pages/Logout";
import Home from '../pages/Home';
import About from '../pages/About';
import BecomingCustomer from '../pages/BecomingCustomer';
import Login from '../pages/Login';
import OurServices from '../pages/OurServices';
import Press from '../pages/Press';
import ToolNServices from '../pages/ToolNServices';
import Registration from '../pages/Registration';
import BecomeAgent from '../pages/BecomeAgent';
import OurCompany from '../pages/OurCompany';
import Result from '../pages/FlightFlow/Result';
import Payment from '../pages/FlightFlow/Payment';
import UserHome from "../pages/FlightFlow/UserHome";
import Confirmation from "../pages/FlightFlow/Confirmation";
import GenericForm from "../pages/FlightFlow/GenericForm";
import ForgotPassword from '../ResetPassword/ForgotPassword';
import ResetPassword from '../ResetPassword/ResetPassword';
import RegistrationSuccesful from '../ResetPassword/RegistrationSuccesful';
import BookingResult from '../pages/MyBookings/BookingResult';
import OrderManager from '../pages/MyBookings/OrderManager';
import BookingFlightDetail from '../pages/MyBookings/BookingFlightDetail';

import Profile from '../pages/Profile/Profile';
import WriteUs from "../pages/Profile/MyProfile/WriteUs";
import ProfileSetting from "../pages/Profile/MyProfile/ProfileSetting";
import MyInformation from "../pages/Profile/MyProfile/MyInformation";
import BookingFlightDetail1 from "../pages/MyBookings/BookingFlightDetail";
import MyAgency from "../pages/Profile/MyAgency/MyAgency";
import MyColeagues from "../pages/Profile/MyAgency/MyColeagues";
import GDSAgency from "../pages/Profile/MyAgency/GDSAgency";
import BankDetail from "../pages/Profile/MyAgency/BankDetail";
import Itinerairies from "../pages/Profile/Itinerairies/Itinerairies";
import InvoicesNCreditMemos from "../pages/Profile/InvoicesNCreditMemos/InvoicesNCreditMemos";
import PriceBreakdownPopup from "../pages/FlightFlow/PriceBreakdownPopup";
import ResultMidPage from "../pages/FlightFlow/ResultMidPage";





const Routes = () => {
  const { token } = useAuth();

  // Define public routes accessible to all users
  const routesForPublic = [
    // {
    //   path: "/myprofile",
    //   element: <Myprofile/>,
    // },
    {
      path: "/profile",
      element: <Profile/>,
    },
    {
      path: "/writeus",
      element: <WriteUs/>,
    },
    {
      path: "/profilesetting",
      element: <ProfileSetting/>,
    },
    {
      path: "/myinformation",
      element: <MyInformation/>,
    },
    {
      path: "/myagency",
      element: <MyAgency/>,
    },
    {
      path: "/mycoleagues",
      element: <MyColeagues/>,
    },
    {
      path: "/gdsagency",
      element: <GDSAgency/>,
    },
    {
      path: "/bankdetail",
      element: <BankDetail/>,
    },
    {
      path: "/itinerairies",
      element: <Itinerairies/>,
    },
    {
      path: "/invoicesncreditmemos",
      element: <InvoicesNCreditMemos/>,
    },
    {
      path: "/bookingdetail",
      element: <BookingFlightDetail/>,
    },
    {
      path: "/becomeagent",
      element: <BecomeAgent/>,
    },
    {
      path: "/booking",
      element: <BookingResult/>,
    },
    {
      path: "/ordermanager",
      element: <OrderManager/>,
    },
    {
      path: "/bookingresult",
      element: <BookingResult/>,
    },
    {
      path: "/bookingflightdetail",
      element: <BookingFlightDetail1/>,
    },
    {
      path: "/OurServices",
      element: <OurServices/>,
    },
    {
      path: "/form",
      element: <GenericForm/>,
    },
    {
      path: "/ourcompany",
      element: <OurCompany/>,
    },
    {
      path: "/press",
      element: <Press/>,
    },
    {
      path: "/resetpassword",
      element: <ResetPassword/>,
    },
    {
      path: "/forgotpassword",
      element: <ForgotPassword/>,
    },
    {
      path: "/registrationsuccesful",
      element: <RegistrationSuccesful/>,
    },
    {
      path: "/toolnservices",
      element: <ToolNServices/>,
    },
    {
      path: "/BecomingCustomer",
      element: <BecomingCustomer/>,
    },
    {
      path: "/about",
      element: <About/>,
    },
    {
      path: "/",
      element: <Home/>,
    },
    {
      path: "/Registration",
      element: <Registration/>,
    },

    {
      path: "/login",
      element: <Login/>,
    },
    {
      path: "/payment",
      element: <Payment/>,
    }, {
      path: "/confirmation",
      element: <Confirmation/>,
    },
    
    {
      path: "/PriceBreakdownPopup",
      element: <PriceBreakdownPopup/>,
    },
    
    {
      path: "/ResultMidPage",
      element: <ResultMidPage/>,
    },
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "",
          element: <div>User Home Page</div>,
        },
        {
          path: "/logout",
          element: <Logout/>,
        },
        {
          path: "/result",
          element: <Result/>,
        },
        {
          path: "/userhome",
          element: <UserHome/>,
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: "/",
      element: <Home/>,
    },
    {
      path: "/Registration",
      element: <Registration/>,
    },

    {
      path: "/login",
      element: <Login/>,
    },
    {
      path: "/ourcompany",
      element: <OurCompany/>,
    },
    {
      path: "/press",
      element: <Press/>,
    },
    
  ];

  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // const router = createBrowserRouter([
  //   ...routesForPublic,
  //   ...(!token ? routesForNotAuthenticatedOnly : []),
  //   ...routesForAuthenticatedOnly,
  // ],{
  //   basename: "/b2bfly",
  // });



  // const router = createHashRouter([
  //   ...routesForPublic,
  //   ...(!token ? routesForNotAuthenticatedOnly : []),
  //   ...routesForAuthenticatedOnly,
  // ],{
  //   basename: "/b2bfly",
  // });



  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
