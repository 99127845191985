import { 
  Accordion, 
  AccordionDetails, 
  AccordionSummary,
  
} from "@mui/material";
const Cprice= ({paxdetail,paxType,currency}) =>{
  if(paxdetail===null || paxdetail?.Pass===undefined)
{
  return false;
}
  return (
<>

                 <li>
                    <Accordion>
                      <AccordionSummary>
                        <div className="row">
                          <div className="col-8">
                          {paxdetail?.Pass} {paxType} <i className="fa fa-angle-down"></i>
                          </div>{" "}
                          <div className="col-4 text-right">{currency} {(paxdetail?.Pass*(parseFloat(paxdetail?.BaseFare)+parseFloat(paxdetail?.Tax))).toFixed(2)}</div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="row">
                          <div className="col-8">
                            Flight Charges <br />
                            Taxes & Fees
                          </div>

                          <div className="col-4 text-right">
                          {currency} {paxdetail.BaseFare} <br />
                          {currency} {paxdetail.Tax}
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </li> 

                         
    </>
    );

}
export default Cprice;