import React from 'react'
import { Link,NavLink } from "react-router-dom";
import {getStorageData,apiBaseUrl} from '../../global';
const LeftCntr = () => {
  return (
    <div>
      <div class="leftCntr">
        <div class="profile_imageBlock">
            <figure class="image"><span class="changeColor">{getStorageData('userSession')?.fname?.charAt(0).toUpperCase()}{getStorageData('userSession')?.lname?.charAt(0).toUpperCase()}
            
            
            </span></figure>
            <div class="name_col">
            <div class="name"> <span class="displayusername_2">{getStorageData('userSession')?.fname} {getStorageData('userSession')?.lname}</span>
                <div class="small"> Last login: <br class="d-none d-xl-block"/>
                <span class="LastLoginDate">{getStorageData('userSession')?.last_login}</span> </div>
            </div>
            </div>
        </div>
        <div class="leftMenu">
            <ul>
                <li><Link to="/myinformation" class="myinformation active"><i class="bi bi-person-lines-fill"></i> My Information</Link></li>
                <li><Link to="/writeus" class="writeus"><i class="bi bi-pencil-square"></i> Write To Us</Link></li>
                <li><Link to="/profilesetting" class="setting"><i class="bi bi-gear"></i> Change Password</Link></li>
                <li><Link to="/logout" class="signout"><i class="bi bi-box-arrow-in-right"></i> Sign Out</Link></li>
            </ul>
        </div>
        </div>
    </div>
  )
}

export default LeftCntr
