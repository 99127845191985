import React, {useState,useEffect} from 'react'
import ImagesPath from './ImagesPath'
import { Link, NavLink } from 'react-router-dom'
import { Nav, NavDropdown, Navbar } from 'react-bootstrap'
// import Menu from './Menu'
//import {  useState } from "react";
import { apiBaseUrl,setStorageData,getStorageData} from '../global';
const HeaderUser = () => {
 // const [userName] = useState(localStorage.getItem('userName'));
 const [website, setWebsite] = useState();
 const [walletBalance1, setwalletBalance1] = useState();
 
 useEffect(()=>{
  var myHeaders = new Headers();
        let formData= new FormData();
        formData.append('userId',getStorageData('userSession')?.id);
        formData.append('paymentMethod','cash');

  const url = apiBaseUrl + '/api/cash';
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formData,
  redirect: 'follow'
  };
  fetch(url, requestOptions)
  .then((response) => response.json())
  .then((result) => {
   //console.log(result);
    setwalletBalance1(result.Balance);
  }
  ); 


 },[]);
  useEffect(() => {
    let searchrq={website_id: "1"};
 var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Accept", "application/json");
  var sestting = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(searchrq),
    redirect: 'follow'
  };
  const url = apiBaseUrl + '/api/getWebsite';
  fetch(url, sestting)
    // fetch('response.json', requestOptions)
      .then(response => response.json())
      .then(result => {
        setWebsite(result);
        setStorageData(result,'website');
      }).catch(error => console.log(error));
//console.log(validateRq);
//debugger;
//console.log(website);
},[]);

 // profile dropdown start
 const [isUserDropdown, setUserDropdown] = useState(false);
 const toggleUserdiv = () => {
   setUserDropdown(!isUserDropdown);
 };
 // profile dropdown ends


  return (
    <>
      {/* <!-- header container start --> */}
      <header id="headerCntr">
        <div className="headertop">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="topsocial">
                    <ul>
                    <li><Link target='blank' to={website?.facebookLink}><i className="fa fa-facebook"></i></Link></li>
                      <li><Link target='blank' to={website?.twitterLink}><i className="fa fa-twitter"></i></Link></li>
                      <li><Link target='blank' to={website?.youtubeLink}><i className="fa fa-google-plus"></i></Link></li>
                      <li><Link target='blank' to={website?.linkdinLink}><i className="fa fa-linkedin"></i></Link></li>
                    </ul>
                  </div>
                  <div className="topsupport">
                    <ul>
                      <li><Link to=""><i className="fa fa-exclamation-circle"></i>Support</Link></li>
                      <li><Link to=""><i className="fa fa-envelope"></i>Email Us</Link></li>

                    
                      <li><Link to={'tel:' + website?.phone1}><i className="fa fa-phone"></i>Help Line : {website?.phone1} </Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="holder"> 
            {/* <!-- menu box start --> */}
            <div className="menuBox">
            <Navbar expand="lg" 
              >
                <Navbar.Brand className="logo" href="/userhome">
                  <img src={ImagesPath.logo} alt="logo" />
                </Navbar.Brand>
                <Navbar.Toggle
                  className="navbar-toggler"
                  type="button"
                  //data-bs-toggle="collapse" 
                  //data-bs-target="#navbarSupportedContent" 
                  aria-controls="navbarSupportedContent" 
                  //aria-expanded="false" 
                  //aria-label="Toggle navigation"
                  > 
                  <span className="bi bi-list"></span> 
                </Navbar.Toggle>
                <Navbar.Collapse
                  id="navbarScroll"
                >
                  <Nav className="navbar-nav me-auto mb-2 mb-lg-0"
                    navbarScroll
                  >
                    <Nav.Link as={NavLink} className="nav-link " aria-current="page" to="/userhome">Book flights</Nav.Link>
                    <Nav.Link as={NavLink} className="nav-link" to="/booking">My Bookings</Nav.Link>
                   
                     {/* <Nav.Link >Wallet{getStorageData('userSession')?.walletBalance}</Nav.Link> */}
                    
                    {/* <Nav.Link as={NavLink} className="nav-link" to="/ordermanager">All about refunds</Nav.Link>
                    <Nav.Link as={NavLink} className="nav-link" to="/">My Team</Nav.Link>
                    <Nav.Link as={NavLink} className="nav-link" to="/">My Airlines</Nav.Link>
                    <NavDropdown title="More" id="navbarScrollingDropdown" to="/">
                      <NavDropdown.Item href="#action3">My Airlines</NavDropdown.Item>
                      <NavDropdown.Item href="#action3">My Library</NavDropdown.Item>
                    </NavDropdown> */}
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div className="loginBtn">
                <Link className="userDropdown_toggle" onClick={toggleUserdiv} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="bi bi-person-circle"></i>{getStorageData('userSession').agency}
                </Link>
                {isUserDropdown && (
                  <ul className="userDropdown">
                    <li>
                      <NavLink className="dropdown-item" to="/profile">
                      {getStorageData('userSession').accountId}
                      </NavLink>
                    </li>
                    {/* <li>
                      <Link className="dropdown-item" to="/">
                        My Company
                      </Link>
                    </li>
                     */}
                     <li>
                     {<Nav.Link >Balance-{walletBalance1}</Nav.Link>} 
                    </li>
                     <li>
                      <NavLink className="dropdown-item" to="/profile">
                        My Profile
                      </NavLink>
                    </li>
                    
                    <li>
                      <Link to="/logout" className="dropdown-item">
                        Logout
                      </Link>
                    </li>
                  </ul>
                )}
              </div>
              {/* <!-- <div className="loginBtn"> <a href="/"><i className="bi bi-person-circle"></i> Profile</a> --> */}
            </div>
            {/* <!-- menu box end --> */}
            </div>
        </div>

      </header>
      <div className="headerblank"></div>
      {/* <!-- header container end -->  */}
    </>
  )
}

export default HeaderUser
