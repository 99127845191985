import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ImagesPath from '../components/ImagesPath'
import { Link } from 'react-router-dom'

const BecomingCustomer = () => {
  return (
    <div>
      <Header/>

      {/* <!--  / Banner container \ --> */}
      <div id="InnerBannerCntr" style={{ background:`url(${ImagesPath.BecomingCustomer_banner})`, backgroundSize: "cover"}}>
        <div className="container">
          <div className='content'>
            <h1>Becoming a customer</h1>
            <p>This site is more than just a platform; it's a partnership</p>
          </div>
        </div>
      </div>
      {/* <!--  \ Banner container / --> */}

      {/* <!--  / about container \ --> */}
      <div id="aboutCntr">
        <div className="container">
            <div className='img'>
              <img src={ImagesPath.about_img1} alt="about_img" />
            </div>
            <h2 className='content'>Becoming a customer</h2>
            <p>Thanks for thinking about FlyUS Travels as a traveling partner you can trust. Becoming a customer is the first thing that will let you access a world of easy travel options, cutting-edge tools, and unbeatable customer service. FlyUS Travels cares about your success and wants to give you the tools and services you need to take your travel business to the next level.</p>
            <div className='clearfix'></div>
        </div>

        <div className="container">
            
            <h3>Why You Should Pick FlyUS Travels?</h3>
            <p><strong> Cutting Edge Technology:</strong> Our booking platform is very advanced, and our APIs can be changed to fit the needs of each customer. Stay ahead of the curve by using technology that makes your business run more smoothly and gives your customers a better experience.</p>
            <p><strong> Large Selection:</strong> You can choose from a huge selection of flights, hotels, car rentals, and other things. Because we have a large network, you can give your clients a lot of choices, so you can meet all of their needs and preferences.</p>
            <p><strong> Supporting You Fully:</strong> This site is more than just a platform; it's a partnership. Get personalized support and dedicated account management to make sure you have the help you need to deal with the complicated travel industry.</p>
            <p><strong> How to Train and Get Help:</strong> Our training sessions and resources will keep you informed and give you power. We give your team thorough training on the newest features and trends in the industry, giving you the tools, you need to succeed.</p>

            <h3>Joining FlyUS Travels:</h3>
            <p><strong> Send Your Inquiry:</strong> To begin, send your inquiry through our online form. Just tell us a few things about your business, and someone from our team will be in touch with you right away.</p>
            <p><strong> Consultation Call:</strong> One of our experts will set up a call with you to talk about your needs, goals, and how FlyUS Travels can best help your business.</p>
            <p><strong> Tailored Solutions:</strong> We will make a solution that fits your business goals based on what we've talked about. What kind of business, travel agency, or tour operator are you? We have the tools and services to meet your needs.</p>
            <p><strong> Onboarding Process:</strong> If you decide to work with us, our smooth onboarding process will make the change go smoothly and quickly. There is no way we can fail because we are with you every step of the way.</p>

            <p>Become one of the successful travel professionals who have chosen FlyUS Travels as their partner of choice. Join as a customer today, and let's go on a journey of success, growth, and new ideas. FlyUS Travels: If you want to be successful, go there!</p>
        </div>
      </div>
      {/* <!--  \ about container / --> */}

      <Footer/>
    </div>
  )
}

export default BecomingCustomer


