import { Link } from "react-router-dom"
import ImagesPath from "./ImagesPath"
import { getStorageData} from '../global';
const Footer = () => {
  return (
    <>
      {/* <!-- footer container start --> */}
      <footer id="footerCntr">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-2 col-sm-5">
              <div className="list">
                <h3>Flyus Travels</h3>
                <ul>
                  <li><Link to="/ourcompany">Our company</Link></li>
                  <li><Link to="/press">Press</Link></li>
                  <li><Link to="/toolnservices">Our services</Link></li>
                  <li><Link to="/registration">Become a Agent</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-sm-7">
              <figure><img className="footer_img" src={ImagesPath.footer_img} alt="footer_img" /></figure>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="list">
                <h3>To find out more about Flyustravels Please contact us at:</h3>
                <ul>
                  <li><span>Contact Number:</span> {getStorageData('website')?.phone2},  {getStorageData('website')?.phone3}</li>
                  <li><span>E-mail address:</span>{getStorageData('website')?.email1}</li>
                  <li><span>Website:</span><a href={getStorageData('website')?.website}> {getStorageData('website')?.website}</a></li>
                </ul>
              </div>
              <div className="social">
                <ul>
                  <li><a target="blank" href={getStorageData('website')?.facebookLink}><i className="bi bi-facebook"></i></a></li>
                  <li><a target="blank" href={getStorageData('website')?.linkdinLink}><i className="bi bi-linkedin"></i></a></li>
                  <li><a target="blank" href={getStorageData('website')?.twitterLink}><i className="bi bi-twitter"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row copyright">
          <div className="col-sm-12">
            <div className="container">
              <p>&copy; 2000 - 2022 Flyustravels, Inc. All Rights Reserved. </p>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- footer container end -->  */}
    </>
  )
}

export default Footer
