// import React from 'react';
 //import {   } from "react-router-dom";
import './App.scss';
import AuthProvider from "./provider/authProvider";
import Routes from "./routes";
// function App() {
//   return (
//     <>
//       <Routes>
//         <Route path='/' element={<Home/>}/>
//         <Route path='/about' element={<About/>}/>
//         <Route path='/becomingcustomer' element={<BecomingCustomer/>}/>
//         <Route path='/login' element={<Login/>}/>
//         <Route path='/ourservices' element={<OurServices/>}/>
//         <Route path='/registration' element={<Registration/>}/>
//         <Route path='/userhome' element={<Registration/>}/>
//         <Route path='/result' element={<Result/>}/>
//         <Route
//           path="*"
//           element={<div style={{ display: "none" }}>Not Found</div>}
//         />
//       </Routes>
//     </>
//   );
// }

function App() {
  return (
  
    <AuthProvider>
      <Routes />
    </AuthProvider>
  
  );
}

export default App;
