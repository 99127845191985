import { 
  Accordion, 
  AccordionDetails, 
  AccordionSummary,
  
} from "@mui/material";
const Bprice= ({validateData}) =>{
  if(validateData===null)
{
  return false;
}
  return (
<>

                     <li>
                    <Accordion  expanded={true}>
                      <AccordionSummary>
                        <div className="d-flex justify-content-between">
                          <div>
                          {validateData.itin.Adults.Pass} Adult <i className="fa fa-angle-down"></i>
                          </div>
                          <div>{validateData.Cur} {(validateData.itin.Adults.Pass*(parseFloat(validateData.itin.Adults.BaseFare)+parseFloat(validateData.itin.Adults.Tax))).toFixed(2)}</div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="d-flex justify-content-between">
                          <div>
                            Flight Charges <br />
                            Taxes & Fees
                          </div>

                          <div>
                          {validateData.Cur} {parseFloat(validateData.itin.Adults.BaseFare)} <br />
                          {validateData.Cur} {parseFloat(validateData.itin.Adults.Tax)}
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </li>
                  {validateData.itin.Childs.Pass>0 && (
                  <li>
                    <Accordion expanded={true}>
                      <AccordionSummary>
                        <div className="d-flex justify-content-between">
                          <div>
                          {validateData.itin.Childs.Pass} Child <i className="fa fa-angle-down"></i>
                          </div>
                          <div>{validateData.Cur} {(validateData.itin.Childs.Pass*(parseFloat(validateData.itin.Childs.BaseFare)+parseFloat(validateData.itin.Childs.Tax))).toFixed(2)}</div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="d-flex justify-content-between">
                          <div>
                            Flight Charges <br />
                            Taxes & Fees
                          </div>

                          <div>
                          {validateData.Cur} {parseFloat(validateData.itin.Childs.BaseFare)} <br />
                          {validateData.Cur} {parseFloat(validateData.itin.Childs.Tax)}
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </li>
                       )  }

                          {validateData.itin.Infants.Pass>0 && (
                  <li>
                    <Accordion expanded={true}>
                      <AccordionSummary>
                        <div className="d-flex justify-content-between">
                          <div>
                          {validateData.itin.Infants.Pass} Infant <i className="fa fa-angle-down"></i>
                          </div>
                          <div>{validateData.Cur} {(validateData.itin.Infants.Pass*(parseFloat(validateData.itin.Infants.BaseFare)+parseFloat(validateData.itin.Infants.Tax))).toFixed(2)}</div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="d-flex justify-content-between">
                          <div>
                            Flight Charges <br />
                            Taxes & Fees
                          </div>

                          <div>
                          {validateData.Cur} {parseFloat(validateData.itin.Infants.BaseFare)} <br />
                          {validateData.Cur} {parseFloat(validateData.itin.Infants.Tax)}
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </li>
                         )}







                      {validateData.itin?.InfantsSeat?.Pass>0 && 
                     <li>
                  <Accordion expanded={true}>
                        <AccordionSummary>
                          <div className="d-flex justify-content-between">
                            <div>
                              Infant Lap <i className="fa fa-angle-down"></i>
                            </div>{" "}
                            <div>$205.80</div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex justify-content-between">
                            <div>
                              Flight Charges <br />
                              Taxes & Fees
                            </div>

                            <div>
                              $79.42 <br />
                              $20.42
                            </div>
                          </div>
                        </AccordionDetails>
                  </Accordion>
                  </li>     
}
                         
    </>
    );

}
export default Bprice;