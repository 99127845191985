
const Layoverdata=({LayOver,inde})=>{
 
  if(LayOver===undefined || LayOver===null || inde===0)
  {
    return false;
  }

  return (
    <>
                                <div className="row">
                              <div className="col-sm-8 offset-sm-4">
                                <div className="layover">
                                <i className="bi bi-stopwatch"></i> Layover : {LayOver}
                                </div>
                              </div>
                            </div>
        </>
        );
}
export default Layoverdata;