import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import ImagesPath from '../components/ImagesPath';


import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
//import { Navigation, Pagination } from 'swiper/modules';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

const index = () => {
  return (
    <div>
      <Header/>

      {/* <!-- banner container start --> */}
      <div id='bannerCntr'>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{
              clickable: true,
            }}
            loop={true}
            autoplay={{
              delay: 0,
              pauseOnMouseEnter: true,
              disableOnInteraction: true,
            }}
            speed={1500}
            //pagination={}
            breakpoints={{
              1200: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
            }}
          >
            <SwiperSlide>
              <div className="block">
                <figure>
                  <img src={ImagesPath.banner1} className="d-sm-block d-none" alt="banner1" />
                  <img src={ImagesPath.mob_banner1} className="d-block d-sm-none" alt="mob_banner1" />
                </figure>
                <div className="carousel-caption caption_holder"> 
                  <span>Welcome to</span>
                  <h1>Flyustravels</h1>
                  <p>your best business-to-business travel partner!</p>
                  <div className="more_btn"> <a className="redBtn demoID" href="/becomeagent">Become a Agent</a> </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="block">
                <figure>
                  <img src={ImagesPath.banner2} className="d-sm-block d-none" alt="banner1" />
                  <img src={ImagesPath.mob_banner2} className="d-block d-sm-none" alt="mob_banner1" />
                </figure>
                <div className="carousel-caption caption_holder"> 
                  <span>Welcome to</span>
                  <h1>Flyustravels</h1>
                  <p>your best business-to-business travel partner!</p>
                  <div className="more_btn"> <a className="redBtn demoID" href="/becomeagent">Become a Agent</a> </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="block">
                <figure>
                  <img src={ImagesPath.banner3} className="d-sm-block d-none" alt="banner1" />
                  <img src={ImagesPath.mob_banner3} className="d-block d-sm-none" alt="mob_banner1" />
                </figure>
                <div className="carousel-caption caption_holder"> 
                  <span>Welcome to</span>
                  <h1>Flyustravels</h1>
                  <p>your best business-to-business travel partner!</p>
                  <div className="more_btn"> <a className="redBtn demoID" href="/becomeagent">Become a Agent</a> </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="block">
                <figure>
                  <img src={ImagesPath.banner4} className="d-sm-block d-none" alt="banner1" />
                  <img src={ImagesPath.mob_banner4} className="d-block d-sm-none" alt="mob_banner1" />
                </figure>
                <div className="carousel-caption caption_holder"> 
                  <span>Welcome to</span>
                  <h1>Flyustravels</h1>
                  <p>your best business-to-business travel partner!</p>
                  <div className="more_btn"> <a className="redBtn demoID" href="/becomeagent">Become a Agent</a> </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="block">
                <figure>
                  <img src={ImagesPath.banner5} className="d-sm-block d-none" alt="banner1" />
                  <img src={ImagesPath.mob_banner5} className="d-block d-sm-none" alt="mob_banner1" />
                </figure>
                <div className="carousel-caption caption_holder"> 
                  <span>Welcome to</span>
                  <h1>Flyustravels</h1>
                  <p>your best business-to-business travel partner!</p>
                  <div className="more_btn"> <a className="redBtn demoID" href="/becomeagent">Become a Agent</a> </div>
                </div>
              </div>
            </SwiperSlide>
            
                
          </Swiper>
      </div>
      {/* <!-- banner container end -->  */}

      {/* <!-- content container start --> */}
      <div id="contentCntr"> 
        
        {/* <!-- service box start --> */}
        <div className="serviceBox">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="m_title">Why Flyus Travel?</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mb_60">
                <div className="service">
                  <figure><img src={ImagesPath.icon1} alt="icon1"/></figure>
                  <h4>Six Years of Excellence</h4>
                  <p>Flyus Travel has been at the forefront of providing excellent travel services ever since we opened. With six years of experience in the field, we've honed our skills to give our valued customers experiences they'll never forget. Because we are dedicated to doing the best job possible, people trust us in the travel industry, and we will continue to strive for perfection in every trip we plan.</p>
                </div>
              </div>
              <div className="col-md-4 mb_60">
                <div className="service">
                  <figure><img src={ImagesPath.icon2} alt="icon2"/></figure>
                  <h4>Airlines with negotiated airfares</h4>
                  <p>One of the best things about Flyus Travel is that we can offer low prices because we negotiate airfares with well-known airlines. We know how important price is when planning a trip, and our relationships with top carriers let us offer you cost-effective options without lowering the quality of service. You can travel for less money without giving up comfort or dependability.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="service">
                  <figure><img src={ImagesPath.icon3} alt="icon3"/></figure>
                  <h4>Online Support</h4>
                  <p>At Flyus Travel, we put your comfort first. We know that traveling plans can go wrong, that's why our support team is available 24 hours a day, 7 days a week. Our online support is only a click away if you have questions about your reservation, need help during your trip, or run into problems you didn't expect. When you travel with Flyus Travel, you're not just a passenger; you're a valued traveler who gets personalized help whenever they need it.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- service box end -->  */}
        
      </div>
      {/* <!-- content container end -->  */}

      <Footer/>
    </div>
  )
}

export default index


