import React, { useState,useEffect } from "react";
import HeaderUser from "../../components/HeaderUser";
import Footer from "../../components/Footer";
import { Link , useSearchParams,useNavigate} from "react-router-dom";
import ImagesPath from "../../components/ImagesPath";
import Form from 'react-bootstrap/Form';
import { apiBaseUrl ,getStorageData} from '../../global';

import Paymenttop from "./leftfilter/Paymenttop";
import Bprice from "./leftfilter/Bprice";
import {urlPart,validateCreditCard,validateYear,validateCVV,validateEmail,validateMobile} from './function.js';
//import{ReactComponent as Plane} from "../../resources/images/icons/plane.svg"
import{ReactComponent as CardZoom} from "../../resources/images/icons/card-zoom.svg"
import {getDay, getMonth,getCardExpiryYear} from './function.js';
import { 
  // Accordion, 
  // AccordionDetails, 
  // AccordionSummary,
  FormControlLabel,
  //FormControl,
  Radio,
  RadioGroup,
  Checkbox,
 // TextField,
} from "@mui/material";
import PaymentTab1 from "./leftfilter/PaymentTab1";
import PaymentTab2 from "./leftfilter/PaymentTab2";
import Baggage from "./leftfilter/Baggage";
import Bcoupon from "./leftfilter/Bcoupon";
import { AlarmOn, CreditCard } from "@mui/icons-material";
//import { Link } from 'react-router-dom';

function Payment() {

    // payment stepper start
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [currentStep, setCurrentStep] = useState(1);
    const [backurl,setBackurl]=useState(null);
    const [validateData,setValidateData]=useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [confirmBooking, setConfirmBooking] = useState(true);
    
    const initalFormValue={
      cardNumber: "",cardHolderName: "",cardExpiryMonth: "",cardExpiryYear: "",cvv: "",
      country: "",address: "",city: "",state: "",pincode: "",email: "",phone: ""
      };
    const [posts, setPosts] = useState(initalFormValue);
    const [adultsArr, setAdultsArr] = useState([]);
    const [childsArr, setChildsArr] = useState([]);
    const [infantsArr, setInfantsArr] = useState([]);
    const [infantsSeatArr, setInfantsSeatArr] = useState([]);

    const [day] = useState(getDay);
    const [month] = useState(getMonth);
    const [carExpiryYear] = useState(getCardExpiryYear);
    const [pnrDetailData,setPnrDetailData] = useState({});
    const [bookingRs,setBookingRs] = useState({});
    const [formValid,setFormValid] = useState(true);
    const [isSubmit,setIsSubmit] = useState(false);
    
    const [countDown, setCountDown] = useState(900);
    const [walletBalance, setwalletBalance] = useState();
    let payMode={cCard:'',cheque:'',hold:''}
    const [paymentMode, setpaymentMode] = useState(payMode);
    const [cash, setcash] = useState({});
    let payMethod={cCard:'cCard',cash:''};
    const [paymentMethod, setpaymentMethod] = useState(payMethod);
    const [TotalFare, setTotalFare] = useState('');
    const [walletBalanceinfo, setwalletBalanceInfo] = useState('');
    const [api_travelType, setapi_travelType] = useState([]);
    
    const handlePaymentMethod=(e)=>{
        
      var name=e.target.name;
      var value=e.target.value;
      if(name==='cCard'){
        paymentMethod.cCard=value;
        paymentMethod.cash='';
      }
      if(name==='cash'){
        paymentMethod.cash=value;
        paymentMethod.cCard='';

        var myHeaders = new Headers();
        let formData= new FormData();
        formData.append('userId',getStorageData('userSession')?.id);
        formData.append('paymentMethod','cash');

  const url = apiBaseUrl + '/api/cash';
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formData,
  redirect: 'follow'
  };
  fetch(url, requestOptions)
  .then((response) => response.json())
  .then((result) => {
    console.log(result);
    setwalletBalance(result.Balance);

    let TraceId = searchParams.get("TraceId");
    
        let storageData=getStorage(TraceId);
        if(storageData){
          var WalletBalacee=result.Balance;
          var totalFare=storageData.itinerary.TotalFare;
     if(totalFare>WalletBalacee){
      setwalletBalanceInfo('Low Balance in Your wallet please ')
    }
        }
       console.log(walletBalanceinfo);
   

  }
  ); 



      }
     
    
      
    }
   
    useEffect(() => {
      var WalletBalacee=getStorageData('userSession')?.walletBalance;
          setwalletBalance(WalletBalacee);

          var paymentModeData=getStorageData('userSession')?.paymentMode;
         /// console.log(paymentModeData);
       // var paymentModeArray=paymentMode.split(',');
        let result1 = paymentModeData.includes("cheque");
        let result2 = paymentModeData.includes("creditcard");
        let result3 = paymentModeData.includes("hold");
        if(result1){
          paymentMode.cheque='cheque';
          
        }
        
        // if(result1 && !result2){
        //   paymentMethod.cCard='';
        //   paymentMode.cheque='cheque';
        //   paymentMethod.cash='cash';
        // }
        if(result2){
          paymentMode.cCard='cCard';
          paymentMethod.cCard='cCard';
        }
        if(result3){
           paymentMode.hold='hold';
          paymentMode.cheque='cheque';
           paymentMethod.cCard='cCard';
        }

        
   },[]);
  
// console.log(paymentMethod.cCard);

   // const [runTimer, setRunTimer] = useState(true);
    useEffect(() => {
      let timerId;
        //   setCountDown(1 * 3);
        if(countDown>0)
        {
          timerId = setInterval(() => {
            setCountDown((countDown) => countDown - 1);
            }, 1000);
        }
        else if(countDown<0)
         {
         clearInterval(timerId);
         }
            
    return(() => {
      clearInterval(timerId)
  })

        //return () => clearInterval(timerId);
    }, []);

   

    let seconds;
    let minutes;

    if(countDown>0)
    {
       seconds = String(countDown % 60).padStart(2, 0);
       minutes = String(Math.floor(countDown / 60)).padStart(2, 0);
    }
    else{
    
       seconds = '00';
       minutes = '00';
    }
// seconds = String(countDown % 60).padStart(2, 0);
//        minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

useEffect(()=>{
  let TraceId = searchParams.get("TraceId");
  let storageData=getStorage(TraceId);
  let Segment_data=storageData.searchRq.segments
  if(storageData){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    var validateFlight = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(Segment_data),
      redirect: 'follow'
    };
    const url = apiBaseUrl + '/api/getTravelType';
  //  alert(url)
    fetch(url, validateFlight)
      // fetch('response.json', requestOptions)
        .then(response => response.json())
        .then(result => {
          setapi_travelType(result)
                   console.log(result);
        
        }).catch(error => console.log(error));

  }
 

//console.log(storageData.searchRq.segments)
},[])

    useEffect(() => {
      let TraceId = searchParams.get("TraceId");
    //  alert(TraceId)
      let adult = searchParams.get("adt");
      let storageData=getStorage(TraceId);
      //console.log(storageData);
      let validateRq={
        src: "",
        userId:localStorage.getItem('id'),
        website:"",
        TraceId: "",
        ItinId: "",
        adults: "",
        childs: "",
        segmentId: [],
        obId: "",
        ibId: "",
        infants: "",
        infantSeat: ""
        };


      let fareRequest={
        Cur:'',
        Airline:'',
        Airport:'',
        engine:'',
        itin:{},
        key:"",
        tripLen:"",
        gds: "",
        TraceId:"",
        ItenId: "",
        adults:'',
        childs:'',
        infants:'',
        infantSeat:"",
        segmentId:[],
        obId: "",
        ibId: "",
        searchRq: {},
        cId:"",
        website:"",
        metaname:"",
        };

        
		if (storageData) {
fareRequest.key=storageData.key;
fareRequest.searchRq=storageData.searchRq;

// console.log(storageData.backUrl);


 
  validateRq.src=storageData.itinerary.Src;
  validateRq.website=storageData.searchRq.website;
  validateRq.TraceId=TraceId;
  validateRq.ItinId=storageData.itinerary.ItinId;
  validateRq.adults=storageData.searchRq.adults;
  validateRq.childs=storageData.searchRq.childs;
  validateRq.infants=storageData.searchRq.infants;
  validateRq.infantSeat=storageData.searchRq.infantSeat;

  storageData.itinerary.Flights.forEach(element => {
    validateRq.segmentId.push(element.ObId);
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    var validateFlight = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(validateRq),
      redirect: 'follow'
    };
    const url = apiBaseUrl + '/api/checkFareMulti';
  //  alert(url)
    fetch(url, validateFlight)
      // fetch('response.json', requestOptions)
        .then(response => response.json())
        .then(result => {
                   console.log(result);
        
        }).catch(error => console.log(error));


//console.log(validateRq);


setBackurl(storageData.backUrl);
fareRequest.Airline=storageData.Airline;
fareRequest.Airport=storageData.Airport;
fareRequest.itin=storageData.itinerary;
fareRequest.TraceId=storageData.TraceId;
fareRequest.gds=storageData.itinerary.Src;
fareRequest.ItenId=storageData.itinerary.ItinId;
fareRequest.Cur=storageData.itinerary.Cur;
fareRequest.adults=storageData.searchRq.adults;
let adults=[];

for(let i=0;i<parseInt(adult);i++)
// for(let i=0;i<parseInt(storageData.searchRq.adults);i++)
{
 let pax={
  paxType: "Adult",
  title: "Mr",
  firstName: "",
  middleName: "",
  lastName: "",
  age: "",
  dob: "",
  day: "",
  month: "",
  year: "",
  gender: "Male",
  email: "",
  wheel:"no",
  meal: "",
  passportCity:"",
  passportCountry: "",
  passportExpiryMonth:"",
  passportExpiryYear:"",
  passportNumber: "",
  passportExpiryMonth:"",
  passportNationality:"",
  seat: "",
  ticketNumber:"",
  travelDeal:""
}
adults.push(pax);
}

setAdultsArr(adults);

fareRequest.childs=storageData.searchRq.childs;
let childs=[];
for(let i=0;i<parseInt(storageData.searchRq.childs);i++)
{
 let pax={
  paxType: "Child",
  title: "Mr",
  firstName: "",
  middleName: "",
  lastName: "",
  age: "",
  dob: "",
  day: "",
  month: "",
  year: "",
  gender: "Male",
  email: "",
  meal: "",
  wheel:"no",
  seat: "",
  ticketNumber:"",
  passportNumber:"",
  travelDeal:""
}
childs.push(pax);
}

setChildsArr(childs);

fareRequest.infants=storageData.searchRq.infants;
let infants=[];
for(let i=0;i<parseInt(storageData.searchRq.infants);i++)
{
 let pax={
  paxType: "Infant",
  title: "Mr",
  firstName: "",
  middleName: "",
  lastName: "",
  age: "",
  dob: "",
  day: "",
  month: "",
  year: "",
  gender: "Male",
  email: "",
  wheel:"No",
  meal: "",
  seat: "",
  ticketNumber:"",
  passportNumber:"",
  travelDeal:""
}
infants.push(pax);
}
setInfantsArr(infants);
fareRequest.infantSeat=storageData.searchRq.infantSeat;
let infantSeat=[];
for(let i=0;i<parseInt(storageData.searchRq.infantSeat);i++)
{
 let pax={
  type: "InfantSeat",
  title: "Mr",
  firstName: "",
  middleName: "",
  lastName: "",
  age: "",
  dob: "",
  day: "",
  month: "",
  year: "",
  gender: "Male",
  email: "",
  wheel:"No",
  meal: "",
  seat: "",
  ticketNumber:"",
  passportNumber:"",
  travelDeal:""
}
infantSeat.push(pax);
}
setInfantsSeatArr(infantSeat);
fareRequest.engine=storageData.searchRq.engine;
fareRequest.cId=storageData.searchRq.cId;
fareRequest.website=storageData.searchRq.website;
fareRequest.metaname=storageData.searchRq.metaname;
setValidateData(fareRequest);
}
else{
  alert('Something Has problem');
  return;
}
},[searchParams,apiBaseUrl]);


const validateAdults=(adultsArr)=>{
let adultsData=[...adultsArr];
let bool=true;
for (let index=0;index<adultsData.length;index++)
{
  adultsData[index].firstNameCheck='';
  adultsData[index].lastNameCheck='';
  adultsData[index].dayCheck='';
  adultsData[index].monthCheck='';
  adultsData[index].yearCheck='';

  adultsData[index].passportExpiryMonthCheck='';
  adultsData[index].passportExpiryYearCheck='';
  adultsData[index].passportCityCheck='';
  adultsData[index].passportCountryCheck='';
  adultsData[index].passportNationalityCheck='';
  adultsData[index].passportNumberCheck='';
 if(adultsData[index].firstName==='')
{
  adultsData[index].firstNameCheck='First Name is required';
  bool= false;
}
if(adultsData[index].lastName==='')
{
 adultsData[index].lastNameCheck='Last Name is required';
 bool= false;
}
if(adultsData[index].day==='')
{
 adultsData[index].dayCheck='Select Day';
 bool= false;
}
if(adultsData[index].month==='')
{
 adultsData[index].monthCheck='Select Month';
 bool= false;
}
if(adultsData[index].year==='')
{
 adultsData[index].yearCheck='Enter Year';
 bool= false;
}
else if(!validateYear(adultsData[index].year))
{
  adultsData[index].yearCheck='Enter Valid Year';
  bool= false;
}
//console.log(api_travelType)
if(api_travelType.includes(2)){



if(adultsData[index].passportCity==='')
{
 adultsData[index].passportCityCheck='Enter City';
 bool= false;
}
if(adultsData[index].passportCountry==='')
{
 adultsData[index].passportCountryCheck='Enter Country';
 bool= false;
}
if(adultsData[index].passportNationality==='')
{
 adultsData[index].passportNationalityCheck='Enter Nationality';
 bool= false;
}
if(adultsData[index].passportNumber==='')
{
 adultsData[index].passportNumberCheck='Enter Passport Number';
 bool= false;
}
if(adultsData[index].passportExpiryMonth==='')
{
 adultsData[index].passportExpiryMonthCheck='Select Month';
 bool= false;
}
if(adultsData[index].passportExpiryYear==='')
{
 adultsData[index].passportExpiryYearCheck='Select Year';
 bool= false;
}

}

}
setAdultsArr(adultsData);
setFormValid(bool);
return bool;
}
const validateChilds=(childsArr)=>{
  let childsData=[...childsArr];
  let bool=true;
  if(childsData.length===0)
  {
    return bool;
  }
  for (let index=0;index<childsData.length;index++)
  {
    childsData[index].firstNameCheck='';
    childsData[index].lastNameCheck='';
    childsData[index].dayCheck='';
    childsData[index].monthCheck='';
    childsData[index].yearCheck='';
   if(childsData[index].firstName==='')
  {
    childsData[index].firstNameCheck='First Name is required';
    bool= false;
  }
  if(childsData[index].lastName==='')
  {
   childsData[index].lastNameCheck='Last Name is required';
   bool= false;
  }
  if(childsData[index].day==='')
  {
   childsData[index].dayCheck='Select Day';
   bool= false;
  }
  if(childsData[index].month==='')
  {
   childsData[index].monthCheck='Select Month';
   bool= false;
  }
  if(childsData[index].year==='')
  {
   childsData[index].yearCheck='Enter Year';
   bool= false;
  }
  else if(!validateYear(childsData[index].year))
{
  childsData[index].yearCheck='Enter Valid Year';
  bool= false;
}
  }
  setChildsArr(childsData);
  setFormValid(bool);
  return bool;
  }
  const validateInfants=(infantsArr)=>{
    let infantsData=[...infantsArr];
    let bool=true;
    if(infantsData.length===0)
    {
      return bool;
    }
    for (let index=0;index<infantsData.length;index++)
    {
      infantsData[index].firstNameCheck='';
      infantsData[index].lastNameCheck='';
      infantsData[index].dayCheck='';
      infantsData[index].monthCheck='';
      infantsData[index].yearCheck='';
     if(infantsData[index].firstName==='')
    {
      infantsData[index].firstNameCheck='First Name is required';
      bool= false;
    }
    if(infantsData[index].lastName==='')
    {
     infantsData[index].lastNameCheck='Last Name is required';
     bool= false;
    }
    if(infantsData[index].day==='')
    {
     infantsData[index].dayCheck='Select Day';
     bool= false;
    }
    if(infantsData[index].month==='')
    {
     infantsData[index].monthCheck='Select Month';
     bool= false;
    }
    if(infantsData[index].year==='')
    {
     infantsData[index].yearCheck='Enter Year';
     bool= false;
    }
    else if(!validateYear(infantsData[index].year))
    {
      infantsData[index].yearCheck='Enter Valid Year';
      bool= false;
    }
    }
    setInfantsArr(infantsData);
    setFormValid(bool);
    return bool;
    }
    const validateInfantsSeat=(infantsSeatArr)=>{
      let infantsSeatData=[...infantsSeatArr];
      let bool=true;
      if(infantsSeatData.length===0)
      {
        return bool;
      }
      for (let index=0;index<infantsSeatData.length;index++)
      {
        infantsSeatData[index].firstNameCheck='';
        infantsSeatData[index].lastNameCheck='';
        infantsSeatData[index].dayCheck='';
        infantsSeatData[index].monthCheck='';
        infantsSeatData[index].yearCheck='';
       if(infantsSeatData[index].firstName==='')
      {
        infantsSeatData[index].firstNameCheck='First Name is required';
        bool= false;
      }
      if(infantsSeatData[index].lastName==='')
      {
       infantsSeatData[index].lastNameCheck='Last Name is required';
       bool= false;
      }
      if(infantsSeatData[index].day==='')
      {
       infantsSeatData[index].dayCheck='Select Day';
       bool= false;
      }
      if(infantsSeatData[index].month==='')
      {
       infantsSeatData[index].monthCheck='Select Month';
       bool= false;
      }
      if(infantsSeatData[index].year==='')
      {
       infantsSeatData[index].yearCheck='Enter Year';
       bool= false;
      }
      else if(!validateYear(infantsSeatData[index].year))
    {
      infantsSeatData[index].yearCheck='Enter Valid Year';
      bool= false;
    }
      }
      setInfantsSeatArr(infantsSeatData);
      setFormValid(bool);
      return bool;
      }

const validatePosts=(posts)=>{
  const errors={};
  let bool=true;

  if(paymentMethod.cCard==='cCard'){
    


    if(!posts.cardNumber)
    {
      errors.cardNumber='cardNumber is required';
      bool=false;
    }
    else if (!validateCreditCard(posts.cardNumber))
    {
      errors.cardNumber='Enter valid card number';
      bool=false;
    }
    if(!posts.cardHolderName)
    {
      errors.cardHolderName='cardHolderName is required';
      bool=false;
    }
    if(!posts.cardExpiryMonth)
    {
      errors.cardExpiryMonth='cardExpiryMonth is required';
      bool=false;
    }
    if(!posts.cardExpiryYear)
    {
      errors.cardExpiryYear='cardExpiryYear is required';
      bool=false;
    }
    let cvvv=validateCVV(posts.cvv);
    if(!posts.cvv)
    {
      errors.cvv='SecurityCode is required';
      bool=false;
    }
    else if (!cvvv)
    {
      errors.cvv='Enter valid security code';
      bool=false;
    }

  }
    if(!posts.country)
    {
      errors.country='Country is required';
      bool=false;
    }
    if(!posts.address)
    {
      errors.address='address is required';
      bool=false;
    }
      if(!posts.city)
    {
      errors.city='city is required';
      bool=false;
    }
    if(!posts.state)
    {
      errors.state='State is required';
      bool=false;
    }
    if(!posts.pincode)
    {
      errors.pincode='Pincode is required';
      bool=false;
    }
    if(!posts.email)
    {
      errors.email='Email is required';
      bool=false;
    } 
    else if (!validateEmail(posts.email))
    {
      errors.email='Enter valid email';
      bool=false;
    }
    if(!posts.phone)
    {
      errors.phone='Phone is required';
      bool=false;
    }
    else if (!validateMobile(posts.phone))
    {
      errors.phone='Enter valid phone number';
      bool=false;
    }
    setFormValid(bool);
    return errors;
}

    const nextStep = (e) => {
      e.preventDefault();
      if (currentStep < 3) {
       // console.log(currentStep);
       
        if(currentStep===2)
        {
          let adultsCheck= validateAdults(adultsArr);
          let childsCheck= validateChilds(childsArr);
          let infantsCheck= validateInfants(infantsArr);
          let infantsSeatCheck=validateInfantsSeat(infantsSeatArr)
          if(adultsCheck===true && childsCheck===true && infantsCheck===true && infantsSeatCheck===true)
          {
            setCurrentStep(currentStep + 1);
          }
        }
        else
        {
          setCurrentStep(currentStep + 1);
        }
      }
      };
    const prevStep = (e) => {
      e.preventDefault();
      if (currentStep > 1) {
        setCurrentStep(currentStep - 1);
      }
    };
    const getStepClass = (stepNumber) => {
        return currentStep === stepNumber ? 'active' : 'inactive';
    };
    // payment stepper ends

    // Flight tab start
    const [flightActiveTab, setflightActiveTab] = useState(1);
    const handleflightActiveTab = (tabNumber) => {
      setflightActiveTab(tabNumber);
    };
    // Flight tab ends

    //refundable show hide start
    const [addRefund, setAddRefund] = useState('add');
    const handleRefundClick = (buttonName) => {
      setAddRefund(buttonName);
    };

   const getStorage=((searchId)=>{
      let data=localStorage.getItem(searchId);
           return JSON.parse(data);
    });
    const setStorage=((TraceId,bookingResponse)=>{
    let da=JSON.stringify(bookingResponse);
    localStorage.setItem(TraceId,da);
    });

    //console.log(adultsArr);
   const handleAdult=(e,i)=>{
    let newsegments = [...adultsArr];
      newsegments[i][e.target.name] = e.target.value;

      if(newsegments[i]['day'] !=='' && newsegments[i]['month'] !==''  && newsegments[i]['year'] !=='')
      {
        newsegments[i]['dob']=newsegments[i]['year']+"-"+newsegments[i]['month']+"-"+newsegments[i]['day'];
      }
    if(newsegments[i][e.target.name+'Check'])
    {
      newsegments[i][e.target.name+'Check']='';
    }
     setAdultsArr(newsegments);
   }; 
   const handleChild=(e,i)=>{
    let newsegments = [...childsArr];
    newsegments[i][e.target.name] = e.target.value;
    if(newsegments[i]['day'] !=='' && newsegments[i]['month'] !==''  && newsegments[i]['year'] !=='')
    {
      newsegments[i]['dob']=newsegments[i]['year']+"-"+newsegments[i]['month']+"-"+newsegments[i]['day'];
    }
    if(newsegments[i][e.target.name+'Check'])
    {
      newsegments[i][e.target.name+'Check']='';
    }
    setChildsArr(newsegments);
   };  
   const handleInfant=(e,i)=>{
    let newsegments = [...infantsArr];
    newsegments[i][e.target.name] = e.target.value;
    if(newsegments[i]['day'] !=='' && newsegments[i]['month'] !==''  && newsegments[i]['year'] !=='')
    {
      newsegments[i]['dob']=newsegments[i]['year']+"-"+newsegments[i]['month']+"-"+newsegments[i]['day'];
    }
    if(newsegments[i][e.target.name+'Check'])
    {
      newsegments[i][e.target.name+'Check']='';
    }
    setInfantsArr(newsegments);
   };  
   
   const handleInfantSeat=(e,i)=>{
    let newsegments = [...infantsSeatArr];
    newsegments[i][e.target.name] = e.target.value;
    if(newsegments[i]['day'] !=='' && newsegments[i]['month'] !==''  && newsegments[i]['year'] !=='')
    {
      newsegments[i]['dob']=newsegments[i]['year']+"-"+newsegments[i]['month']+"-"+newsegments[i]['day'];
    }
    if(newsegments[i][e.target.name+'Check'])
    {
      newsegments[i][e.target.name+'Check']='';
    }
    setInfantsSeatArr(newsegments);
   };  
   const checkCreditCard=(card)=>{
    let errors={};
    if (!validateCreditCard(card))
    {
      errors.cardNumber='Enter valid card number';
    }
    else {
      errors.cardNumber=''; 
    }
    setFormErrors(errors);
   };
   const checkCvv=(card)=>{
    let errors={};
    if (!validateCVV(card))
    {
      errors.cvv='Enter valid cvv';
    }
    else {
      errors.cvv=''; 
    }
    setFormErrors(errors);
   };

   const checkEmail=(card)=>{
    let errors={};
    if (!validateEmail(card))
    {
      errors.email='Enter valid email';
    }
    else {
      errors.email=''; 
    }
    setFormErrors(errors);
   };
   const checkPhone=(card)=>{
    let errors={};
    if (!validateMobile(card))
    {
      errors.phone='Enter valid mobile';
    }
    else {
      errors.phone=''; 
    }
    setFormErrors(errors);
   };


   const handleChange = (event) => {
    const { name, value } = event.target;
 //console.log(posts);
  setPosts({...posts,[name]: value});
  if(name==='cardNumber')
    {
      checkCreditCard(value)
    }
    if(name==='cvv')
    {
      checkCvv(value)
    }
    if(name==='email')
    {
      checkEmail(value)
    }
    if(name==='phone')
    {
      checkPhone(value)
    }
  //setFormErrors(validatePosts(posts));
 //console.log(posts)
    // if(secondStep===true)
    // {
    //   setFormErrors(validate(posts));
    // }
    
  }
  // useEffect(()=>{
  //   if(Object.keys(formErrors).length===0 && isSubmit===true)
  //   {
  //    console.log(posts)
  //   }
  // },[formErrors,posts])

 
  
  
 

  const handleSubmit = (event) => {
    event.preventDefault();
    
  let error=validatePosts(posts);
  setFormErrors(error);
  setIsSubmit(true);
   console.clear();
   console.log(error)
   
   if(Object.keys(error).length>0)
   {
    return false;
   }
     const initialBooking = {
      searchRequest:{},
      selectedItinerary: {},
      billingDetail: {},
      travellerDetails:{},
      pnrDetail:{},
      Airline:'',
      payMethod:'',
      Airport:'',
      markup:''
    };
    
            const pnrRequest = {
            userId:localStorage.getItem('id'),
            TraceId:validateData.itin.TraceId,
            ItinId:validateData.itin.ItinId,
            segmentId:[],
            airline:[],
            src:validateData.itin.Src,
            cId:validateData.searchRq.cId,
            website:validateData.searchRq.website,
            metaname:validateData.searchRq.metaname,
            booking:validateData.searchRq.booking,
            eType:validateData.searchRq.eType,
            travellerDetails:{},
            billingDetail:{},
    };
    const paxDetails={ adult: [], child: [], infant: [], infantSeat: [] };
    paxDetails.adult=adultsArr;
    paxDetails.child=childsArr;
    paxDetails.infant=infantsArr;
    paxDetails.infantSeat=infantsSeatArr;
    let cardExpiry=posts.cardExpiryYear+"/"+posts.cardExpiryMonth;
    posts.cardExpiry=cardExpiry;
    pnrRequest.travellerDetails=paxDetails;
    pnrRequest.billingDetail=posts;
    //console.log(validateData.itin.Flights);
    let pnrairline=[];
    validateData.itin.Flights.forEach(element => {
    pnrRequest.segmentId.push(element.ObId);
    //console.log(array);
    let array=element.Segments;
    array.forEach(element2 => {
      pnrairline.push(element2.MktAir);
      pnrairline.push(element2.OptAir);
    });
  
  
    const getUniqueValues = (array) => (
      array.reduce((accu, curValue) => (
        accu.includes(curValue) ? accu : [...accu, curValue]
      ), [])	

    )
    pnrRequest.airline=getUniqueValues(pnrairline);
//console.log(pnrRequest.airline);

    
      
    });
  
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    var pnrRq = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(pnrRequest),
      redirect: 'follow'
    };
    const url = apiBaseUrl + '/api/getPnrMulticity';
    setConfirmBooking(false);
    fetch(url, pnrRq)
      // fetch('response.json', requestOptions)
        .then(response => response.json())
        .then(result => {
                    console.log(result);
                 
                    
                    if(result.status==='OK')
                    {

                      setPnrDetailData(result);
                      initialBooking.pnrDetail=result; 
                      if(paymentMethod.cash==='cash'){
                        initialBooking.payMethod='cash'; 
                      }else{
                        initialBooking.payMethod='cCard';
                      }
                      let TraceId = searchParams.get("TraceId");
                      let storageData1=getStorage(TraceId);
                      
                      initialBooking.searchRequest=validateData.searchRq;
    initialBooking.selectedItinerary=validateData.itin;
    initialBooking.billingDetail=posts;
    initialBooking.travellerDetails=paxDetails;
    initialBooking.Airline=validateData.Airline;
    initialBooking.Airport=validateData.Airport;
    initialBooking.markup=storageData1.markup;
    //alert(storageData1.markup);
    setBookingRs(initialBooking);
    let queryString=urlPart(1);
    setStorage(validateData.itin.TraceId,initialBooking);
    navigate("/confirmation?"+ queryString, {replace: true });
                       }else{
                        setConfirmBooking(true);
                       }
              }).catch(error =>{
                setConfirmBooking(true);
                console.log(error)
              } );

  }

useEffect(()=>{
  let TraceId = searchParams.get("TraceId");
    let storageData=getStorage(TraceId);
   // console.log(storageData.markup);
    var totalFare=storageData.itinerary.TotalFare;
  setTotalFare(totalFare)
},[]);
  useEffect(() => {
    // if(paymentMethod.cash){
    //   let TraceId = searchParams.get("TraceId");
    
    //     let storageData=getStorage(TraceId);
    //     if(storageData){
    //       var totalFare=storageData.itinerary.TotalFare;
           
    //   var WalletBalacee=getStorageData('userSession')?.walletBalance;
    //  if(totalFare>WalletBalacee){
    //   setwalletBalanceInfo('Low Balance in Your wallet please ')
    // }
    //     }
    //   //console.log(walletBalanceinfo);
   
    //  } 
    var myHeaders = new Headers();
    let formData= new FormData();
    formData.append('userId',getStorageData('userSession')?.id);
    formData.append('paymentMethod','cash');

const url = apiBaseUrl + '/api/cash';
var requestOptions = {
method: 'POST',
headers: myHeaders,
body: formData,
redirect: 'follow'
};
fetch(url, requestOptions)
.then((response) => response.json())
.then((result) => {
console.log(result);
setwalletBalance(result.Balance);

let TraceId = searchParams.get("TraceId");
//console.log(TotalFare)
    let storageData=getStorage(TraceId);
    if(storageData){
      var WalletBalacee=result.Balance;
      var totalFare=storageData.itinerary.TotalFare;
 if(totalFare>WalletBalacee){
  setwalletBalanceInfo('Low Balance in Your wallet please ')
}
    }
   console.log(walletBalanceinfo);


}
); 


    
    },[]);
   // 
   //console.log(TotalFare)
//console.log(posts)
   //console.log(travlerDetail);
    //refundable show hide ends
    const errorStyle = {
      color: "red"
    };
  return (
    <>
      <HeaderUser />

      {/* <!--  / Results container \ --> */}
      <div id="resultCntr" className="position-relative">
        
        <div className="container">

          <Paymenttop backUrl={backurl} />
          <form onSubmit={handleSubmit}>
          <div className="row">
            
            <div className="col-lg-9 col-md-12">

              {/* <!-- payment wrapper--> */}
              <div className="payment__wrapper">

                <div className="timerPayment">
                  <div className="iconBlock">
                    {/* <img className="watch" src={ImagesPath.stopWatch} alt="stopWatch" /> */}
                    <AlarmOn className="icon"/>
                    <span> {minutes}M : {seconds}S</span>
                  </div>
                  <strong>Please make your payment to keep this session active.</strong>
                </div>

                {/* <!-- / payment steps Box \--> */}
                <div className="payment__stepBox">
                  <ul>
                    
                    <li className={"step1li " + getStepClass(1)}>
                      <i className="bi bi-check-circle"></i> Itinerary{" "}
                      <span className="d-none d-md-inline-block">Details</span>
                    </li>
                    
                    <li className={"step2li " + getStepClass(2)}>
                      <i className="bi bi-check-circle"></i> Traveler{" "}
                      <span className="d-none d-md-inline-block">Details</span>
                    </li>
                    
                    <li className={"step3li " + getStepClass(3)}>
                      <i className="bi bi-check-circle"></i> Payment{" "}
                      <span className="d-none d-md-inline-block">Details</span>
                    </li>
                   
                  </ul>
                </div>
                {/* <!-- \ payment steps  Box /--> */}

                {/* <!-- / Step 1 Box \--> */}
                {currentStep === 1 && (
                <div className="step_1" style={{ display: "block" }}>
                  {/* <!-- / itenary Box \--> */}
                  <div className="flight__itenaryBox">
                    {/* <!-- Flight head--> */}
                    <div className="head d-none d-lg-inline-block">
                      <h2 className="page__title">
                        <strong>Flight</strong> Detail
                      </h2>
                    </div>
                    {/* <!-- Flight detail--> */}

                    <div className="flightDetail">

                     

                      <div className="tab-content">
                        {/* <!-- Depart tab start here--> */}
                        {/* {flightActiveTab === 1 &&  */}
                           <div id="departTabcontent">
                            <PaymentTab1 validateData={validateData} />
                            </div>
                        {/* } */}

                        
                        {/* <!-- Depart tab end here--> */}

                        {/* <!-- Return Tab start here--> */}
                       
                        {/* {flightActiveTab === 2 && */}
                       
                          {/* }  */}

                        {/* <!-- Retrun tab End here--> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- \ itenary Box /--> */}

                  <div className="clearfix"></div>
                </div>
                )}
                {/* <!-- / Step 2 Box \--> */}

                {/* <!-- / Traveler Box \--> */}
                {currentStep === 2 && (
                <div className="travelerBox step_2" style={{ display: "block" }}>
                  {/* <!--traveler section 1--> */}
                 
                 {adultsArr.map((adultval,index)=>
                
                   <div className="traveler_block" key={'adults'+index}>
                    <h4 className="mb-1">Traveler {index+1} {adultval.paxType} </h4>
                    <p>
                      Please be careful - Passenger details must match your
                      passport or government-issued photo ID
                    </p>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                                          name="radio-buttons-group"
                                          defaultValue="Male">
                        <ul className="adultType inputSet">
                          <li>
                          <FormControlLabel  name="gender"  onChange={(e)=>handleAdult(e,index)}   value="Male" control={<Radio />} label="Male" />
                          </li>
                          <li>
                          <FormControlLabel name="gender"   onChange={(e)=>handleAdult(e,index)}  value="Female" control={<Radio />} label="Female" />
                          </li>
                        </ul>
                    </RadioGroup>

                    <div className="form__block">
                    <div className="row form-group">

{/* <div className="col-md-4 col-sm-6">
    <Form.Label>First name <i className="required_star">*</i></Form.Label>

   <Form.Control  value={adultval.firstName || ""} onChange={(e)=>handleAdult(e,index)} name="firstName" type="text" placeholder="First name" />
    <div style={errorStyle} >{adultval.firstNameCheck}</div> 
   </div>           */}


  <div className="col-md-4 col-sm-6">
    <Form.Label>
      First name <i className="required_star">*</i>
    </Form.Label>
    <div className={formValid===false && adultval.firstName===''?'is-error':''}>
      <Form.Control value={adultval.firstName || ""} onChange={(e)=>handleAdult(e,index)} name="firstName" type="text" placeholder="First name" />
      <i className= {(formValid===true || adultval.firstName!=='')?'':'bi bi-exclamation-circle icon'}></i>
      <div className={adultval.firstNameCheck===''?'mt-2':''}>{adultval.firstNameCheck}</div>
       </div> 
  </div>

  <div className="col-md-4 col-sm-6">
    <Form.Label>Middle name</Form.Label>
    <Form.Control value={adultval.middleName || ""} onChange={(e)=>handleAdult(e,index)} name="middleName" type="text" placeholder="Middle name" />
    
  </div>

  <div className="col-md-4 col-sm-6">
    <Form.Label>
      Last name <i className="required_star">*</i>
    </Form.Label>
    
      <Form.Control value={adultval.lastName || ""} onChange={(e)=>handleAdult(e,index)} name="lastName" type="text" placeholder="Last name" />
      <div style={errorStyle}>{adultval.lastNameCheck}</div> 
      {/* <div className="is-error mt-2">Enter Last Name</div> */}
   
  </div>





</div>

                      <div className="row form-group">
                        <div class="col-sm-4 mt-2">
                          <label class="formlabel">Wheelchair </label>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="no"
                            name="radio-buttons-group"
                          >
                              <ul className="adultType inputSet">
                                <li>
                                  <FormControlLabel value="yes" onChange={(e)=>handleAdult(e,index)} name="wheel"     control={<Radio />} label="Yes" />
                                </li>
                                <li>
                                    <FormControlLabel value="no" onChange={(e)=>handleAdult(e,index)} name="wheel"     control={<Radio />} label="No" />
                                </li>
                              </ul>
                          </RadioGroup>
                        </div>
                        
                        
                        {/* <div className="col-sm-4">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email address <i className="required_star">*</i></Form.Label>
                            <Form.Control required value={adultval.email || ""} name="email" onChange={(e)=>handleAdult(e,index)} type="email" placeholder="name@example.com" />
                          </Form.Group>
                         </div> */}

                         <div className="col-sm-4">
                         <Form.Label>
                            Date of Birth <i className="required_star">*</i>
                          </Form.Label>
                          <div className="row">
                            <div className="col">
                              <div>
                                <Form.Select  value={adultval.day || ""} onChange={(e)=>handleAdult(e,index)} name="day" aria-label="Default select example">

                                 {day.map((d,dayind)=>
                                      <option key={'opday'+dayind} value={d.value}>{d.name}</option>
                                 )}
                                 
                                  
                                </Form.Select>
                                <span style={errorStyle}>{adultval.dayCheck}</span> 
                              </div>
                            </div>

                            <div className="col p-0">
                              <div className="select-className">
                                <Form.Select value={adultval.month || ""} onChange={(e)=>handleAdult(e,index)} name="month" aria-label="Default select example">
                                {month.map((d,dayind)=>
                                      <option key={'mont'+dayind} value={d.value}>{d.name}</option>
                                 )}
                               
                                </Form.Select>
                                <span style={errorStyle}>{adultval.monthCheck}</span> 
                              </div>
                            </div>

                            <div className="col">
                              <div className="select-className">
                                <Form.Control maxLength="4" value={adultval.year || ""} onChange={(e)=>handleAdult(e,index)} name="year" type="text" placeholder="Year" />
                                <span style={errorStyle}>{adultval.yearCheck}</span> 
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-sm-4 inputSet theme2 d-none d-md-block">
                          <div className="travel_deal_checkbox">
                            <FormControlLabel value={adultval.travelDeal || ""} onChange={(e)=>handleAdult(e,index)}  name="travelDeal"  control={<Checkbox />} label="Send me the latest travel deals, special offers,
                                coupons." />
                          </div>
                        </div> */}

                        
                      
                        <div class="col-sm-4 mt-2">
                          <label class="formlabel">Meal </label>
                          <div class="select-class">
                          <Form.Select value={adultval.meal || ""} onChange={(e)=>handleAdult(e,index)} name="meal" class="form-control">
                                    <option value="">Select Meal </option>
                                    <option value="Veg">Veg </option>
                                    <option value="nonveg">Non veg</option>
                                    <option value="childmeal">Child meal</option>
                                    <option value="gluttonfree">Glutton free</option>
                           </Form.Select>
                            </div>
                        </div>
                      </div>
                    {api_travelType?.includes(2)? 
      <div className="row form-group">
      <div className="col-md-4 col-sm-6">
         <Form.Label>Passport Number</Form.Label>
         <Form.Control value={adultval.passportNumber || ""} onChange={(e)=>handleAdult(e,index)} name="passportNumber" type="text" placeholder="Passport Number" />
         <span style={errorStyle}>{adultval.passportNumberCheck}</span> 
     </div>
     <div className="col-md-4 col-sm-6">
         <Form.Label>Passport City</Form.Label>
         <Form.Control value={adultval.passportCity || ""} onChange={(e)=>handleAdult(e,index)} name="passportCity" type="text" placeholder="Passport City" />
         <span style={errorStyle}>{adultval.passportCityCheck}</span> 
     </div>
     <div className="col-md-4 col-sm-6">
         <Form.Label>Passport Country</Form.Label>
         <Form.Control value={adultval.passportCountry || ""} onChange={(e)=>handleAdult(e,index)} name="passportCountry" type="text" placeholder="Passport Country" />
         <span style={errorStyle}>{adultval.passportCountryCheck}</span> 
     </div>
     <div className="col-md-4 col-sm-6">
         <Form.Label>Nationality</Form.Label>
         <Form.Control value={adultval.passportNationality || ""} onChange={(e)=>handleAdult(e,index)} name="passportNationality" type="text" placeholder="Nationality" />
         <span style={errorStyle}>{adultval.passportNationalityCheck}</span> 
     </div>
     <div className="col">
                              <Form.Label>
                                Passport Expiry Month <i className="required_star">*</i>
                              </Form.Label>
                              <Form.Select required name="passportExpiryMonth" value={adultval.passportExpiryMonth || ""} onChange={(e)=>handleAdult(e,index)} aria-label="Default select example">
                                {month.map((val,index)=>
                                 <option key={'passportExpiryMonth'+index} value={val.value}>{val.name}</option>
                                )}
                              </Form.Select>
                              <span style={errorStyle}>{adultval.passportExpiryMonthCheck}</span> 
                              
                            </div>

                            <div className="col p-0">
                              <Form.Label>
                              Passport Expiry Year<i className="required_star">*</i>
                              </Form.Label>
                              <Form.Select required name="passportExpiryYear" value={adultval.passportExpiryYear || ""} onChange={(e)=>handleAdult(e,index)} aria-label="Default select example">
                              
                              {carExpiryYear.map((val,index)=>
                                 <option key={'passportExpiryYear'+index} value={val.value}>{val.name}</option>
                                
                                )}
                               
                              </Form.Select>
                              <span style={errorStyle}>{adultval.passportExpiryYearCheck}</span> 
                              </div>

                </div>
                      

                      :''}
                      

                      
                    </div>
                  </div>
                 )}
                  
                  {childsArr.map((Childval,index)=>
                
                <div className="traveler_block" key={'Childs'+index}>
                <h4 className="mb-1">Traveler {index+1} {Childval.paxType} </h4>
                <p>
                  Please be careful - Passenger details must match your
                  passport or government-issued photo ID
                </p>

             
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                                      name="radio-buttons-group"
                                      defaultValue="Male">
                    <ul className="adultType inputSet">
                      <li>
                      <FormControlLabel  name="gender"  onChange={(e)=>handleChild(e,index)}   value="Male" control={<Radio />} label="Male" />
                      </li>
                      <li>
                      <FormControlLabel name="gender"   onChange={(e)=>handleChild(e,index)}  value="Female" control={<Radio />} label="Female" />
                      </li>
                    </ul>
                </RadioGroup>

                <div className="form__block">
                <div className="row form-group">

<div className="col-md-4 col-sm-6">
<Form.Label>First name <i className="required_star">*</i></Form.Label>
<Form.Control  value={Childval.firstName || ""} onChange={(e)=>handleChild(e,index)} name="firstName" type="text" placeholder="First name" />
<div style={errorStyle} >{Childval.firstNameCheck}</div> 
</div>          


{/* <div className="col-md-4 col-sm-6">
<Form.Label>
  First name <i className="required_star">*</i>
</Form.Label>

<div className="is-error">
  <Form.Control onChange={(e)=>handleChild(e,index)} name="firstName" type="text" placeholder="First name" />
 
  <i className="bi bi-exclamation-circle icon"></i>
  <div className=" mt-2">Enter First Name</div>
</div>



</div> */}

<div className="col-md-4 col-sm-6">
<Form.Label>Middle name</Form.Label>
<Form.Control value={Childval.middleName || ""} onChange={(e)=>handleChild(e,index)} name="middleName" type="text" placeholder="Middle name" />

</div>

<div className="col-md-4 col-sm-6">
<Form.Label>
  Last name <i className="required_star">*</i>
</Form.Label>

  <Form.Control value={Childval.lastName || ""} onChange={(e)=>handleChild(e,index)} name="lastName" type="text" placeholder="Last name" />
  <div style={errorStyle}>{Childval.lastNameCheck}</div> 
  {/* <div className="is-error mt-2">Enter Last Name</div> */}

</div>





</div>
<div className="row form-group">
                        <div class="col-sm-6 mt-2">
                          <label class="formlabel">Wheelchair <i class="required_star">*</i></label>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="no"
                            name="radio-buttons-group"
                          >
                              <ul className="adultType inputSet">
                                <li>
                                  <FormControlLabel value="yes" onChange={(e)=>handleChild(e,index)} name="wheel"  control={<Radio />} label="Yes" />
                                </li>
                                <li>
                                    <FormControlLabel value="no"  onChange={(e)=>handleChild(e,index)} name="wheel"  control={<Radio />} label="No" />
                                </li>
                              </ul>
                          </RadioGroup>
                        </div>
                        <div class="col-sm-6 mt-2">
                          <label class="formlabel">Meal <i class="required_star">*</i></label>
                          <div class="select-class">
                          <Form.Select value={Childval.meal || ""} onChange={(e)=>handleChild(e,index)} name="meal" class="form-control">
                                    <option value="">Select Meal </option>
                                    <option value="Veg">Veg </option>
                                    <option value="nonveg">Non veg</option>
                                    <option value="childmeal">Child meal</option>
                                    <option value="gluttonfree">Glutton free</option>
                           </Form.Select>
                            </div>
                        </div>
                      </div>
                  <div className="row">
                    
                    {/* <div className="col-sm-4">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Email address <i className="required_star">*</i></Form.Label>
                        <Form.Control required value={Childval.email || ""} name="email" onChange={(e)=>handleChild(e,index)} type="email" placeholder="name@example.com" />
                      </Form.Group>
                     </div> */}

                     <div className="col-sm-4">
                     <Form.Label>
                        Date of Birth <i className="required_star">*</i>
                      </Form.Label>
                      <div className="row">
                        <div className="col">
                          <div>
                            <Form.Select  value={Childval.day || ""} onChange={(e)=>handleChild(e,index)} name="day" aria-label="Default select example">

                             {day.map((d,dayind)=>
                                  <option key={'opday'+dayind} value={d.value}>{d.name}</option>
                             )}
                             
                              
                            </Form.Select>
                            <span style={errorStyle}>{Childval.dayCheck}</span> 
                          </div>
                        </div>

                        <div className="col p-0">
                          <div className="select-className">
                            <Form.Select value={Childval.month || ""} onChange={(e)=>handleChild(e,index)} name="month" aria-label="Default select example">
                            {month.map((d,dayind)=>
                                  <option key={'msont'+dayind} value={d.value}>{d.name}</option>
                             )}
                           
                            </Form.Select>
                            <span style={errorStyle}>{Childval.monthCheck}</span> 
                          </div>
                        </div>

                        <div className="col">
                          <div className="select-className">
                            <Form.Control maxLength="4" value={Childval.year || ""} onChange={(e)=>handleChild(e,index)} name="year" type="text" placeholder="Year" />
                            <span style={errorStyle}>{Childval.yearCheck}</span> 
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4 inputSet theme2 d-none d-md-block">
                      <div className="travel_deal_checkbox">
                        <FormControlLabel value={Childval.travelDeal || ""} onChange={(e)=>handleChild(e,index)}  name="travelDeal"  control={<Checkbox />} label="Send me the latest travel deals, special offers,
                            coupons." />
                      </div>
                    </div>

                    
                  </div>
                  

                  
                </div>
              </div>
              )}

               {infantsArr.map((Infantval,index)=>
                
                <div className="traveler_block" key={'Infants'+index}>
  <h4 className="mb-1">Traveler {index+1} {Infantval.paxType} </h4>
  <p>
    Please be careful - Passenger details must match your
    passport or government-issued photo ID
  </p>
  <RadioGroup
    aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        defaultValue="Male">
      <ul className="adultType inputSet">
        <li>
        <FormControlLabel  name="gender"  onChange={(e)=>handleInfant(e,index)}   value="Male" control={<Radio />} label="Male" />
        </li>
        <li>
        <FormControlLabel name="gender"   onChange={(e)=>handleInfant(e,index)}  value="Female" control={<Radio />} label="Female" />
        </li>
      </ul>
  </RadioGroup>

  <div className="form__block">
  <div className="row form-group">

{/* <div className="col-md-4 col-sm-6">
<Form.Label>First name <i className="required_star">*</i></Form.Label>

<Form.Control  value={Infantval.firstName || ""} onChange={(e)=>handleInfant(e,index)} name="firstName" type="text" placeholder="First name" />
<div style={errorStyle} >{Infantval.firstNameCheck}</div> 
</div>           */}


<div className="col-md-4 col-sm-6">
<Form.Label>
First name <i className="required_star">*</i>
</Form.Label>
<div className={formValid===false && Infantval.firstName===''?'is-error':''}>
<Form.Control value={Infantval.firstName || ""} onChange={(e)=>handleInfant(e,index)} name="firstName" type="text" placeholder="First name" />
<i className= {(formValid===true || Infantval.firstName!=='')?'':'bi bi-exclamation-circle icon'}></i>
<div className={Infantval.firstNameCheck===''?'mt-2':''}>{Infantval.firstNameCheck}</div>
</div> 
</div>

<div className="col-md-4 col-sm-6">
<Form.Label>Middle name</Form.Label>
<Form.Control value={Infantval.middleName || ""} onChange={(e)=>handleInfant(e,index)} name="middleName" type="text" placeholder="Middle name" />

</div>

<div className="col-md-4 col-sm-6">
<Form.Label>
Last name <i className="required_star">*</i>
</Form.Label>

<Form.Control value={Infantval.lastName || ""} onChange={(e)=>handleInfant(e,index)} name="lastName" type="text" placeholder="Last name" />
<div style={errorStyle}>{Infantval.lastNameCheck}</div> 
{/* <div className="is-error mt-2">Enter Last Name</div> */}

</div>





</div>
<div className="row form-group">
                        <div class="col-sm-6 mt-2">
                          <label class="formlabel">Wheelchair <i class="required_star">*</i></label>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="no"
                            name="radio-buttons-group"
                          >
                              <ul className="adultType inputSet">
                                <li>
                                  <FormControlLabel value="yes"  onChange={(e)=>handleInfant(e,index)} name="wheel"  control={<Radio />} label="Yes" />
                                </li>
                                <li>
                                    <FormControlLabel value="no"  onChange={(e)=>handleInfant(e,index)} name="wheel"  control={<Radio />} label="No" />
                                </li>
                              </ul>
                          </RadioGroup>
                        </div>
                        <div class="col-sm-6 mt-2">
                          <label class="formlabel">Meal <i class="required_star">*</i></label>
                          <div class="select-class">
                          <Form.Select value={Infantval.meal || ""} onChange={(e)=>handleInfant(e,index)} name="meal" class="form-control">
                                    <option value="">Select Meal </option>
                                    <option value="Veg">Veg </option>
                                    <option value="nonveg">Non veg</option>
                                    <option value="childmeal">Child meal</option>
                                    <option value="gluttonfree">Glutton free</option>
                           </Form.Select>
                            </div>
                        </div>
                      </div>
    <div className="row">
      
      {/* <div className="col-sm-4">
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Email address <i className="required_star">*</i></Form.Label>
          <Form.Control required value={Infantval.email || ""} name="email" onChange={(e)=>handleInfant(e,index)} type="email" placeholder="name@example.com" />
        </Form.Group>
       </div> */}

       <div className="col-sm-4">
       <Form.Label>
          Date of Birth <i className="required_star">*</i>
        </Form.Label>
        <div className="row">
          <div className="col">
            <div>
              <Form.Select  value={Infantval.day || ""} onChange={(e)=>handleInfant(e,index)} name="day" aria-label="Default select example">

               {day.map((d,dayind)=>
                    <option key={'opdday'+dayind} value={d.value}>{d.name}</option>
               )}
               
                
              </Form.Select>
              <span style={errorStyle}>{Infantval.dayCheck}</span> 
            </div>
          </div>

          <div className="col p-0">
            <div className="select-className">
              <Form.Select value={Infantval.month || ""} onChange={(e)=>handleInfant(e,index)} name="month" aria-label="Default select example">
              {month.map((d,dayind)=>
                    <option key={'mossnt'+dayind} value={d.value}>{d.name}</option>
               )}
             
              </Form.Select>
              <span style={errorStyle}>{Infantval.monthCheck}</span> 
            </div>
          </div>

          <div className="col">
            <div className="select-className">
              <Form.Control maxLength="4" value={Infantval.year || ""} onChange={(e)=>handleInfant(e,index)} name="year" type="text" placeholder="Year" />
              <span style={errorStyle}>{Infantval.yearCheck}</span> 
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-4 inputSet theme2 d-none d-md-block">
        <div className="travel_deal_checkbox">
          <FormControlLabel value={Infantval.travelDeal || ""} onChange={(e)=>handleInfant(e,index)}  name="travelDeal"  control={<Checkbox />} label="Send me the latest travel deals, special offers,
              coupons." />
        </div>
      </div>

      
    </div>
    

    
  </div>
</div>
              )}


              {infantsSeatArr?.map((Infantval,index)=>
                
                <div className="traveler_block" key={'InfantSeat'+index}>
                <h4 className="mb-1">Traveler {index+1} {Infantval.paxType} </h4>
                <p>
                  Please be careful - Passenger details must match your
                  passport or government-issued photo ID
                </p>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                                      name="radio-buttons-group"
                                      defaultValue="Male">
                    <ul className="adultType inputSet">
                      <li>
                      <FormControlLabel  name="gender"  onChange={(e)=>handleInfantSeat(e,index)}   value="Male" control={<Radio />} label="Male" />
                      </li>
                      <li>
                      <FormControlLabel name="gender"   onChange={(e)=>handleInfantSeat(e,index)}  value="Female" control={<Radio />} label="Female" />
                      </li>
                    </ul>
                </RadioGroup>
              
                <div className="form__block">
                <div className="row form-group">
              
              {/* <div className="col-md-4 col-sm-6">
              <Form.Label>First name <i className="required_star">*</i></Form.Label>
              
              <Form.Control  value={Infantval.firstName || ""} onChange={(e)=>handleInfantSeat(e,index)} name="firstName" type="text" placeholder="First name" />
              <div style={errorStyle} >{Infantval.firstNameCheck}</div> 
              </div>           */}
              
              
              <div className="col-md-4 col-sm-6">
              <Form.Label>
              First name <i className="required_star">*</i>
              </Form.Label>
              <div className={formValid===false && Infantval.firstName===''?'is-error':''}>
              <Form.Control value={Infantval.firstName || ""} onChange={(e)=>handleInfantSeat(e,index)} name="firstName" type="text" placeholder="First name" />
              <i className= {(formValid===true || Infantval.firstName!=='')?'':'bi bi-exclamation-circle icon'}></i>
              <div className={Infantval.firstNameCheck===''?'mt-2':''}>{Infantval.firstNameCheck}</div>
              </div> 
              </div>
              
              <div className="col-md-4 col-sm-6">
              <Form.Label>Middle name</Form.Label>
              <Form.Control value={Infantval.middleName || ""} onChange={(e)=>handleInfantSeat(e,index)} name="middleName" type="text" placeholder="Middle name" />
              
              </div>
              
              <div className="col-md-4 col-sm-6">
              <Form.Label>
              Last name <i className="required_star">*</i>
              </Form.Label>
              
              <Form.Control value={Infantval.lastName || ""} onChange={(e)=>handleInfantSeat(e,index)} name="lastName" type="text" placeholder="Last name" />
              <div style={errorStyle}>{Infantval.lastNameCheck}</div> 
              {/* <div className="is-error mt-2">Enter Last Name</div> */}
              
              </div>
              
              
              
              
              
              </div>

                     <div className="row form-group">
                        <div class="col-sm-6 mt-2">
                          <label class="formlabel">Wheelchair <i class="required_star">*</i></label>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="no"
                            name="radio-buttons-group"
                          >
                              <ul className="adultType inputSet">
                                <li>
                                  <FormControlLabel value="yes"  onChange={(e)=>handleInfantSeat(e,index)} name="wheel"  control={<Radio />} label="Yes" />
                                </li>
                                <li>
                                    <FormControlLabel value="no"  onChange={(e)=>handleInfantSeat(e,index)} name="wheel"  control={<Radio />} label="No" />
                                </li>
                              </ul>
                          </RadioGroup>
                        </div>
                        <div class="col-sm-6 mt-2">
                          <label class="formlabel">Meal <i class="required_star">*</i></label>
                          <div class="select-class">
                          <Form.Select value={Infantval.meal || ""} onChange={(e)=>handleInfantSeat(e,index)} name="meal" class="form-control">
                                    <option value="">Select Meal </option>
                                    <option value="Veg">Veg </option>
                                    <option value="nonveg">Non veg</option>
                                    <option value="childmeal">Child meal</option>
                                    <option value="gluttonfree">Glutton free</option>
                           </Form.Select>
                            </div>
                        </div>
                      </div>
                  <div className="row">
                    
                    {/* <div className="col-sm-4">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Email address <i className="required_star">*</i></Form.Label>
                        <Form.Control required value={Infantval.email || ""} name="email" onChange={(e)=>handleInfantSeat(e,index)} type="email" placeholder="name@example.com" />
                      </Form.Group>
                     </div> */}
              
                     <div className="col-sm-4">
                     <Form.Label>
                        Date of Birth <i className="required_star">*</i>
                      </Form.Label>
                      <div className="row">
                        <div className="col">
                          <div>
                            <Form.Select  value={Infantval.day || ""} onChange={(e)=>handleInfantSeat(e,index)} name="day" aria-label="Default select example">
              
                             {day.map((d,dayind)=>
                                  <option key={'ofpdday'+dayind} value={d.value}>{d.name}</option>
                             )}
                             
                              
                            </Form.Select>
                            <span style={errorStyle}>{Infantval.dayCheck}</span> 
                          </div>
                        </div>
              
                        <div className="col p-0">
                          <div className="select-className">
                            <Form.Select value={Infantval.month || ""} onChange={(e)=>handleInfantSeat(e,index)} name="month" aria-label="Default select example">
                            {month.map((d,dayind)=>
                                  <option key={'monsst'+dayind} value={d.value}>{d.name}</option>
                             )}
                           
                            </Form.Select>
                            <span style={errorStyle}>{Infantval.monthCheck}</span> 
                          </div>
                        </div>
              
                        <div className="col">
                          <div className="select-className">
                            <Form.Control maxLength="4" value={Infantval.year || ""} onChange={(e)=>handleInfantSeat(e,index)} name="year" type="text" placeholder="Year" />
                            <span style={errorStyle}>{Infantval.yearCheck}</span> 
                          </div>
                        </div>
                      </div>
                    </div>
              
                    <div className="col-sm-4 inputSet theme2 d-none d-md-block">
                      <div className="travel_deal_checkbox">
                        <FormControlLabel value={Infantval.travelDeal || ""} onChange={(e)=>handleInfantSeat(e,index)}  name="travelDeal"  control={<Checkbox />} label="Send me the latest travel deals, special offers,
                            coupons." />
                      </div>
                    </div>
              
                    
                  </div>
                  
              
                  
                </div>
              </div>
              )}
                 

                  
                  {/* <!--traveler section 1--> */}
               

                  {/* <!-- Checked baggage Start here--> */}

              <Baggage  bag={false} />



              {/* <!-- Checked baggage End --> */}

                  <div className="clearfix"></div>
                </div>
                )}
                {/* <!-- \ Traveler Box /--> */}

                {/* <!-- / payment Box \--> */}
                {currentStep === 3 && (

                <div className="paymentBox step_3" style={{ display: "block" }}>
                  {/* <!--payment section 1--> */}
                  <div className="payment_block">
                    <h4 className="mb-1">Payment Details</h4>

                    <p>
                      All card information is fully encrypted, secure and
                      protected.
                    </p>

                    <div className="form__block">
                      <div className="row form-group">
                       {walletBalance > TotalFare && walletBalance!=='' && paymentMode.cheque!==''  ?
                        <div className="col-sm-12 mb-3">
                        <p> <strong>Choose one option out of two.</strong></p>
                        <div className="d-flex">
                          <div class="form-check radioBtn me-4">                                 
                            <input class="form-check-input" type="radio" name="cCard" value="cCard"  checked={paymentMethod.cCard} onChange={(e)=>handlePaymentMethod(e)} id="CreditCard"/>
                            <label class="form-check-label" for="CreditCard">
                              CreditCard
                            </label>
                          </div>
                          <div class="form-check radioBtn me-4">
                            <input class="form-check-input" type="radio" name="cash" value="cash"  checked={paymentMethod.cash} onChange={(e)=>handlePaymentMethod(e)} id="Cash"/>
                            <label class="form-check-label" for="Cash">
                              Cash
                            </label>
                          </div>
                          {/* <div class="form-check radioBtn me-4">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                            <label class="form-check-label" for="flexRadioDefault2">
                              Cheque
                            </label>
                          </div>
                          <div class="form-check radioBtn">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="Hold"/>
                            <label class="form-check-label" for="Hold">
                              Hold
                            </label>
                          </div> */}
                        </div>
                        </div>
                        :''}
                       { paymentMethod.cCard?
                        <div className="col-sm-6">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Card number <i className="required_star">*</i></Form.Label>
                            <Form.Control required maxLength="16" name="cardNumber" value={posts.cardNumber || ""} onChange={(event)=>handleChange(event)} type="tel" className="cc-number" placeholder="" />
                          </Form.Group>
                          {(formErrors.cardNumber) && <div style={errorStyle}>{formErrors.cardNumber}</div>}
                        </div>
                         :''}
                         {paymentMethod.cCard?
                        <div className="col-sm-6">
                          <label className="mt-sm-1 mb-sm-0 d-block">
                            Available payment methods
                          </label>
                          <img
                            src={ImagesPath.card}
                            alt=""
                            className="mt-1"
                          />
                        </div>
                        :''}
                       
                      </div>
                      {paymentMethod.cCard?
                      <div className="row form-group">
                        <div className="col-sm-6">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Card holder’s name <i className="required_star">*</i></Form.Label>
                            <Form.Control required name="cardHolderName" value={posts.cardHolderName || ""} onChange={(event)=>handleChange(event)} type="tel" className="cc-number" placeholder="" />
                            {(formErrors.cardHolderName) && <div style={errorStyle}>{formErrors.cardHolderName}</div>}
                          </Form.Group>
                        </div>
                      </div>
                      :''}
                       { paymentMethod.cCard?
                      <div className="row form-group">
                        <div className="col-sm-6">
                          <div className="row">
                            <div className="col">
                              <Form.Label>
                                Month <i className="required_star">*</i>
                              </Form.Label>
                              <Form.Select required name="cardExpiryMonth" value={posts.cardExpiryMonth || ""} onChange={(event)=>handleChange(event)} aria-label="Default select example">
                                {month.map((val,index)=>
                                 <option key={'cardExpiryMonth'+index} value={val.value}>{val.name}</option>
                                
                                )}
                              </Form.Select>
                              {(formErrors.cardExpiryMonth || formValid===false) && <div style={errorStyle}>{formErrors.cardExpiryMonth}</div>}
                              
                            </div>

                            <div className="col p-0">
                              <Form.Label>
                                Year <i className="required_star">*</i>
                              </Form.Label>
                              <Form.Select required name="cardExpiryYear" value={posts.cardExpiryYear || ""} onChange={(event)=>handleChange(event)} aria-label="Default select example">
                              
                              {carExpiryYear.map((val,index)=>
                                 <option key={'carExpiryYear'+index} value={val.value}>{val.name}</option>
                                
                                )}
                               
                              </Form.Select>
                              {(formErrors.cardExpiryYear || formValid===false) && <div style={errorStyle}>{formErrors.cardExpiryYear}</div>}
                              </div>

                            <div className="col">
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>CVV <i className="required_star">*</i></Form.Label>
                                <Form.Control maxLength={4}  required name="cvv" value={posts.cvv || ""} onChange={(event)=>handleChange(event)} type="tel" className="cc-number" placeholder="" />
                                {formErrors.cvv  && <div style={errorStyle}>{formErrors.cvv}</div>}
                              
                              </Form.Group>
                            </div>
                          </div>
                      </div>
                     
                        <div className="col-sm-6">
                          <div className="card__zoom">
                            <CardZoom/>
                            Digit number from your card
                          </div>
                        </div>
                      </div>
                       :''}
                      
                    </div>
                  </div>

                  {/* <!--Billing Details --> */}
                  <div className="billing_block hr-line">
                    <h4>Billing Details</h4>

                    <p>As per Bank records or credit card company</p>

                    <div className="form__block">
                      <div className="row">
                        <div className="col-sm-6">
                          <Form.Label>
                            Country <i className="required_star">*</i>
                          </Form.Label>
                          <Form.Select required name="country" value={posts.country || ""} onChange={(event)=>handleChange(event)}  aria-label="Default select example">
                          <option value="">Select Country</option>
                                      <option value="US">United States of America</option>
     <option value="AF">Afghanistan</option>
     <option value="AL">Albania</option>
     <option value="DZ">Algeria</option>
     <option value="AS">American Samoa</option>
     <option value="AD">Andorra</option>
     <option value="AO">Angola</option>
     <option value="AI">Anguilla</option>
     <option value="AQ">Antarctica</option>
     <option value="AG">Antigua and Barbuda</option>
     <option value="AR">Argentina</option>
     <option value="AM">Armenia</option>
     <option value="AW">Aruba</option>
     <option value="AU">Australia</option>
     <option value="AT">Austria</option>
     <option value="AZ">Azerbaijan</option>
     <option value="BS">Bahamas</option>
     <option value="BH">Bahrain</option>
     <option value="BD">Bangladesh</option>
     <option value="BB">Barbados</option>
     <option value="BY">Belarus</option>
     <option value="BE">Belgium</option>
     <option value="BZ">Belize</option>
     <option value="BJ">Benin</option>
     <option value="BM">Bermuda</option>
     <option value="BT">Bhutan</option>
     <option value="BO">Bolivia (Plurinational State of)</option>
     <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
     <option value="BA">Bosnia and Herzegovina</option>
     <option value="BW">Botswana</option>
     <option value="BV">Bouvet Island</option>
     <option value="BR">Brazil</option>
     <option value="IO">British Indian Ocean Territory</option>
     <option value="BN">Brunei Darussalam</option>
     <option value="BG">Bulgaria</option>
     <option value="BF">Burkina Faso</option>
     <option value="BI">Burundi</option>
     <option value="CV">Cabo Verde</option>
     <option value="KH">Cambodia</option>
     <option value="CM">Cameroon</option>
     <option value="CA">Canada</option>
     <option value="KY">Cayman Islands (the)</option>
     <option value="CF">Central African Republic (the)</option>
     <option value="TD">Chad</option>
     <option value="CL">Chile</option>
     <option value="CN">China</option>
     <option value="CX">Christmas Island</option>
     <option value="CC">Cocos (Keeling) Islands</option>
     <option value="CO">Colombia</option>
     <option value="KM">Comoros</option>
     <option value="CD">Congo (the Democratic Republic of the)</option>
     <option value="CG">Congo</option>
     <option value="CK">Cook Islands</option>
     <option value="CR">Costa Rica</option>
     <option value="HR">Croatia</option>
     <option value="CU">Cuba</option>
     <option value="CW">Cura?ao</option>
     <option value="CY">Cyprus</option>
     <option value="CZ">Czechia</option>
     <option value="CI">C?te d'Ivoire</option>
     <option value="DK">Denmark</option>
     <option value="DJ">Djibouti</option>
     <option value="DM">Dominica</option>
     <option value="DO">Dominican Republic (the)</option>
     <option value="EC">Ecuador</option>
     <option value="EG">Egypt</option>
     <option value="SV">El Salvador</option>
     <option value="GQ">Equatorial Guinea</option>
     <option value="ER">Eritrea</option>
     <option value="EE">Estonia</option>
     <option value="SZ">Eswatini</option>
     <option value="ET">Ethiopia</option>
     <option value="FK">Falkland Islands[Malvinas]</option>
     <option value="FO">Faroe Islands</option>
     <option value="FJ">Fiji</option>
     <option value="FI">Finland</option>
     <option value="FR">France</option>
     <option value="GF">French Guiana</option>
     <option value="PF">French Polynesia</option>
     <option value="TF">French Southern Territories (the)</option>
     <option value="GA">Gabon</option>
     <option value="GM">Gambia</option>
     <option value="GE">Georgia</option>
     <option value="DE">Germany</option>
     <option value="GH">Ghana</option>
     <option value="GI">Gibraltar</option>
     <option value="GR">Greece</option>
     <option value="GL">Greenland</option>
     <option value="GD">Grenada</option>
     <option value="GP">Guadeloupe</option>
     <option value="GU">Guam</option>
     <option value="GT">Guatemala</option>
     <option value="GG">Guernsey</option>
     <option value="GN">Guinea</option>
     <option value="GW">Guinea-Bissau</option>
     <option value="GY">Guyana</option>
     <option value="HT">Haiti</option>
     <option value="HM">Heard Island and McDonald Islands</option>
     <option value="VA">Holy See</option>
     <option value="HN">Honduras</option>
     <option value="HK">Hong Kong</option>
     <option value="HU">Hungary</option>
     <option value="IS">Iceland</option>
     <option value="IN">India</option>
     <option value="ID">Indonesia</option>
     <option value="IR">Iran (Islamic Republic of)</option>
     <option value="IQ">Iraq</option>
     <option value="IE">Ireland</option>
     <option value="IM">Isle of Man</option>
     <option value="IL">Israel</option>
     <option value="IT">Italy</option>
     <option value="JM">Jamaica</option>
     <option value="JP">Japan</option>
     <option value="JE">Jersey</option>
     <option value="JO">Jordan</option>
     <option value="KZ">Kazakhstan</option>
     <option value="KE">Kenya</option>
     <option value="KI">Kiribati</option>
     <option value="KP">Korea (the Democratic People's Republic of)</option>
     <option value="KR">Korea (the Republic of)</option>
     <option value="KW">Kuwait</option>
     <option value="KG">Kyrgyzstan</option>
     <option value="LA">Lao People's Democratic Republic</option>
     <option value="LV">Latvia</option>
     <option value="LB">Lebanon</option>
     <option value="LS">Lesotho</option>
     <option value="LR">Liberia</option>
     <option value="LY">Libya</option>
     <option value="LI">Liechtenstein</option>
     <option value="LT">Lithuania</option>
     <option value="LU">Luxembourg</option>
     <option value="MO">Macao</option>
     <option value="MG">Madagascar</option>
     <option value="MW">Malawi</option>
     <option value="MY">Malaysia</option>
     <option value="MV">Maldives</option>
     <option value="ML">Mali</option>
     <option value="MT">Malta</option>
     <option value="MH">Marshall Islands</option>
     <option value="MQ">Martinique</option>
     <option value="MR">Mauritania</option>
     <option value="MU">Mauritius</option>
     <option value="YT">Mayotte</option>
     <option value="MX">Mexico</option>
     <option value="FM">Micronesia (Federated States of)</option>
     <option value="MD">Moldova (the Republic of)</option>
     <option value="MC">Monaco</option>
     <option value="MN">Mongolia</option>
     <option value="ME">Montenegro</option>
     <option value="MS">Montserrat</option>
     <option value="MA">Morocco</option>
     <option value="MZ">Mozambique</option>
     <option value="MM">Myanmar</option>
     <option value="NA">Namibia</option>
     <option value="NR">Nauru</option>
     <option value="NP">Nepal</option>
     <option value="NL">Netherlands</option>
     <option value="NC">New Caledonia</option>
     <option value="NZ">New Zealand</option>
     <option value="NI">Nicaragua</option>
     <option value="NE">Niger</option>
     <option value="NG">Nigeria</option>
     <option value="NU">Niue</option>
     <option value="NF">Norfolk Island</option>
     <option value="MP">Northern Mariana Islands</option>
     <option value="NO">Norway</option>
     <option value="OM">Oman</option>
     <option value="PK">Pakistan</option>
     <option value="PW">Palau</option>
     <option value="PS">Palestine, State of</option>
     <option value="PA">Panama</option>
     <option value="PG">Papua New Guinea</option>
     <option value="PY">Paraguay</option>
     <option value="PE">Peru</option>
     <option value="PH">Philippines</option>
     <option value="PN">Pitcairn</option>
     <option value="PL">Poland</option>
     <option value="PT">Portugal</option>
     <option value="PR">Puerto Rico</option>
     <option value="QA">Qatar</option>
     <option value="MK">Republic of North Macedonia</option>
     <option value="RO">Romania</option>
     <option value="RU">Russian Federation (the)</option>
     <option value="RW">Rwanda</option>
     <option value="RE">Runion</option>
     <option value="BL">Saint Barthlemy</option>
     <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
     <option value="KN">Saint Kitts and Nevis</option>
     <option value="LC">Saint Lucia</option>
     <option value="MF">Saint Martin (French part)</option>
     <option value="PM">Saint Pierre and Miquelon</option>
     <option value="VC">Saint Vincent and the Grenadines</option>
     <option value="WS">Samoa</option>
     <option value="SM">San Marino</option>
     <option value="ST">Sao Tome and Principe</option>
     <option value="SA">Saudi Arabia</option>
     <option value="SN">Senegal</option>
     <option value="RS">Serbia</option>
     <option value="SC">Seychelles</option>
     <option value="SL">Sierra Leone</option>
     <option value="SG">Singapore</option>
     <option value="SX">Sint Maarten (Dutch part)</option>
     <option value="SK">Slovakia</option>
     <option value="SI">Slovenia</option>
     <option value="SB">Solomon Islands</option>
     <option value="SO">Somalia</option>
     <option value="ZA">South Africa</option>
     <option value="GS">South Georgia and the South Sandwich Islands</option>
     <option value="SS">South Sudan</option>
     <option value="ES">Spain</option>
     <option value="LK">Sri Lanka</option>
     <option value="SD">Sudan</option>
     <option value="SR">Suriname</option>
     <option value="SJ">Svalbard and Jan Mayen</option>
     <option value="SE">Sweden</option>
     <option value="CH">Switzerland</option>
     <option value="SY">Syrian Arab Republic</option>
     <option value="TW">Taiwan (Province of China)</option>
     <option value="TJ">Tajikistan</option>
     <option value="TZ">Tanzania, United Republic of</option>
     <option value="TH">Thailand</option>
     <option value="TL">Timor-Leste</option>
     <option value="TG">Togo</option>
     <option value="TK">Tokelau</option>
     <option value="TO">Tonga</option>
     <option value="TT">Trinidad and Tobago</option>
     <option value="TN">Tunisia</option>
     <option value="TR">Turkey</option>
     <option value="TM">Turkmenistan</option>
     <option value="TC">Turks and Caicos Islands</option>
     <option value="TV">Tuvalu</option>
     <option value="UG">Uganda</option>
     <option value="UA">Ukraine</option>
     <option value="AE">United Arab Emirates</option>
     <option value="GB">United Kingdom of Great Britain and Northern Ireland</option>
     <option value="UM">United States Minor Outlying Islands</option>
     <option value="UY">Uruguay</option>
     <option value="UZ">Uzbekistan</option>
     <option value="VU">Vanuatu</option>
     <option value="VE">Venezuela (Bolivarian Republic of)</option>
     <option value="VN">Viet Nam</option>
     <option value="VG">Virgin Islands (British)</option>
     <option value="VI">Virgin Islands (U.S.)</option>
     <option value="WF">Wallis and Futuna</option>
     <option value="EH">Western Sahara</option>
     <option value="YE">Yemen</option>
     <option value="ZM">Zambia</option>
     <option value="ZW">Zimbabwe</option>
     <option value="AX">Land Islands</option>
                          </Form.Select>
                        </div>
                      
                        <div className="col-sm-6">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Address <i className="required_star">*</i></Form.Label>
                            <Form.Control required name="address" value={posts.address || ""} onChange={(event)=>handleChange(event)} type="text" className="cc-number" placeholder="" />
                          </Form.Group>
                        </div>

                        <div className="col-sm-6">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>City town <i className="required_star">*</i></Form.Label>
                            <Form.Control required name="city" value={posts.city || ""} onChange={(event)=>handleChange(event)} type="text" className="cc-number" placeholder="Enter City Name" />
                          </Form.Group>
                        </div>


                        <div className="col-sm-6">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>State <i className="required_star">*</i></Form.Label>
                            <Form.Control required name="state" value={posts.state || ""} onChange={(event)=>handleChange(event)} type="text" className="cc-number" placeholder="Enter City Name" />
                          </Form.Group>
                        </div>
                      
                       

                        <div className="col-sm-6">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Postal Code <i className="required_star">*</i></Form.Label>
                            <Form.Control maxLength={10} required name="pincode" value={posts.pincode || ""} onChange={(event)=>handleChange(event)} type="text" className="cc-number" placeholder="" />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!--Contact Details --> */}
                  <div className="contact_block hr-line">
                    <h4>Contact Information</h4>

                    <div className="form__block">
                      <div className="row form-group">
                        <div className="col-sm-6">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Contact Number <i className="required_star">*</i></Form.Label>
                            <Form.Control name="phone" maxLength={15} required value={posts.phone || ""} onChange={(event)=>handleChange(event)} type="text" className="cc-number" placeholder="" />
                            {formErrors.phone  && <div style={errorStyle}>{formErrors.phone}</div>}
                          </Form.Group>
                        </div>

                        <div className="col-sm-6">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email Address <i className="required_star">*</i></Form.Label>
                            <Form.Control name="email" maxLength={80} required value={posts.email || ""} onChange={(event)=>handleChange(event)} type="email" className="cc-number" placeholder="" />
                            {formErrors.email  && <div style={errorStyle}>{formErrors.email}</div>}
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!--bottom content --> */}
                  <div className="bottom_text inputSet theme2">
                    <label>
                      <FormControlLabel control={<Checkbox />} />
                    </label>
                    
                    

                    <p>
                      By clicking 'Confirm &amp; Book', I agree that I have read
                      and accepted the above policies and Flyustravel.com's{" "}
                      <Link to="/">Terms and Conditions</Link> and{" "}
                      <Link to="/">Privacy Policy</Link>
                    </p>
                  </div>

                 {confirmBooking===true && ( <div className="bottom__row">
                    <button type="submit" className="continue__button confirm">
                      Confirm &amp; Book
                      <span>
                        <i className="fa fa-lock"></i> Secure Payment
                      </span>
                    </button>
                  </div>)}
                 

                  <div className="clearfix"></div>
                  
                </div>
                )}
                {/* <!-- \ payment Box /--> */}
                

                <div className="bottom__row d-flex justify-content-between">
                    <button 
                      onClick={prevStep} 
                      className={currentStep > 1 ? 'continue__button' : 'continue__button disabled'}
                    >
                        Back
                    </button>
                    <button 
                    
                        onClick={nextStep} 
                        className={currentStep < 3 ? 'continue__button' : 'continue__button disabled'}
                    >
                        Continue
                    </button>
                  </div>
                
              </div>
              {/* <!-- payment wrapper--> */}
            </div>
           
            <div className="col-lg-3 col-md-12">
              {/* <!--Price block start here --> */}
              <div className="price__Box">
                <div className="head">
                  <h4>Price details</h4>
                </div>

                <ul>

                  <Bprice validateData={validateData} />
               
                  <Bcoupon show={false} handleRefundClick={handleRefundClick} addRefund={addRefund} />


                </ul>

                <div className="total_price">
                  <div className="row">
                   {validateData!==null && (<div className="col-7">Total Price ({validateData.itin.Cur})</div>) }
                   {validateData!==null && (<div className="col-5 text-right">{validateData.itin.TotalFare}</div>) }  
                    
                  </div>
                </div>

                <div className="price__note">
                  All Fares displayed are quoted in USD and inclusive of base
                  fare, taxes and all fees. Additional{" "}
                  <Link to="/">Baggage Fees</Link> may apply as per the airline
                  policies. Your Credit/Debit card may be billed in multiple
                  charges totaling the final total price.
                </div>
              </div>
              {/* <!--Price block end here --> */}

             
            </div>

          </div>
          </form>


        </div>
      </div>
      {/* <!--  \ Results container / --> */}

      <Footer />
    </>
  );
}

export default Payment;
