import React, {useState,useEffect} from 'react'
import HeaderUser from '../../components/HeaderUser'
import Footer from '../../components/Footer'
import { Form, Link } from 'react-router-dom'
import { AirplaneTicket, AirplanemodeInactive, Done, DoneAll, Flight, FlightTakeoff, KeyboardArrowDown } from '@mui/icons-material'
import { Button, Checkbox, FormControlLabel, Radio, TextField } from '@mui/material'
import { CalendarMonth } from "@mui/icons-material";
    
    
    
  
import dayjs, { Dayjs } from 'dayjs';
// import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import BookingList from './BookingComponent/BookingList';
import {getStorageData,apiBaseUrl} from '../../global';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
// dayjs.extend(utc);
//     dayjs.extend(timezone);
// dayjs.tz.setDefault("America/Los_Angeles");

const BookingResult = () => {
    
    // booking filter start here


    
    const [isBookingStatus, setIsBookingStatus] = useState(false);
        const toggleBookingStatus = () => {
            setissubmit(false); 
        setIsTKTL(false);
        setIsBookingStatus(!isBookingStatus);
        setIsMoreFilter(false);
        setIsFareType(false);
        setIsCreator(false);
    };

    const [isTKTL, setIsTKTL] = useState(false);
        const toggleTKTL = () => {
            setissubmit(false); 
        setIsTKTL(!isTKTL);
        setIsBookingStatus(false);
        setIsMoreFilter(false);
        setIsFareType(false);
        setIsCreator(false);
    };
    
    const [isFareType, setIsFareType] = useState(false);
        const toggleFareType = () => {
            setissubmit(false);   
        setIsBookingStatus(false);
        setIsFareType(!isFareType);
        setIsTKTL(false);
        setIsCreator(false);
        setIsMoreFilter(false);
    };

    const [isCreator, setIsCreator] = useState(false);
        const toggleCreator = () => {
            setissubmit(false); 
        setIsBookingStatus(false);
        setIsCreator(!isCreator);
        setIsTKTL(false);
        setIsMoreFilter(false);
        setIsFareType(false);
    };

    const [isMoreFilter, setIsMoreFilter] = useState(false);
        const toggleMoreFilter = () => {
            setissubmit(false); 
        setIsBookingStatus(false);
        setIsMoreFilter(!isMoreFilter);
        setIsCreator(false);
        setIsTKTL(false);
        setIsFareType(false);
        
    };

    const [isSorting, setIsSorting] = useState(false);
        const toogleSorting = () => {
        setIsSorting(!isSorting);
        setissubmit(false); 
        setIsBookingStatus(false);
        setIsMoreFilter(false);
        setIsCreator(false);
        setIsTKTL(false);
        setIsFareType(false);
    };
    const [bookingData,setbookingData] = useState();
    
    

    // booking filter ends here

    useEffect(() => {

       
     let searchrq={website: "1",userId:getStorageData('userSession')?.id};
       var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        var sestting = {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify(searchrq),
          redirect: 'follow'
        };
        const url = apiBaseUrl + '/api/bookingfetch';
        fetch(url, sestting)
          // fetch('response.json', requestOptions)
            .then(response => response.json())
            .then(result => {
                
                setbookingData(result.bookingDetailData);
               
                
            }).catch(error => console.log(error));
      //console.log(validateRq);
     
      //console.log(website);
      },[]);






     let totalResults= bookingData?.length>0?bookingData?.length:0;
     const initialfare = [{faretype:"PUB",isSelected:false}
    //  {faretype:"NET",isSelected:false},
    //  {faretype:"TOP",isSelected:false},
    //  {faretype:"WEB",isSelected:false},
    //  {faretype:"VFR",isSelected:false},
    //  {faretype:"CRU",isSelected:false},
    //  {faretype:"NET",isSelected:false}
    ]

    
     //const [creator,setcreator] = useState(initialfare);
     const [ftype,setisftype] = useState(initialfare);
     const [filterShow,setfilterShow] = useState(false);
     const [bookingstatus,setbookingstaus] = useState('');
     const [tktl,settktl] = useState('');
     const [issubmit,setissubmit] = useState(false);
     const [sortName,setsortName] = useState();



     const [bookfromdate,setbookfromdate] = useState(null)
     const [booktodate,setbooktodate] = useState(null);
     const [travelfromdate,settravelfromdate] = useState(null);
     const [traveltodate,settraveltodate] = useState(null);
     
     const [orgi2,setorgi2] = useState('');
     const [orgi1,setorgi1] = useState('');
     const [Flight,setFlight] = useState({
        airline:"",airline1:"",airline2:""
     });
     const [Notes,setNotes] = useState({});
const hadleNote=(e)=>{
    var value=e.target.value
    var name=e.target.name
    setNotes({...Notes, [name]:value})

}
    const hadleflight=(e)=>{
        var value=e.target.value
        var name=e.target.name
        setFlight({...Flight,[name]:value})
       
    }
                                             
                                             
 const hadleAirport1=(e)=>{
    setorgi1(e.target.value)
        
     }
     const hadleAirport2=(e)=>{
        setorgi2(e.target.value)
        
     }

     const [status,setstatusss] = useState({});
    const handlefaretype= (faretype,index)=>{
        setfilterShow(true)
        const ffff = [...ftype];
        ffff[index] = { ...faretype, isSelected: !faretype.isSelected };
      
      
        let filterData = ffff.filter(       
            (faretype) => faretype.isSelected === true 
          );
        let  arr={}
          filterData.map((e,i)=>{
            arr[i]=e.faretype
          })
    //console.log(JSON.stringify(arr))
    setstatusss(JSON.stringify(arr))
    console.log(status)
    setisftype(ffff);
     
    }


     const resetfaretype =(e)=>{
        const ffff = [...ftype];
        ffff.forEach((farereset,index) => {
            let faretype=farereset.faretype;
            ffff[index] = {faretype:faretype, isSelected:false};
                      });
                      setisftype(ffff); 

                     
           

     }
    

     
     
      const handleClick =(e) =>{
        setbookingstaus(e.target.textContent)
        setfilterShow(true)
        setIsBookingStatus(false);
      }
     const handleclicktktl=(e)=>{
        setfilterShow(true)
        settktl(e.target.textContent)
        setIsTKTL(false);
     }

     const resetfilter =() =>{
        setfilterShow(false)
        setissubmit(false)
        setisftype(initialfare)
        setbookingstaus('')
        settktl('')
        setbookfromdate(null)
        setbooktodate(null)
        settravelfromdate(null)
        settraveltodate(null)
        setorgi1('')
        setorgi2('')
        setsortName('')
        setIsBookingStatus(false);
        setIsCreator(false);
        setIsTKTL(false);
        setIsMoreFilter(false);
        setIsFareType(false);   
        setIsSorting(false);
        setFlight({
            airline:"",airline1:"",airline2:""
         })
         
         var myHeaders = new Headers();
 
  let formData = new FormData();


  formData.append('userId',getStorageData('userSession')?.id);
  formData.append('bookingStatus','');
  formData.append('tktl','');
  formData.append('bookfromdate','');
  formData.append('booktodate','');
  formData.append('travelfromdate','');   
  formData.append('traveltodate','');
  formData.append('orgairport','');
  formData.append('desairport','');
  formData.append('Airline','');   
  formData.append('sortName','');  
  formData.append('website',"1");  
  const url = apiBaseUrl + '/api/b2bsearch';
  var requestOptions = {
    method: 'POST',
      headers: myHeaders,
      body: formData,
    redirect: 'follow'
  };
  fetch(url, requestOptions)
  .then((response) => response.json())
  .then(result => {
    setissubmit(false);            
    setbookingData(result.bookingDetailData);
   
    
}).catch(error => console.log(error));


     }





      const handleSubmit = (event) => {
        event.preventDefault();
        
        
       var airlinecode=''
       var airlinecode1='' 
       var airlinecode2=''
       if(Flight.airline){
         var airlinecode=Flight.airline ;
       }
       if(Flight.airline1) {
       var airlinecode1=','+Flight.airline1 ;
       }
       if(Flight.airline2) {
        var airlinecode2=','+Flight.airline2;
       }
       var finalairlinecode= airlinecode + airlinecode1+airlinecode2;

        if(issubmit){
            setIsBookingStatus(false);
        setIsCreator(false);
        setIsTKTL(false);
        setIsMoreFilter(false);
        setIsFareType(false);  
        setIsSorting(false); 
       
            var bookfromdate11 =bookfromdate?moment(bookfromdate).format("YYYY-MM-DD"):'';
        var booktodate12=booktodate?moment(booktodate).format("YYYY-MM-DD"):'';
        var travelfromdate13=travelfromdate?moment(travelfromdate).format("YYYY-MM-DD"):'';
        var traveltodate14=traveltodate?moment(traveltodate).format("YYYY-MM-DD"):'';
            
        var myHeaders = new Headers();
 
  let formData = new FormData();


  formData.append('userId',getStorageData('userSession')?.id);
  formData.append('bookingStatus',bookingstatus||'');
  formData.append('tktl',tktl||'');
  formData.append('bookfromdate',bookfromdate11);
  formData.append('booktodate',booktodate12);
  formData.append('travelfromdate',travelfromdate13);   
  formData.append('traveltodate',traveltodate14);
  formData.append('orgairport',orgi1?orgi1:'');
  formData.append('desairport',orgi2?orgi2:'');
  formData.append('Airline',finalairlinecode); 
  formData.append('sortName',sortName?sortName:'');   
  
  formData.append('website',"1");  
  const url = apiBaseUrl + '/api/b2bsearch';
  var requestOptions = {
    method: 'POST',
      headers: myHeaders,
      body: formData,
    redirect: 'follow'
  };
  fetch(url, requestOptions)
  .then((response) => response.json())
  .then(result => {
    setissubmit(false);            
    setbookingData(result.bookingDetailData);
   
    
}).catch(error => console.log(error));
   
               
}




       
      }
    
  return (
    <>
        <HeaderUser/>

        {/* mybooking container start */}
        <div id='myBookingCntr'>
            <div className='container'>
                <div className='bookingresultBox'>

                    <div className='bookingHead'>
                        <h3>Results <span>({totalResults} Results found)</span></h3>
                        <Link className="notification">Notifications: <span>0</span></Link>
                    </div>

                    <div className='bookingFilter'>
                        <form onSubmit={handleSubmit}>
                        <div className='filtrleft'>
                            <div className='filterBlock'>
                                <button className='filterButton' onClick={toggleBookingStatus}>All Booking Status <span><KeyboardArrowDown/></span></button>
                                           
                                                
                                              
                                              
                                          
                                {isBookingStatus && (
                                 
                                    <div className='filterdropdown'>
                                        <h5>All Booking Status</h5>
                                        <ul>
                                            <li>
                                                <AirplaneTicket className='icon'/>
                                                <Link className="bookingstatus" onClick={handleClick}>NEW</Link>
                                            </li>
                                            <li className='orange'>
                                                <Done className='icon'/>
                                                <Link onClick={handleClick}
                                              className="bookingstatus">PENDING </Link>
                                               
                                            </li>
                                            <li className='green'>
                                                <FlightTakeoff className='icon'/>
                                                <Link onClick={handleClick}
                                              className="bookingstatus">CONFIRMED</Link>
                                              
                                            </li>
                                            <li className='red'>
                                                <AirplanemodeInactive className='icon'/>
                                                <Link onClick={handleClick}
                                              className="bookingstatus">REFUND</Link>
                                              
                                            </li>
                                            <li>
                                                <AirplanemodeInactive className='icon'/>
                                                <Link onClick={handleClick}
                                              className="bookingstatus">CANCEL</Link>
                                                
                                            </li>
                                            
                                            {/* <li>
                                                <FlightTakeoff className='icon'/>
                                                <Link onClick={(e) => setbookingstaus(e.target.textContent)}
                                              className="bookingstatus">Departed </Link>
                                                
                                            </li> */}
                                        </ul>
                                    </div>
                                    
                                )}
                            </div>
                            <div className='filterBlock'>
                                <button className='filterButton' onClick={toggleTKTL}>  All TKTL <span><KeyboardArrowDown/></span></button>
                                 
                                {isTKTL && (
                                    <div className='filterdropdown'>
                                        <h5> All TKTL</h5>
                                        <ul>
                                            <li className='red'>
                                                <AirplaneTicket className='icon'/>
                                                <Link onClick={handleclicktktl}
                                              className="bookingstatus"> TKTL &#60; 2 days</Link>
                                               
                                            </li>
                                            <li className='orange'>
                                                <AirplaneTicket className='icon'/>
                                                <Link onClick={handleclicktktl}
                                              className="bookingstatus"> TKTL 2-7 days   </Link>
                                              
                                            </li>
                                            <li className='green'>
                                                <AirplaneTicket className='icon'/>
                                                <Link onClick={handleclicktktl}
                                              className="bookingstatus">TKTL &#62; 7 days </Link>
                                              
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div className='filterBlock'>
                                <button className='filterButton' onClick={toggleFareType}>Fare types<span><KeyboardArrowDown/></span></button>
                                
                              
                                {isFareType && (
                                    <div className='filterdropdown'>
                                        <ul className='fareradio p_L'>
                                            {ftype.map((f,i)=>(
                                                   <li>
                                                   <FormControlLabel  control={<Checkbox  checked={f.isSelected}
                                                   onChange={() => handlefaretype(f, i)}   />} label={f.faretype} />
                                               </li>
                                            )
                                            )}
                                            
                                            {/* <li>
                                                <FormControlLabel name="NET" control={<Checkbox />} label="NET" />
                                            </li>
                                            <li>
                                                <FormControlLabel name="TOP" control={<Checkbox />} label="TOP" />
                                            </li>
                                            <li>
                                                <FormControlLabel name="WEB" control={<Checkbox />} label="WEB" />
                                            </li>
                                            <li>
                                                <FormControlLabel name="VFR" control={<Checkbox />} label="VFR" />
                                            </li>
                                            <li>
                                                <FormControlLabel name="CRU" control={<Checkbox />} label="CRU" />
                                            </li>
                                            <li>
                                                <FormControlLabel name="MIL" control={<Checkbox />} label="MIL" />
                                            </li> */}
                                            <li className='mb-3'>
                                                <Button variant="contained" onClick={toggleFareType} className='Btnstyle m_r'>Done</Button>
                                                <Button variant="contained" onClick={resetfaretype} className='Btnstyle'>Reset</Button>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div className='filterBlock'>
                                <button className='filterButton' onClick={toggleCreator}>Creator<span><KeyboardArrowDown/></span></button>
                                {isCreator && (
                                    <div className='filterdropdown'>
                                        <ul className='fareradio p_L'>
                                            <li>
                                                <FormControlLabel value={getStorageData('userSession')?.fname} control={<Checkbox checked />} label={getStorageData('userSession')?.fname} />
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div className='filterBlock'>
                                <button className='filterButton' onClick={toggleMoreFilter}>More filters<span><KeyboardArrowDown/></span></button>
                                {isMoreFilter && (
                                    <div className='filterdropdown morefilter'>
                                        <div className='row'>
                                            <div className='col-12 mb-2'>
                                                <label className='labelTitle' htmlFor="Booking Date"><strong> Booking Date</strong></label>
                                                <div className='row justify-content-between'>
                                                    
                                                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker 
                                                        
                                                        value={bookfromdate}
                                                        onChange={(e) => setbookfromdate(e)}
                                                        label="Booking date from" />
                                                        
                                                       
                                                    </DemoContainer>
                                                    </LocalizationProvider> */}
                                                    <div className='col-6'>
                                                     <DatePicker
                                                     label="Booking date from"
                           selected={bookfromdate}
                            onChange={(e) => setbookfromdate(e)}
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="Booking date from"
                            monthsShown={1}
                            className="datepicker"
                          />

                         
                        
                                                    </div>
                                                    {/* <div className='col-6'>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker
                                                        
                                                        value={booktodate}
                                                        onChange={(e) => setbooktodate(e)}
                                                         label="Booking date to" />
                                                    </DemoContainer>
                                                    </LocalizationProvider>
                                                    </div> */}
                                                    <div className='col-6'>
                                                     <DatePicker
                            onChange={(e) => setbooktodate( e)}
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="Booking date to"
                           
                            selected={booktodate}
                            monthsShown={1}
                            className="datepicker"
                          />

                         
                        
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-12 mb-2'>
                                                <label className='labelTitle' htmlFor="Booking Date"><strong>Travel Date(s)</strong></label>
                                                <div className='row justify-content-between'>
                                                    {/* <div className='col-6'>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker 
                                                        
                                                         value={travelfromdate}
                                                         onChange={(e) => settravelfromdate(e)}
                                                        label="Travel date from" />
                                                    </DemoContainer>
                                                    </LocalizationProvider>
                                                    </div> */}
                                                    <div className='col-6'>
                                                     <DatePicker
                            selected={travelfromdate}
                            onChange={(e) => settravelfromdate( e)}
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="Travel date from"
                           
                           
                            monthsShown={1}
                            className="datepicker"
                          />

                         
                        
                                                    </div>
                                                    {/* <div className='col-6'>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker 
                                                        
                                                         value={traveltodate}
                                                         onChange={(e) => settraveltodate(e)}
                                                         label="Travel date to" />
                                                    </DemoContainer>
                                                    </LocalizationProvider>
                                                    </div> */}
                                                    <div className='col-6'>
                                                     <DatePicker
                                                     selected={traveltodate}
                            onChange={(e) => settraveltodate( e)}
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="Travel date to"
                            monthsShown={1}
                            className="datepicker"
                          />

                          
                        
                                                    </div>
                                                </div>
                                                <div className='noteText'>Add a single date in the first field or fill both fields for date range.</div>
                                            </div>
                                            <div className='col-12 mb-2'>
                                                <label className='labelTitle' htmlFor="Booking Date"><strong>Airports</strong></label>
                                                
   
                                                <div className='row'>
                                                <div className='col-6'>
                                                    <TextField id="outlined-controlled" inputProps={{ maxLength: 3 }} value={orgi1} 
                                                     onChange={hadleAirport1}
                                                    label=" Origin Apt"  /></div>
                                                    <div className='col-6'>
                                                    <TextField id="outlined-basic" label="Destination Apt" inputProps={{ maxLength: 3 }} value={orgi2}
                                                     onChange={hadleAirport2}
                                                    variant="outlined" />
                                                    </div>
                                                  
                                                </div>
                                               
                                               
           
                                            </div>
                                            <div className='col-12 mb-2'>
                                                <label className='labelTitle' htmlFor="Booking Date"><strong>Airline / Carrier</strong></label>
                                                <div className='clearfix'></div>
                                                <TextField className='carrierBlock' inputProps={{ maxLength: 2 }}  name='airline' value={Flight.airline || ""} onChange={(event) =>hadleflight(event)} id="outlined-basic" variant="outlined" />
                                                <TextField className='carrierBlock' inputProps={{ maxLength: 2 }} name='airline1' value={Flight.airline1|| ""} onChange={(event) =>hadleflight(event)} id="outlined-basic" variant="outlined" />
                                                <TextField className='carrierBlock' inputProps={{ maxLength: 2 }} name='airline2' value={Flight.airline2|| ""} onChange={(event) =>hadleflight(event)} id="outlined-basic" variant="outlined" />    
                                            </div>
                                            <div className='col-12 mb-2'>
                                                <label className='labelTitle' htmlFor="Booking Date"><strong>Notes</strong></label>
                                                <div className='clearfix'></div>
                                                <TextField id="outlined-basic" name='note' value={Notes.note} onChange={(event) =>hadleNote(event)}  label="Notes" variant="outlined" />
                                                {/* <div className='row'>
                                                    <div className='col-6'><TextField id="outlined-basic" label="Departure Apt" variant="outlined" /></div>
                                                    <div className='col-6'>
                                                    <TextField id="outlined-basic" label="Destination Apt" variant="outlined" />
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className='col-12 mb-2'>
                                                <Button onClick={()=>{
                                                    setIsMoreFilter(!isMoreFilter);
                                                }} variant="contained">Done</Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div><button type="submit" name="submit" onClick={(e)=>setissubmit(true)} className='handleBtn'>APPLY FILTERS</button></div>
                            <button onClick={resetfilter} className='resetBtn'>RESET ALL FILTERS</button>
                        </div>
                        </form>
                        
                        <div className='sorting'>
                            <button className='shortingBtn' onClick={toogleSorting}>Sort <span><KeyboardArrowDown/></span></button>
                            { isSorting && (
                            <div className='shortdropdown'>
                                <ul>
                                    <li><Link onClick={(e)=>{setsortName(e.target.textContent); setIsSorting(false)}} > Booking date </Link></li>
                                    <li><Link onClick={(e)=>{setsortName(e.target.textContent); setIsSorting(false)}} > Pax name </Link></li>
                                    <li><Link onClick={(e)=>{setsortName(e.target.textContent); setIsSorting(false)}} > Origin </Link></li>
                                    <li><Link onClick={(e)=>{setsortName(e.target.textContent); setIsSorting(false)}} > Destination </Link></li>
                                    <li><Link onClick={(e)=>{setsortName(e.target.textContent); setIsSorting(false)}} > Departure date </Link></li>
                                    <li><Link onClick={(e)=>{setsortName(e.target.textContent); setIsSorting(false)}} > Return date </Link></li>
                                    <li><Link onClick={(e)=>{setsortName(e.target.textContent); setIsSorting(false)}} > Total price </Link></li>
                                    
                                    
                                </ul>
                            </div>
                            )}
                        </div>
                    </div>
                    {/* <!-- applyedFilter Box start --> */}
     
                    {filterShow  &&
            <div className="FilterContainer">
              <div className="applyedFilterBox bookingfilter">
                <h6>Applyed Filter</h6>
                <ul>
                {bookingstatus &&
                <li>
                  {bookingstatus} <i onClick={()=>{setbookingstaus('')}} class="bi bi-x resetFilter"></i>
                  </li>
                  }
                  
                  {tktl &&
                  <li>
                  {tktl}<i onClick={()=>{settktl('')}} class="bi bi-x resetFilter" ></i>
                  </li>
                  }
                     {ftype.map((f,i)=>(
                                    f.isSelected &&  <li>{f.faretype}<i onClick={()=>{handlefaretype(f, i)}}  class="bi bi-x resetFilter"></i> </li>
                                ))}  
                 
                  {/* <li>
                    American Airlines <i class="bi bi-x resetFilter"></i>
                  </li>
                  <li>
                    Layover time - 3h 40m <i class="bi bi-x resetFilter"></i>
                  </li> */}
                  <li class="clear_filter" onClick={()=>{setisftype(initialfare);setbookingstaus('');settktl(''); setfilterShow(false);}}  >Clear All filters</li>
                </ul>
              </div>
            </div>
           }
            {/* <!-- applyedFilter Box end --> */}

                    <BookingList bookingdata={bookingData}  />
                </div>
            </div>
        </div>
        {/* mybooking container ends */}

        <Footer/>
    </>
  )
}

export default BookingResult
