import React from 'react'
import { useEffect, useRef } from 'react';
//import { useEffect,useState } from 'react';

const About = () => {

  
  
  const shouldLock = useRef(true)
  
  useEffect(() => {

   
    if(shouldLock.current){
      shouldLock.current=false;

      console.log('aaaaaaaaaaaaa')
    }
    
},[]);





  return (
    <div>
      <h1>About</h1>
    </div>
  )
}

export default About


