import React from "react";
import HeaderUser from "../../../components/HeaderUser";
import Footer from "../../../components/Footer";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { CalendarMonth } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";

const InvoicesNCreditMemos = () => {
  return (
    <>
      <HeaderUser />

      {/* <!-- form container start --> */}
      <div id="profileCntr">
        <div class="container">
          {/* <!--  / Profile  container \ --> */}

          <div class="row">
            <div class="col-sm-12 row-flex">
              {/* <!--  / Right container Start here \ --> */}
              <div id="ItinerariesBox" class="ItinerariesBox">
                <h3 class="main_title">Invoices / credit vouchers</h3>

                <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
                  <Link underline="hover" color="inherit" to="/profile">
                    Profile
                  </Link>
                  <Typography color="text.primary">
                    Invoices or credit vouchers
                  </Typography>
                </Breadcrumbs>

                {/* <!-- Form--> */}
                <div class="formBox">
                  <p class="text">
                    You can filter the display by selecting a search criterion
                    such as document date, document number, file key, name etc.
                    If you do not select a search criterion all documents will
                    be displayed.
                  </p>

                  <div class="form_innerBox">
                    <div class="row form-group">
                      <div class="col-sm-6">
                        <label class="lable">
                          Search for<sup class="star">*</sup>
                        </label>
                        <div class="select_dropdown">
                          <select
                            class="form-control"
                            data-val="true"
                            name="Gender"
                          >
                            <option selected="selected" value="">
                              Invoices and credit vouchers
                            </option>
                            <option value="1">
                              Invoices and credit vouchers
                            </option>
                            <option value="2">Credit vouchers</option>
                            <option value="3">Credit vouchers</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-sm-6">
                        <label class="lable">
                          Document date<sup class="star">*</sup>
                        </label>
                        <div class="row form-group">
                          <div class="col-md-6">
                            <div class="daterelative">
                              <input class="textbox dateInput" type="text" />
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="daterelative">
                              <input class="textbox dateInput" type="text" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row form-group">
                      <div class="col-sm-6">
                        <label class="lable">Filekey</label>
                        <input
                          class="textbox textOnly"
                          name="Filekey"
                          type="text"
                          value=""
                        />
                      </div>
                      <div class="col-sm-6">
                        <label class="lable">
                          Document number<sup class="star">*</sup>
                        </label>
                        <input
                          class="textbox textOnly"
                          name="Document number"
                          type="text"
                          value="Raj"
                        />
                      </div>
                    </div>
                    <div class="row form-group">
                      <div class="col-sm-6">
                        <label class="lable">
                          Last name of first traveler<sup class="star">*</sup>
                        </label>
                        <input class="textbox" type="text" value="" />
                      </div>
                      <div class="col-sm-6">
                        <label class="lable">
                          First name of first traveler<sup class="star">*</sup>
                        </label>
                        <input class="textbox textOnly" type="text" value="" />
                      </div>
                    </div>

                    <button class="button float-end mt_mob" type="button">
                      Save
                    </button>

                    <button
                      class="button float-end grayBtn mt_mob"
                      type="button"
                    >
                      cancel
                    </button>
                  </div>
                  <div class="clearfix"></div>
                </div>
                {/* <!-- TAble--> */}
              </div>
              {/* <!-- End My Information--> */}
            </div>
          </div>
        </div>

        {/* <!--  / Profile  container \ -->  */}
      </div>
      {/* <!-- form container end --> */}

      <Footer />
    </>
  );
};
export default InvoicesNCreditMemos;
