import React, { useState } from 'react'
import HeaderUser from '../../components/HeaderUser'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/perspective.css';
import 'tippy.js/themes/light.css';
import { ArrowCircleDown, CalendarMonth, ErrorOutline } from '@mui/icons-material';
import MultiSelectDropdown from './BookingComponent/MultiSelectDropdown';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const BookingFlightDetail1 = () => {

  // Passenger Data start
  const [isDataDisable, setIsDataDisable] = useState('add');
  const handleDateVisibility = (buttonName) => {
    setIsDataDisable(buttonName);
  };
  // Passenger Data ends


  // Passenger tab start
  // const [passengerActiveTab, setpassengerActiveTab] = useState(false);
  // const handlepassengerDetail = (tabNumber) => {
  //   setpassengerActiveTab(tabNumber);
  // };

  const [isFFN, setIsFFN] = useState(false);
  const [isTSA, setIsTSA] = useState(false);
  const [isAPIS, setIsAPIS] = useState(false);

  const FFNShow = () => {
    setIsFFN(true);
  };
  const TSAShow = () => {
    setIsTSA(true);
  };
  const APISShow = () => {
    setIsAPIS(true);
  };

  const FFNHide = () => {
    setIsFFN(false);
  };
  const TSAHide = () => {
    setIsTSA(false);
  };
  const APISHide = () => {
    setIsAPIS(false);
  };
  // Passenger tab ends
  
  //Redress start
  const[isRedress, setIsRedress] = useState(true);
  const toggleRedress = () => {
    setIsRedress(!isRedress);
  };
  //Redress ends
    
  return (
    <>
        <HeaderUser/>

        {/* mybooking container start */}
        <div id='myBookingCntr'>
            <div className='container'>

              {/* booking Head start */}
              <div className='bookingHead'>
                  <h3>Results <span>(3 Results found)</span></h3>
                  <ul className='bookingheadright'>
                    <li><Link to="/">Show</Link></li>
                    <li><Link to="/">Change</Link></li>
                    <li><Link to="/">New search</Link></li>
                  </ul>
              </div>
              {/* booking Head ends */}

              {/* pnrBox start */}
              <div className='pnrBox'>
                <div className='pnrHead'>
                    <ul>
                      <li>SPNR <span>S031GOW</span></li>
                      <li>BOFXSK <span>departed</span></li>
                    </ul>
                    <Link className='Notifications'>Notifications: <span className='Noti_count'>0</span></Link>
                </div>
                <div className='pnrdetailBlock'>
                  <div className='pnrDetail'>
                    <ul>
                      <li><span className='Filekeybooking_title'>Filekey: </span> BOFXSK - departed</li>
                      <li><span className='Filekeytitle'>Travel period:</span> 11JUL23 - 12JUL23</li>
                      <li><span className='Filekeytitle'>Booking date:</span> 03MAY23, 10:50P</li>
                      <li><span className='Filekeytitle'>Booked by:</span> Rajdeep Singh</li>
                      <li><span className='Filekeytitle'>Last edited by:</span> Rajdeep Singh <span className='edite_date'>03MAY23, 10:50P</span></li>
                    </ul>
                  </div>
                  <div className='pnrHistory'>
                    <button className='pnrHistoryBtn'>PNR History</button>
                    <div className='TotlaFare'>$ 887.20</div>
                  </div>
                </div>
              </div>
              {/* pnrBox emds */}

              {/* Passenger Details start */}
              <div className='book_pass_detail'>
                <div className='booking_title'>Passenger Details</div>
                <div className='pass_tab'>
                  <ul>
                    <li>
                      <Link 
                      className="tab-button active tabclick"
                      onClick={FFNShow}
                      >
                        FFN
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="tab-button active tabclick"
                        onClick={TSAShow}
                      >
                        TSA
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="tab-button active tabclick"
                        onClick={APISShow}
                      >
                        APIS
                      </Link>
                    </li>
                  </ul>
                  
                  <div className='passenger_names_ticket'>
                    <span>BOHDE/WILLIAM MICHAEL MR (23SEP1952)</span>
                    <span>006-7965910977</span>
                  </div>
                </div>

                <div className='clearfix'></div>

                  
                  {isFFN && 
                    <div className='booking_form tab'>
                      <h5>
                        FFN 
                        <Tippy
                          interactive={true} 
                          animation='perspective' 
                          content={
                            <div>
                              <p>Please select an airline / frequent flyer program from the list and enter the frequent flyer number (FFN) as printed on the card. Only those airlines offering an agreement with the validating carrier are being displayed.</p>
                            </div>
                          }
                          >
                            <ErrorOutline className='errorIcon'/>
                          </Tippy>
                      </h5>
                      <div className='row mt-3'>
                        <div className='col-md-3'>
                          <label htmlFor="" className='mb-1'>Program</label>
                          <select class="form-select" aria-label="Default select example">
                            <option selected>United States of America</option>
                            <option value="1">Canada</option>
                            <option value="2">Mexico</option>
                            <option value="3">Afghanistan</option>
                          </select>
                        </div>
                        <div className='col-md-3'>
                          <label htmlFor="" className='mb-1'>Frequent flyer number</label>
                          <input type="text" class="form-control" placeholder="Frequent flyer number"></input>
                        </div>
                        <div className='col-md-12'>
                          <button className='btn mt-2 me-2 green'>Confirm</button>
                          <button 
                          className='btn mt-2 red'
                          onClick={FFNHide}
                          >Close & ignore</button>
                        </div>
                      </div>
                    </div>
                  }

                  {isTSA && 
                    <div>
                      <div className='booking_form tab'>
                      <h5>
                        TSA * 
                        <Tippy
                          interactive={true} 
                          animation='perspective' 
                          content={
                            <div>
                              <p>Both the Surname and First Name(s) must match exactly the machine readable section of the passport. Any deviation leads to a denial of transportation by the operating airline. Travel agency and passengers are responsible for verifying the data accuracy, a liability by the ticket consolidator is strictly excluded.\n Please note the hints on the official website of the <Link>Department of Homeland Security.</Link> </p>
                            </div>
                          }
                          >
                            <ErrorOutline className='errorIcon'/>
                          </Tippy>
                      </h5>
                        <div className='row mt-3'>
                          <div className='col-md-3'>
                            <label htmlFor="" className='mb-1'>Last name as per passport *</label>
                            <input type="text" class="form-control" placeholder="Last name as per passport"></input>
                          </div>
                          <div className='col-md-3'>
                            <label htmlFor="" className='mb-1'>1st given name as per passport *</label>
                            <input type="text" class="form-control" placeholder="1st given name as per passport"></input>
                          </div>
                          <div className='col-md-3'>
                            <label htmlFor="" className='mb-1'>2nd and add. given names as per passport</label>
                            <input type="text" class="form-control" placeholder="2nd and add. given names as per passport"></input>
                          </div>
                          <div className='col-md-3'>
                            <label htmlFor="" className='mb-1'>Date of Birth</label>
                            {/* <input type="text" class="form-control" placeholder="Date of Birth"></input> */}
                            <div className="dateblock2">
                                
                                <DatePicker
                                  dateFormat="dd-MMM-yyyy"
                                  placeholderText="Departure Date"
                                  required="required"
                                  monthsShown={2}
                                  className="datepicker"
                                />
                                <CalendarMonth className="icons"/>
                              </div>
                          </div>
                          <div className='col-md-12 mt-3'>
                            <div>
                              <button onClick={toggleRedress} className='RedressNum'>Redress number and known traveller number 
                                <ArrowCircleDown className='downarrow'/>
                              </button>
                              <Tippy
                                interactive={true} 
                                animation='perspective' 
                                content={
                                  <div>
                                    <p>The redress number is a 7 digit number provided by the Department of Homeland Security under there Traveler Redress Inquiry Program (TRIP). If a traveler already has a redress number e.g. due to watch list issues, screening problems at ports of entry or any other difficulties, it can be provided here.
  The known traveller number (KTN) is a 9 digit number, which can be found on the back of passenger's NEXUS, SENTRI or Global Entry card. If a known traveller number was entered in the booking and printed in the barcode on passenger's boarding pass, it will allow him/her to use TSA Pre✓® lanes.</p>
                                  </div>
                                }
                                >
                                  <ErrorOutline className='toltip'/>
                                </Tippy>
                            </div>
                            <div className={isRedress ? 'RedressNumHide' : 'RedressNumShow row'}>
                              <div className="row">
                                <div className='col-md-3'>
                                  <label htmlFor="" className='mb-1'>Redress number</label>
                                  <input type="text" class="form-control" placeholder="Redress number"></input>
                                </div>
                                <div className='col-md-3'>
                                  <label htmlFor="" className='mb-1'>Known Traveller Number</label>
                                  <input type="text" class="form-control" placeholder="Known Traveller Number"></input>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <button className='btn mt-2 me-2 green'>Confirm</button>
                            <button className='btn mt-2 red' onClick={TSAHide}>Close & ignore</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  {isAPIS && 
                    <div>
                      <div className='booking_form tab'>
                      <h5> APIS </h5>
                      <h6>Primary data of the travel document (DOCS)</h6>
                        <div className='row mt-3'>
                          <div className='col-md-3 mb-3'>
                            <label htmlFor="" className='mb-1'>Last name as per passport *</label>
                            <input type="text" class="form-control" placeholder="Last name as per passport"></input>
                          </div>
                          <div className='col-md-3 mb-3'>
                            <label htmlFor="" className='mb-1'>1st given name as per passport *</label>
                            <input type="text" class="form-control" placeholder="1st given name as per passport"></input>
                          </div>
                          <div className='col-md-3 mb-3'>
                            <label htmlFor="" className='mb-1'>2nd and add. given names as per passport</label>
                            <input type="text" class="form-control" placeholder="2nd and add. given names as per passport"></input>
                          </div>
                          <div className='col-md-3 mb-3'>
                            <label htmlFor="" className='mb-1'>Date of Birth *</label>
                            <div className="dateblock2">
                                
                                <DatePicker
                                  dateFormat="dd-MMM-yyyy"
                                  placeholderText="Departure Date"
                                  required="required"
                                  monthsShown={2}
                                  className="datepicker"
                                />
                                <CalendarMonth className="icons"/>
                              </div>
                          </div>
                          <div className='col-md-3 mb-3'>
                            <label htmlFor="" className='mb-1'>Document type *</label>
                            <select class="form-select" aria-label="Default select example">
                              <option selected> Passport (P) </option>
                              <option value="1"> National ID (I) </option>
                              <option value="2"> Permanent resident card (C) </option>
                              <option value="3"> Alien resident card (A) </option>
                            </select>
                          </div>
                          <div className='col-md-3 mb-3'>
                            <label htmlFor="" className='mb-1'>Number of document *</label>
                            <input type="text" class="form-control" placeholder="Number of document"></input>
                          </div>
                          <div className='col-md-3 mb-3'>
                            <label htmlFor="" className='mb-1'>Country of issuance *</label>
                            <input type="text" class="form-control" placeholder="Country of issuance"></input>
                          </div>
                          <div className='col-md-3 mb-3'>
                            <label htmlFor="" className='mb-1'>Nationality</label>
                            <select class="form-select" aria-label="Default select example">
                              <option selected>United States of America</option>
                              <option value="1">Canada</option>
                              <option value="2">Mexico</option>
                              <option value="3">Albania</option>
                            </select>
                          </div>
                          <div className='col-md-3 mb-3'>
                            <label htmlFor="" className='mb-1'>Validity *</label>
                            <input type="text" class="form-control" placeholder="Validity"></input>
                          </div>
                          <div className='col-md-12 mt-3'>
                            <div><button onClick={toggleRedress} className='RedressNum'>Passenger contact address (DOCA) <ArrowCircleDown/></button>  </div>
                            <div className={isRedress ? 'RedressNumHide' : 'RedressNumShow row'}>
                              <div className="row">
                                <div className='col-md-12'>
                                  <label className='me-4 my-2'>Type of address</label>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="Destination" value="option1" />
                                    <label class="form-check-label" for="Destination">Destination</label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="Residence" value="option2" />
                                    <label class="form-check-label" for="Residence">Residence</label>
                                  </div>
                                </div>
                                <div className='col-md-3 mb-3'>
                                  <label htmlFor="" className='mb-1'>Address details</label>
                                  <input type="text" class="form-control" placeholder="Address details"></input>
                                </div>
                                <div className='col-md-3 mb-3'>
                                  <label htmlFor="" className='mb-1'>ZIP / postal code</label>
                                  <input type="text" class="form-control" placeholder="ZIP / postal code"></input>
                                </div>
                                <div className='col-md-3 mb-3'>
                                  <label htmlFor="" className='mb-1'>City</label>
                                  <input type="text" class="form-control" placeholder="City"></input>
                                </div>
                                <div className='col-md-3 mb-3'>
                                  <label htmlFor="" className='mb-1'>State / province</label>
                                  <input type="text" class="form-control" placeholder="State / province"></input>
                                </div>
                                <div className='col-md-3 mb-3'>
                                  <label htmlFor="" className='mb-1'>Country</label>
                                  <select class="form-select" aria-label="Default select example">
                                    <option selected>United States of America</option>
                                    <option value="1">Canada</option>
                                    <option value="2">Mexico</option>
                                    <option value="3">Albania</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <button className='btn mt-2 me-2 green'>Confirm</button>
                            <button className='btn mt-2 red' onClick={APISHide}>Close & ignore</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

              </div>
              {/* Passenger Details ends */}

              {/* Itinerary Details start */}
              <div className='book_pass_detail'>
                <div className='booking_title'>Itinerary details</div>
                <div className='BookingItinerary'>
                  <div className='ItineraryInfo'>
                    <div className='f_logo_code'>
                      <img src="./images/airline/DL.jpg" alt="DL" />
                      <Tippy
                          interactive={true} 
                          animation='perspective' 
                          content={<div>Delta Airlines</div>}
                          >
                      <span>DL</span>
                      </Tippy>
                    </div>
                  
                    <ul className='ItineraryIcon'>
                      <li>
                      <Tippy
                          interactive={true} 
                          animation='perspective' 
                          content={<div>Conso NET fares</div>}
                          >
                        <Link>
                            NET
                        </Link>
                        </Tippy>
                      </li>
                      <li>
                      <Tippy
                          interactive={true} 
                          animation='perspective' 
                          content={<div>Total Saving </div>}
                          >
                        <Link>
                            %
                        </Link>
                        </Tippy>
                      </li>
                      <li>
                        <Tippy
                          interactive={true} 
                          animation='perspective' 
                          content={<div>1 piece allowed</div>}
                          >
                        <Link>1PC</Link>
                        </Tippy>
                      </li>
                      <li>
                        <Tippy
                          interactive={true} 
                          animation='perspective' 
                          content={<div>Total markup $ 0.00</div>}
                          >
                        <Link>FEE</Link>
                        </Tippy>
                      </li>
                      <li>
                        <Tippy
                          interactive={true} 
                          animation='perspective' 
                          content={<div>03 May 2023 10:50P</div>}
                          >
                        <Link>TKT</Link>
                        </Tippy>
                      </li>
                    </ul>
                    
                    <div className='Info'>
                      Departed03MAY23,
                      <span>Airline Filekey(s):DL/</span>
                    </div>
                  </div>

                  <div className='Itinerarylist'>
                    <div className='f_logo_code'>
                      <img src="./images/airline/DL.jpg" alt="DL" />
                      <Tippy
                        interactive={true} 
                        animation='perspective' 
                        content={<div>Delta Airlines</div>}
                        >
                        <span>DL</span>
                      </Tippy>
                      <span>2947</span>
                    </div>
                    <div className='time_date_class'>
                      <ul>
                        <li>Tue, 11JUL</li>
                        <li>
                          <Tippy
                          interactive={true} 
                          animation='perspective' 
                          content={<div>Kansas City International Apt</div>}
                          >
                            <span>MCI</span>
                          </Tippy>  
                          - 
                          <Tippy
                          interactive={true} 
                          animation='perspective' 
                          content={<div>Minneapolis International Apt</div>}
                          >
                            <span>MSP</span>
                          </Tippy>
                        </li>
                        <li>03:30P - 04:53P</li>
                        <li>01:23 h</li>
                        <li>
                          <Tippy
                            interactive={true} 
                            animation='perspective' 
                            content={<div>Booking class</div>}
                            >
                          <span>U/</span>
                          </Tippy>
                        </li>
                      </ul>
                    </div>
                    <div className='airlineStatus'>
                        <Tippy
                            interactive={true} 
                            animation='perspective' 
                            content={<div>Confirmed</div>}
                            >
                          <span className='mr_30px'>HK1</span>
                        </Tippy>
                        <span>Transfer in MSP 03:02 h</span>
                    </div>
                  </div>

                  <div className='Itinerarylist'>
                    <div className='f_logo_code'>
                      <img src="./images/airline/DL.jpg" alt="DL" />
                      <Tippy
                        interactive={true} 
                        animation='perspective' 
                        content={<div>Delta Airlines</div>}
                        >
                        <span>DL</span>
                      </Tippy>
                      <span>2947</span>
                    </div>
                    <div className='time_date_class'>
                      <ul>
                        <li>Tue, 11JUL</li>
                        <li>
                          <Tippy
                          interactive={true} 
                          animation='perspective' 
                          content={<div>Kansas City International Apt</div>}
                          >
                            <span>MCI</span>
                          </Tippy>  
                          - 
                          <Tippy
                          interactive={true} 
                          animation='perspective' 
                          content={<div>Minneapolis International Apt</div>}
                          >
                            <span>MSP</span>
                          </Tippy>
                        </li>
                        <li>03:30P - 04:53P</li>
                        <li>01:23 h</li>
                        <li>
                          <Tippy
                            interactive={true} 
                            animation='perspective' 
                            content={<div>Booking class</div>}
                            >
                          <span>U/</span>
                          </Tippy>
                        </li>
                      </ul>
                    </div>
                    <div className='airlineStatus'>
                        <Tippy
                            interactive={true} 
                            animation='perspective' 
                            content={<div>Confirmed</div>}
                            >
                          <span className='mr_30px'>HK1</span>
                        </Tippy>
                        <span>Transfer in MSP 03:02 h</span>
                    </div>
                  </div>

                  <div className='Itinerarylist'>
                    <div className='f_logo_code'>
                      <img src="./images/airline/DL.jpg" alt="DL" />
                      <Tippy
                        interactive={true} 
                        animation='perspective' 
                        content={<div>Delta Airlines</div>}
                        >
                        <span>DL</span>
                      </Tippy>
                      <span>2947</span>
                    </div>
                    <div className='time_date_class'>
                      <ul>
                        <li>Tue, 11JUL</li>
                        <li>
                          <Tippy
                          interactive={true} 
                          animation='perspective' 
                          content={<div>Kansas City International Apt</div>}
                          >
                            <span>MCI</span>
                          </Tippy>  
                          - 
                          <Tippy
                          interactive={true} 
                          animation='perspective' 
                          content={<div>Minneapolis International Apt</div>}
                          >
                            <span>MSP</span>
                          </Tippy>
                        </li>
                        <li>03:30P - 04:53P</li>
                        <li>01:23 h</li>
                        <li>
                          <Tippy
                            interactive={true} 
                            animation='perspective' 
                            content={<div>Booking class</div>}
                            >
                          <span>U/</span>
                          </Tippy>
                        </li>
                      </ul>
                    </div>
                    <div className='airlineStatus'>
                        <Tippy
                            interactive={true} 
                            animation='perspective' 
                            content={<div>Confirmed</div>}
                            >
                          <span className='mr_30px'>HK1</span>
                        </Tippy>
                        <span>Transfer in MSP 03:02 h</span>
                    </div>
                  </div>
                </div>
                <div className='clearfix'></div>
              </div>
              {/* Itinerary Details ends */}

              {/* Entry & health regulations start */}
              <div className='booking_form'>
              
                <div className='booking_title'>
                  Entry & health regulations
                  <Tippy
                  interactive={true} 
                  animation='perspective' 
                  content={
                    <div>
                      <p>Here you can create a pdf document with entry & health (incl. COVID-19) information for the first destination country and up to 3 nationalities of travellers in different languages. Note: all information has been carefully researched and is regularly updated, but is not guaranteed. Information reflects the known status at the time of preparation of the document and may change at any time (even unannounced and at short notice). Only the information provided by the foreign representatives of the destination/transit countries is authoritative. Please inform traveller(s) check the current status again with the relevant authorities shortly before the trip.</p>
                    </div>
                  }
                  >
                    <ErrorOutline className='titletooltipIcon'/>
                  </Tippy>
                </div>
                <div className='row mt-3'>
                  <div className='col-md-5'>
                    <label htmlFor="" className='mb-1'>Nationality</label>
                    {/* <select class="form-select" aria-label="Default select example">
                      <option selected>United States of America</option>
                      <option value="1">Canada</option>
                      <option value="2">Mexico</option>
                      <option value="3">Afghanistan</option>
                    </select> */}
                    <MultiSelectDropdown/>
                  </div>
                  <div className='col-md-5'>
                    <label htmlFor="" className='mb-1'>Language</label>
                    <select class="form-select" aria-label="Default select example">
                      <option selected> English </option>
                      <option value="1"> Dutch </option>
                      <option value="2"> French </option>
                      <option value="3"> German </option>
                    </select>
                  </div>
                  <div className='col-md-2'>
                    <div className='invisible d-none d-md-block'>...</div>
                    <button className='btn mt-2'>Create PDF</button>
                  </div>
                </div>
                
                <div className='clearfix'></div>
              </div>
              {/* Entry & health regulations ends */}

              {/* Passenger contact data start */}
              <div className='booking_form'>
              
                  <div className='booking_title'>
                    Passenger contact data 
                      <Tippy
                      interactive={true} 
                      animation='perspective' 
                      content={
                        <div>
                          <p>According to IATA resolution 830d, it becomes mandatory to ask passengers for contact data in case of irregular operations by June01, 2019. Those data (mobile phone number and/or email address) will be submitted to the carrier(s) in the corresponding booking. According to IATA resolution 830d, airline(s) will not use the passenger contact data for marketing purposes.</p>
                        </div>
                      }
                      >
                        <ErrorOutline className='titletooltipIcon'/>
                      </Tippy>
                  </div>

                  {isDataDisable === 'add' && (  
                    <div 
                    //style={{ display: isDataVisible ? 'block' : 'none' }}
                    >
                      <div className='row'>
                        <div className='col-md-5'>
                          <div className='row mt-3'>
                            <div className='col-md-6'>
                              <label htmlFor="" className='mb-1'>Country</label>
                              <select class="form-select" disabled aria-label="Default select example">
                                <option selected>United States of America</option>
                                <option value="1">Canada</option>
                                <option value="2">Mexico</option>
                                <option value="3">Afghanistan</option>
                              </select>
                            </div>
                            <div className='col-md-6'>
                              <label htmlFor="" className='mb-1'>Mobile phone number</label>
                              <input type="tel" class="form-control" disabled placeholder="Mobile phone number"></input>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-1 d-flex flex-column justify-content-center align-items-center mt-3'>
                          <div className='invisible d-none d-md-block'>...</div>
                          <strong>and / or</strong>
                        </div>
                        <div className='col-md-6'>
                          <div className='row mt-3'>
                            <div className='col-md-5'>
                              <label htmlFor="" className='mb-1'>Email</label>
                              <input type="email" class="form-control" disabled placeholder="Email"></input>
                            </div>
                            <div className='col-md-5'>
                              <label htmlFor="" className='mb-1'>Passenger association</label>
                              <input type="text" class="form-control" disabled placeholder="Passenger association"></input>
                            </div>
                            <div className='col-md-2'>
                              <div className='invisible d-none d-md-block'>...</div>
                              <button 
                              //className='btn mt-2' 
                              onClick={() => handleDateVisibility("delete")}
                              className={isDataDisable === 'delete' ? 'active-button' : 'btn mt-2'}
                              >Edit</button>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  )}

                {isDataDisable === 'delete' && (
                  <div 
                  //style={{ display: isDataVisible ? 'block' : 'none' }}
                  >
                    <div className='row'>
                      <div className='col-md-5'>
                        <div className='row mt-3'>
                          <div className='col-md-6'>
                            <label htmlFor="" className='mb-1'>Country</label>
                            <select class="form-select" aria-label="Default select example">
                              <option selected>United States of America</option>
                              <option value="1">Canada</option>
                              <option value="2">Mexico</option>
                              <option value="3">Afghanistan</option>
                            </select>
                          </div>
                          <div className='col-md-6'>
                            <label htmlFor="" className='mb-1'>Mobile phone number</label>
                            <input type="tel" class="form-control" placeholder="Mobile phone number"></input>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-1 d-flex flex-column justify-content-center align-items-center mt-3'>
                        <div className='invisible d-none d-md-block'>...</div>
                        <strong>and / or</strong>
                      </div>
                      <div className='col-md-6'>
                        <div className='row mt-3'>
                          <div className='col-md-4'>
                            <label htmlFor="" className='mb-1'>Email</label>
                            <input type="email" class="form-control" placeholder="Email"></input>
                          </div>
                          <div className='col-md-4'>
                            <label htmlFor="" className='mb-1'>Passenger association</label>
                            <input type="text" class="form-control" placeholder="Passenger association"></input>
                          </div>
                          <div className='col-md-4'>
                            <div className='invisible d-none d-md-block'>...</div>
                            <button className='btn mt-1 me-2'>Save</button>
                            <button 
                            //className='btn mt-1' 
                            onClick={() => handleDateVisibility("add")}
                            className={isDataDisable === 'add' ? 'active-button' : 'btn mt-2'}
                            >Close & ignore</button>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                )}
                
                <div className='clearfix'></div>
              </div>
              {/* Passenger contact data ends */}

              {/* Price details & payment start */}
              <div className='Booking_payment_detail'>
                  <div className='booking_title'>Price details & payment</div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <ul>
                        <li><span className='paxtitle'>Pax</span> BOHDE/WILLIAM MICHAEL  MR (23SEP1952)</li>
                        <li><span className='paxtitle'>Price</span> $582.00</li>
                        <li><span className='paxtitle'>Tax</span> $305.20</li>
                        <li><span className='paxtitle'>Tkt-fee</span> $0.00</li>
                        <li><span className='paxtitle'>Service fee PIC</span> $0.00</li>
                        <li><span className='paxtitle'> Service Fee DL</span> $0.00</li>
                        <li><span className='paxtitle'>Markup</span> $0.00 </li>
                      </ul>
                      <div className='totalFare'><span className='FareTitle'>Total</span> <span className='Fare'>$887.20</span></div>
                    </div>
                    <div className='col-md-12 d-flex flex-row justify-content-between'>
                      
                        <button className='actionBtn green'>Ticket order</button>
                        <button className='actionBtn yellow'>Rules</button>
                        <button className='actionBtn red'>Cancel booking</button>
                      
                    </div>
                  </div>
                  
              </div>
              {/* Price details & payment ends */}

            </div>
        </div>
        {/* mybooking container ends */}

        <Footer/>
    </>
  )
}

export default BookingFlightDetail1
