import React, { useState,useEffect } from "react";
import HeaderUser from "../../../components/HeaderUser";
import Footer from "../../../components/Footer";
import AgencyLeftMenu from "../../../components/Profile/AgencyLeftMenu";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import {getStorageData,apiBaseUrl} from '../../../global';
//import { Link } from "react-router-dom";

const MyAgency = () => {

    
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [isContactShow, setIsContactShow] = useState(false);
    
  
    const toggleInfoVisibility = () => {
      setIsInfoVisible(!isInfoVisible);
    };
  
    const toggleContactShow = () => {
      setIsContactShow(!isContactShow);
    };

    var initial={profile_img:"",name:"",logo:"",dob:"",mname:"",gender:"",email:"",title:"",fname:"",lname:"",mobile:"",agency:"",country:"",state:"",city:"",zipcode:"",address:"",website:"",comment:"",consortium:"",IATA:"",CLIA:"",ASTA:"",reservation:"",roleId:"",ownerId:"",businessLicense:"",einTax:"",website_id:"",parent_id:"",cId:"",company_name:"",last_login:""};
  const [profileData, setProfileData] = useState(initial);
  const [msg, setmsg] = useState('');

    useEffect(() => {

        // console.log(getStorageData('userSession'));
         //debugger;
          let searchrq={website: "1",userId:getStorageData('userSession')?.id};
       var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        var sestting = {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify(searchrq),
          redirect: 'follow'
        };
        const url = apiBaseUrl + '/api/myagency';
        fetch(url, sestting)
          // fetch('response.json', requestOptions)
            .then(response => response.json())
            .then(result => {
            setProfileData(result.profile);
            }).catch(error => console.log(error));
      //console.log(validateRq);
      //debugger;
      //console.log(website);
      },[]);

      const handleForm2 = (event) => {
        const name = event.target.name;
          const value = event.target.value;
          setProfileData(values => ({...values, [name]: value}))
      }
      const handlelogo=(e)=>{
        // setprofileImg(e.target.files[0]);
         const name = e.target.name;
          const value = e.target.files[0];
         // //console.log()
          setProfileData(values => ({...values, [name]: value}))
       }

       const handleSubmit1 = (event) => {
        event.preventDefault();
      
        
        var myHeaders = new Headers();
        var postdata={...profileData};
        //console.log(profileData);
        let formData = new FormData();
        formData.append('id', postdata.id);
        formData.append('address', postdata.address);   
        formData.append('country', postdata.country);  
        formData.append('mobile', postdata.mobile);
        formData.append('email', postdata.email);
        formData.append('logo', postdata.logo); 
      
        const url = apiBaseUrl + '/api/myagencycontact';
        var requestOptions = {
          method: 'POST',
            headers: myHeaders,
            body: formData,
          redirect: 'follow'
        };
        fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if(result.status==='OK'){
            setmsg(result.message);
            setIsContactShow(false);
          }else{
            setIsContactShow(false);
            setmsg('Oops something went wrong please try again!');
          }
      
        }
        ); 
      
       
      
      }

  
  return (
    <>
      <HeaderUser />

      {/* <!-- form container start --> */}
      <div id="profileCntr">
        <div class="container"> 
        {/* <!--  / Profile  container \ --> */}
        
        <div class="row">
            <div class="col-sm-12 row-flex"> 

            {/* <!--  / Left container Start here \ --> */}
            <AgencyLeftMenu/>
            {/* <!--  / Left container Start ends \ --> */}
            
            {/* <!--  / Right container Start here \ --> */}
            <div class="rightCntr prof-section" id="myinformation" >
                <div id="myinfoTab"> 
                
                <h2 class="main_title">My Company</h2>
                
                <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
                    <Link underline="hover" color="inherit" to="/profile">
                      Profile
                    </Link>
                    <Typography color="text.primary">My Company</Typography>
                </Breadcrumbs>

                {/* <!-- My detail tab Start here --> */}
                <div> 
                    {/* <!--  / company Information Start here \ --> */}
                    
                    <div class="formBox">
                    <div class="row">
                        <div class="col-sm-12 title">
                        <h2>Agency data</h2>
                        {/* <Link class="edit" onClick={toggleInfoVisibility} to=""> Edit</Link> */}
                        {/* <a class="edit personal_infoform" href="/"> Edit</a>  */}
                        </div>
                    </div>
                    
                    <div id="personal_infoform" style={{ display: isInfoVisible ? 'block' : 'none' }}>
                    
                        
                        <div class="row form-group">
                            <div class="col-sm-6">
                            <label class="lable">Customer number<sup class="star">*</sup></label>
                            <input class="textbox textOnly" data-val="true" maxlength="50" name="Customer number" type="text" value="Customer number"/>
                            </div>
                            <div class="col-sm-6">
                            <label class="lable">Name of the travel agency Chain</label>
                            <input class="textbox textOnly" name="Name" type="text" value=""/>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-sm-6">
                            <label class="lable">Managing director<sup class="star">*</sup></label>
                            <input class="textbox textOnly" name="Name" type="text" value="Raj"/>
                            </div>
                        
                        </div>
                        
                        
                        <button class="button float-end mt_mob" type="button" >Save</button>
                    
                        <button class="button float-end grayBtn mt_mob" type="button">cancel</button>
                    </div>
                    
                    
                    
                    {/* <!-- Company information start --> */}
                    <div id="personal_infoDetail" style={{ display: isInfoVisible ? 'none' : 'block' }}>
                        <ul class="content_detail"> 
                        <li><span class="label">Acount number :</span><span>{profileData?.accountId}</span></li>
                        <li><span class="label">Name of the travel agency Chain :</span> <span>{profileData?.agency}</span></li>
                        <li><span class="label">Managing director / Owner : </span> <span>{profileData?.fname} {profileData?.lname}</span></li>
                        
                        </ul>
                    </div>
                    {/* <!-- Company information end -->  */}
                    </div>
                    
                    
                    {/* <!--  / Address, phone number, etc. Start here \ --> */}
                    <form onSubmit={handleSubmit1} method="post" >
                    <div class="formBox">
                    <div class="row">
                        <div class="col-sm-12 title">
                        <h2>Address, phone number, etc.</h2>
                        <Link class="edit contact_infoform" onClick={toggleContactShow} to=""> Edit</Link>
                        {/* <a class="edit contact_infoform" href="/"> Edit</a> */}
                         </div>
                    </div>
                    {/* <!-- Edit Mode For Contact information --> */}
                    <div id="contact_infoform" style={{ display: isContactShow ? 'block' : 'none' }}>
                    
                        <div class="row form-group">
                            {/* <div class="col-sm-6">
                            <label class="lable">Street<sup class="star">*</sup></label>
                            <input class="textbox" name="Street" type="text" value=""/>
                            </div> */}

                            <div class="col-sm-6">
                          <label class="lable">Street<sup class="star">*</sup></label>
                          <input class="textbox" onChange={(e)=>handleForm2(e)} name="address" type="text" value={profileData?.address}/>
                         </div>
                            
                            <div class="col-sm-6">
                          <label class="lable">Country<sup class="star">*</sup></label>
                          <div class="select_dropdown">
                            <select class="Select Country" onChange={(e)=>handleForm2(e)} value={profileData?.country} name="country">
                            <option value="US">United States of America</option>
     <option value="AF">Afghanistan</option>
     <option value="AL">Albania</option>
     <option value="DZ">Algeria</option>
     <option value="AS">American Samoa</option>
     <option value="AD">Andorra</option>
     <option value="AO">Angola</option>
     <option value="AI">Anguilla</option>
     <option value="AQ">Antarctica</option>
     <option value="AG">Antigua and Barbuda</option>
     <option value="AR">Argentina</option>
     <option value="AM">Armenia</option>
     <option value="AW">Aruba</option>
     <option value="AU">Australia</option>
     <option value="AT">Austria</option>
     <option value="AZ">Azerbaijan</option>
     <option value="BS">Bahamas</option>
     <option value="BH">Bahrain</option>
     <option value="BD">Bangladesh</option>
     <option value="BB">Barbados</option>
     <option value="BY">Belarus</option>
     <option value="BE">Belgium</option>
     <option value="BZ">Belize</option>
     <option value="BJ">Benin</option>
     <option value="BM">Bermuda</option>
     <option value="BT">Bhutan</option>
     <option value="BO">Bolivia (Plurinational State of)</option>
     <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
     <option value="BA">Bosnia and Herzegovina</option>
     <option value="BW">Botswana</option>
     <option value="BV">Bouvet Island</option>
     <option value="BR">Brazil</option>
     <option value="IO">British Indian Ocean Territory</option>
     <option value="BN">Brunei Darussalam</option>
     <option value="BG">Bulgaria</option>
     <option value="BF">Burkina Faso</option>
     <option value="BI">Burundi</option>
     <option value="CV">Cabo Verde</option>
     <option value="KH">Cambodia</option>
     <option value="CM">Cameroon</option>
     <option value="CA">Canada</option>
     <option value="KY">Cayman Islands (the)</option>
     <option value="CF">Central African Republic (the)</option>
     <option value="TD">Chad</option>
     <option value="CL">Chile</option>
     <option value="CN">China</option>
     <option value="CX">Christmas Island</option>
     <option value="CC">Cocos (Keeling) Islands</option>
     <option value="CO">Colombia</option>
     <option value="KM">Comoros</option>
     <option value="CD">Congo (the Democratic Republic of the)</option>
     <option value="CG">Congo</option>
     <option value="CK">Cook Islands</option>
     <option value="CR">Costa Rica</option>
     <option value="HR">Croatia</option>
     <option value="CU">Cuba</option>
     <option value="CW">Cura?ao</option>
     <option value="CY">Cyprus</option>
     <option value="CZ">Czechia</option>
     <option value="CI">C?te d'Ivoire</option>
     <option value="DK">Denmark</option>
     <option value="DJ">Djibouti</option>
     <option value="DM">Dominica</option>
     <option value="DO">Dominican Republic (the)</option>
     <option value="EC">Ecuador</option>
     <option value="EG">Egypt</option>
     <option value="SV">El Salvador</option>
     <option value="GQ">Equatorial Guinea</option>
     <option value="ER">Eritrea</option>
     <option value="EE">Estonia</option>
     <option value="SZ">Eswatini</option>
     <option value="ET">Ethiopia</option>
     <option value="FK">Falkland Islands[Malvinas]</option>
     <option value="FO">Faroe Islands</option>
     <option value="FJ">Fiji</option>
     <option value="FI">Finland</option>
     <option value="FR">France</option>
     <option value="GF">French Guiana</option>
     <option value="PF">French Polynesia</option>
     <option value="TF">French Southern Territories (the)</option>
     <option value="GA">Gabon</option>
     <option value="GM">Gambia</option>
     <option value="GE">Georgia</option>
     <option value="DE">Germany</option>
     <option value="GH">Ghana</option>
     <option value="GI">Gibraltar</option>
     <option value="GR">Greece</option>
     <option value="GL">Greenland</option>
     <option value="GD">Grenada</option>
     <option value="GP">Guadeloupe</option>
     <option value="GU">Guam</option>
     <option value="GT">Guatemala</option>
     <option value="GG">Guernsey</option>
     <option value="GN">Guinea</option>
     <option value="GW">Guinea-Bissau</option>
     <option value="GY">Guyana</option>
     <option value="HT">Haiti</option>
     <option value="HM">Heard Island and McDonald Islands</option>
     <option value="VA">Holy See</option>
     <option value="HN">Honduras</option>
     <option value="HK">Hong Kong</option>
     <option value="HU">Hungary</option>
     <option value="IS">Iceland</option>
     <option value="IN">India</option>
     <option value="ID">Indonesia</option>
     <option value="IR">Iran (Islamic Republic of)</option>
     <option value="IQ">Iraq</option>
     <option value="IE">Ireland</option>
     <option value="IM">Isle of Man</option>
     <option value="IL">Israel</option>
     <option value="IT">Italy</option>
     <option value="JM">Jamaica</option>
     <option value="JP">Japan</option>
     <option value="JE">Jersey</option>
     <option value="JO">Jordan</option>
     <option value="KZ">Kazakhstan</option>
     <option value="KE">Kenya</option>
     <option value="KI">Kiribati</option>
     <option value="KP">Korea (the Democratic People's Republic of)</option>
     <option value="KR">Korea (the Republic of)</option>
     <option value="KW">Kuwait</option>
     <option value="KG">Kyrgyzstan</option>
     <option value="LA">Lao People's Democratic Republic</option>
     <option value="LV">Latvia</option>
     <option value="LB">Lebanon</option>
     <option value="LS">Lesotho</option>
     <option value="LR">Liberia</option>
     <option value="LY">Libya</option>
     <option value="LI">Liechtenstein</option>
     <option value="LT">Lithuania</option>
     <option value="LU">Luxembourg</option>
     <option value="MO">Macao</option>
     <option value="MG">Madagascar</option>
     <option value="MW">Malawi</option>
     <option value="MY">Malaysia</option>
     <option value="MV">Maldives</option>
     <option value="ML">Mali</option>
     <option value="MT">Malta</option>
     <option value="MH">Marshall Islands</option>
     <option value="MQ">Martinique</option>
     <option value="MR">Mauritania</option>
     <option value="MU">Mauritius</option>
     <option value="YT">Mayotte</option>
     <option value="MX">Mexico</option>
     <option value="FM">Micronesia (Federated States of)</option>
     <option value="MD">Moldova (the Republic of)</option>
     <option value="MC">Monaco</option>
     <option value="MN">Mongolia</option>
     <option value="ME">Montenegro</option>
     <option value="MS">Montserrat</option>
     <option value="MA">Morocco</option>
     <option value="MZ">Mozambique</option>
     <option value="MM">Myanmar</option>
     <option value="NA">Namibia</option>
     <option value="NR">Nauru</option>
     <option value="NP">Nepal</option>
     <option value="NL">Netherlands</option>
     <option value="NC">New Caledonia</option>
     <option value="NZ">New Zealand</option>
     <option value="NI">Nicaragua</option>
     <option value="NE">Niger</option>
     <option value="NG">Nigeria</option>
     <option value="NU">Niue</option>
     <option value="NF">Norfolk Island</option>
     <option value="MP">Northern Mariana Islands</option>
     <option value="NO">Norway</option>
     <option value="OM">Oman</option>
     <option value="PK">Pakistan</option>
     <option value="PW">Palau</option>
     <option value="PS">Palestine, State of</option>
     <option value="PA">Panama</option>
     <option value="PG">Papua New Guinea</option>
     <option value="PY">Paraguay</option>
     <option value="PE">Peru</option>
     <option value="PH">Philippines</option>
     <option value="PN">Pitcairn</option>
     <option value="PL">Poland</option>
     <option value="PT">Portugal</option>
     <option value="PR">Puerto Rico</option>
     <option value="QA">Qatar</option>
     <option value="MK">Republic of North Macedonia</option>
     <option value="RO">Romania</option>
     <option value="RU">Russian Federation (the)</option>
     <option value="RW">Rwanda</option>
     <option value="RE">Runion</option>
     <option value="BL">Saint Barthlemy</option>
     <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
     <option value="KN">Saint Kitts and Nevis</option>
     <option value="LC">Saint Lucia</option>
     <option value="MF">Saint Martin (French part)</option>
     <option value="PM">Saint Pierre and Miquelon</option>
     <option value="VC">Saint Vincent and the Grenadines</option>
     <option value="WS">Samoa</option>
     <option value="SM">San Marino</option>
     <option value="ST">Sao Tome and Principe</option>
     <option value="SA">Saudi Arabia</option>
     <option value="SN">Senegal</option>
     <option value="RS">Serbia</option>
     <option value="SC">Seychelles</option>
     <option value="SL">Sierra Leone</option>
     <option value="SG">Singapore</option>
     <option value="SX">Sint Maarten (Dutch part)</option>
     <option value="SK">Slovakia</option>
     <option value="SI">Slovenia</option>
     <option value="SB">Solomon Islands</option>
     <option value="SO">Somalia</option>
     <option value="ZA">South Africa</option>
     <option value="GS">South Georgia and the South Sandwich Islands</option>
     <option value="SS">South Sudan</option>
     <option value="ES">Spain</option>
     <option value="LK">Sri Lanka</option>
     <option value="SD">Sudan</option>
     <option value="SR">Suriname</option>
     <option value="SJ">Svalbard and Jan Mayen</option>
     <option value="SE">Sweden</option>
     <option value="CH">Switzerland</option>
     <option value="SY">Syrian Arab Republic</option>
     <option value="TW">Taiwan (Province of China)</option>
     <option value="TJ">Tajikistan</option>
     <option value="TZ">Tanzania, United Republic of</option>
     <option value="TH">Thailand</option>
     <option value="TL">Timor-Leste</option>
     <option value="TG">Togo</option>
     <option value="TK">Tokelau</option>
     <option value="TO">Tonga</option>
     <option value="TT">Trinidad and Tobago</option>
     <option value="TN">Tunisia</option>
     <option value="TR">Turkey</option>
     <option value="TM">Turkmenistan</option>
     <option value="TC">Turks and Caicos Islands</option>
     <option value="TV">Tuvalu</option>
     <option value="UG">Uganda</option>
     <option value="UA">Ukraine</option>
     <option value="AE">United Arab Emirates</option>
     <option value="GB">United Kingdom of Great Britain and Northern Ireland</option>
     <option value="UM">United States Minor Outlying Islands</option>
     <option value="UY">Uruguay</option>
     <option value="UZ">Uzbekistan</option>
     <option value="VU">Vanuatu</option>
     <option value="VE">Venezuela (Bolivarian Republic of)</option>
     <option value="VN">Viet Nam</option>
     <option value="VG">Virgin Islands (British)</option>
     <option value="VI">Virgin Islands (U.S.)</option>
     <option value="WF">Wallis and Futuna</option>
     <option value="EH">Western Sahara</option>
     <option value="YE">Yemen</option>
     <option value="ZM">Zambia</option>
     <option value="ZW">Zimbabwe</option>
     <option value="AX">Land Islands</option>
                            
                            </select>
                           </div>
                        </div>
                            
                        
                        </div>
                        <div class="row form-group">
                            
                            {/* <div class="col-sm-6">
                            <label class="lable">Mobile</label>
                            <input class="textbox numbersOnly" data-val-length-max="10" data-val-length-min="10" id="Mobile" maxlength="10" name="Mobile" type="text" value=""/>
                            </div> */}

                            <div class="col-sm-6">
                          <label class="lable">Mobile</label>
                          <input class="textbox numbersOnly" data-val-length-max="10" data-val-length-min="10" onChange={(e)=>handleForm2(e)}  id="Mobile" maxlength="10" name="mobile" type="text" value={profileData?.mobile} />
                         </div>
                            <div class="col-sm-6">
                            <label class="lable">Email<sup class="star">*</sup> </label>
                            <input class="textbox" onChange={(e)=>handleForm2(e)}  name="email" type="text" value={profileData?.email}/>
                            </div>
                        
                        </div>
                        
                        <div class="row form-group">
                            
                            {/* <div class="col-sm-6">
                            <label class="lable">Homepage</label>
                            <input class="textbox"  onChange={(e)=>handleForm2(e)}  name="homepage" type="text" value=""/>
                            </div> */}

                         {/* <div class="col-sm-6">
                        <label class="lable">Profile Image</label>
                        <div class="input-group">
                            <input name="profile_img" onChange={(e)=>handlelogo(e)}  type="file" class="form-control" ></input><button class="btn btn-outline-secondary uploadbtn" type="button">Upload</button>
                            
                          </div>
                          <span id="tsa"><img width={40} height={50} src={apiBaseUrl+'/public/admin_assets/adImages/'+profileData?.profile_img} /> </span>
                          </div> */}
                            <div class="col-sm-6">
                            <label class="lable">Our Logo<sup class="star">*</sup></label>
                                <div class="input-group">
                                    <input type="file" name="logo" onChange={(e)=>handlelogo(e)} class="form-control" aria-label="Upload"/>
                                    <button class="btn btn-outline-secondary uploadbtn" type="button" id="inputGroupFileAddon04">Upload</button>
                                </div>
                                                    
                            </div>
                        </div>
                        <button class="button float-end mt_mob" type="submit"   >Save </button>
                        <button class="button float-end grayBtn mt_mob" type="button">cancel</button>
                        
                    </div>
                    {/* <!-- Edit Mode For Contact information -->  */}
                    
                    
                    {/* <!-- Contact information start --> */}
                    <div id="contact_infoDetail" style={{ display: isContactShow ? 'none' : 'block' }}>
                    <div  class="alert alert-danger msgdiv">{msg}</div>
                        <ul class="content_detail">
                        <li><span class="label">Street :</span> {profileData?.address} </li>
                        <li><span class="label">Country / Post code / Town  :</span> <span>{profileData?.country} - {profileData?.zipcode} {profileData?.city}</span></li>
                      
                      {/* <li><span class="label">Country   : </span> <span id="cntry">{profileData?.country}</span></li> */}
                      {/* <li><span class="label">Contact Phone   : </span> <span id="ccontact">-</span></li> */}
                      <li><span class="label">Contact Phone    : </span> <span id="ccontact">{profileData?.mobile}</span></li>
                      <li><span class="label">Email     : </span> <span id="cemail">{profileData?.email}</span></li>

                        {/* <li><span class="label">Street :</span> <span>707 S GRADY WAY SUITE 600</span></li> */}
                        
                        
                       
                        <li><span class="label">Homepage   : <Link class="forgotlink" to="/">b2b.flyustravels.com</Link> </span> </li>
                        {/* <li><span class="label">Our Logo   : </span> <span>FLYUSTRAVELS.COM</span></li> */}
                        </ul>
                    </div>
                    {/* <!-- Contact information end -->  */}
                    </div>
                    </form>
                    {/* <!--  \ Address, phone number End here / -->  */}
                </div>
                
                
                {/* <!--  / Our opening times Start here \ --> */}
                    <div class="formBox">
                    <div class="row">
                        <div class="col-sm-12 title">
                        <h2>Our opening times</h2>
                        {/* <a class="edit contact_infoform" href="/"> Edit</a>  */}
                        </div>
                    </div>
                    {/* <!-- Edit Mode For Contact information --> */}
                    <div id="ouropening_infoform" style={{display:"none"}}>
                    <div class="table-responsive">
                                <table class="table table-bordered table-striped" style={{width: "80%"}}>
                                <thead>
                                    <tr>
                                    <th>Day</th>
                                    <th>available from</th>
                                    <th>available until</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td>Monday</td>
                                    <td> 
                                        <div class="select_dropdown">
                                        <select class="Select" name="Country">
                                        <option>06:00</option>
                                        <option>06:30</option>
                                        <option>07:00</option>
                                        <option>07:30</option>
                                        <option>08:00</option>
                                        
                                        </select>
                                        </div>
                                        </td>
                                    <td> 
                                    <div class="select_dropdown">
                                        <select class="Select" name="Country">
                                        <option>06:00</option>
                                        <option>06:30</option>
                                        <option>07:00</option>
                                        <option>07:30</option>
                                        <option>08:00</option>
                                        
                                        </select>
                                        </div> 
                                        </td>
                                    </tr>
                                    <tr>
                                    <td>Tuesday</td>
                                    <td> 
                                        <div class="select_dropdown">
                                        <select class="Select" name="Country">
                                        <option>06:00</option>
                                        <option>06:30</option>
                                        <option>07:00</option>
                                        <option>07:30</option>
                                        <option>08:00</option>
                                        
                                        </select>
                                        </div>
                                        </td>
                                    <td> 
                                    <div class="select_dropdown">
                                        <select class="Select" name="Country">
                                        <option>06:00</option>
                                        <option>06:30</option>
                                        <option>07:00</option>
                                        <option>07:30</option>
                                        <option>08:00</option>
                                        
                                        </select>
                                        </div> 
                                        </td>
                                    </tr>
                                    <tr>
                                    <td>Wednesday</td>
                                    <td> 
                                        <div class="select_dropdown">
                                        <select class="Select" name="Country">
                                        <option>06:00</option>
                                        <option>06:30</option>
                                        <option>07:00</option>
                                        <option>07:30</option>
                                        <option>08:00</option>
                                        
                                        </select>
                                        </div>
                                        </td>
                                    <td> 
                                    <div class="select_dropdown">
                                        <select class="Select" name="Country">
                                        <option>06:00</option>
                                        <option>06:30</option>
                                        <option>07:00</option>
                                        <option>07:30</option>
                                        <option>08:00</option>
                                        
                                        </select>
                                        </div> 
                                        </td>
                                    </tr>
                                    <tr>
                                    <td>Thursday</td>
                                    <td> 
                                        <div class="select_dropdown">
                                        <select class="Select" name="Country">
                                        <option>06:00</option>
                                        <option>06:30</option>
                                        <option>07:00</option>
                                        <option>07:30</option>
                                        <option>08:00</option>
                                        
                                        </select>
                                        </div>
                                        </td>
                                    <td> 
                                    <div class="select_dropdown">
                                        <select class="Select" name="Country">
                                        <option>06:00</option>
                                        <option>06:30</option>
                                        <option>07:00</option>
                                        <option>07:30</option>
                                        <option>08:00</option>
                                        
                                        </select>
                                        </div> 
                                        </td>
                                    </tr>
                                    <tr>
                                    <td>Friday</td>
                                    <td> 
                                        <div class="select_dropdown">
                                        <select class="Select" name="Country">
                                        <option>06:00</option>
                                        <option>06:30</option>
                                        <option>07:00</option>
                                        <option>07:30</option>
                                        <option>08:00</option>
                                        
                                        </select>
                                        </div>
                                        </td>
                                    <td> 
                                    <div class="select_dropdown">
                                        <select class="Select" name="Country">
                                        <option>06:00</option>
                                        <option>06:30</option>
                                        <option>07:00</option>
                                        <option>07:30</option>
                                        <option>08:00</option>
                                        
                                        </select>
                                        </div> 
                                        </td>
                                    </tr>
                                    <tr>
                                    <td>Saturday</td>
                                    <td> 
                                        <div class="select_dropdown">
                                        <select class="Select" name="Country">
                                        <option>06:00</option>
                                        <option>06:30</option>
                                        <option>07:00</option>
                                        <option>07:30</option>
                                        <option>08:00</option>
                                        
                                        </select>
                                        </div>
                                        </td>
                                    <td> 
                                    <div class="select_dropdown">
                                        <select class="Select" name="Country">
                                        <option>06:00</option>
                                        <option>06:30</option>
                                        <option>07:00</option>
                                        <option>07:30</option>
                                        <option>08:00</option>
                                        
                                        </select>
                                        </div> 
                                        </td>
                                    </tr>
                                    <tr>
                                    <td>Sunday</td>
                                    <td> 
                                        <div class="select_dropdown">
                                        <select class="Select" name="Country">
                                        <option>06:00</option>
                                        <option>06:30</option>
                                        <option>07:00</option>
                                        <option>07:30</option>
                                        <option>08:00</option>
                                        
                                        </select>
                                        </div>
                                        </td>
                                    <td> 
                                    <div class="select_dropdown">
                                        <select class="Select" name="Country">
                                        <option>06:00</option>
                                        <option>06:30</option>
                                        <option>07:00</option>
                                        <option>07:30</option>
                                        <option>08:00</option>
                                        
                                        </select>
                                        </div> 
                                        </td>
                                    </tr>
                                </tbody>
                                </table>
                                </div>
                                
                                <div style={{width:"80%"}}>
                                <div class="row form-group">
                            
                            <div class="col-sm-12">
                            <label class="lable">Emergency phone number</label>
                            <input class="textbox" type="text" value=""/>
                            </div>
                            
                        </div>
                        
                        
                            <div class="row form-group">
                            
                            <div class="col-sm-12">
                            <label class="lable">Note</label>
                            <input class="textbox" type="text" value=""/>
                            </div>
                            
                        </div>
                        
                        <button class="button float-end mt_mob" type="button" >Save </button>
                        <button class="button float-end grayBtn mt_mob" type="button">cancel</button>
                        </div>
                        
                    </div>
                    {/* <!-- Edit Mode For Contact information -->  */}
                    
                    
                    {/* <!-- Contact information start --> */}
                    <div id="contact_infoDetail">
                        <div class="tabContent2 table-responsive">
                                <table class="table table-bordered table-striped" style={{width: "80%"}}>
                                <thead>
                                    <tr class="aer-hidden-xs">
                                    <th><strong>Day</strong></th>
                                    <th><strong>Available from</strong></th>
                                    <th><strong>Available until</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td>Monday</td>
                                    <td> 06:00 </td>
                                    <td> 23:30 </td>
                                    </tr>
                                    <tr>
                                    <td>Tuesday</td>
                                    <td> 06:00 </td>
                                    <td> 23:30 </td>
                                    </tr>
                                    <tr>
                                    <td>Wednesday</td>
                                    <td> 06:00 </td>
                                    <td> 23:30 </td>
                                    </tr>
                                    <tr>
                                    <td>Thursday</td>
                                    <td> 06:00 </td>
                                    <td> 23:30 </td>
                                    </tr>
                                    <tr>
                                    <td>Friday</td>
                                    <td> 06:00 </td>
                                    <td> 23:30 </td>
                                    </tr>
                                    <tr>
                                    <td>Saturday</td>
                                    <td> 06:00 </td>
                                    <td> 23:30 </td>
                                    </tr>
                                    <tr>
                                    <td>Sunday</td>
                                    <td> 06:00 </td>
                                    <td> 23:30 </td>
                                    </tr>
                                </tbody>
                                </table>
                                </div>
                    </div>
                    {/* <!-- Contact information end -->  */}
                    </div>
                    {/* <!--  \ Our opening times End here / -->  */}
                
                {/* <!-- My detail tab Start here -->  */}
                
                </div>
            </div>
            {/* <!-- End My Information--> */}
            
            </div>
        </div>
        </div>
        
    {/* <!--  / Profile  container \ -->  */}
    </div>
    {/* <!-- form container end --> */}

      <Footer />
    </>
  );
};

export default MyAgency;
