import React from "react";
import HeaderUser from "../../../components/HeaderUser";
import Footer from "../../../components/Footer";
import AgencyLeftMenu from "../../../components/Profile/AgencyLeftMenu";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
//import { Link } from "react-router-dom";

const BankDetail = () => {
  
  return (
    <>
      <HeaderUser />

      {/* <!-- form container start --> */}
      <div id="profileCntr">
        <div class="container"> 
        {/* <!--  / Profile  container \ --> */}
        
        <div class="row">
            <div class="col-sm-12 row-flex"> 

            {/* <!--  / Left container Start here \ --> */}
            <AgencyLeftMenu/>
            {/* <!--  / Left container Start ends \ --> */}
            
            {/* <!--  / Right container Start here \ --> */}
            <div class="rightCntr prof-section" id="myinformation" >
            <div id="myinfoTab"> 
              
             <h2 class="main_title">Bank details and contract data</h2>
			 
             <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
                <Link underline="hover" color="inherit" to="/profile">
                  Profile
                </Link>
                <Typography color="text.primary">Bank details and contract data</Typography>
              </Breadcrumbs>

              {/* <!-- My detail tab Start here --> */}
              <div> 
                {/* <!--  / company Information Start here \ --> */}
                <div class="formBox">
                  <div class="row">
                    <div class="col-sm-12">
					<div class="bankInformation">
                      <h2>Payment method and bank details</h2>
					  
					  <p>our chosen method of payment: Cash payment</p>
					  
					  <h2>My credit card agreement</h2>
					  
					  <p>By accepting your contract, you have simultaneously concluded a credit card agreement with us. By doing so, you have confirmed to us that you accept the IATA regulations as amended from time to time, in particular the section on credit card payment transactions in the BSP Manual for Agents Chapter 14 Local Procedures in the BSP - Germany.</p>
                        <h2>Your contract with Picassotravel</h2>
                        <p>You signed your agency contract with us on: 01 Nov 2021</p>
                        <h2>We are happy to help</h2>
                        <p>Do you have questions about your contract or credit card agreement? Do you need a copy of these documents?</p>
                        <p>Then please contact our agency administration.</p>


					  
					  </div>
                       </div>
                  </div>
				  
              
                  
                  
                  
                 
                </div>
               
              </div>
              
			 
			 
          </div>
		{/* <!-- End My Information--> */}
		
        </div>
            {/* <!-- End My Information--> */}
            
            </div>
        </div>
        </div>
        
    {/* <!--  / Profile  container \ -->  */}
    </div>
    {/* <!-- form container end --> */}

      <Footer />
    </>
  );
};

export default BankDetail;
