import React from "react";
import HeaderUser from "../../../components/HeaderUser";
import Footer from "../../../components/Footer";
import AgencyLeftMenu from "../../../components/Profile/AgencyLeftMenu";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
//import { Link } from "react-router-dom";

const MyColeagues = () => {
  return (
    <>
      <HeaderUser />

      {/* <!-- form container start --> */}
      <div id="profileCntr">
        <div class="container">
          {/* <!--  / Profile  container \ --> */}

          <div class="row">
            <div class="col-sm-12 row-flex">
              {/* <!--  / Left container Start here \ --> */}
              <AgencyLeftMenu />
              {/* <!--  / Left container Start ends \ --> */}

              {/* <!--  / Right container Start here \ --> */}
              <div class="rightCntr prof-section" id="myinformation">
                <div id="myinfoTab">
                  <h2 class="main_title">My colleagues</h2>

                  <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
                    <Link underline="hover" color="inherit" to="/profile">
                      Profile
                    </Link>
                    <Typography color="text.primary">My colleagues</Typography>
                  </Breadcrumbs>

                  {/* <!-- My detail tab Start here --> */}
                  <div>
                    {/* <!--  / company Information Start here \ --> */}
                    <div class="formBox">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="tabContent2 table-responsive">
                            <table
                              class="table table-bordered table-striped"
                              style={{width: "80%"}}
                            >
                              <thead>
                                <tr class="aer-hidden-xs">
                                  <th>
                                    <strong>Name</strong>
                                  </th>
                                  <th>
                                    <strong>Email</strong>
                                  </th>
                                  <th>
                                    <strong>Role</strong>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Singh, Rajdeep</td>
                                  <td> RAJDEEP@USTRAVELS.NET</td>
                                  <td> Admin</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <a
                            href="/"
                            class="colleagues_invitelink"
                            title="Invite your colleagues"
                          >
                            Please click here to invite your colleagues.
                          </a>

                          <br />

                          <div class="show_colleage">
                            <div class="row form-group">
                              <div class="col-sm-6">
                                <label class="lable">Email</label>
                                <input class="textbox" type="text" value="" />
                              </div>
                              <div class="col-sm-6">
                                <label class="lable">
                                  Role<sup class="star">*</sup>
                                </label>
                                <div class="select_dropdown">
                                  <select class="Select Country">
                                    <option>Admin</option>
                                    <option>User</option>
                                    <option>Sub-Agent</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <button
                              class="button float-end mt_mob"
                              type="button"
                            >
                              Send invitation{" "}
                            </button>
                            <button
                              class="button float-end grayBtn mt_mob"
                              type="button"
                            >
                              cancel
                            </button>

                            <div class="clearfix"></div>
                            <div class="qa">
                              <h3>Who's who?</h3>
                              <p>
                                <strong>Which role has which rights?</strong>
                              </p>
                              <p>
                                <strong>Admins </strong>are owners, managing
                                directors or office managers of your agency. In
                                addition to your own user profile, you can also
                                manage the profiles of your colleagues. This
                                includes, for example, deactivating colleagues
                                on parental leave, blocking former colleagues
                                and inviting new colleagues. Admins also have
                                access to all areas of the Cockipt website and
                                can edit the data of their agency as long as it
                                is not contract relevant.
                                <br />
                                <strong>Our tip: </strong>Each agency should
                                have two admins, so that the self-administration
                                of the agency and its users is guaranteed.
                              </p>
                              <p>
                                <strong>Users</strong> are counter agents. They
                                can only edit their own user profile and have no
                                access to the profiles of their colleagues. You
                                can use all booking functions of the website.
                                But they have only limited access to their
                                agency's data and cannot process it either.
                                Users have no access to sensitive information
                                such as sales statistics.
                              </p>
                              <p>
                                <strong>Sub-agents</strong> are users with
                                clearly limited rights, mostly trainees or
                                freelancers of an agency. They can only edit
                                their own user profile, have limited access to
                                the data of their agency and no access to
                                sensitive information such as sales statistics,
                                service fees, etc..
                                <br />
                                Sub-agents can only make reservations in the
                                cockpit booking world, but cannot issue tickets.
                                Therefore they do not have access to the order
                                wizard.
                              </p>

                              <p>
                                <strong>
                                  Please use our website with care!
                                </strong>
                              </p>
                              <p>
                                We would like to remind you that you as the
                                managing director/owner are still responsible
                                for observing the terms of the contract. You are
                                also responsible for any misconduct of your
                                employees, for misconduct of third parties
                                employed by you (e.g. freelancers) and for
                                misconduct of other third parties, should you
                                invite them to use our websites.
                                <br />
                                We therefore ask you to handle the access
                                provided with due care and, in the event of
                                changes - for example when employees leave the
                                company - to immediately block their access
                                authorisation.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- End My Information--> */}
              </div>
              {/* <!-- End My Information--> */}
            </div>
          </div>
        </div>

        {/* <!--  / Profile  container \ -->  */}
      </div>
      {/* <!-- form container end --> */}

      <Footer />
    </>
  );
};

export default MyColeagues;
