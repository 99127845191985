import React, {useState,useEffect} from 'react';
import ImagesPath from '../components/ImagesPath';
import {apiBaseUrl} from '../global';
import {  useSearchParams } from "react-router-dom";
const ResetPassword = () => {
   // resetpassword?id=2&vId=$2y$10$2j/evYzksC6JDJFed0lMpeLGgSTnsc9bM0xK2I/7DkUJEs7Z1J.om
   const initialValues={changePass:"",confirmPass:""};
   const [searchParams] = useSearchParams();
   const [posts, setPosts] = useState(initialValues);
   const [formErrors, setFormErrors] = useState({});
   const [msg, setMsg] = useState({});
   const [isSubmit, setIsSubmit] = useState(false);
   

   let id = searchParams.get("id");
    let vId = searchParams.get("vId");

    const validate=(posts)=>{
      const errors={};
      
      if(!posts.changePass)
      {
        errors.changePass='New Password is required';
        
      }
      else if(posts.changePass.length<=5)
      {
        errors.changePass='Password must be greater than 5 character';
      }
      if(!posts.confirmPass)
      {
        errors.confirmPass='Confirm Password is required';
      }
      else if(posts.changePass!==posts.confirmPass)
      {
        errors.changePass='Password does not match';
      }
      return errors;
  }
 

    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setPosts(values => ({...values, [name]: value}));
      ///setFormErrors(validate(posts)); 
      setIsSubmit(false);
          
    }
   
    const handleSubmit = (event) => {
        event.preventDefault();
        var postdata={...posts};
        setFormErrors(validate(postdata)); 
        setIsSubmit(true);
       
      
       }
   
       useEffect(()=>{
        if(Object.keys(formErrors).length===0 && isSubmit) {
          var myHeaders = new Headers();
          var postdata={...posts};
          let formData = new FormData();
          formData.append('id', id);
          formData.append('vId', vId);  
          formData.append('changePass', postdata.changePass);   
               const url = apiBaseUrl + '/api/changeforgotPass';
               var requestOptions = {
                 method: 'POST',
                 headers: myHeaders,
                 body: formData,
                 redirect: 'follow'
               };
               fetch(url, requestOptions)
               .then((response)=>response.json())
               .then((result)=>{
                 setMsg(result); 
                
               }
               ); 
           }
       },[formErrors,posts,isSubmit]);



  return (
    <>
    <div id='ResetPassPopup'>
          
          <div className='ResetBlock'>
            <div className='logo mb-5'><img src={ImagesPath.logo} alt="logo" /></div>
            
            <div id="formCntr">
              {/* <!-- form box start --> */}
              <div class="formBox">
              {msg.msg && <span className="msg">{msg.msg}</span>}
                  <h4>Reset your Password.</h4>
                  <form action="#" onSubmit={handleSubmit}>
                      <fieldset>
                          
                          <div class="row">
                              <div class="col-sm-12 form_col">
                                  <div class="form-floating">
                                      <input class="form-control input" id="changePass" name="changePass" onChange={(event)=>handleChange(event)} value={posts.changePass || ""} type="password" required placeholder="Password"/>
                                      <label for="floatingInputValue">Change Password</label>
                                      {formErrors.changePass && <span className="error">{formErrors.changePass}</span>}
                                  </div>
                              </div>
                          </div>

                          <div class="row">
                              <div class="col-sm-12 form_col">
                                  <div class="form-floating">
                                      <input class="form-control input" id="confirmPass" name="confirmPass" onChange={(event)=>handleChange(event)} value={posts.confirmPass || ""} type="password" required placeholder="Password"/>
                                      <label for="floatingInputValue">Confirm Password</label>
                                      {formErrors.confirmPass && <span className="error">{formErrors.confirmPass}</span>}
                                  </div>
                              </div>
                          </div>

                          <div class="row">
                              <div class="col-sm-12 form_col d-flex justify-content-center">
                                  <button class="submitBtn" type="submit" name='submit'> Reset Your Password</button>
                              </div>
                          </div>

                      </fieldset>
                  </form>
              </div>
              {/* <!-- form box end --> */}
            </div>
             
          </div>

    </div>
    </>
  )
}

export default ResetPassword


