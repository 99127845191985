//export const apiBaseUrl='http://127.0.0.1:8000';
//export const apiBaseUrl='http://localhost/adminb2bapi';
export const apiBaseUrl='https://flyustravels.com/adminb2bapi';
export const website='flyustravels';
export const cId='FLS125';
export const engine='b2b';
export const metaname='flyustravels';

export function myHeaders()
{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    return myHeaders;
     // myHeaders.append("Key", storageData.Key);
}

export const setStorageData=((data,key)=>{
   
    let da=JSON.stringify(data);
    localStorage.setItem(key,da);
     
  });
  
  export const getStorageData=((key)=>{
    let data=localStorage.getItem(key);
         return JSON.parse(data);
  });
