import React from "react";
import HeaderUser from "../../../components/HeaderUser";
import Footer from "../../../components/Footer";
import ImagesPath from "../../../components/ImagesPath";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
//import { Link } from "react-router-dom";

const Itinerairies = () => {
  
  return (
    <>
      <HeaderUser />

      {/* <!-- form container start --> */}
      <div id="profileCntr">
        <div class="container"> 
        {/* <!--  / Profile  container \ --> */}
        
        <div class="row">
            <div class="col-sm-12 row-flex"> 

            
            {/* <!--  / Right container Start here \ --> */}
            <div id="ItinerariesBox" class="ItinerariesBox" >
				<h3 class="main_title">Itineraries</h3>
				
				<Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
					<Link underline="hover" color="inherit" to="/profile">
					Profile
					</Link>
					<Typography color="text.primary">Itineraries</Typography>
              	</Breadcrumbs>
				
				{/* <!-- Form--> */}
				<div class="formBox">
				
				<p class="text">You can filter the display by selecting departure date, file key, name, etc If you do not filter the display, the last 200 itineraries (or so called PDF tickets) will automatically be shown.</p>
				<div class="form_innerBox">
				<div class="row">
					<div class="col-md-6">
						
						<div class="row form-group">
							<div class="col-md-6">
							<label class="lable">Departure date</label>
							<div class="daterelative">
							<input class="textbox dateInput" readonly="" type="text" />
							<i class="bi bi-calendar-check"></i>
							</div>
							</div>
							
							<div class="col-md-6">
							<label class="lable visibility-hidden">&nbsp;</label>
							<div class="daterelative">
							<input class="textbox dateInput" type="text" />
							<i class="bi bi-calendar-check"></i>
							</div>
							</div>
						</div>
						{/* <!-- Row1--> */}
							<div class="row form-group">
							<div class="col-md-6">
							<label class="lable">Date of creation</label>
							<div class="daterelative">
							<input class="textbox dateInput" type="text" />
							<i class="bi bi-calendar-check"></i>
							</div>
							</div>
							
							<div class="col-md-6">
							<label class="lable visibility-hidden">&nbsp;</label>
							<div class="daterelative">
							<input class="textbox dateInput" type="text" />
							<i class="bi bi-calendar-check"></i>
							</div>
							</div>
						</div>
					{/* <!-- Row2--> */}
					</div>
					<div class="col-md-6">
						
						<div class="row form-group">
							<div class="col-md-6">
							<label class="lable">Filekey</label>
							<div class="daterelative">
							<input class="textbox" type="text" />
							
							</div>
							</div>
							
							<div class="col-md-6">
							<label class="lable">First name</label>
							<div class="daterelative">
							<input class="textbox" type="text" />
							
							</div>
							</div>
						</div>
						{/* <!-- Row1--> */}
							<div class="row form-group">
							<div class="col-md-6">
							<label class="lable">Document number</label>
							<div class="daterelative">
							<input class="textbox" type="text" />
							</div>
							</div>
							
							<div class="col-md-6">
							<label class="lable">Last name</label>
							<div class="daterelative">
							<input class="textbox" type="text" />
							</div>
							</div>
						</div>
					{/* <!-- Row2--> */}
					</div>
					
					
				</div>
				<button class="button float-end mt_mob" type="button">Save</button>
					<button class="button float-end grayBtn mt_mob" type="button">cancel</button>
				<div class="clearfix"></div>
				
				<br/>
				<br/>
					<div class="tabContent2 table-responsive">
					<table class="table table-bordered table-striped">
					<thead>
						<tr class="aer-hidden-xs">
						<th><strong>Departure date</strong></th>
						<th><strong>File key</strong></th>
						<th><strong>Document number</strong></th>
						<th><strong>Date of creation</strong></th>
						<th><strong>Name</strong></th>
						<th><strong>PDF</strong></th>
						</tr>
					</thead>
					<tbody>
						<tr>
						<td>06JUL23</td>
						<td>4UBGCF</td>
						<td>10108945</td>
						<td>02JUN23</td>
						<td>SIARI, MOHAMED-SALAH</td>
						<td><a href="/"><img class="pdficon" src={ImagesPath.PDFileicon} alt="" /></a></td>
						</tr>
						
						<tr>
						<td>06JUL23</td>
						<td>4UBGCF</td>
						<td>10108945</td>
						<td>02JUN23</td>
						<td>SIARI, MOHAMED-SALAH</td>
						<td><a href="/"><img class="pdficon" src={ImagesPath.PDFileicon} alt="" /></a></td>
						</tr>
						
						
						<tr>
						<td>06JUL23</td>
						<td>4UBGCF</td>
						<td>10108945</td>
						<td>02JUN23</td>
						<td>SIARI, MOHAMED-SALAH</td>
						<td><a href="/"><img class="pdficon" src={ImagesPath.PDFileicon} alt="" /></a></td>
						</tr>
						
						<tr>
						<td>06JUL23</td>
						<td>4UBGCF</td>
						<td>10108945</td>
						<td>02JUN23</td>
						<td>SIARI, MOHAMED-SALAH</td>
						<td><a href="/"><img class="pdficon" src={ImagesPath.PDFileicon} alt="" /></a></td>
						</tr>
						
						<tr>
						<td>06JUL23</td>
						<td>4UBGCF</td>
						<td>10108945</td>
						<td>02JUN23</td>
						<td>SIARI, MOHAMED-SALAH</td>
						<td><a href="/"><img class="pdficon" src={ImagesPath.PDFileicon} alt="" /></a></td>
						</tr>
					</tbody>
					</table>
					</div>
					</div>
					</div>
				{/* <!-- TAble--> */}
				
			</div>
            {/* <!-- End My Information--> */}
            
            </div>
        </div>
        </div>
        
    {/* <!--  / Profile  container \ -->  */}
    </div>
    {/* <!-- form container end --> */}

      <Footer />
    </>
  );
};
export default Itinerairies;
