export function urlPart(index)
{
  let hre=window.location.href;
  let arrr=hre.split('?');
  return arrr[index];
}
export function fullUrl()
{
  let hre=window.location.href;
 
  return hre;
}
export function inArray(data,array)
{
  let bool=false;
  array.forEach((da)=>{
   // console.log(da);
    if(String(da)===data)
    {
      bool=true;
    }
  })
return bool;
}
export function getStopValue(Stopes)
{
  var stopId;
  if(Stopes==="Zero")
{
   stopId=0;
}
else if(Stopes==="One")
{
   stopId=1;
}
else if(Stopes==="Two")
{
   stopId=2;
}
else if(Stopes==="Three")
{
   stopId=3;
}
else if(Stopes==="Four")
{
   stopId=4;
}
return stopId;
}
function stopCheck(iStopA,fStopA){

 if(fStopA.length<2)
 {
 let i= Math.max(...iStopA);
 let f= Math.max(...fStopA);
  if(i!==f)
  {
    return false;
  }
  else
  {
    return true;
  }
 }
 else{

 let bool =false;
  fStopA.forEach(element => {
    
    let i= Math.max(...iStopA);
    if(element===i)
    {
      bool=true;
    }

  });
return bool;

 }

}
export function airlineCheck(iAirline,filterAirline)
{
  let bool=false;
  filterAirline.forEach(element => {
   if(inArray(String(element),iAirline))
   {
    bool=true;
   }
  });
  return bool;
}
export function airportCheck(iAirport,filterAirport)
{
  let bool=false;
  filterAirport.forEach(element => {
   if(inArray(String(element),iAirport))
   {
    bool=true;
   }
  });
  return bool;
}



export function deepFilter(item,cfilter)
{
  var iairline=[];
  var iStopA=[];
  var iairport=[];
  var iPrice=parseFloat(item.FarePerAdult);
  item.Flights.forEach(ele => {
    iStopA.push(ele.Stop);
    ele.Segments.forEach(air=>{
      iairline.push(air.MktAir);
      iairport.push(air.From);
      iairport.push(air.To);

    })
  });
  if(cfilter.airport.length>0 && cfilter.airport[0]!=='')  
  {
 let stt=airportCheck(iairport,cfilter.airport);
 if(stt===false){
  return false;
      }
  }
  if(cfilter.airline.length>0 && cfilter.airline[0]!=='')  
  {
 let stt=airlineCheck(iairline,cfilter.airline);
 if(stt===false){
  return false;
      }
  }

 

  if(cfilter.price.length>0 && cfilter.price[0]!=='')  
  {
    if(iPrice<cfilter.price[0] || iPrice>cfilter.price[1])
    {
      return false;
    }
  }
  if(cfilter.stop.length>0 && cfilter.stop[0]!=='')  
  {
 let stt=stopCheck(iStopA,cfilter.stop);
 if(stt===false){
  return false;
      }
  }

  
//   if(cfilter.locality.length>0 && cfilter.locality[0]!=='')  
//   {
//           let locality="";
//          if(item.locality!=='')
//          locality=item.locality; 
//          if (cfilter.locality.indexOf(locality) === -1)
//           return false;
//   }
  


//   if(cfilter.availableMealPlan.length>0 && cfilter.availableMealPlan[0]!=='')  
//   {
//     let meal=cfilter.availableMealPlan;
//     let facilitiesData=item.facilities;
//     // console.log(facilitiesData);
//     // debugger;
//     if(mealPlanCheck(meal,facilitiesData)===false)
//     {
//       return false;
//     }
//   }

// if(cfilter.budget.length>0 && cfilter.budget[0]!=='')  
// {
//        let pricePerNight=0;
//        if(item.pricePerNight!=='')
//        pricePerNight=item.pricePerNight; 
//        if(!filterBudget(pricePerNight,cfilter.budget))
//        {
//          return false;
//        } 
// }
// if(cfilter.fareType.length>0 && cfilter.fareType[0]!=='')  
// {
//        let fareType='';
//        if(item.fareType!=='')
//        fareType=item.fareType; 
//        if (cfilter.fareType.indexOf(fareType) === -1)
//         return false;
// }

// if(cfilter.propertyType.length>0 && cfilter.propertyType[0]!=='')  
// {
//        let propertyType='';
//        if(item.propertyType!=='')
//        propertyType=item.propertyType; 
//        if (cfilter.propertyType.indexOf(propertyType) === -1)
//         return false;
// }

// if(cfilter.rating.length>0 && cfilter.rating[0]!=='')  
// {
//         let hotelRating=0;
//        if(item.hotelRating!=='')
//        hotelRating=item.hotelRating; 
//        if (cfilter.rating.indexOf(hotelRating) === -1)
//         return false;
// }



return true;

}

export function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
export const compareArrays = (a, b) =>
a.length === b.length && a.every((element, index) => element === b[index]);

export function ChangeDateFormat(checkInDate)
{
  const monthArray = ["January","February","March","April","May","June","July","August","September","October","November","December"];
     let ar=checkInDate.split('-');
     let datee=ar[1]+'/'+ar[0]+'/'+ar[2];
    const newDate=new Date(datee)
    let month=monthArray[newDate.getMonth()];
   let checKIn= ar[0]+'-'+month+'-'+ar[2];
   return checKIn;
}

export function TripAdvisorRatings(props)
{
  let rating=props.tripadvisor.rating;
   if(rating!=="")  return <h6> <strong> {props.tripadvisor.rating} </strong> Excellent <span> {props.tripadvisor.review} reviews </span> </h6>
   else
   return '';
}

export function ImageRender(props)
{
  let imageUrl=props.image;
 
  return <img src={imageUrl} alt="flight" />
}
export function Rating(props) {
  let rating=props.rate;
  //return <li><i className="fa fa-star-half-o"></i></li>;
  let starRatingArr=[];
  for(let i=1;i<=rating;i++)
  {
    starRatingArr.push(<i className="fa fa-star"></i>);  
  }
    if(rating==='1.5' || rating==='2.5' || rating==='3.5' || rating==='4.5')    
    {
      starRatingArr.push(<i className="fa fa-star-half-o"></i>);
    }
  
  return (
    <>
     <ul className="rating">
      {starRatingArr.map((val,index)=>
      <li key={'ratingKk'+index}>{val}</li>
      )
      }
     </ul>
    </>
  )
  }
  export function convertLowerCase(str)
  {
   str= String(str)
   return str.toLowerCase();
  }
export function discountPrice(price)
{
  price=parseFloat(price);
  let priceCalculated=((price*10)/100)+price;
  return priceCalculated.toFixed(2);
}

export function sortPriceDesc (array) {
  array.sort(
      (a, b) => parseFloat(b.FarePerAdult) - parseFloat(a.FarePerAdult)
  );
  // console.log('Done!');
}
export function sortPriceAsc (array) {
  array.sort(
      (a, b) => parseFloat(a.FarePerAdult) - parseFloat(b.FarePerAdult)
  );
  // console.log('Done!');
}

export function isEmptyObject(searchRq)
{
  if (Object.keys(searchRq).length === 0 && searchRq.constructor === Object) {
return true
  } else {
return false
  }
}

export const fareTypeReducer = (state, action) => {

  switch(action.type) {
    case "COMPLETE":
      return state.map((fare) => {
        if (fare.fareType === action.value) {
          return { ...fare,isSelected:!fare.isSelected};
        } else {
          return fare;
        }
      });
      case 'initial': 
      return action.payload;
    default:
      return state;
  }
};

export const getData=()=>{
    fetch('response.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
       // console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        console.log(myJson);
       
      });
  }
  export function getClass(classtype)
{
  if(classtype==='Y')
  {
    return 'Economy Class';
  }
  else if(classtype==='F')
  {
    return 'First Class';
  }
  else if(classtype==='S')
  {
    return 'Premium Economy';
  } 
  else if(classtype==='C')
  {
    return 'Business Class';
  }else{
    return 'Unknown';
  }
}
 
export function getClassShort(classtype)
{
  if(classtype==='Y')
  {
    return 'Economy';
  }
  else if(classtype==='F')
  {
    return 'First';
  }
  else if(classtype==='S')
  {
    return 'Premium';
  } 
  else if(classtype==='C')
  {
    return 'Business';
  }else{
    return 'Unknown';
  }
}

export function formatEft(eft)
{
if(eft!=='' && eft!==undefined)
{
  return eft.substring(0,2)+'H '+eft.substring(3,5)+'M';
}
}
export function getDay()
{
  let day=[{value:'',name:"Select Day"}];
  for(let i=1;i<=31;i++)
  {
    let da;
    if(i<10)
    {
      da='0'+i;
    }else{
      da=i.toString();
    }
    day.push({value:da,name:da});
  }
  return day;
}
export function getMonth()
{
    let day=[];
    day.push({value:'',name:"Select Month"},{value:'01',name:'January'},{value:'02',name:'February'},{value:'03',name:'March'},{value:'04',name:'April'},{value:'05',name:'May'},{value:'06',name:'June'},{value:'07',name:'July'},{value:'08',name:'August'},{value:'09',name:'September'},{value:'10',name:'October'},{value:'11',name:'November'},{value:'12',name:'December'});
    return day;
}
export function getCardExpiryYear()
{
  let day=[{value:'',name:"Select Year"}];
  let now = new Date();
  let newdate =now.getFullYear();
  
  //debugger;
  let lastDate=parseInt(newdate)+40;
  for(let i=newdate;i<=lastDate;i++)
  {
        day.push({value:i,name:i});
  }
  return day;
}
export function getInfantYear()
{
  let day=[{value:'',name:"Select Year"}];
  let now = new Date();
  let newdate =now.getFullYear();
  
  //debugger;
  let lastDate=parseInt(newdate)-2;
  for(let i=newdate;i<=lastDate;i++)
  {
        day.push({value:i,name:i});
  }
  return day;
}
export function validateEmail(sEmail) {
 var filter= /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
 // var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  if (filter.test(sEmail)) {
      return true;
  }
  else {
      return false;
  }
}

export function validateMobile(mobile) {
  var filter = /^[0-9]{10,15}$/;
  if (filter.test(mobile)) {
      return true;
  }
  else {
      return false;
  }
}
export function validateCreditCard(card)
{
var filter = /^[0-9]{15,16}$/;
if (filter.test(card)) {
 // alert()
      return true;
  }
  else {
      return false;
  }
}
export function validateYear(card)
{
  if(card==='0000')
  {
    return false;
  }
  var filter = /^[0-9]{4}$/;
if (filter.test(card)) {
      return true;
  }
  else {
      return false;
  }

}
export function validateCVV(cvv)
{
var filter = /^[0-9]{3,4}$/;
if (filter.test(cvv)) {
      return true;
  }else{
    return false;
  }
  
}

export function includeAirline(airline1,airline2,airline3)
{
  let allAirline='';

      let selectAir=false;
      if(airline1!=='')
      {
        selectAir=true;
        allAirline=airline1;
      }
      if(airline2!=='')
      {
        if(selectAir===true)
        {
          allAirline+=","+airline2;
        }
        else{
          allAirline=airline2;
        }
        selectAir=true;
        
      }
      if(airline3!=='')
      {
        if(selectAir===true)
        {
          allAirline+=","+airline3;
        }else{
          allAirline=airline3;
        }
        
      }
      if(allAirline==='')
      {
        return "Airline";
      }
      return allAirline;
}

export const stringToBoolean = (stringValue) => {
  switch(stringValue?.toLowerCase()?.trim()){
      case "true": 
      case "yes": 
      case "1": 
        return true;

      case "false": 
      case "no": 
      case "0": 
      case null: 
      case undefined:
        return false;

      default: 
        return stringValue;
  }
}

export const getStorageData = (key) => {
let js= localStorage.getItem(key);
return JSON.parse(js);
}