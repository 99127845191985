import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ImagesPath from '../components/ImagesPath'

const Press = () => {
  return (
    <div>
      <Header/>

      {/* <!--  / Banner container \ --> */}
      <div id="InnerBannerCntr" style={{ background:`url(${ImagesPath.press_banner})`, backgroundSize: "cover"}}>
        <div className="container">
          <div className='content'>
            <h1>Press</h1>
            <p>Explore the exciting world.</p>
          </div>
        </div>
      </div>
      {/* <!--  \ Banner container / --> */}

      {/* <!--  / about container \ --> */}
      <div id="aboutCntr">
        <div className="container">

            <div className='img'>
              <img src={ImagesPath.about_img1} alt="about_img1" />
            </div>
            <h2 className='content'>Press</h2>
            <p>Explore the exciting world of travel with FlyUS Travels's "What's New?" section. It's where you can find the newest and most interesting news in the travel business. We choose content that goes beyond the ordinary, like breaking news and special offers, so you're always up to date. Get the latest information on travel trends, new technologies, and the best things to do in a destination to make your trip more interesting.</p>
            <div className='clearfix'></div>
        </div>
        <div className="container">
          <p>We want to keep you informed about more than just the news. That's why we bring you special deals and travel tips that will make your trip better. The adventure doesn't end there, though. We also look into who has been writing about these trends. Read articles that will make you think, expert analyses, and interesting stories that will help you understand how global travel is changing. FlyUS Travels isn't just a way to see the world; it's also your friend in the story of exploration, which is always changing. Don't forget to stay tuned and inspired. Let's travel the world together.</p>
          
        </div>
      </div>
      {/* <!--  \ about container / --> */}

      <Footer/>
    </div>
  )
}

export default Press


