import React from "react";
//import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import HeaderUser from "../../components/HeaderUser";

const Profile = () => {
  
  return (
    <>
      <HeaderUser />

      {/* <!-- form container start --> */}
      <div id="profileCntr">

        {/* <!--  / Profile  container \ --> */}
        <div class="container">
          <div class="row">
            <div class="col-sm-12 row-flex">
              <div class="profile_main_page">
                <h2>My Flyus </h2>

                <ul>
                  <li>
                    <Link to="/myinformation">
                      {" "}
                      <span class="icons">
                        <i class="bi bi-person"></i>
                      </span>
                      <p>My Profile</p>
                    </Link>
                  </li>

                  <li>
                    <Link to="/myagency">
                      <span class="icons">
                        <i class="bi bi-people"></i>
                      </span>
                      <p>My Agency</p>
                    </Link>
                  </li>

                  <li>
                    <Link to="/itinerairies">
                      <span class="icons">
                        <i class="bi bi-filetype-pdf"></i>
                      </span>
                      <p>Itinerairies (PDF Tickets)</p>
                    </Link>
                  </li>

                  <li>
                    <Link to="/invoicesncreditmemos">
                      <span class="icons">
                        <i class="bi bi-receipt"></i>
                      </span>
                      <p>Invoices & Credit Memos</p>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <!--  / Profile  container \ -->  */}

      </div>
      {/* <!-- form container end --> */}

      <Footer />
    </>
  );
};

export default Profile;
